<template>
  <wrapper>
    <user-attribute-filters @select="onChallengeFilter" :show-user-type="false" class="justify-content-end"></user-attribute-filters>
    <div>Note: To avoid excessive load on our databases, we only show the latest active 100 readers below.</div>
    <div class="box box-primary">
      <div class="box-header with-border"><h3 class="box-title">Points per attribute</h3></div>
      <div class="box-body">
        <p><em>To implement a simplified <b>points per star</b> set all these to the same number. Otherwise set them differently to award different levels of accuracy.</em></p>
        <div class="row">
          <div class="col-sm-3 col-lg-2">
            <div class="form-group">
              <label for="per1StarInput">Per 1 Star</label>
              <input type="number" class="form-control" id="per1StarInput" step="1" min="0" v-model="points.per_1star" />
            </div>
          </div>
          <div class="col-sm-3 col-lg-2">
            <div class="form-group">
              <label for="per2StarInput">Per 2 Stars</label>
              <input type="number" class="form-control" id="per2StarInput" step="1" min="0" v-model="points.per_2stars" />
            </div>
          </div>
          <div class="col-sm-3 col-lg-2">
            <div class="form-group">
              <label for="per3StarInput">Per 3 Stars</label>
              <input type="number" class="form-control" id="per3StarInput" step="1" min="0" v-model="points.per_3stars"/>
            </div>
          </div>

          <div class="col-sm-3 col-lg-2">
            <div class="form-group">
              <label for="perBookReadInput">Per Book Read</label>
              <input type="number" class="form-control" id="perBookReadInput" step="1" min="0" v-model="points.per_read" />
            </div>
          </div>
          <div class="col-sm-3 col-lg-2">
            <div class="form-group">
              <label for="perMinuteInput">Per Minute of Reading <small>(rounded down)</small></label>
              <input type="number" class="form-control" id="perMinuteInput" step="1" min="0" v-model="points.per_minute" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="leaderboard-page">
      <vue-good-table
          styleClass="vgt-table striped"
          theme="auris-admin"
          :line-numbers="true"
          :columns="columns"
          :rows="rows"
          :totalRows="totalRecords"
          :pagination-options="{
            enabled: true,
            mode: 'records',
            perPage: 25,
            position: 'bottom',
            perPageDropdown: [10, 25, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page', // for 'pages' mode
            allLabel: 'All',
          }"
          :sort-options="{
            enabled: true,
            initialSortBy: { field: 'score', type: 'desc' },
          }"
      >

        <template slot="table-row" slot-scope="props">
          <template v-if="props.column.field == 'actions'">
            <button @click="goToAdminPage($event)" class="btn btn-link user-link" :data-id="props.row.id"><i class="fa fa-edit"></i> Manage</button>
            <button
                @click="beginDeleteAdmin({id: props.row.id})"
                class="btn btn-link delete" :data-id="props.row.id"
            >
              <i class="fa fa-trash"></i>Delete
            </button>
          </template>
        </template>
      </vue-good-table>
    </section>
      <p>Scoring could also:</p>
      <ul>
        <li>Reduce number of points for subsequent 3 star reads to deter children reading the same simple and short book over and over just for points.</li>
        <li>Add multipliers for "special events"; 2x on Easter Egg Hunt books, 1.5x on books higher than your suggested lexile reading level, 5x on Enid Blyton (and therefore paying for)</li>
        <li>3 stars equals >90% accuracy, maybe a 1.25x multipler if you get >98% (all words turned green)</li>
        <li>1.1x multipler if you read the entire book in 1 sitting</li>
        <li>Modify reading time points to the page count; deincentivise purposely going slow, could even make it a negative if they try to "play the system"</li>
      </ul>
  </wrapper>
</template>

<script>
import Wrapper from '@/components/Wrapper';
import api from '@/api';
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';
import UserAttributeFilters from '@/components/UserAttributeFilters';

export default {
  name: 'leaderboard',
  components: {
    Wrapper,
    VueGoodTable,
    UserAttributeFilters,
  },
  data() {
    return {
      original_rows: [],
      columns: [
        {
          label: 'Name',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Time Read',
          field: this.formatTime,
          sortable: false,
        },
        {
          label: 'Books Read',
          field: 'books_read',
          type: 'number',
          sortable: false,
        },
        {
          label: 'Stars',
          field: this.totalStars,
          type: 'number',
          sortable: false,
        },
        {
          label: 'Score',
          field: 'score',
          type: 'number',
          formatFn: this.formatScore,
        },
      ],
      totalRecords: 0,
      previousSearchQuery: null,
      leaderboard: [],
      challenge: 'all',
      points: {
        per_minute: 0.5,
        per_read: 10,
        per_1star: 5,
        per_2stars: 10,
        per_3stars: 20,
      },
    };
  },
  mounted() {
    this.$store.commit('SET_CURRENT_USER', null);
    this.$store.commit('SET_CURRENT_SCHOOL', null);
    this.$nextTick(() => {
      this.loadLeaderboard();
    });
  },
  computed: {
    rows() {
      return this.original_rows.map((r) => {
        r.score = this.makeScore(r);
        return r;
      });
    },
  },
  methods: {
    loadLeaderboard() {
      this.$store.dispatch('app/setGlobalLoading', true);
      api.get('admin/leaderboard', { params: { challenge: this.challenge } })
        .then((response) => {
          this.$store.dispatch('app/setGlobalLoading', false);
          const { leaderboard } = response.data;
          // format the description and the date.
          this.original_rows = leaderboard;
        })
        .catch((error) => {
          this.$store.dispatch('app/setGlobalLoading', false);
          this.$apiResponse.renderErrorMessage(error);
        });
    },
    formatTime(rowObj) {
      const e = rowObj.time_read;
      const h = Math.floor(e / 3600);
      const m = Math.floor((e % 3600) / 60);
      const s = Math.floor(e % 60);
      const str = [];
      str.push(h > 0 ? `${h}h` : '');
      str.push(m > 0 ? `${m}m` : '');
      str.push(s > 0 ? `${s}s` : '');

      return str.filter((ss) => ss.length > 0).join(' ');
    },
    totalStars(rowObj) {
      return rowObj.one_star + rowObj.two_stars + rowObj.three_stars;
    },
    makeScore(rowObj) {
      return 0
       + (rowObj.one_star * this.points.per_1star)
       + (rowObj.two_stars * this.points.per_2stars)
       + (rowObj.three_stars * this.points.per_3stars)
       + (rowObj.books_read * this.points.per_read)
       + Math.floor((rowObj.time_read / 60) * this.points.per_minute);
    },
    formatScore(score) {
      return (new Intl.NumberFormat('en-US')).format(score);
    },
    onChallengeFilter({ challenge }) {
      this.original_rows = [];
      this.challenge = challenge;
      this.loadLeaderboard();
    },
  },
};
</script>
