var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "form-horizontal school-form",
      attrs: { action: "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.validateForm($event)
        }
      }
    },
    [
      _c("fieldset", [
        _c("legend", [_vm._v("Information")]),
        _c(
          "div",
          {
            staticClass: "form-group",
            class: { "has-error": _vm.errors.first("name") }
          },
          [
            _c(
              "label",
              { staticClass: "col-sm-2 control-label", attrs: { for: "name" } },
              [_vm._v("Name")]
            ),
            _c("div", { staticClass: "col-sm-10 input-group" }, [
              _vm._m(0),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.mutableValue.name,
                    expression: "mutableValue.name"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  name: "name",
                  id: "name",
                  autocomplete: "off"
                },
                domProps: { value: _vm.mutableValue.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.mutableValue, "name", $event.target.value)
                  }
                }
              })
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("name"),
                    expression: "errors.has('name')"
                  }
                ],
                staticClass: "col-sm-10 col-sm-offset-2 help-block is-danger"
              },
              [_vm._v(" " + _vm._s(_vm.errors.first("name")) + " ")]
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "form-group",
            class: { "has-error": _vm.errors.first("urn") }
          },
          [
            _c(
              "label",
              { staticClass: "col-sm-2 control-label", attrs: { for: "urn" } },
              [_vm._v("URN")]
            ),
            _c("div", { staticClass: "input-group" }, [
              _vm._m(1),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.mutableValue.urn,
                    expression: "mutableValue.urn"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  name: "urn",
                  id: "urn",
                  autocomplete: "off"
                },
                domProps: { value: _vm.mutableValue.urn },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.mutableValue, "urn", $event.target.value)
                  }
                }
              })
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("urn"),
                    expression: "errors.has('urn')"
                  }
                ],
                staticClass: "col-sm-10 col-sm-offset-2 help-block is-danger"
              },
              [_vm._v(" " + _vm._s(_vm.errors.first("urn")) + " ")]
            )
          ]
        )
      ]),
      _c(
        "fieldset",
        [
          _c("legend", [_vm._v("Primary Contact")]),
          _vm.countriesLoaded
            ? _c("contact-form", {
                attrs: { countries: _vm.countries },
                on: { updated: _vm.handlePrimaryContactUpdate },
                model: {
                  value: _vm.mutableValue.primaryContact,
                  callback: function($$v) {
                    _vm.$set(_vm.mutableValue, "primaryContact", $$v)
                  },
                  expression: "mutableValue.primaryContact"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "fieldset",
        [
          _c("legend", [_vm._v("Billing Contact")]),
          _c("div", { staticClass: "form-group form-checkbox" }, [
            _c("label", { staticClass: "control-label" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.mutableValue.billingSameAsPrimary,
                    expression: "mutableValue.billingSameAsPrimary"
                  }
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.mutableValue.billingSameAsPrimary)
                    ? _vm._i(_vm.mutableValue.billingSameAsPrimary, null) > -1
                    : _vm.mutableValue.billingSameAsPrimary
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.mutableValue.billingSameAsPrimary,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.mutableValue,
                            "billingSameAsPrimary",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.mutableValue,
                            "billingSameAsPrimary",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.mutableValue, "billingSameAsPrimary", $$c)
                    }
                  }
                }
              }),
              _vm._v(" Billing address same as primary contact ")
            ])
          ]),
          !_vm.mutableValue.billingSameAsPrimary && _vm.countriesLoaded
            ? _c("contact-form", {
                attrs: { countries: _vm.countries, prefix: "billing" },
                on: { updated: _vm.handleBillingContactUpdate },
                model: {
                  value: _vm.mutableValue.billingContact,
                  callback: function($$v) {
                    _vm.$set(_vm.mutableValue, "billingContact", $$v)
                  },
                  expression: "mutableValue.billingContact"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box-footer" },
        [_c("loading-button", [_vm._v("Submit")])],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-user" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-user" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }