import RecordingsView from '@/views/Recordings';
import RecordingView from '@/views/Recording';

export default ([
  {
    path: '/recordings',
    component: RecordingsView,
    name: 'Recordings',
    meta: { description: 'Manage recordings used for ASR training & testing', requiresAuth: true, authorize: ['recordings'] },
  },
  {
    path: '/recordings/:id',
    component: RecordingView,
    name: 'Recording',
    meta: { description: 'Manage recordings used for ASR training & testing', requiresAuth: true, authorize: ['recordings'] },
  },
]);
