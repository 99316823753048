<template>
  <wrapper>
  <!-- Main content -->
    <section class="account-page">
      <div class="row">
        <div class="col-md-12">
          <div class="box box-info">
            <!-- Input Addons -->
            <div class="box-header with-border">
              <h3 class="box-title">Edit User</h3>
            </div>
            <div class="box-body">
              <account-form v-model="form" @validated="updateAccount" :apiErrors="apiErrors" v-if="!isLoading"></account-form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </wrapper>
  <!-- /.content -->
</template>

<script>
import UserMixin from '@/mixins/UserMixin';
import Wrapper from '@/components/Wrapper';
import Form from '@/classes/Form';
import AccountForm from '@/components/forms/AccountForm';

export default {
  name: 'account',
  mixins: [UserMixin],
  components: {
    AccountForm,
    'account-form': AccountForm,
    Wrapper,
  },
  data() {
    return {
      section: 'Account',
      isLoading: false,
      user: null,
      form: new Form({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        weekly_digest_optin: '',
        permissions: [],
      }),
      apiErrors: null,
      availableCollections: [],
      availablePublishers: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch('getBookResources', 'collections').then((resource) => {
        this.availableCollections = resource;
      }).catch((e) => this.$apiResponse.renderErrorMessage(e));
      this.$store.dispatch('getBookResources', 'publishers').then((resource) => {
        this.availablePublishers = resource;
      }).catch((e) => this.$apiResponse.renderErrorMessage(e));
      this.$nextTick(() => {
        this.getUser(this.$route.params.userid)
          .then((user) => {
            this.user = user;
            if (this.$route.meta.context === 'user') {
              this.$store.commit('SET_CURRENT_USER', this.user);
              this.$store.commit('SET_CURRENT_SCHOOL', null);
            }
            this.form.first_name = user.first_name;
            this.form.last_name = user.last_name;
            this.form.email = user.email;
            this.form.weekly_digest_optin = user.weekly_digest_optin_at !== null;
            const { permissions } = user;
            this.form.permissions = this.formatPermissions(permissions);
          });
      });
    });
  },
  methods: {
    formatPermissions(permissions) {
      const formattedPermissions = {
        books_access: { collection: {}, publisher: {} },
      };
      this.availableCollections.forEach((collection) => {
        formattedPermissions.books_access.collection[collection] = {
          type: 'collection',
          key: collection,
          include_drafts: false,
          bypass_paywall: false,
        };
      });
      this.availablePublishers.forEach((publisher) => {
        formattedPermissions.books_access.publisher[publisher] = {
          type: 'publisher',
          key: publisher,
          include_drafts: false,
          bypass_paywall: false,
        };
      });

      formattedPermissions.books_access.misc = {};
      formattedPermissions.books_access.misc.all = {
        type: 'all',
        key: 'all',
        include_drafts: false,
        bypass_paywall: false,
      };

      if (permissions.books_access) {
        permissions.books_access.forEach((b) => {
          if (b.type === 'all') {
            formattedPermissions.books_access.misc.all = {
              type: b.type,
              key: b.key,
              include_drafts: b.include_drafts,
              bypass_paywall: b.bypass_paywall,
            };
          } else {
            formattedPermissions.books_access[b.type][b.key] = {
              type: b.type,
              key: b.key,
              include_drafts: b.include_drafts,
              bypass_paywall: b.bypass_paywall,
            };
          }
        });
      }

      return formattedPermissions;
    },
    updateAccount() {
      this.$store.dispatch('app/setGlobalLoading', true);
      this.isLoading = true;

      if (!this.form.password || this.form.password.trim().length === 0) {
        delete this.form.password;
      }

      const temp = { ...this.form.permissions };

      const permissions = { books_access: [] };
      if (temp.books_access) {
        Object.values(temp.books_access).forEach((values) => {
          Object.values(values).forEach((row) => {
            if (row.include_drafts || row.bypass_paywall) {
              permissions.books_access.push(row);
            }
          });
        });
      }
      this.form.permissions = permissions;

      this.form.post(`admin/users/${this.user.id}`)
        .then((response) => {
          this.form.permissions = temp;
          this.$store.dispatch('app/setGlobalLoading', false);
          this.$apiResponse.renderSuccessMessage(response, 'User details updated');
        })
        .catch((error) => {
          if (error.errors) {
            const entries = Object.entries(error.errors);
            this.apiErrors = entries;
            this.$apiResponse.renderErrorMessage(error, 'An error has occurred please see below.');
          } else {
            this.$apiResponse.renderErrorMessage(error);
          }
          this.$store.dispatch('app/setGlobalLoading', false);
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => this.isLoading = false);
    },
  },
};
</script>
