<template>
  <wrapper>
  <!-- Main content -->
    <section class="account-page">
      <div class="row">
        <div class="col-md-12">
          <div class="box box-info">
            <!-- Input Addons -->
            <div class="box-header with-border">
              <h3 class="box-title">Edit User</h3>
            </div>
            <div class="box-body">
              <account-form
                  v-if="countriesLoaded && userLoaded"
                  v-model="form"
                  @validated="updateAccount"
                  :countries="countries"
                  :collections="availableCollections"
                  :publishers="availablePublishers"
                  :account-fields="{
                    first_name: true,
                    last_name: true,
                    password: true,
                    email: true,
                    country: true,
                    region: true,
                    permissions: true
                  }" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </wrapper>
  <!-- /.content -->
</template>

<script>
import UserMixin from '@/mixins/UserMixin';
import Wrapper from '@/components/Wrapper';
import Form from '@/classes/Form';
import AccountForm from '@/components/forms/AccountForm';
import CountriesMixin from '@/mixins/CountriesMixin';

export default {
  name: 'edit-user',
  mixins: [UserMixin, CountriesMixin],
  components: {
    AccountForm,
    'account-form': AccountForm,
    Wrapper,
  },
  data() {
    return {
      section: 'Account',
      isLoading: false,
      userLoaded: false,
      user: null,
      form: new Form({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        country: '',
        region: '',
        permissions: null,
      }),
      availableCollections: {},
      availablePublishers: {},
      countries: [],
    };
  },
  mounted() {
    this.getUser();
    this.getCountries();
    this.$nextTick(async () => {
      await this.$store.dispatch('admins/getBookResources', 'collections').then((resource) => {
        this.availableCollections = resource.reduce((obj, item) => {
          obj[item] = {
            key: item,
            type: 'collection',
            bypass_paywall: false,
            include_drafts: false,
          };

          return obj;
        }, {});
      }).catch((e) => this.$apiResponse.renderErrorMessage(e));
      await this.$store.dispatch('admins/getBookResources', 'publishers').then((resource) => {
        this.availablePublishers = resource.reduce((obj, item) => {
          obj[item] = {
            key: item,
            type: 'publisher',
            bypass_paywall: false,
            include_drafts: false,
          };

          return obj;
        }, {});
      }).catch((e) => this.$apiResponse.renderErrorMessage(e));
    });
  },
  methods: {
    getUser() {
      const userId = this.$route.params.userid;
      this.$store.dispatch('users/fetchUserById', { id: userId, params: { } })
        .then((response) => {
          const { user } = response;
          this.form.first_name = user.first_name;
          this.form.last_name = user.last_name;
          this.form.email = user.email;
          this.form.country = user.country;
          this.form.region = user.region;
          if (user.user_type !== 'enterprise') {
            const { permissions } = user;
            this.form.permissions = this.formatPermissions(permissions);
          }
          this.userLoaded = true;
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
        });
    },
    updateAccount() {
      this.$store.dispatch('app/setGlobalLoading', true);
      this.isLoading = true;

      if (!this.form.password || this.form.password.trim().length === 0) {
        delete this.form.password;
      }

      const userId = this.$route.params.userid;

      this.form.post(`admin/users/${userId}`)
        .then(() => {
          this.$store.dispatch('app/setGlobalLoading', false);
          this.$router.push(`/users/${userId}`);
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.isLoading = false;
          this.$store.dispatch('app/setGlobalLoading', false);
        });
    },
    formatPermissions(permissions) {
      if (Array.isArray(permissions)) return { books_access: [] };
      if (!typeof permissions === 'object' && !Object.prototype.hasOwnProperty.call(permissions, 'books_access')) return { books_access: [] };
      if (permissions.books_access.length === 0) return { books_access: [] };

      permissions.books_access.forEach((permission) => {
        switch (permission.type) {
          case 'collection':
            this.availableCollections[permission.key].include_drafts = permission.include_drafts;
            this.availableCollections[permission.key].bypass_paywall = permission.bypass_paywall;
            break;
          case 'publisher':
            this.availablePublishers[permission.key].include_drafts = permission.include_drafts;
            this.availablePublishers[permission.key].bypass_paywall = permission.bypass_paywall;
            break;
          default:
            break;
        }
      });

      return permissions;
    },
  },
};
</script>
