<template>
  <form class="ui form form-horizontal" @submit.prevent="validateForm" autocomplete="off">
    <div class="renewal-input" :class="{'has-error': errors.first('renewal_duration_length')}">
      <input type="number" class="form-control renewal-input__inner" id="renewal_duration_length" name="renewal_duration_length" step="1" min="1"
              autocomplete="off"
              v-model="mutableValue.renewal_duration_length"
              v-validate="'required|numeric|decimal:0'">
      <select class="form-control renewal-input__inner" name="renewal_duration_unit" v-model="mutableValue.renewal_duration_unit">
        <option :value="durationUnit" v-for="(durationUnit, index) in durationUnits" :key="index">{{ durationUnit }}</option>
      </select>
    </div>
    <div class="text-center text-danger" v-show="errors.has('renewal_duration_length')">
      {{ errors.first('renewal_duration_length') }}
    </div>
    <div class="mb-3 mt-3 text-center">
      <div class="btn-group btn-group-toggle">
        <label class="btn btn-primary" :class="{'active': mutableValue.max_devices === '1'}"><input type="radio" name="max_devices" value="1" v-validate="'required'" v-model="mutableValue.max_devices" />Single Device</label>
        <label class="btn btn-primary" :class="{'active': mutableValue.max_devices === '10'}"><input type="radio" name="max_devices" value="10" v-validate="'required'" v-model="mutableValue.max_devices" />Multi Device</label>
      </div>
      <div class="text-center text-danger" v-show="errors.has('max_devices')">
        {{ errors.first('max_devices') }}
      </div>
    </div>

    <div class="text-center">
      <loading-button>Save custom subscription</loading-button>
    </div>
  </form>
</template>

<script>
import FormMixin from '@/mixins/FormMixin';
import LoadingButton from '@/components/utils/LoadingButton';

export default {
  name: 'free-trial-subscription-form',
  props: ['value'],
  mixins: [FormMixin],
  data() {
    return {
      loading: false,
      durationUnits: ['day', 'week', 'month', 'year'],
    };
  },
  components: {
    LoadingButton,
  },
  computed: {
    mutableValue() {
      return Object.assign(this.value);
    },
  },
};
</script>

<style lang="scss" scoped>

.renewal-input {
  display: flex;
  align-items: stretch;
  > * {
    height: 34px;
    &:first-child { border-right-width: 0; }
  }
  input { flex: 1 1 60%; }
  select { flex: 1 1 40%; }
}

// Hide the radio element, in BS this is done via the data-toggle class but we don't want BS
// to handle the toggle on these, we want vue's v-model to do it instead
.btn-group:not([data-toggle]) input[type="radio"] {
  position: absolute;
  clip: rect(0,0,0,0);
  pointer-events: none;
}
</style>
