var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", { ref: "parent", attrs: { tag: "div" } }, [
    _c(
      "form",
      {
        staticClass: "ui form form-horizontal",
        attrs: { autocomplete: "on" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _vm.stage === 0
          ? _c(
              "ValidationObserver",
              { key: 0, ref: "details", attrs: { tag: "section" } },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "form-title" }, [
                    _c("h3", [_vm._v("Enter School Details")])
                  ])
                ]),
                _c("ValidationProvider", {
                  attrs: { name: "name", rules: "required", slim: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "name" }
                                  },
                                  [_vm._v("School Name")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.enterprise.name,
                                              expression: "form.enterprise.name"
                                            }
                                          ],
                                          staticClass: "form-control width-100",
                                          attrs: {
                                            name: "name",
                                            placeholder: "Name",
                                            type: "text",
                                            autocomplete: "off",
                                            id: "name",
                                            "data-lpignore": "true"
                                          },
                                          domProps: {
                                            value: _vm.form.enterprise.name
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form.enterprise,
                                                "name",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2270559674
                  )
                }),
                _c("ValidationProvider", {
                  attrs: { name: "urn", rules: "required", slim: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "urn" }
                                  },
                                  [_vm._v("URN")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.enterprise.urn,
                                              expression: "form.enterprise.urn"
                                            }
                                          ],
                                          staticClass: "form-control width-100",
                                          attrs: {
                                            name: "urn",
                                            placeholder: "URN",
                                            type: "text",
                                            autocomplete: "off",
                                            id: "urn",
                                            "data-lpignore": "true"
                                          },
                                          domProps: {
                                            value: _vm.form.enterprise.urn
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form.enterprise,
                                                "urn",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    494376672
                  )
                })
              ],
              1
            )
          : _vm._e(),
        _vm.stage === 1
          ? _c(
              "ValidationObserver",
              { key: 1, ref: "primary_contact", attrs: { tag: "section" } },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "form-title" }, [
                    _c("h3", [_vm._v("Primary Contact")])
                  ])
                ]),
                _c("ValidationProvider", {
                  attrs: {
                    name: "primary_contact_name",
                    rules: "required",
                    slim: ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "primary_contact_name" }
                                  },
                                  [_vm._v("Name")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.form.contact.primary
                                                  .contact_name,
                                              expression:
                                                "form.contact.primary.contact_name"
                                            },
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: { required: true },
                                              expression: "{ required: true }"
                                            }
                                          ],
                                          staticClass: "form-control width-100",
                                          attrs: {
                                            name: "primary_contact_name",
                                            placeholder: "Name",
                                            type: "text",
                                            autocomplete: "off",
                                            id: "primary_contact_name",
                                            "data-lpignore": "true"
                                          },
                                          domProps: {
                                            value:
                                              _vm.form.contact.primary
                                                .contact_name
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form.contact.primary,
                                                "contact_name",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1936054435
                  )
                }),
                _c("ValidationProvider", {
                  attrs: {
                    name: "primary_contact_job_role",
                    rules: { required: false },
                    slim: ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "primary_contact_job_role" }
                                  },
                                  [_vm._v("Job Role")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.form.contact.primary
                                                  .job_role,
                                              expression:
                                                "form.contact.primary.job_role"
                                            },
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: { required: true },
                                              expression: "{ required: true }"
                                            }
                                          ],
                                          staticClass: "form-control width-100",
                                          attrs: {
                                            name: "primary_contact_job_role",
                                            placeholder: "Job Role",
                                            type: "text",
                                            autocomplete: "off",
                                            id: "primary_contact_job_role",
                                            "data-lpignore": "true"
                                          },
                                          domProps: {
                                            value:
                                              _vm.form.contact.primary.job_role
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form.contact.primary,
                                                "job_role",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2190675336
                  )
                }),
                _c("ValidationProvider", {
                  attrs: {
                    name: "primary_address_line_1",
                    rules: "required",
                    slim: ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "primary_address_line_1" }
                                  },
                                  [_vm._v("Address line 1")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.form.contact.primary
                                                  .address_line_1,
                                              expression:
                                                "form.contact.primary.address_line_1"
                                            }
                                          ],
                                          staticClass: "form-control width-100",
                                          attrs: {
                                            name: "primary_address_line_1",
                                            placeholder: "Address line 1",
                                            type: "text",
                                            autocomplete: "off",
                                            id: "primary_address_line_1",
                                            "data-lpignore": "true"
                                          },
                                          domProps: {
                                            value:
                                              _vm.form.contact.primary
                                                .address_line_1
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form.contact.primary,
                                                "address_line_1",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    195697043
                  )
                }),
                _c("ValidationProvider", {
                  attrs: {
                    name: "primary_address_line_2",
                    rules: { required: false },
                    slim: ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "primary_address_line_2" }
                                  },
                                  [_vm._v("Address line 2")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.form.contact.primary
                                                  .address_line_2,
                                              expression:
                                                "form.contact.primary.address_line_2"
                                            }
                                          ],
                                          staticClass: "form-control width-100",
                                          attrs: {
                                            name: "primary_address_line_2",
                                            placeholder: "Address line 2",
                                            type: "text",
                                            autocomplete: "off",
                                            id: "primary_address_line_2",
                                            "data-lpignore": "true"
                                          },
                                          domProps: {
                                            value:
                                              _vm.form.contact.primary
                                                .address_line_2
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form.contact.primary,
                                                "address_line_2",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2895645552
                  )
                }),
                _c("ValidationProvider", {
                  attrs: {
                    name: "primary_address_postcode",
                    rules: "required",
                    slim: ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "primary_address_postcode" }
                                  },
                                  [_vm._v("Postcode")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.form.contact.primary
                                                  .postcode,
                                              expression:
                                                "form.contact.primary.postcode"
                                            }
                                          ],
                                          staticClass: "form-control width-100",
                                          attrs: {
                                            name: "primary_address_postcode",
                                            placeholder: "Postcode",
                                            type: "text",
                                            autocomplete: "off",
                                            id: "primary_address_postcode",
                                            "data-lpignore": "true"
                                          },
                                          domProps: {
                                            value:
                                              _vm.form.contact.primary.postcode
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form.contact.primary,
                                                "postcode",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    681130534
                  )
                }),
                _c("ValidationProvider", {
                  attrs: {
                    name: "primary_address_country",
                    rules: "required",
                    slim: ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "primary_address_country" }
                                  },
                                  [_vm._v("Country")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.form.contact.primary
                                                    .country_code,
                                                expression:
                                                  "form.contact.primary.country_code"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              name: "primary_address_country",
                                              id: "primary_address_country"
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.$set(
                                                  _vm.form.contact.primary,
                                                  "country_code",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              }
                                            }
                                          },
                                          _vm._l(_vm.countries, function(
                                            country
                                          ) {
                                            return _c(
                                              "option",
                                              {
                                                key: country["id"],
                                                domProps: {
                                                  value: country["id"],
                                                  selected:
                                                    country["id"] === _vm.value
                                                }
                                              },
                                              [_vm._v(_vm._s(country["name"]))]
                                            )
                                          }),
                                          0
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1925991560
                  )
                }),
                _c("ValidationProvider", {
                  attrs: { name: "primary_region", slim: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "primary_address_region" }
                                  },
                                  [_vm._v("Region")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _vm.primaryRegions.length > 0
                                          ? _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.form.contact.primary
                                                        .region,
                                                    expression:
                                                      "form.contact.primary.region"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  name:
                                                    "primary_address_region",
                                                  id: "primary_address_region"
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$selectedVal = Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function(o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function(o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                    _vm.$set(
                                                      _vm.form.contact.primary,
                                                      "region",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  }
                                                }
                                              },
                                              _vm._l(
                                                _vm.primaryRegions,
                                                function(group, key) {
                                                  return _c(
                                                    "optgroup",
                                                    {
                                                      key: key,
                                                      attrs: {
                                                        label:
                                                          group["group_name"] !=
                                                          ""
                                                            ? group[
                                                                "group_name"
                                                              ]
                                                            : "Chose a region"
                                                      }
                                                    },
                                                    _vm._l(
                                                      group["values"],
                                                      function(region, k) {
                                                        return _c(
                                                          "option",
                                                          {
                                                            key: k,
                                                            domProps: {
                                                              value: region,
                                                              selected:
                                                                region ===
                                                                _vm.value
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(region)
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.form.contact.primary
                                                      .region,
                                                  expression:
                                                    "form.contact.primary.region"
                                                }
                                              ],
                                              staticClass:
                                                "form-control width-100",
                                              attrs: {
                                                name: "primary_address_region",
                                                placeholder: "Region",
                                                type: "text",
                                                autocomplete: "off",
                                                id: "primary_region",
                                                "data-lpignore": "true"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.form.contact.primary
                                                    .region
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.form.contact.primary,
                                                    "region",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                      ]
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    500643757
                  )
                }),
                _c("ValidationProvider", {
                  attrs: { name: "primary_city", slim: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "primary_city" }
                                  },
                                  [_vm._v("City")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.form.contact.primary.city,
                                              expression:
                                                "form.contact.primary.city"
                                            }
                                          ],
                                          staticClass: "form-control width-100",
                                          attrs: {
                                            name: "primary_city",
                                            placeholder: "City",
                                            type: "text",
                                            autocomplete: "off",
                                            id: "primary_city",
                                            "data-lpignore": "true"
                                          },
                                          domProps: {
                                            value: _vm.form.contact.primary.city
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form.contact.primary,
                                                "city",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3218220349
                  )
                }),
                _c("ValidationProvider", {
                  attrs: { name: "primary_county", slim: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "primary_county" }
                                  },
                                  [_vm._v("County")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.form.contact.primary.county,
                                              expression:
                                                "form.contact.primary.county"
                                            }
                                          ],
                                          staticClass: "form-control width-100",
                                          attrs: {
                                            name: "primary_county",
                                            placeholder: "County",
                                            type: "text",
                                            autocomplete: "off",
                                            id: "primary_county",
                                            "data-lpignore": "true"
                                          },
                                          domProps: {
                                            value:
                                              _vm.form.contact.primary.county
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form.contact.primary,
                                                "county",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1528109088
                  )
                }),
                _c("ValidationProvider", {
                  attrs: {
                    name: "primary_address_telephone",
                    rules: { required: true, regex: /^[+0-9]+$/ },
                    slim: ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "primary_address_telephone" }
                                  },
                                  [_vm._v("Telephone")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.form.contact.primary
                                                  .telephone,
                                              expression:
                                                "form.contact.primary.telephone"
                                            }
                                          ],
                                          staticClass: "form-control width-100",
                                          attrs: {
                                            name: "primary_address_telephone",
                                            placeholder: "Telephone",
                                            type: "text",
                                            autocomplete: "off",
                                            id: "primary_address_telephone",
                                            "data-lpignore": "true"
                                          },
                                          domProps: {
                                            value:
                                              _vm.form.contact.primary.telephone
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form.contact.primary,
                                                "telephone",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1395511511
                  )
                }),
                _c("ValidationProvider", {
                  attrs: {
                    name: "primary_email",
                    rules: { required: true, email: true },
                    slim: ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "primary_email" }
                                  },
                                  [_vm._v("Email")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.form.contact.primary.email,
                                              expression:
                                                "form.contact.primary.email"
                                            }
                                          ],
                                          staticClass: "form-control width-100",
                                          attrs: {
                                            name: "primary_email",
                                            placeholder: "Email",
                                            type: "text",
                                            autocomplete: "off",
                                            id: "primary_email",
                                            "data-lpignore": "true"
                                          },
                                          domProps: {
                                            value:
                                              _vm.form.contact.primary.email
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form.contact.primary,
                                                "email",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2722845942
                  )
                })
              ],
              1
            )
          : _vm._e(),
        _vm.stage === 2
          ? _c(
              "ValidationObserver",
              {
                key: 2,
                ref: "contacts",
                staticClass: "form-group",
                attrs: { tag: "section" }
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "text-center" }, [
                    _c("h3", [
                      _vm._v(
                        "Is the Billing Contact the same as the Primary Contact?"
                      )
                    ])
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "col-sm-offset-6 col-sm-10 col-md-8" },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "btn-group btn-group-toggle",
                          attrs: { "data-toggle": "buttons" },
                          on: {
                            click: function($event) {
                              return _vm.handleContactsAreTheSameButtons($event)
                            }
                          }
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "btn btn-secondary",
                              class: { active: _vm.form.contacts_are_the_same }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.contacts_are_the_same,
                                    expression: "form.contacts_are_the_same"
                                  }
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "contacts_are_the_same",
                                  id: "contacts_are_the_same_yes"
                                },
                                domProps: {
                                  value: true,
                                  checked: _vm._q(
                                    _vm.form.contacts_are_the_same,
                                    true
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "contacts_are_the_same",
                                      true
                                    )
                                  }
                                }
                              }),
                              _vm._v(" Yes ")
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              class: {
                                active:
                                  _vm.form.contacts_are_the_same !== null &&
                                  !_vm.form.contacts_are_the_same
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.contacts_are_the_same,
                                    expression: "form.contacts_are_the_same"
                                  }
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "contacts_are_the_same",
                                  id: "contacts_are_the_same_no"
                                },
                                domProps: {
                                  value: false,
                                  checked: _vm._q(
                                    _vm.form.contacts_are_the_same,
                                    false
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "contacts_are_the_same",
                                      false
                                    )
                                  }
                                }
                              }),
                              _vm._v(" No ")
                            ]
                          )
                        ]
                      )
                    ])
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm.stage === 3
          ? _c(
              "ValidationObserver",
              { ref: "billing_contact" },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "form-title" }, [
                    _c("h3", [_vm._v("Billing Contact")])
                  ])
                ]),
                _c("ValidationProvider", {
                  attrs: {
                    name: "billing_contact_name",
                    rules: { required: true },
                    slim: ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "billing_contact_name" }
                                  },
                                  [_vm._v("Name")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.form.contact.billing
                                                  .contact_name,
                                              expression:
                                                "form.contact.billing.contact_name"
                                            }
                                          ],
                                          staticClass: "form-control width-100",
                                          attrs: {
                                            name: "billing_contact_name",
                                            placeholder: "Name",
                                            type: "text",
                                            autocomplete: "off",
                                            id: "billing_contact_name",
                                            "data-lpignore": "true"
                                          },
                                          domProps: {
                                            value:
                                              _vm.form.contact.billing
                                                .contact_name
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form.contact.billing,
                                                "contact_name",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    753466981
                  )
                }),
                _c("ValidationProvider", {
                  attrs: {
                    name: "billing_contact_job_role",
                    rules: { required: false },
                    slim: ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "billing_contact_job_role" }
                                  },
                                  [_vm._v("Job Role")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.form.contact.billing
                                                  .job_role,
                                              expression:
                                                "form.contact.billing.job_role"
                                            },
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: { required: true },
                                              expression: "{ required: true }"
                                            }
                                          ],
                                          staticClass: "form-control width-100",
                                          attrs: {
                                            name: "billing_contact_job_role",
                                            placeholder: "Job Role",
                                            type: "text",
                                            autocomplete: "off",
                                            id: "billing_contact_job_role",
                                            "data-lpignore": "true"
                                          },
                                          domProps: {
                                            value:
                                              _vm.form.contact.billing.job_role
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form.contact.billing,
                                                "job_role",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3702069871
                  )
                }),
                _c("ValidationProvider", {
                  attrs: {
                    name: "billing_address_line_1",
                    rules: { required: true },
                    slim: ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "billing_address_line_1" }
                                  },
                                  [_vm._v("Address line 1")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.form.contact.billing
                                                  .address_line_1,
                                              expression:
                                                "form.contact.billing.address_line_1"
                                            }
                                          ],
                                          staticClass: "form-control width-100",
                                          attrs: {
                                            name: "billing_address_line_1",
                                            placeholder: "Address line 1",
                                            type: "text",
                                            autocomplete: "off",
                                            id: "primary_address_line_1",
                                            "data-lpignore": "true"
                                          },
                                          domProps: {
                                            value:
                                              _vm.form.contact.billing
                                                .address_line_1
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form.contact.billing,
                                                "address_line_1",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3712859347
                  )
                }),
                _c("ValidationProvider", {
                  attrs: {
                    name: "billing_address_line_2",
                    rules: { required: false },
                    slim: ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "billing_address_line_2" }
                                  },
                                  [_vm._v("Address line 2")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.form.contact.billing
                                                  .address_line_2,
                                              expression:
                                                "form.contact.billing.address_line_2"
                                            }
                                          ],
                                          staticClass: "form-control width-100",
                                          attrs: {
                                            name: "billing_address_line_2",
                                            placeholder: "Address line 2",
                                            type: "text",
                                            autocomplete: "off",
                                            id: "billing_address_line_2",
                                            "data-lpignore": "true"
                                          },
                                          domProps: {
                                            value:
                                              _vm.form.contact.billing
                                                .address_line_2
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form.contact.billing,
                                                "address_line_2",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    989718103
                  )
                }),
                _c("ValidationProvider", {
                  attrs: {
                    name: "billing_address_postcode",
                    rules: "required",
                    slim: ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "billing_address_postcode" }
                                  },
                                  [_vm._v("Postcode")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.form.contact.billing
                                                  .postcode,
                                              expression:
                                                "form.contact.billing.postcode"
                                            }
                                          ],
                                          staticClass: "form-control width-100",
                                          attrs: {
                                            name: "billing_address_postcode",
                                            placeholder: "Postcode",
                                            type: "text",
                                            autocomplete: "off",
                                            id: "billing_address_postcode",
                                            "data-lpignore": "true"
                                          },
                                          domProps: {
                                            value:
                                              _vm.form.contact.billing.postcode
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form.contact.billing,
                                                "postcode",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1045719105
                  )
                }),
                _c("ValidationProvider", {
                  attrs: {
                    name: "billing_address_country",
                    rules: { required: true },
                    slim: ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "billing_address_country" }
                                  },
                                  [_vm._v("Country")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.form.contact.billing
                                                    .country_code,
                                                expression:
                                                  "form.contact.billing.country_code"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              name: "billing_address_country",
                                              id: "billing_address_country"
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.$set(
                                                  _vm.form.contact.billing,
                                                  "country_code",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              }
                                            }
                                          },
                                          _vm._l(_vm.countries, function(
                                            country
                                          ) {
                                            return _c(
                                              "option",
                                              {
                                                key: country["id"],
                                                domProps: {
                                                  value: country["id"],
                                                  selected:
                                                    country["id"] === _vm.value
                                                }
                                              },
                                              [_vm._v(_vm._s(country["name"]))]
                                            )
                                          }),
                                          0
                                        )
                                      ]
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3985338632
                  )
                }),
                _c("ValidationProvider", {
                  attrs: { name: "billing_region", slim: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "billing_address_region" }
                                  },
                                  [_vm._v("Region")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _vm.billingRegions.length > 0
                                          ? _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.form.contact.billing
                                                        .region,
                                                    expression:
                                                      "form.contact.billing.region"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  name:
                                                    "billing_address_region",
                                                  id: "billing_address_region"
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$selectedVal = Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function(o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function(o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                    _vm.$set(
                                                      _vm.form.contact.billing,
                                                      "region",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  }
                                                }
                                              },
                                              _vm._l(
                                                _vm.billingRegions,
                                                function(group, key) {
                                                  return _c(
                                                    "optgroup",
                                                    {
                                                      key: key,
                                                      attrs: {
                                                        label:
                                                          group["group_name"] !=
                                                          ""
                                                            ? group[
                                                                "group_name"
                                                              ]
                                                            : "Chose a region"
                                                      }
                                                    },
                                                    _vm._l(
                                                      group["values"],
                                                      function(region, k) {
                                                        return _c(
                                                          "option",
                                                          {
                                                            key: k,
                                                            domProps: {
                                                              value: region,
                                                              selected:
                                                                region ===
                                                                _vm.value
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(region)
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.form.contact.billing
                                                      .region,
                                                  expression:
                                                    "form.contact.billing.region"
                                                }
                                              ],
                                              staticClass:
                                                "form-control width-100",
                                              attrs: {
                                                name: "billing_region",
                                                placeholder: "Region",
                                                type: "text",
                                                autocomplete: "off",
                                                id: "billing_region",
                                                "data-lpignore": "true"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.form.contact.billing
                                                    .region
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.form.contact.billing,
                                                    "region",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                      ]
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1976955620
                  )
                }),
                _c("ValidationProvider", {
                  attrs: { name: "billing_city", slim: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "billing_city" }
                                  },
                                  [_vm._v("City")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.form.contact.billing.city,
                                              expression:
                                                "form.contact.billing.city"
                                            }
                                          ],
                                          staticClass: "form-control width-100",
                                          attrs: {
                                            name: "billing_city",
                                            placeholder: "City",
                                            type: "text",
                                            autocomplete: "off",
                                            id: "billing_city",
                                            "data-lpignore": "true"
                                          },
                                          domProps: {
                                            value: _vm.form.contact.billing.city
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form.contact.billing,
                                                "city",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3346792282
                  )
                }),
                _c("ValidationProvider", {
                  attrs: { name: "billing_county", slim: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "billing_county" }
                                  },
                                  [_vm._v("County")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.form.contact.billing.county,
                                              expression:
                                                "form.contact.billing.county"
                                            }
                                          ],
                                          staticClass: "form-control width-100",
                                          attrs: {
                                            name: "billing_county",
                                            placeholder: "County",
                                            type: "text",
                                            autocomplete: "off",
                                            id: "billing_county",
                                            "data-lpignore": "true"
                                          },
                                          domProps: {
                                            value:
                                              _vm.form.contact.billing.county
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form.contact.billing,
                                                "county",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    4051152647
                  )
                }),
                _c("ValidationProvider", {
                  attrs: {
                    name: "billing_address_telephone",
                    rules: { required: true, regex: /^[+0-9]+$/ },
                    slim: ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "billing_address_telephone" }
                                  },
                                  [_vm._v("Telephone")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.form.contact.billing
                                                  .telephone,
                                              expression:
                                                "form.contact.billing.telephone"
                                            }
                                          ],
                                          staticClass: "form-control width-100",
                                          attrs: {
                                            name: "billing_address_telephone",
                                            placeholder: "Telephone",
                                            type: "text",
                                            autocomplete: "off",
                                            id: "billing_address_telephone",
                                            "data-lpignore": "true"
                                          },
                                          domProps: {
                                            value:
                                              _vm.form.contact.billing.telephone
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form.contact.billing,
                                                "telephone",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    194667504
                  )
                }),
                _c("ValidationProvider", {
                  attrs: {
                    name: "billing_email",
                    rules: { required: true, email: true },
                    slim: ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "billing_email" }
                                  },
                                  [_vm._v("Email")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.form.contact.billing.email,
                                              expression:
                                                "form.contact.billing.email"
                                            }
                                          ],
                                          staticClass: "form-control width-100",
                                          attrs: {
                                            name: "billing_email",
                                            placeholder: "Email",
                                            type: "text",
                                            autocomplete: "off",
                                            id: "billing_email",
                                            "data-lpignore": "true"
                                          },
                                          domProps: {
                                            value:
                                              _vm.form.contact.billing.email
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form.contact.billing,
                                                "email",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3951984401
                  )
                })
              ],
              1
            )
          : _vm._e(),
        _vm.stage === 4
          ? _c("school-subscription-form", {
              attrs: { value: _vm.form.subscription, "display-buttons": false }
            })
          : _vm._e(),
        _vm.stage === 5
          ? _c(
              "ValidationObserver",
              { key: 0, ref: "details", attrs: { tag: "section" } },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "form-title" }, [
                    _c("h3", [_vm._v("Admin Account Details")])
                  ])
                ]),
                _c("ValidationProvider", {
                  attrs: { name: "user_first_name", rules: "required" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "user_first_name" }
                                  },
                                  [_vm._v("First Name")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.user.first_name,
                                              expression: "form.user.first_name"
                                            }
                                          ],
                                          staticClass: "form-control width-100",
                                          attrs: {
                                            name: "user_first_name",
                                            placeholder: "First Name",
                                            type: "text",
                                            autocomplete: "off",
                                            id: "user_first_name",
                                            "data-lpignore": "true"
                                          },
                                          domProps: {
                                            value: _vm.form.user.first_name
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form.user,
                                                "first_name",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1387012101
                  )
                }),
                _c("ValidationProvider", {
                  attrs: { name: "user_last_name", rules: "required" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "user_last_name" }
                                  },
                                  [_vm._v("Last Name")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.user.last_name,
                                              expression: "form.user.last_name"
                                            }
                                          ],
                                          staticClass: "form-control width-100",
                                          attrs: {
                                            name: "user_last_name",
                                            placeholder: "First Name",
                                            type: "text",
                                            autocomplete: "off",
                                            id: "user_last_name",
                                            "data-lpignore": "true"
                                          },
                                          domProps: {
                                            value: _vm.form.user.last_name
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form.user,
                                                "last_name",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1395277093
                  )
                }),
                _c("ValidationProvider", {
                  attrs: {
                    name: "user_email",
                    rules: { required: true, email: true }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "user_email" }
                                  },
                                  [_vm._v("Email")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.user.email,
                                              expression: "form.user.email"
                                            }
                                          ],
                                          staticClass: "form-control width-100",
                                          attrs: {
                                            name: "user_email",
                                            placeholder: "Email",
                                            type: "text",
                                            autocomplete: "off",
                                            id: "user_email",
                                            "data-lpignore": "true"
                                          },
                                          domProps: {
                                            value: _vm.form.user.email
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form.user,
                                                "email",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3305067595
                  )
                }),
                _c("ValidationProvider", {
                  attrs: { name: "user_job_role" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "has-error": errors.length > 0 }
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-3 control-label",
                                    attrs: { for: "user_job_role" }
                                  },
                                  [_vm._v("Job Role")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9 col-md-9" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group width-100" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.user.job_role,
                                              expression: "form.user.job_role"
                                            }
                                          ],
                                          staticClass: "form-control width-100",
                                          attrs: {
                                            name: "user_job_role",
                                            placeholder: "Job Role",
                                            type: "text",
                                            autocomplete: "off",
                                            id: "user_job_role",
                                            "data-lpignore": "true"
                                          },
                                          domProps: {
                                            value: _vm.form.user.job_role
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form.user,
                                                "job_role",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2872722475
                  )
                })
              ],
              1
            )
          : _vm._e(),
        _vm.stage === 6
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-9" }, [
                _c(
                  "fieldset",
                  { staticClass: "stats-box stats-box-border white mb-1-25" },
                  [
                    _c("legend", { staticClass: "stats-box-legend" }, [
                      _vm._v("Confirm School Details")
                    ]),
                    _c(
                      "div",
                      { staticClass: "stats-box-heading-with-button" },
                      [
                        _c("h3", { staticClass: "stats-box-heading" }, [
                          _vm._v("School Details")
                        ]),
                        _c(
                          "button",
                          {
                            on: {
                              click: function($event) {
                                _vm.stage = 0
                              }
                            }
                          },
                          [
                            _vm._v("Edit "),
                            _c("font-awesome-icon", {
                              attrs: { icon: ["fas", "edit"] }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c("ul", { staticClass: "stats-box-list" }, [
                      _vm.form.enterprise.name
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _c("span", [_vm._v("School Name")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.form.enterprise.name))
                            ])
                          ])
                        : _vm._e(),
                      _vm.form.enterprise.urn
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _c("span", [_vm._v("School Urn")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.form.enterprise.urn))
                            ])
                          ])
                        : _vm._e()
                    ]),
                    _c(
                      "div",
                      { staticClass: "stats-box-heading-with-button" },
                      [
                        _c("h3", { staticClass: "stats-box-heading" }, [
                          _vm._v("Primary Contact")
                        ]),
                        _c(
                          "button",
                          {
                            on: {
                              click: function($event) {
                                _vm.stage = 1
                              }
                            }
                          },
                          [
                            _vm._v("Edit "),
                            _c("font-awesome-icon", {
                              attrs: { icon: ["fas", "edit"] }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c("ul", { staticClass: "stats-box-list" }, [
                      _vm.form.contact.primary.contact_name
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _c("span", [_vm._v("Name")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.form.contact.primary.contact_name)
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.form.contact.primary.job_role
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _c("span", [_vm._v("Job Role")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.form.contact.primary.job_role))
                            ])
                          ])
                        : _vm._e(),
                      _vm.form.contact.primary.address_line_1
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _c("span", [_vm._v("Address Line 1")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.form.contact.primary.address_line_1)
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.form.contact.primary.address_line_2
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _c("span", [_vm._v("Address Line 2")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.form.contact.primary.address_line_2)
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.form.contact.primary.postcode
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _c("span", [_vm._v("Postcode")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.form.contact.primary.postcode))
                            ])
                          ])
                        : _vm._e(),
                      _vm.form.contact.primary.country_code
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _c("span", [_vm._v("Country Code")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.form.contact.primary.country_code)
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.form.contact.primary.region
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _c("span", [_vm._v("Region")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.form.contact.primary.region))
                            ])
                          ])
                        : _vm._e(),
                      _vm.form.contact.primary.city
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _c("span", [_vm._v("City")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.form.contact.primary.city))
                            ])
                          ])
                        : _vm._e(),
                      _vm.form.contact.primary.county
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _c("span", [_vm._v("County")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.form.contact.primary.county))
                            ])
                          ])
                        : _vm._e(),
                      _vm.form.contact.primary.telephone
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _c("span", [_vm._v("Telephone")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.form.contact.primary.telephone))
                            ])
                          ])
                        : _vm._e(),
                      _vm.form.contact.primary.email
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _c("span", [_vm._v("Email")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.form.contact.primary.email))
                            ])
                          ])
                        : _vm._e()
                    ]),
                    !_vm.form.contacts_are_the_same
                      ? _c("div", [
                          _c(
                            "div",
                            { staticClass: "stats-box-heading-with-button" },
                            [
                              _c("h3", { staticClass: "stats-box-heading" }, [
                                _vm._v("Billing Contact")
                              ]),
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.stage = 3
                                    }
                                  }
                                },
                                [
                                  _vm._v("Edit "),
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["fas", "edit"] }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _c("ul", { staticClass: "stats-box-list" }, [
                            _vm.form.contact.billing.contact_name
                              ? _c(
                                  "li",
                                  { staticClass: "stats-box-list-item" },
                                  [
                                    _c("span", [_vm._v("Name")]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.contact.billing.contact_name
                                        )
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm.form.contact.billing.job_role
                              ? _c(
                                  "li",
                                  { staticClass: "stats-box-list-item" },
                                  [
                                    _c("span", [_vm._v("Job Role")]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.contact.billing.job_role
                                        )
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm.form.contact.billing.address_line_1
                              ? _c(
                                  "li",
                                  { staticClass: "stats-box-list-item" },
                                  [
                                    _c("span", [_vm._v("Address Line 1")]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.contact.billing
                                            .address_line_1
                                        )
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm.form.contact.billing.address_line_2
                              ? _c(
                                  "li",
                                  { staticClass: "stats-box-list-item" },
                                  [
                                    _c("span", [_vm._v("Address Line 2")]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.contact.billing
                                            .address_line_2
                                        )
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm.form.contact.billing.postcode
                              ? _c(
                                  "li",
                                  { staticClass: "stats-box-list-item" },
                                  [
                                    _c("span", [_vm._v("Postcode")]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.contact.billing.postcode
                                        )
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm.form.contact.billing.country_code
                              ? _c(
                                  "li",
                                  { staticClass: "stats-box-list-item" },
                                  [
                                    _c("span", [_vm._v("Country Code")]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.contact.billing.country_code
                                        )
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm.form.contact.billing.region
                              ? _c(
                                  "li",
                                  { staticClass: "stats-box-list-item" },
                                  [
                                    _c("span", [_vm._v("Region")]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.form.contact.billing.region)
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm.form.contact.billing.city
                              ? _c(
                                  "li",
                                  { staticClass: "stats-box-list-item" },
                                  [
                                    _c("span", [_vm._v("City")]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.form.contact.billing.city)
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm.form.contact.billing.county
                              ? _c(
                                  "li",
                                  { staticClass: "stats-box-list-item" },
                                  [
                                    _c("span", [_vm._v("County")]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.form.contact.billing.county)
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm.form.contact.billing.telephone
                              ? _c(
                                  "li",
                                  { staticClass: "stats-box-list-item" },
                                  [
                                    _c("span", [_vm._v("Telephone")]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.contact.billing.telephone
                                        )
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm.form.contact.billing.email
                              ? _c(
                                  "li",
                                  { staticClass: "stats-box-list-item" },
                                  [
                                    _c("span", [_vm._v("Email")]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.form.contact.billing.email)
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ])
                        ])
                      : _c("div", [
                          _c(
                            "div",
                            { staticClass: "stats-box-heading-with-button" },
                            [
                              _c("h3", { staticClass: "stats-box-heading" }, [
                                _vm._v("Billing Contact")
                              ]),
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.stage = 2
                                    }
                                  }
                                },
                                [
                                  _vm._v("Edit "),
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["fas", "edit"] }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _c("ul", { staticClass: "stats-box-list" }, [
                            _c("li", { staticClass: "stats-box-list-item" }, [
                              _vm._v(
                                " Billing Contact is the same as the primary contact "
                              )
                            ])
                          ])
                        ]),
                    _c(
                      "div",
                      { staticClass: "stats-box-heading-with-button" },
                      [
                        _c("h3", { staticClass: "stats-box-heading" }, [
                          _vm._v("Subscription Details")
                        ]),
                        _c(
                          "button",
                          {
                            on: {
                              click: function($event) {
                                _vm.stage = 4
                              }
                            }
                          },
                          [
                            _vm._v("Edit "),
                            _c("font-awesome-icon", {
                              attrs: { icon: ["fas", "edit"] }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c("ul", { staticClass: "stats-box-list" }, [
                      _vm.form.subscription.max_readers
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _c("span", [_vm._v("Maximum Readers")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.form.subscription.max_readers))
                            ])
                          ])
                        : _vm._e(),
                      _vm.form.subscription.renewal_price
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _c("span", [_vm._v("Cost")]),
                            _c("span", [
                              _vm._v(
                                "£" +
                                  _vm._s(_vm.form.subscription.renewal_price)
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.form.subscription.renewal_duration_unit
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _c("span", [_vm._v("Duration")]),
                            _c("span", [
                              _vm._v(
                                "1 " +
                                  _vm._s(
                                    _vm.form.subscription.renewal_duration_unit
                                  )
                              )
                            ])
                          ])
                        : _vm._e()
                    ]),
                    _c(
                      "div",
                      { staticClass: "stats-box-heading-with-button" },
                      [
                        _c("h3", { staticClass: "stats-box-heading" }, [
                          _vm._v("Admin Account")
                        ]),
                        _c(
                          "button",
                          {
                            on: {
                              click: function($event) {
                                _vm.stage = 5
                              }
                            }
                          },
                          [
                            _vm._v("Edit "),
                            _c("font-awesome-icon", {
                              attrs: { icon: ["fas", "edit"] }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c("ul", { staticClass: "stats-box-list" }, [
                      _vm.form.user.first_name
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _c("span", [_vm._v("First Name")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.form.user.first_name))
                            ])
                          ])
                        : _vm._e(),
                      _vm.form.user.last_name
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _c("span", [_vm._v("Last Name")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.form.user.last_name))
                            ])
                          ])
                        : _vm._e(),
                      _vm.form.user.email
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _c("span", [_vm._v("Email")]),
                            _c("span", [_vm._v(_vm._s(_vm.form.user.email))])
                          ])
                        : _vm._e(),
                      _vm.form.user.job_role
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _c("span", [_vm._v("Job Role")]),
                            _c("span", [_vm._v(_vm._s(_vm.form.user.job_role))])
                          ])
                        : _vm._e()
                    ])
                  ]
                )
              ])
            ])
          : _vm._e(),
        _vm.showControls
          ? _c("div", { staticClass: "col-sm-offset-6 col-sm-10 col-md-8" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-2" }, [
                  _vm.stage > 0
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          on: {
                            click: function($event) {
                              return _vm.handleBackStage()
                            }
                          }
                        },
                        [_vm._v("Back")]
                      )
                    : _vm._e()
                ]),
                _c(
                  "div",
                  { staticClass: "col-md-2" },
                  [
                    _c(
                      "loading-button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "submit", isLoading: _vm.isLoading }
                      },
                      [_vm._v(_vm._s(_vm.submitButtonText))]
                    )
                  ],
                  1
                )
              ])
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }