<template>
  <div class="audio-player" :class="{ 'show-skip-controls': isAudioLoaded }" @mousemove="scrub" @mouseup="stopScrubbing">
    <div class="audio-player__main">
      <div class="audio-player__loading" v-if="isLoading">
        <div class="audio-player__loading--spinner"></div>
      </div>
      <div class="audio-player__button audio-player__button--play" @click="togglePlay()" v-if="!isLoading">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 18 24">
          <path fill="#566574" fill-rule="evenodd" :d=" status === 'playing' ? 'M0 0h6v24H0zM12 0h6v24h-6z' : 'M18 12L0 24V0' " />
        </svg>
      </div>

      <div class="audio-player__controls">
        <span class="audio-player__time--current">{{currentTime}}</span>
        <div class="audio-player__slider-hit-area" @mousedown="startScrubbing">
          <div class="audio-player__slider" data-direction="horizontal" ref="durationSlider">
            <div class="audio-player__progress" :style="{ width: durationPercent }">
              <div class="audio-player__progress--pin"></div>
            </div>
          </div>
        </div>
        <span class="audio-player__time--total">{{totalTime}}</span>
      </div>
      <div class="audio-player__button audio-player__button--playx" @click="downloadAudioFile" v-show="isAudioLoaded && allowDownload">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26 26">
          <path fill="#566574" d="M20 21c0-0.547-0.453-1-1-1s-1 0.453-1 1 0.453 1 1 1 1-0.453 1-1zM24 21c0-0.547-0.453-1-1-1s-1 0.453-1 1 0.453 1 1 1 1-0.453 1-1zM26 17.5v5c0 0.828-0.672 1.5-1.5 1.5h-23c-0.828 0-1.5-0.672-1.5-1.5v-5c0-0.828 0.672-1.5 1.5-1.5h7.266l2.109 2.125c0.578 0.562 1.328 0.875 2.125 0.875s1.547-0.313 2.125-0.875l2.125-2.125h7.25c0.828 0 1.5 0.672 1.5 1.5zM20.922 8.609c0.156 0.375 0.078 0.812-0.219 1.094l-7 7c-0.187 0.203-0.453 0.297-0.703 0.297s-0.516-0.094-0.703-0.297l-7-7c-0.297-0.281-0.375-0.719-0.219-1.094 0.156-0.359 0.516-0.609 0.922-0.609h4v-7c0-0.547 0.453-1 1-1h4c0.547 0 1 0.453 1 1v7h4c0.406 0 0.766 0.25 0.922 0.609z"></path>
        </svg>
      </div>

    </div>
    <div class="audio-player__extra">
      <div class="audio-player__skip_controls">
        <div class="audio-player__control--circle" @click="skipRelative(-5)">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 399.69 399.28">
            <path fill="currentColor" d="m74.24 355.6 20.06-30.93c3.3 2.31 6.44 4.46 9.52 6.67a161.44 161.44 0 0 0 67.63 28.17 165.22 165.22 0 0 0 79.89-5.93c27.16-8.76 50-24.27 69.16-45.39a156.85 156.85 0 0 0 37-70.12 162.8 162.8 0 0 0 4.75-40.07 170 170 0 0 0-7.08-45.76 154.73 154.73 0 0 0-29.63-55.59c-20-24-44.44-41.57-74.24-51.22-20.71-6.7-42-9.67-63.79-7.88-27.54 2.25-53.18 10.4-76.36 25.63a161.18 161.18 0 0 0-47.4 47.69c-.16.26-.28.55-.59 1.17h61.43v37.28H0V24.72h37.14v58.47c39.07-51.85 90.19-80.54 154.6-83s118.24 21.2 160.12 70c69 80.39 61.5 198.66-10.71 270.61-74.1 73.79-188.8 76.66-266.91 14.8Z"/>
            <path fill="currentColor" d="M177.09 239.63c2.59 11.48 15.28 16.73 28.09 16.73 14.52 0 28.84-9.82 28.84-27.23 0-15.66-13.46-25.48-29.06-25.48-14.09 0-22.81 7.39-26.36 13h-21.09l6.35-76.92h86.4v19.06h-66.5l-3.55 37.93h1.19c3.12-4.28 13.13-11.28 29.59-11.28 25.39 0 47 15.56 47 43 0 28.1-21.73 46.68-53.69 46.68-28.84 0-48.75-15.27-49.93-35.5Z"/>
          </svg>
        </div>
        <div class="audio-player__control--circle" @click="skipRelative(-2)">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 399.69 399.28">
            <path fill="currentColor" d="m74.24 355.6 20.06-30.93c3.3 2.31 6.44 4.46 9.52 6.67a161.44 161.44 0 0 0 67.63 28.17 165.22 165.22 0 0 0 79.89-5.93c27.16-8.76 50-24.27 69.16-45.39a156.85 156.85 0 0 0 37-70.12 162.8 162.8 0 0 0 4.75-40.07 170 170 0 0 0-7.08-45.76 154.73 154.73 0 0 0-29.63-55.59c-20-24-44.44-41.57-74.24-51.22-20.71-6.7-42-9.67-63.79-7.88-27.54 2.25-53.18 10.4-76.36 25.63a161.18 161.18 0 0 0-47.4 47.69c-.16.26-.28.55-.59 1.17h61.43v37.28H0V24.72h37.14v58.47c39.07-51.85 90.19-80.54 154.6-83s118.24 21.2 160.12 70c69 80.39 61.5 198.66-10.71 270.61-74.1 73.79-188.8 76.66-266.91 14.8Z"/>
            <path fill="currentColor" d="M152.78 179.55v-1.07c0-20.23 16.14-40.16 48.42-40.16 29.06 0 48 15.85 48 36.85 0 17.7-12.06 29-25.4 42.11l-36.26 36v1.17h64.24v19.25h-98.14v-14.51l54.56-54c8-7.88 16.57-16.63 16.57-28 0-12-10.22-19.94-24.1-19.94-15.5 0-24.54 9.92-24.54 21.1v1.17Z"/>
          </svg>
        </div>
        <div class="audio-player__control--circle" @click="skipRelative(2)">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 399.69 399.28">
            <path fill="currentColor" d="m325.45 355.6-20.06-30.93c-3.3 2.31-6.44 4.46-9.52 6.67a161.34 161.34 0 0 1-67.63 28.17 165.23 165.23 0 0 1-79.89-5.93c-27.16-8.76-50-24.27-69.16-45.39a156.85 156.85 0 0 1-37-70.12A163.28 163.28 0 0 1 37.44 198a170 170 0 0 1 7.08-45.76 154.73 154.73 0 0 1 29.63-55.6c19.95-24 44.45-41.57 74.25-51.22 20.7-6.7 42-9.67 63.78-7.88 27.54 2.25 53.18 10.4 76.36 25.63a161.18 161.18 0 0 1 47.4 47.69c.16.26.28.55.59 1.17H275.1v37.28h124.59V24.72h-37.14v58.47C323.48 31.34 272.36 2.65 208 .18s-118.24 21.2-160.12 70c-69 80.39-61.5 198.66 10.72 270.61 74.04 73.8 188.74 76.67 266.85 14.81Z"/>
            <path fill="currentColor" d="M150.78 177.55v-1.07c0-20.23 16.14-40.16 48.42-40.16 29.06 0 48 15.85 48 36.85 0 17.7-12.06 29-25.4 42.11l-36.26 36v1.17h64.24v19.25h-98.14v-14.51l54.56-54c8-7.88 16.57-16.63 16.57-28 0-12-10.22-19.94-24.1-19.94-15.5 0-24.54 9.92-24.54 21.1v1.17Z"/>
          </svg>
        </div>
        <div class="audio-player__control--circle" @click="skipRelative(5)">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 399.69 399.28">
            <path fill="currentColor" d="m325.45 355.6-20.06-30.93c-3.3 2.31-6.44 4.46-9.52 6.67a161.34 161.34 0 0 1-67.63 28.17 165.23 165.23 0 0 1-79.89-5.93c-27.16-8.76-50-24.27-69.16-45.39a156.85 156.85 0 0 1-37-70.12A163.28 163.28 0 0 1 37.44 198a170 170 0 0 1 7.08-45.76 154.73 154.73 0 0 1 29.63-55.6c19.95-24 44.45-41.57 74.25-51.22 20.7-6.7 42-9.67 63.78-7.88 27.54 2.25 53.18 10.4 76.36 25.63a161.18 161.18 0 0 1 47.4 47.69c.16.26.28.55.59 1.17H275.1v37.28h124.59V24.72h-37.14v58.47C323.48 31.34 272.36 2.65 208 .18s-118.24 21.2-160.12 70c-69 80.39-61.5 198.66 10.72 270.61 74.04 73.8 188.74 76.67 266.85 14.81Z"/>
            <path fill="currentColor" d="M177.09 239.63c2.59 11.48 15.28 16.73 28.09 16.73 14.52 0 28.84-9.82 28.84-27.23 0-15.66-13.45-25.48-29.06-25.48-14.09 0-22.81 7.39-26.36 13h-21.09l6.35-76.92h86.4v19.06h-66.5l-3.55 37.93h1.19c3.12-4.28 13.13-11.28 29.59-11.28 25.39 0 47 15.56 47 43 0 28.1-21.73 46.68-53.69 46.68-28.84 0-48.75-15.27-49.93-35.5Z"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'audio-player',
  props: ['src', 'allowDownload'],
  data() {
    return {
      audio: null,
      status: null, // null, loading, loaded, playing, paused
      isAudioLoaded: false,
      currentTimeSecs: 0.0,
      totalTimeSecs: 0.0,
      isScrubbing: false,
    };
  },
  computed: {
    isLoading() {
      return this.status === 'loading';
    },
    currentTime() {
      return this.isAudioLoaded ? this.formatTime(this.currentTimeSecs) : '--:--';
    },
    totalTime() {
      return this.isAudioLoaded ? this.formatTime(this.totalTimeSecs) : '--:--';
    },
    durationPercent() {
      const percent = (this.currentTimeSecs / this.totalTimeSecs) * 100;
      return `${percent}%`;
    },
  },
  mounted() {
    this.audio = this.src !== null ? new Audio(this.src) : new Audio();
    this.audio.addEventListener('timeupdate', () => { this.currentTimeSecs = this.audio.currentTime; });
    this.audio.addEventListener('loadeddata', () => {
      this.status = 'loaded';
      this.isAudioLoaded = true;
      this.audio.play();
    });
    this.audio.addEventListener('loadedmetadata', () => { this.totalTimeSecs = this.audio.duration; });
    this.audio.addEventListener('ended', () => { this.status = 'paused'; });
    this.audio.addEventListener('pause', () => { this.status = 'paused'; });
    this.audio.addEventListener('play', () => { this.status = 'playing'; });
  },
  beforeDestroy() {
    this.audio.pause();
  },
  methods: {
    togglePlay() {
      if (this.src === null) {
        this.status = 'loading';
        this.$emit('loadUrl');
      } else if (this.audio.paused) {
        this.audio.play();
      } else {
        this.audio.pause();
      }
    },
    startScrubbing() {
      if (!this.isAudioLoaded) {
        alert('Please press play before skipping'); // eslint-disable-line no-alert
        return;
      }
      this.isScrubbing = true;
    },
    scrub(event) {
      if (this.isScrubbing) {
        this.audio.pause();
        this.syncTimeToMouseEvent(event);
      }
    },
    stopScrubbing(event) {
      if (this.isScrubbing) {
        this.isScrubbing = false;
        this.syncTimeToMouseEvent(event);
        this.audio.play();
      }
    },
    syncTimeToMouseEvent(event) {
      const sliderRect = this.$refs.durationSlider.getBoundingClientRect();
      const mouseX = Math.min(sliderRect.width, Math.max(0, event.clientX - sliderRect.left));
      const sliderPinPercent = mouseX / sliderRect.width;
      const setTimeTo = this.totalTimeSecs * sliderPinPercent;
      this.audio.currentTime = setTimeTo;
    },
    formatTime(time) {
      const min = Math.floor(time / 60);
      const sec = Math.floor(time % 60);
      return `${min}:${(sec < 10) ? (`0${sec}`) : sec}`;
    },
    skipRelative(secs) {
      const current = this.audio.currentTime;
      let changeTo = current + secs;
      if (changeTo < 0) { changeTo = 0; }
      if (changeTo > this.totalTimeSecs) { changeTo = this.totalTimeSecs; }
      this.audio.currentTime = changeTo;
      if (this.status === 'paused') {
        this.audio.play();
      }
    },
    downloadAudioFile() {
      window.open(this.src, '_blank');
    },
  },
  watch: {
    src() {
      this.audio.src = this.src;
    },
  },
};
</script>
<style lang="scss">
.audio-player {
  position: relative;
   filter:         drop-shadow(0px 4px 4px rgba(0, 0, 0, .07));

  &__main {
    position: relative;
    //box-shadow: 0 4px 16px 0 rgba(0, 0, 0, .07);
    padding: 16px 24px;
    border-radius: 4px;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    z-index:2;
  }

  &__button {
    cursor: pointer;
  }

  &__loading {
    &--spinner {
      width: 18px;
      height: 18px;
      background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/355309/loading.png);
      background-size: cover;
      background-repeat: no-repeat;
      animation: spin 0.4s linear infinite;
    }
  }

  &__progress {
    background-color: #44BFA3;
    border-radius: inherit;
    position: absolute;
    pointer-events: none;
    width: 0;
    height: 100%;
    &--pin {
      height: 16px;
      width: 16px;
      border-radius: 8px;
      background-color: #44BFA3;
      position: absolute;
      pointer-events: none;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.32);
      right: -8px;
      top: -6px;
    }
  }

  &__controls {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 18px;
    color: #55606E;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    margin-left: 24px;
    margin-right: 24px;
    span {
      cursor: default;
    }
  }

  &__extra {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 1;
    transform: translateY(-100%);
    transition: transform 0.35s ease-in-out;
  }

  &__skip_controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap:12px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    //box-shadow: 0 6px 6px 0 rgba(0, 0, 0, .07);
    background: #fff;
    padding: 6px 15px;
  }

  &__control--circle {
    width: 22px;
    height: 22px;
    color: #566574;
    cursor: pointer;
    &:hover {
      color: #3c4c5c;
    }
  }

  &__slider-hit-area {
    flex-grow: 1;
    padding: 15px 0;
    cursor: pointer;
    // background: rgba(255,255,0,0.5);
  }

  &__slider {
    background-color: #D8D8D8;
    position: relative;
    margin-left: 16px;
    margin-right: 16px;
    border-radius: 2px;
    height: 4px;
  }

  &.show-skip-controls {
     .audio-player__main { border-bottom-left-radius: 0; }
     .audio-player__extra {
      transform: translateY(0);
      // .audio-player__skip-controls {
      //   //box-shadow: 0 6px 6px 0 rgba(0, 0, 0, .07);
      // }
     }

  }
}

@keyframes spin {
  from {
    transform: rotateZ(0);
  }
  to {
    transform: rotateZ(1turn);
  }
}
</style>
