var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("wrapper", [
    _c(
      "section",
      { staticClass: "promocode-page" },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: "text-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/promocodes")
                    }
                  }
                },
                [_vm._v("Back to overview")]
              )
            ])
          ])
        ]),
        _vm.promocode
          ? [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "box box-info" }, [
                    _c("div", { staticClass: "box-header" }, [
                      _c("h3", { staticClass: "box-title" }, [
                        _vm._v("General")
                      ])
                    ]),
                    _c("div", { staticClass: "box-body" }, [
                      _c("table", { staticClass: "table table-striped" }, [
                        _c("tbody", [
                          _c("tr", [
                            _c("th", [_vm._v("Description")]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.promocodePrettyDescription))
                            ])
                          ]),
                          _vm.promocode.type !== "free_trial"
                            ? _c("tr", [
                                _c("th", [_vm._v("Discount Value")]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.promocode.discount_value))
                                ])
                              ])
                            : _vm._e(),
                          _vm.displayOnlyFor("subscription")
                            ? _c("tr", [
                                _c("th", [_vm._v("Duration Length")]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.promocode.duration_length))
                                ])
                              ])
                            : _vm._e(),
                          _vm.displayOnlyFor("subscription")
                            ? _c("tr", [
                                _c("th", [_vm._v("Duration Unit")]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.promocode.duration_unit))
                                ])
                              ])
                            : _vm._e()
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "box box-info" }, [
                    _c("div", { staticClass: "box-header" }, [
                      _c("h3", { staticClass: "box-title" }, [
                        _vm._v("Restrictions")
                      ])
                    ]),
                    _c("div", { staticClass: "box-body" }, [
                      _c("table", { staticClass: "table table-striped" }, [
                        _c("tbody", [
                          _c("tr", [
                            _c("th", [_vm._v("Valid From")]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.formatDate(_vm.promocode.valid_from))
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("Valid Until")]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatDate(_vm.promocode.valid_until)
                                )
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("Pricing Options")]),
                            _c("td", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.formatPricingOptions(
                                    _vm.promocode.valid_options
                                  )
                                )
                              }
                            })
                          ]),
                          _vm.displayOnlyFor("subscription")
                            ? _c("tr", [
                                _c("th", [_vm._v("Min User Created At")]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatDate(
                                        _vm.promocode.min_user_created_at
                                      )
                                    )
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _vm.displayOnlyFor("subscription")
                            ? _c("tr", [
                                _c("th", [
                                  _vm._v("Min Subscription Created At")
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatDate(
                                        _vm.promocode
                                          .min_subscription_created_at
                                      )
                                    )
                                  )
                                ])
                              ])
                            : _vm._e()
                        ])
                      ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "box box-info" }, [
                    _c("div", { staticClass: "box-header" }, [
                      _c("h3", { staticClass: "box-title" }, [
                        _vm._v("Usage limits")
                      ])
                    ]),
                    _c("div", { staticClass: "box-body" }, [
                      _c("table", { staticClass: "table table-striped" }, [
                        _c("tbody", [
                          _c("tr", [
                            _c("th", [_vm._v("Max Uses Global")]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.promocode.max_uses_global))
                            ])
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("Max Uses Per User")]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.promocode.max_uses_per_user))
                            ])
                          ]),
                          _vm.displayOnlyFor("subscription")
                            ? _c("tr", [
                                _c("th", [
                                  _vm._v("First Time Subscribers Only")
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatBool(
                                        _vm.promocode
                                          .only_first_time_subscribers
                                      )
                                    )
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _vm.displayOnlyFor("subscription")
                            ? _c("tr", [
                                _c("th", [
                                  _vm._v("Exclude Current Subscribers")
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatBool(
                                        _vm.promocode
                                          .exclude_current_subscribers
                                      )
                                    )
                                  )
                                ])
                              ])
                            : _vm._e()
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "box box-info" }, [
                    _c("div", { staticClass: "box-header" }, [
                      _c("h3", { staticClass: "box-title" }, [
                        _vm._v("Web links")
                      ])
                    ]),
                    _c("div", { staticClass: "box-body" }, [
                      _c("div", [_c("strong", [_vm._v("Pretty URL")])]),
                      _c("div", [
                        _c("span", { staticClass: "lead" }, [
                          _vm._v(
                            _vm._s(_vm.promotionCodePrettyUrl(_vm.promocode))
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.promotionCodePrettyUrl(_vm.promocode),
                              title: _vm.promotionCodePrettyUrl(_vm.promocode),
                              target: "_blank"
                            }
                          },
                          [_c("i", { staticClass: "fa fa-external-link" })]
                        )
                      ]),
                      _c("div", [
                        _c("em", [
                          _vm._v(
                            "Use this when sharing the link in plain text such as on Twitter or Facebook."
                          )
                        ])
                      ]),
                      _c("p"),
                      _c("div", [
                        _c("strong", [_vm._v("Effective URL")]),
                        _vm._v(" "),
                        _c("span", { staticClass: "label label-success" }, [
                          _vm._v("Preferred")
                        ])
                      ]),
                      _c("div", [
                        _c("span", { staticClass: "lead" }, [
                          _vm._v(
                            _vm._s(_vm.promotionCodeRealUrl(_vm.promocode))
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.promotionCodeRealUrl(_vm.promocode),
                              title: _vm.promotionCodeRealUrl(_vm.promocode),
                              target: "_blank"
                            }
                          },
                          [_c("i", { staticClass: "fa fa-external-link" })]
                        )
                      ]),
                      _c("div", [
                        _c("em", [
                          _vm._v(
                            "Use this whenever the link is not visible, such as in QR codes, Buttons or URL minifiers."
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ]
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }