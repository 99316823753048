<template>
  <form action="" class="form-horizontal school-form" @submit.prevent="validateForm">
    <fieldset>
      <legend>Information</legend>
      <div class="form-group" :class="{'has-error': errors.first('name')}">
        <label for="name" class="col-sm-2 control-label">Name</label>
        <div class="col-sm-10 input-group">
          <span class="input-group-addon"><i class="fa fa-user"></i></span>
          <input type="text" class="form-control" name="name" id="name" autocomplete="off"
                 v-model="mutableValue.name"
                 v-validate="'required'">
        </div>
        <div class="col-sm-10 col-sm-offset-2 help-block is-danger" v-show="errors.has('name')">
          {{ errors.first('name') }}
        </div>
      </div>
      <div class="form-group" :class="{'has-error': errors.first('urn')}">
        <label for="urn" class="col-sm-2 control-label">URN</label>
        <div class="input-group">
          <span class="input-group-addon"><i class="fa fa-user"></i></span>
          <input type="text" class="form-control" name="urn" id="urn" autocomplete="off"
                 v-model="mutableValue.urn"
                 v-validate="'required'">
        </div>
        <div class="col-sm-10 col-sm-offset-2 help-block is-danger" v-show="errors.has('urn')">
          {{ errors.first('urn') }}
        </div>
      </div>
    </fieldset>
    <fieldset>
      <legend>Primary Contact</legend>
      <contact-form v-model="mutableValue.primaryContact" :countries="countries" @updated="handlePrimaryContactUpdate" v-if="countriesLoaded">
      </contact-form>
    </fieldset>
    <fieldset>
      <legend>Billing Contact</legend>
      <div class="form-group form-checkbox">
        <label class="control-label">
          <input type="checkbox" v-model="mutableValue.billingSameAsPrimary">
          Billing address same as primary contact
        </label>
      </div>
      <contact-form v-model="mutableValue.billingContact" :countries="countries" prefix="billing" v-if="!mutableValue.billingSameAsPrimary && countriesLoaded" @updated="handleBillingContactUpdate">
      </contact-form>
    </fieldset>
    <div class="box-footer">
      <loading-button>Submit</loading-button>
    </div>
  </form>
</template>

<script>
import ContactForm from '@/components/forms/ContactForm';
import FormMixin from '@/mixins/FormMixin';
import LoadingButton from '@/components/utils/LoadingButton';
import CountriesMixin from '@/mixins/CountriesMixin';

export default {
  name: 'school-form',
  props: ['value'],
  inject: ['$validator'],
  mixins: [FormMixin, CountriesMixin],
  components: {
    ContactForm,
    LoadingButton,
    'contact-form': ContactForm,
    'loading-button': LoadingButton,
  },
  data() {
    return {
      mutableValue: {},
      countries: [],
    };
  },
  mounted() {
    this.getCountries();
    this.mutableValue = { ...this.value };
  },
  methods: {
    handlePrimaryContactUpdate(newValue) {
      this.mutableValue.primaryContact = newValue;
    },
    handleBillingContactUpdate(newValue) {
      this.mutableValue.billingContact = newValue;
    },
  },
  watch: {
    mutableValue: {
      handler(newValue) {
        this.$emit('updated', newValue);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.form-horizontal .form-group {
  margin-left: 0;
  margin-right: 0;
}

form.school-form .help-block {
  padding-left: 0;
}

.input-group span.input-group-addon i {
  width: 14px;
}
</style>
