<template>
    <section class="schools-page">
      <div class="row">
        <div class="col-md-8">
          <create-school-wizard/>
        </div>
      </div>
    </section>
</template>

<script>
import CreateSchoolWizard from '@/components/forms/CreateSchoolWizard';

export default {
  name: 'create-school',
  components: {
    CreateSchoolWizard,
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>
