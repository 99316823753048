var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("wrapper", [
    _c(
      "section",
      {
        staticClass: "content dashboard-page",
        class: _vm.isLoading ? "is-loading" : "has-loaded"
      },
      [
        _c("div", { staticClass: "dashboard-compare" }, [
          _c("span", [_vm._v("Compare to previous")]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.compareToLastYear,
                  expression: "compareToLastYear"
                }
              ],
              staticClass: "dashboard-compare__select",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.compareToLastYear = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            [
              _c("option", { domProps: { value: false } }, [_vm._v("period")]),
              _c("option", { domProps: { value: true } }, [_vm._v("year")])
            ]
          )
        ]),
        _c("h2", [_vm._v("Home Users")]),
        _c(
          "div",
          { staticClass: "dashbox__grid" },
          [
            _c(
              "DashBox",
              {
                attrs: {
                  id: "dashbox-total-home-users",
                  title: "Total Home Users",
                  value: _vm.stats.home.total_users.value
                }
              },
              [
                _c("DashBoxVelocityTable", {
                  attrs: {
                    slot: "table",
                    periods: _vm.stats.home.total_users.periods,
                    compareToLastYear: _vm.compareToLastYear
                  },
                  slot: "table"
                })
              ],
              1
            ),
            _c(
              "FlipCard",
              { attrs: { id: "dashbox-active-home-users" } },
              [
                _c("DashBox", {
                  attrs: {
                    slot: "front",
                    title: "Active Home Users",
                    value: _vm.stats.home.active_users.value
                  },
                  slot: "front"
                }),
                _c(
                  "div",
                  {
                    staticClass: "dashbox__help",
                    attrs: { slot: "back" },
                    slot: "back"
                  },
                  [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.stats.home.active_users.value.toLocaleString("en")
                        ) + " home users"
                      )
                    ]),
                    _vm._v(" have been seen on the Fonetti platform in the "),
                    _c("strong", [_vm._v("past 7 days")]),
                    _vm._v(".")
                  ]
                )
              ],
              1
            ),
            _c(
              "DashBox",
              {
                attrs: {
                  id: "dashbox-subscribers",
                  title: "Subscribers",
                  value: _vm.stats.home.subscribers.value,
                  conversion: _vm.stats.home.subscribers.conversion_rate
                }
              },
              [
                _c("template", { slot: "table" }, [
                  _c("table", { staticClass: "dashbox__table" }, [
                    _c("tbody", [
                      _c("tr", [
                        _c("td", [_vm._v("Android")]),
                        _c("td", [
                          _c("i", { staticClass: "fa fa-fw fa-user" }),
                          _vm._v(
                            _vm._s(
                              _vm.stats.home.subscribers.providers.android
                                .single
                            )
                          )
                        ]),
                        _c("td", [
                          _c("i", { staticClass: "fa fa-fw fa-users" }),
                          _vm._v(
                            _vm._s(
                              _vm.stats.home.subscribers.providers.android.multi
                            )
                          )
                        ])
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("iOS")]),
                        _c("td", [
                          _c("i", { staticClass: "fa fa-fw fa-user" }),
                          _vm._v(
                            _vm._s(
                              _vm.stats.home.subscribers.providers.ios.single
                            )
                          )
                        ]),
                        _c("td", [
                          _c("i", { staticClass: "fa fa-fw fa-users" }),
                          _vm._v(
                            _vm._s(
                              _vm.stats.home.subscribers.providers.ios.multi
                            )
                          )
                        ])
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("Stripe")]),
                        _c("td", [
                          _c("i", { staticClass: "fa fa-fw fa-user" }),
                          _vm._v(
                            _vm._s(
                              _vm.stats.home.subscribers.providers.stripe.single
                            )
                          )
                        ]),
                        _c("td", [
                          _c("i", { staticClass: "fa fa-fw fa-users" }),
                          _vm._v(
                            _vm._s(
                              _vm.stats.home.subscribers.providers.stripe.multi
                            )
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ],
              2
            ),
            _c(
              "DashBox",
              {
                attrs: {
                  id: "dashbox-home-total-readers",
                  title: "Total Home Readers",
                  value: _vm.stats.home.total_readers.value
                }
              },
              [
                _c("DashBoxVelocityTable", {
                  attrs: {
                    slot: "table",
                    periods: _vm.stats.home.total_readers.periods,
                    compareToLastYear: _vm.compareToLastYear
                  },
                  slot: "table"
                })
              ],
              1
            ),
            _c(
              "FlipCard",
              { attrs: { id: "dashbox-home-active-readers" } },
              [
                _c("DashBox", {
                  attrs: {
                    slot: "front",
                    title: "Active Home Readers",
                    value: _vm.stats.home.active_readers.value
                  },
                  slot: "front"
                }),
                _c(
                  "div",
                  {
                    staticClass: "dashbox__help",
                    attrs: { slot: "back" },
                    slot: "back"
                  },
                  [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.stats.home.active_readers.value.toLocaleString(
                            "en"
                          )
                        ) + " home readers"
                      )
                    ]),
                    _vm._v(" have opened a book in the "),
                    _c("strong", [_vm._v("past 7 days")]),
                    _vm._v(".")
                  ]
                )
              ],
              1
            ),
            _c(
              "DashBox",
              {
                attrs: {
                  id: "dashbox-home-read-time",
                  title: "Reading Time",
                  value: _vm.format_seconds(_vm.stats.home.reading_time.value)
                }
              },
              [
                _c("DashBoxVelocityTable", {
                  attrs: {
                    slot: "table",
                    periods: _vm.stats.home.reading_time.periods,
                    compareToLastYear: _vm.compareToLastYear,
                    numType: "seconds"
                  },
                  slot: "table"
                })
              ],
              1
            ),
            _c(
              "DashBox",
              {
                attrs: {
                  id: "dashbox-home-books-read",
                  title: "Books Read",
                  value: _vm.stats.home.books_read.value
                }
              },
              [
                _c("DashBoxVelocityTable", {
                  attrs: {
                    slot: "table",
                    periods: _vm.stats.home.books_read.periods,
                    compareToLastYear: _vm.compareToLastYear
                  },
                  slot: "table"
                })
              ],
              1
            )
          ],
          1
        ),
        _c("h2", [_vm._v("Schools")]),
        _c(
          "div",
          { staticClass: "dashbox__grid" },
          [
            _c("DashBox", {
              attrs: {
                id: "dashbox-total-schools",
                title: "Total Schools",
                value: _vm.stats.schools.total_schools.value
              }
            }),
            _c(
              "FlipCard",
              { attrs: { id: "dashbox-total-teachers" } },
              [
                _c("DashBox", {
                  attrs: {
                    slot: "front",
                    title: "Total Teachers",
                    value: _vm.stats.schools.total_teachers.value,
                    active_value: _vm.stats.schools.total_teachers.active
                  },
                  slot: "front"
                }),
                _c(
                  "div",
                  {
                    staticClass: "dashbox__help",
                    attrs: { slot: "back" },
                    slot: "back"
                  },
                  [
                    _c("i", { staticClass: "fa fa-bolt" }),
                    _vm._v(" "),
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.stats.schools.total_teachers.active.toLocaleString(
                            "en"
                          )
                        )
                      )
                    ]),
                    _vm._v(" active teachers have logged in, in the "),
                    _c("strong", [_vm._v("past 7 days")]),
                    _vm._v(".")
                  ]
                )
              ],
              1
            ),
            _c("DashBox", {
              attrs: {
                id: "dashbox-total-classes",
                title: "Total Classes",
                value: _vm.stats.schools.total_classes.value
              }
            }),
            _c(
              "FlipCard",
              { attrs: { id: "dashbox-total-pupils" } },
              [
                _c("DashBox", {
                  attrs: {
                    slot: "front",
                    title: "Total Pupils",
                    value: _vm.stats.schools.total_pupils.value,
                    active_value: _vm.stats.schools.total_pupils.active
                  },
                  slot: "front"
                }),
                _c(
                  "div",
                  {
                    staticClass: "dashbox__help",
                    attrs: { slot: "back" },
                    slot: "back"
                  },
                  [
                    _c("i", { staticClass: "fa fa-bolt" }),
                    _vm._v(" "),
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.stats.schools.total_pupils.active.toLocaleString(
                            "en"
                          )
                        )
                      )
                    ]),
                    _vm._v(" active pupils have opened a book in the "),
                    _c("strong", [_vm._v("past 7 days")]),
                    _vm._v(".")
                  ]
                )
              ],
              1
            ),
            _c(
              "DashBox",
              {
                attrs: {
                  id: "dashbox-school-read-time",
                  title: "Reading Time",
                  value: _vm.format_seconds(
                    _vm.stats.schools.reading_time.value
                  )
                }
              },
              [
                _c("DashBoxVelocityTable", {
                  attrs: {
                    slot: "table",
                    periods: _vm.stats.schools.reading_time.periods,
                    compareToLastYear: _vm.compareToLastYear,
                    numType: "seconds"
                  },
                  slot: "table"
                })
              ],
              1
            ),
            _c(
              "DashBox",
              {
                attrs: {
                  id: "dashbox-school-books-read",
                  title: "Books Read",
                  value: _vm.stats.schools.books_read.value
                }
              },
              [
                _c("DashBoxVelocityTable", {
                  attrs: {
                    slot: "table",
                    periods: _vm.stats.schools.books_read.periods,
                    compareToLastYear: _vm.compareToLastYear
                  },
                  slot: "table"
                })
              ],
              1
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }