var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "books-report-page" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-3" },
        [
          _c(
            "FlipCard",
            { attrs: { id: "dashbox-active-home-users" } },
            [
              _c("DashBox", {
                attrs: {
                  slot: "front",
                  title: "Reading Sessions",
                  value: _vm.startedReadingSessionsTotal
                },
                slot: "front"
              }),
              _c(
                "div",
                {
                  staticClass: "dashbox__help",
                  attrs: { slot: "back" },
                  slot: "back"
                },
                [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.startedReadingSessionsTotal.toLocaleString("en")
                      ) + " reading sessions"
                    )
                  ]),
                  _vm._v(" have been "),
                  _c("strong", [_vm._v("started")]),
                  _vm._v(" in the selected time period. "),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.completedReadingSessionsTotal))
                  ]),
                  _vm._v(" were complete reads.")
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-3" },
        [
          _c("DashBox", {
            attrs: {
              id: "dashbox-total-reading-time",
              title: "Reading Time",
              value: _vm.readingTimeTotal
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "col-md-6" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "align-items": "flex-end",
              gap: "1em"
            }
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  gap: "0.5em"
                }
              },
              [
                _c("label", [_vm._v("Filter by date:")]),
                _c("DatePickerWithDropdown", {
                  attrs: {
                    placeholder: "Show data for...",
                    reference: "time_period",
                    value: _vm.dateRangeModel,
                    initialDateRange: _vm.dateRangeModel
                  },
                  on: {
                    "date-range-change": function($event) {
                      return _vm.handleChangeDateRange($event, "fetchCharts")
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  gap: "2em"
                }
              },
              [
                _c("user-attribute-filters", {
                  attrs: { "show-user-type": false },
                  on: { select: _vm.onFilterUserAttributes }
                }),
                _c("reader-attribute-filters", {
                  on: { select: _vm.onFilterReaderAttributes }
                })
              ],
              1
            )
          ]
        )
      ])
    ]),
    _c("div", { staticClass: "row mt-5" }, [
      _c(
        "div",
        { staticClass: "col-md-12 charts-container" },
        [_c("chart", { attrs: { chart: _vm.charts.readingSessions } })],
        1
      )
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12 charts-container" },
        [
          _c("chart", {
            staticClass: "chart-featured mr-5",
            attrs: { chart: _vm.charts.readingTime }
          }),
          _c("chart", { attrs: { chart: _vm.charts.readingLevels } })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }