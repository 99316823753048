import _ from 'lodash';
import config from '@/config';

export default {
  methods: {
    textifyPricingOption(price) {
      return `${price.package_variant.package.title} -- ${price.duration_unit.charAt(0).toUpperCase() + price.duration_unit.slice(1)}ly -- [${_.capitalize(price.payment_provider)} | ${price.currency}]`;
    },
    readersToVariantTitle(readers) {
      // Variant titles should be added in API, for now we cheat!
      return readers === 1 ? 'Single' : 'Family';
    },
    promotionCodePrettyUrl(pc) {
      return `https://www.fonetti.com/promo/${pc.code}`;
    },
    promotionCodeRealUrl(pc) {
      return `${config.webPortalURL}/?promo=${pc.code}`;
    },
  },
};
