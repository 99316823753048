import { render, staticRenderFns } from "./Schools.vue?vue&type=template&id=3130c3b6&"
import script from "./Schools.vue?vue&type=script&lang=js&"
export * from "./Schools.vue?vue&type=script&lang=js&"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&lang=css&"
import style1 from "./Schools.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/annelise/Documents/Code/admin-panel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3130c3b6')) {
      api.createRecord('3130c3b6', component.options)
    } else {
      api.reload('3130c3b6', component.options)
    }
    module.hot.accept("./Schools.vue?vue&type=template&id=3130c3b6&", function () {
      api.rerender('3130c3b6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/schools/Schools.vue"
export default component.exports