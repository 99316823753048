<template>
  <div class="wrapper">
    <f-navbar></f-navbar>
    <f-sidebar></f-sidebar>
    <div class="content-wrapper">
      <section class="content-header" v-if="!$route.meta.noPageTitle">
        <h1>
          {{ pageHeading || $route.meta.title || $route.name.toUpperCase() }}
          <small>{{ $route.meta.description }}</small>
        </h1>
      </section>
      <section class="content">
        <div class="row">
          <div class="col-md-12">
            <action-messages></action-messages>
          </div>
        </div>
        <slot></slot>
      </section>
    </div>
    <f-footer></f-footer>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';
import Sidebar from '@/components/Sidebar';

export default {
  name: 'wrapper',
  components: {
    'f-navbar': Navbar,
    'f-footer': Footer,
    'f-sidebar': Sidebar,
  },
  created() {
    const adminLTEScript = document.createElement('script');
    if (!this.adminLTEIsLoaded) {
      adminLTEScript.setAttribute('src', '/js/AdminLTE/app.min.js');
      document.body.appendChild(adminLTEScript);
      this.$store.commit('SET_ADMIN_LTE', true);
    }
  },
  computed: {
    ...mapGetters([
      'adminLTEIsLoaded',
    ]),
    ...mapState('app', ['pageHeading']),
  },
};
</script>
