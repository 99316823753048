<template>
  <wrapper>
    <section class="subscription-page">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 box-tools">
          <div class="pull-right" v-if="user">
            <router-link :to="'/users/' + user.id + '/subscriptions/new'" class="btn btn-primary"><i
                class="fa fa-plus"></i> Add a free trial subscription
            </router-link>
          </div>
        </div>
      </div>
      <div class="row">
        <div v-if="subscriptions.length === 0" class="col-md-12">
          <div class="alert alert-info">
            No subscriptions
          </div>
        </div>
        <div class="col-md-6" v-for="subscription in subscriptions" :key="subscription.id">
          <div class="box">
            <div class="box-header" v-if="subscription.package">
              <h3 class="box-title">Package {{ subscription.package.type }}: {{ subscription.package.title }}</h3>
            </div>
            <div class="box-body no-padding table-responsive">
              <table class="table table-borderless">
                <tbody>
                <tr>
                  <th class="cell-25">Renewal Status</th>
                  <td>{{ subscription.renewal_status }}</td>
                </tr>
                <tr>
                  <th>Expiry Date</th>
                  <td>{{ subscription.expires_at }}</td>
                </tr>
                <tr>
                  <th>Max Readers</th>
                  <td>{{ subscription.max_readers }}</td>
                </tr>
                <tr>
                  <th>Duration</th>
                  <td>{{ subscription.renewal_duration_length }} {{ pluralize(subscription.renewal_duration_length,
                    subscription.renewal_duration_unit) }}
                  </td>
                </tr>
                <tr>
                  <th>Price</th>
                  <td>{{ subscription.payment_amount }}£</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </wrapper>
</template>

<script>
import Wrapper from '@/components/Wrapper';
import SubscriptionMixin from '@/mixins/SubscriptionMixin';
import UserMixin from '@/mixins/UserMixin';

export default {
  name: 'user-subscriptions',
  mixins: [SubscriptionMixin, UserMixin],
  components: {
    Wrapper,
  },
  data() {
    return {
      user: null,
      subscriptions: [],
      isLoading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getSubscriptionsData();
      this.getUserData();
    });
  },
  methods: {
    getSubscriptionsData() {
      this.getUserSubscriptions(this.$route.params.id)
        .then((subscriptions) => {
          this.subscriptions = subscriptions;
        });
    },
    getUserData() {
      this.getUser(this.$route.params.id)
        .then((user) => {
          this.user = user;
        });
    },
    pluralize(count, unit) {
      if (count === 0 || count > 1) return `${unit}s`;
      return unit;
    },
  },
};
</script>
