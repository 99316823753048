<template>
  <form class="ui form form-horizontal" @submit.prevent="validateForm" autocomplete="off">
    <div class="form-group" :class="{ 'has-error': errors.has('name') }">
      <label for="name" class="col-sm-2 control-label">Name</label>
      <div class="col-sm-10 col-md-8">
        <div class="input-group width-100">
          <input class="form-control width-100" name="first_name" placeholder="Name" type="text" autocomplete="off"
                 v-model="mutableValue.name"
                 v-validate="{ required: true, min: 2 }"
                 id="name">
        </div>
        <span v-show="errors.has('name')" class="text-danger">{{ errors.first('name') }}</span>
      </div>
    </div>

    <div class="form-group" :class="{ 'has-error': errors.has('age') }">
      <label for="age" class="col-sm-2 control-label">Age</label>
      <div class="col-sm-10 col-md-8">
        <div class="input-group width-100">
          <input class="form-control width-100" name="age" placeholder="Age" type="number" autocomplete="off"
                 v-model="mutableValue.age"
                 id="age">
        </div>
        <span v-show="errors.has('age')" class="text-danger">{{ errors.first('age') }}</span>
      </div>
    </div>

    <div class="form-group">
      <div class="col-sm-offset-2 col-sm-10 col-md-8">
        <loading-button>Submit</loading-button>
      </div>
    </div>
  </form>
</template>

<script>
import LoadingButton from '@/components/utils/LoadingButton';
import FormMixin from '@/mixins/FormMixin';

export default {
  name: 'reader-form',
  props: ['value', 'isNew'],
  mixins: [FormMixin],
  components: { LoadingButton },
  computed: {
    mutableValue() {
      return Object.assign(this.value);
    },
  },
};
</script>
