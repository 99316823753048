var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "ui form form-horizontal",
      attrs: { autocomplete: "off" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.validateForm($event)
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "form-group",
          class: { "has-error": _vm.errors.has("name") }
        },
        [
          _c(
            "label",
            { staticClass: "col-sm-2 control-label", attrs: { for: "name" } },
            [_vm._v("Name")]
          ),
          _c("div", { staticClass: "col-sm-10 col-md-8" }, [
            _c("div", { staticClass: "input-group width-100" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.mutableValue.name,
                    expression: "mutableValue.name"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { required: true, min: 2 },
                    expression: "{ required: true, min: 2 }"
                  }
                ],
                staticClass: "form-control width-100",
                attrs: {
                  name: "first_name",
                  placeholder: "Name",
                  type: "text",
                  autocomplete: "off",
                  id: "name"
                },
                domProps: { value: _vm.mutableValue.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.mutableValue, "name", $event.target.value)
                  }
                }
              })
            ]),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("name"),
                    expression: "errors.has('name')"
                  }
                ],
                staticClass: "text-danger"
              },
              [_vm._v(_vm._s(_vm.errors.first("name")))]
            )
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "form-group",
          class: { "has-error": _vm.errors.has("age") }
        },
        [
          _c(
            "label",
            { staticClass: "col-sm-2 control-label", attrs: { for: "age" } },
            [_vm._v("Age")]
          ),
          _c("div", { staticClass: "col-sm-10 col-md-8" }, [
            _c("div", { staticClass: "input-group width-100" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.mutableValue.age,
                    expression: "mutableValue.age"
                  }
                ],
                staticClass: "form-control width-100",
                attrs: {
                  name: "age",
                  placeholder: "Age",
                  type: "number",
                  autocomplete: "off",
                  id: "age"
                },
                domProps: { value: _vm.mutableValue.age },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.mutableValue, "age", $event.target.value)
                  }
                }
              })
            ]),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("age"),
                    expression: "errors.has('age')"
                  }
                ],
                staticClass: "text-danger"
              },
              [_vm._v(_vm._s(_vm.errors.first("age")))]
            )
          ])
        ]
      ),
      _c("div", { staticClass: "form-group" }, [
        _c(
          "div",
          { staticClass: "col-sm-offset-2 col-sm-10 col-md-8" },
          [_c("loading-button", [_vm._v("Submit")])],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }