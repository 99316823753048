var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("aside", { staticClass: "main-sidebar" }, [
    _c("section", { staticClass: "sidebar" }, [
      _c(
        "ul",
        { staticClass: "sidebar-menu", attrs: { "data-widget": "tree" } },
        [
          _c("li", { staticClass: "header" }, [_vm._v("MAIN NAVIGATION")]),
          !_vm.accessRestricted
            ? _c(
                "router-link",
                {
                  staticClass: "pageLink",
                  attrs: {
                    tag: "li",
                    "active-class": "active",
                    to: "/",
                    exact: ""
                  }
                },
                [
                  _c("a", [
                    _c("i", { staticClass: "fa fa-desktop" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "page" }, [_vm._v("Dashboard")])
                  ])
                ]
              )
            : _vm._e(),
          _vm.hasAccess("reports")
            ? _c("li", { staticClass: "treeview reports" }, [
                _vm._m(0),
                _c(
                  "ul",
                  { staticClass: "treeview-menu" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "pageLink",
                        attrs: {
                          tag: "li",
                          "active-class": "active",
                          to: "/reports/books",
                          exact: ""
                        }
                      },
                      [
                        _c("a", [
                          _c("i", { staticClass: "fa fa-circle-o" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "page" }, [
                            _vm._v("Books Usage")
                          ])
                        ])
                      ]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "pageLink",
                        attrs: {
                          tag: "li",
                          "active-class": "active",
                          to: "/reports/users",
                          exact: ""
                        }
                      },
                      [
                        _c("a", [
                          _c("i", { staticClass: "fa fa-circle-o" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "page" }, [_vm._v("Users")])
                        ])
                      ]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "pageLink",
                        attrs: {
                          tag: "li",
                          "active-class": "active",
                          to: "/reports/subscribers",
                          exact: ""
                        }
                      },
                      [
                        _c("a", [
                          _c("i", { staticClass: "fa fa-circle-o" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "page" }, [
                            _vm._v("Subscribers")
                          ])
                        ])
                      ]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "pageLink",
                        attrs: {
                          tag: "li",
                          "active-class": "active",
                          to: "/reports/reading",
                          exact: ""
                        }
                      },
                      [
                        _c("a", [
                          _c("i", { staticClass: "fa fa-circle-o" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "page" }, [
                            _vm._v("Reading")
                          ])
                        ])
                      ]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "pageLink",
                        attrs: {
                          tag: "li",
                          "active-class": "active",
                          to: "/reports/purchases",
                          exact: ""
                        }
                      },
                      [
                        _c("a", [
                          _c("i", { staticClass: "fa fa-circle-o" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "page" }, [
                            _vm._v("Purchases")
                          ])
                        ])
                      ]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "pageLink",
                        attrs: {
                          tag: "li",
                          "active-class": "active",
                          to: "/reports/challenges",
                          exact: ""
                        }
                      },
                      [
                        _c("a", [
                          _c("i", { staticClass: "fa fa-circle-o" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "page" }, [
                            _vm._v("Challenges")
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm.hasAccess("schools")
            ? _c(
                "router-link",
                {
                  staticClass: "pageLink",
                  attrs: { tag: "li", "active-class": "active", to: "/schools" }
                },
                [
                  _c("a", [
                    _c("i", { staticClass: "fa fa-building" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "page" }, [_vm._v("Schools")])
                  ]),
                  !!_vm.currentSchool
                    ? _c(
                        "ul",
                        {
                          staticClass: "treeview-menu menu-open menu-fixed-open"
                        },
                        [
                          !_vm.accessRestricted
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "pageLink",
                                  attrs: {
                                    tag: "li",
                                    "active-class": "active",
                                    to: "/schools/" + _vm.currentSchool.id
                                  }
                                },
                                [
                                  _c("a", [
                                    _c("i", { staticClass: "fa fa-circle-o" }),
                                    _vm._v(
                                      _vm._s(
                                        _vm.trimMenuText(_vm.currentSchool.name)
                                      )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm.hasAccess("users")
            ? _c(
                "router-link",
                {
                  staticClass: "pageLink",
                  attrs: {
                    tag: "li",
                    "active-class": "active",
                    to: "/users",
                    exact: ""
                  }
                },
                [
                  _c("a", [
                    _c("i", { staticClass: "fa fa-users" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "page" }, [_vm._v("Users")])
                  ])
                ]
              )
            : _vm._e(),
          _vm.hasAccess("users")
            ? _c(
                "router-link",
                {
                  staticClass: "pageLink",
                  attrs: {
                    tag: "li",
                    "active-class": "active",
                    to: "/promocodes",
                    exact: ""
                  }
                },
                [
                  _c("a", [
                    _c("i", { staticClass: "fa fa-ticket" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "page" }, [_vm._v("Promocodes")])
                  ])
                ]
              )
            : _vm._e(),
          _vm.hasAccess("recordings")
            ? _c(
                "router-link",
                {
                  staticClass: "pageLink",
                  attrs: {
                    tag: "li",
                    "active-class": "active",
                    to: "/recordings",
                    exact: ""
                  }
                },
                [
                  _c("a", [
                    _c("i", { staticClass: "fa fa-microphone" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "page" }, [_vm._v("Recordings")])
                  ])
                ]
              )
            : _vm._e(),
          _vm.isSuperAdmin
            ? _c(
                "router-link",
                {
                  staticClass: "pageLink",
                  attrs: {
                    tag: "li",
                    "active-class": "active",
                    to: "/admins",
                    exact: ""
                  }
                },
                [
                  _c("a", [
                    _c("i", { staticClass: "fa fa-users" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "page" }, [_vm._v("Admins")])
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "#" } }, [
      _c("i", { staticClass: "fa fa-table" }),
      _vm._v(" "),
      _c("span", [_vm._v("Reports")]),
      _c("span", { staticClass: "pull-right-container" }, [
        _c("i", { staticClass: "fa fa-angle-left pull-right" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }