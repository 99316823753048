<template>
  <wrapper>
    <section class="recordings-page">
      <div class="flex justify-content-between top-filters">
        <user-attribute-filters @select="onChallengeFilter" :show-user-type="false"></user-attribute-filters>
        <school-attribute-filters class="ml-5"  @select="onSchoolFilter" />
        <div class="user-filters ml-5">
          <div class="user-filter user-filter__break">
            <div class="label-container"><label>Tags:</label></div>
            <multiselect v-model="serverParams.filterTags" placeholder="Search for a tag" :options="filterTagOptions" :multiple="true" @input="loadItems"></multiselect>
          </div>
        </div>
        <div class="user-filters ml-5">
          <div class="user-filter user-filter__break">
            <div class="label-container"><label>Transcription status:</label></div>
            <select name="transcriptionStatus" v-model="serverParams.transcriptionStatus" @change="loadItems">
              <option value="any">Any</option>
              <option value="transcribed">Transcribed</option>
              <option value="not_transcribed">Not Transcribed</option>
              <option value="pending">Marked for Transcription</option>
            </select>
          </div>
        </div>
        <div class="transcription-filters user-filters ml-5">
          <div class="label-container">
            <label for="transcribed">Hide Transcribed Recordings:</label>
          </div>
          <input type="number" name="transcibed" id="transcribed" min="0" @change="onHideTranscribedChanged">
        </div>
        <div class="transcription-filters user-filters ml-5">
          <div class="label-container">
            <label for="transcribed">Hide if shorter than (secs):</label>
          </div>
          <input type="number" name="transcibed" id="transcribed" min="0" @change="onHideShorterThan">
        </div>
      </div>

      <vue-good-table
        v-if="showTable"
        styleClass="vgt-table striped"
        theme="auris-admin"
        mode="remote"
        :columns="columns"
        :rows="rows"
        :totalRows="totalRecords"
        :pagination-options="paginationOptions"
        :search-options="{
          enabled: false,
        }"
        :sort-options="{
          enabled: true,
          initialSortBy: serverParams.sort[0]
        }"
        :isLoading="loadingTableData"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        @on-search="onSearchChange"
      >
      <template slot="table-row" slot-scope="props">
        <router-link v-if="props.column.field == 'actions'" :to="`/recordings/${props.row.id}`"><i class="fa fa-eye"></i>view</router-link>
      </template>
      <template slot="loadingContent">
        <div class="loadingContent">
            <i class="fa fa-refresh fa-spin"></i>
        </div>
      </template>
      <div slot="table-actions">
        <div class="button-group">
          <button type="button" class="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown"><i class="fa fa-cog" aria-hidden="true"></i> <span class="caret"></span></button>
          <ul class="dropdown-menu" style="top: auto; left: auto; right: 0;">
            <li v-for="(column, index) in columns" :key="index">
              <a href="#" class="small" tabIndex="-1" @click.capture.stop="toggleColumn( index, $event )"><input :checked="!column.hidden" type="checkbox" />&nbsp;{{column.label}}</a>
            </li>
          </ul>
        </div>
      </div>
      <template slot="column-filter" slot-scope="props">
        <div class="column-filter-datepicker" v-if="props.column.filterOptions && props.column.filterOptions.customFilter">
          <DatePickerWithDropdown
            reference="time_period"
            v-model="props.column.filterOptions.filterValue"
          />
        </div>
      </template>
      </vue-good-table>
    </section>
  </wrapper>
</template>

<script>
import Wrapper from '@/components/Wrapper';
import api from '@/api';
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';
import moment from 'moment';
import qs from 'qs';
import { debounce, findIndex, isEqual } from 'lodash';
import DatePickerWithDropdown from '@/components/DatePickerWithDropdown';
import UserAttributeFilters from '@/components/UserAttributeFilters';
import SchoolAttributeFilters from '@/components/SchoolAttributeFilters';
import Multiselect from 'vue-multiselect';

export default {
  name: 'recordings',
  components: {
    SchoolAttributeFilters,
    Wrapper,
    VueGoodTable,
    DatePickerWithDropdown,
    UserAttributeFilters,
    Multiselect,
  },
  data() {
    return {
      showTable: false,
      prevRoute: null,
      tableInited: false,
      loadingTableData: false,
      filterTagOptions: [],
      paginationOptions: {
        enabled: true,
        perPage: 20,
      },
      rows: [],
      columns: [
        {
          label: '#',
          field: 'id',
          type: 'number',
        },
        {
          label: 'Book',
          field: 'book.title',
          sortable: false,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'User',
          field: 'user.full_name',
          sortable: false,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Reader',
          field: 'reader_name',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Key',
          field: 'common_key',
          hidden: true,
        },
        {
          label: 'Duration',
          field: 'duration',
          formatFn(val) {
            const m = moment.duration(val, 'seconds');
            return m.humanize();
          },
        },
        {
          label: 'Stars',
          field: 'session.stars',
          sortable: false,
        },
        {
          label: 'Accuracy',
          // eslint-disable-next-line prefer-template
          field: (row) => (row.session ? (row.session.has_completed_book ? row.session.full_book_accuracy : row.session.range_progressive_accuracy) + '%' : ''),
          sortable: false,
        },
        // {
        //   label: 'WCPM',
        //   field: 'session.words_correct_per_minute',
        //   sortable: false,
        // },
        {
          label: 'Location',
          field: 'reader_location_name',
          sortable: false,
          hidden: true,
        },
        {
          label: 'Date',
          field: 'recorded_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss', // expects 2018-03-16
          dateOutputFormat: 'MMM do yyyy p',
          filterOptions: {
            enabled: true,
            customFilter: true,
            filterValue: null,
          },
        },
        {
          label: 'Action',
          field: 'actions',
          sortable: false,
        },
      ],
      totalRecords: 0,
      serverParams: {
        searchTerm: '',
        transcriptionStatus: 'any',
        challengeFilter: 'all',
        enterprise_id: null,
        filterTags: [],
        sort: [
          {
            field: 'id',
            type: 'desc',
          },
        ],
        page: 1,
        perPage: 20,
        hideTranscribedRecordings: 0,
        hideIfShorterThanX: 0,
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  created() {
    api.get('admin/recordings_config')
      .then((response) => {
        this.filterTagOptions = response.data.tags;
      });
  },
  mounted() {
    this.loadTableState();
    const localTableConfig = JSON.parse(window.localStorage.getItem('recordings_table_config'));
    if (localTableConfig) {
      const columnsConfigHash = this.columns.reduce((p, c) => p + c.label, '');
      if (localTableConfig.hideColumns && columnsConfigHash === localTableConfig.columnsConfigHash) {
        Object.keys(localTableConfig.hideColumns).forEach((c) => {
          this.$set(this.columns[c], 'hidden', localTableConfig.hideColumns[c] === true);
        });
      }
    }

    this.$nextTick(() => {
      // Start the view with the table hidden, then show it. This delays the creation of the component.
      // Needed because I couldn't set the pagination properties early enough in the execution flow so that VGT would pick it up
      // Needing to only set these pagination settings when prevRoute is a single recording view causes this problem as beforeRouteEnter fires after created() and the VGT pagination init
      this.showTable = true;
    });
  },
  methods: {

    toggleColumn(index) {
      // Set hidden to inverse of what it currently is
      this.$set(this.columns[index], 'hidden', !this.columns[index].hidden);
      this.saveTableConfig();
    },

    saveTableConfig() {
      const tblConfig = {
        hideColumns: Object.keys(this.columns).reduce((p, c) => {
          p[c] = this.columns[c].hidden === true;
          return p;
        }, {}),
        columnsConfigHash: this.columns.reduce((p, c) => p + c.label, ''),
      };
      window.localStorage.setItem('recordings_table_config', JSON.stringify(tblConfig));
    },

    onChallengeFilter({ challenge }) {
      this.updateParams({ challengeFilter: challenge });
    },

    onSchoolFilter({ schoolId }) {
      this.updateParams({ enterprise_id: schoolId });
    },

    updateParams(newProps) {
      const newServerParams = { ...this.serverParams, ...newProps };
      if (!isEqual(newServerParams, this.serverParams)) {
        this.serverParams = { ...this.serverParams, ...newProps };
        this.loadItems();
      }
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
    },

    onSortChange(params) {
      this.updateParams({ sort: params, page: 1 });
    },

    onColumnFilter(params) {
      this.updateParams({
        columnFilters: { ...this.serverParams.columnFilters, ...params.columnFilters },
        page: 1,
      });
    },

    onSearchChange: debounce(function (params) {
      this.updateParams({ searchTerm: params.searchTerm, page: 1 });
    }, 500),

    onHideTranscribedChanged: debounce(function (event) {
      const { value } = event.target;
      if (!Number.isNaN(value)) {
        this.updateParams({ hideTranscribedRecordings: value, page: 1 });
      } else {
        event.target.value = 0;
      }
    }, 500),
    onHideShorterThan: debounce(function (event) {
      const { value } = event.target;
      if (!Number.isNaN(value)) {
        this.updateParams({ hideIfShorterThanX: value, page: 1 });
      } else {
        event.target.value = 0;
      }
    }, 500),
    // load items is what brings back the rows from server
    loadItems() {
      this.loadingTableData = true;

      const userId = this.$route.params.userid;
      if (userId !== null) {
        this.serverParams.user_id = userId;
      }
      api.get('admin/recordings', {
        params: this.serverParams,
        paramsSerializer(params) {
          return qs.stringify(params);
        },
      }).then((response) => {
        this.loadingTableData = false;
        this.totalRecords = response.data.meta.totalRecords;
        this.rows = response.data.recordings.map((r) => {
          if (r.user) {
            r.user.full_name = `${r.user.first_name} ${r.user.last_name}`;
          }
          r.recorded_at = moment(r.recorded_at).format('YYYY-MM-DD HH:mm:ss'); // The format that vue-good-tables expects
          return r;
        });
        this.saveTableState();
      }).catch((error) => {
        this.loadingTableData = false;
        this.$apiResponse.renderErrorMessage(error);
      });
    },

    saveTableState() {
      const state = {
        serverParams: this.serverParams,
        rows: this.rows,
        totalRecords: this.totalRecords,
      };
      this.$store.commit('SET_RECORDINGS_TABLE_STATE', state);
    },

    loadTableState() {
      let didLoad = false;
      this.loadingTableData = false;
      if (this.prevRoute !== null && this.prevRoute.name === 'Recording') {
        const state = this.$store.state.recordingsTableState;
        if (state && state.serverParams) {
          this.rows = state.rows;
          this.totalRecords = state.totalRecords;
          this.serverParams = state.serverParams;

          // Custom filters
          Object.keys(this.serverParams.columnFilters || {}).forEach((f) => {
            const foundIndex = findIndex(this.columns, { field: f });
            if (foundIndex) {
              this.$set(this.columns[foundIndex].filterOptions, 'filterValue', this.serverParams.columnFilters[f]);
            }
          });

          // Paging
          if (state.serverParams.perPage) {
            this.$set(this.paginationOptions, 'perPage', state.serverParams.perPage);
          }
          if (state.serverParams.page) {
            this.$set(this.paginationOptions, 'setCurrentPage', state.serverParams.page);
          }

          didLoad = true;
        }
      }

      if (!didLoad) {
        this.loadItems();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.top-filters {
  flex-wrap: wrap;
  gap: 1em;
}
.column-filter-datepicker {
  display:flex;
  justify-content: flex-end;
  .datepicker-wrapper {
    height: 32px;
    input.input-inner { width: 90px; }
    .dropdown-toggle {
      padding: 0;
      width: auto;
      .fa { margin: 0 0.5em; }
    }
  }
}
.transcription-filters {
  align-items: center;
  gap: 0;
  input {
    border: 1px solid #ccc;
    min-height: 40px;
    max-width: 60px;
    padding: 0 0.5em;
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    -moz-appearance: textfield;
    &:focus-visible {
      outline: none;
    }
  }
}
</style>
