<template>
  <div class="user-filters">
    <div class="user-filter user-filter__break">
      <div class="label-container">
        <label>School:</label>
      </div>
      <select name="schoolId" id="schoolId" v-model="schoolId" @change="onSelect">
        <option :value="null">All</option>
        <option v-for="school in schools" :value="school.id" :key="school.id">{{ school.name }}</option>
      </select>
    </div>
  </div>
</template>

<script>
import SchoolMixin from '@/mixins/SchoolMixin';

export default {
  name: 'school-attribute-filters',
  mixins: [SchoolMixin],
  props: {
    cssStyles: String,
  },
  data() {
    return {
      schoolId: null,
      schools: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      // eslint-disable-next-line no-return-assign
      this.$store.dispatch('schools/getSchools', {}, true).then((schools) => this.schools = schools.enterprises.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())));
    });
  },
  methods: {
    onSelect() {
      const obj = {};
      obj.schoolId = this.schoolId ?? null;
      this.$emit('select', obj);
    },
  },
};
</script>
