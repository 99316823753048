<template>
  <wrapper>
    <section class="recording-page" v-if="recording">
      <div class="text-right mb-5" v-if="(recording.transcription || '') === ''">
        <button class="btn btn-primary" @click="togglePriority">
          <span v-if="!recording.is_high_priority"><i class="fa fa-square-o"></i> Highlight for transcription</span>
          <span v-else><i class="fa fa-check-square-o"></i> Unhighlight for transcription</span>
        </button>
      </div>
      <div class="row row-flex">
        <div class="col-md-7">
          <div class="row">
            <div class="col-md-12">
              <div class="box box-info">
                <div class="box-header">
                  <h3 class="box-title">Basic Information</h3>
                </div>
                <div class="box-body">
                  <table class="table table-striped">
                    <tbody>
                    <tr>
                      <th class="cell-35">Book</th>
                      <td>
                        <span>{{ recording.book.title }}</span>&nbsp;
                        <a class="badge ml-3" :href="'https://cms.auris.tech/books/'+recording.book.id+'/edit'" target="_blank"><i class="fa fa-external-link mr-1"></i> Open in CMS</a><br>
                        <span>Age range: {{ recording.book.age_range }}  |  Reading Level: {{ recording.book.reading_level }}</span>
                      </td>
                    </tr>
                    <tr v-if="canSeePII">
                      <th class="cell-30">Reader</th>
                      <td>{{ recording.reader_name }} ({{ recording.reader_location_name || "Unknown Location" }})</td>
                    </tr>
                    <tr>
                      <th class="cell-30">Age</th>
                      <td>{{ recording.reader_age }}</td>
                    </tr>
                    <tr>
                      <th class="cell-30">Date Recorded</th>
                      <td>{{ recording.recorded_at }}</td>
                    </tr>
                    <tr>
                      <th class="cell-30">Source</th>
                      <td>{{ recording.upload_source_user }}</td>
                    </tr>
                     <tr>
                      <th class="cell-30">Wav Key</th>
                      <td @click="copyTextFromElementEvent">{{recording.common_key+'__'+recording.book.id}}</td>
                    </tr>
                    <!--<tr v-if="canSeePII">
                      <th class="cell-30">INFO HELPER</th>
                      <td @click="copyTextFromElementEvent">{{recording.common_key+'__'+recording.book.id}}.wav<br>{{recording.user !== null ? recording.user.first_name+' '+recording.user.last_name : 'Unknown User'}}{{ recording.reader_name !== null ? ' ('+recording.reader_name+')' : ''}} - {{recording.book.title}} - {{ recording.recorded_at }}</td>
                    </tr> -->
                    <tr>

                      <td colspan="2" v-if="reader_recordings_count.total !== null">{{reader_recordings_count.total}} recordings by this reader<br />{{reader_recordings_count.transcribed}} have been transcribed so far</td>
                      <td colspan="2" v-else>Recording counts unavailable - Reader profile has been deleted</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="recording.session">
            <div class="col-md-12">
              <div class="box box-info">
                <div class="box-header">
                  <h3 class="box-title">Session Information</h3>
                </div>
                <div class="box-body">
                  <table class="table table-striped">
                    <tbody>
                    <tr>
                      <th class="cell-30">Reading Status</th>
                      <td>{{ recording.session.has_completed_book ? 'Book Completed' : `Page ${recording.session.last_page} of ${recording.book.pages_count}` }}</td>
                    </tr>
                    <tr v-if="recording.session.has_completed_book">
                      <th class="cell-30">Accuracy</th>
                      <td>{{ recording.session.full_book_accuracy }}%</td>
                    </tr>
                    <tr v-else>
                      <th class="cell-30">Projected Accuracy</th>
                      <td>{{ recording.session.range_progressive_accuracy }}%</td>
                    </tr>
                    <tr>
                      <th class="cell-30">Stars</th>
                      <td>
                        <template v-for="star in stars">
                          <img alt="" width="25" src="https://d3vlekh17wu64n.cloudfront.net/email_assets/stars_gold.png" :key="star" />
                        </template>
                        <template v-for="star in greyStars">
                          <img alt="" width="25" src="https://d3vlekh17wu64n.cloudfront.net/email_assets/stars_grey.png" :key="'g'+star" />
                        </template>
                      </td>
                    </tr>
                    <tr v-if="recording.session.words_correct_per_minute">
                      <th class="cell-30">Words correct per minute</th>
                      <td>{{ recording.session.words_correct_per_minute }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="box box-info">
                <div class="box-header">
                  <h3 class="box-title">Manual Metadata</h3>
                </div>
                <div class="box-body">
                  <form ref="recordingAttributesForm" @submit.prevent="saveMetadata">
                    <table id="recordingAttributesTbl" class="table table-striped mb-0">
                      <tbody>
                        <tr>
                          <th class="cell-30">Verified Child Voice?</th>
                          <td>
                            <div class="radio"><label><input type="radio" name="is_verified_child" :value="true" v-model="is_verified_child">Yes, this is a child reading</label></div>
                            <div class="radio"><label><input type="radio" name="is_verified_child" :value="false" v-model="is_verified_child">No, it is <b><u>not</u></b> a child reading</label></div>
                          </td>
                        </tr>
                        <tr>
                          <th class="cell-30">Background Noise</th>
                          <td>
                            <div class="row">
                              <div class="col-md-12 mb-4">
                                <b>Level</b>
                                <select class="form-control" v-model="bg_noise_level">
                                  <option :value="0">0 - No background noise at all</option>
                                  <option :value="1">1 - Low volume noise but can still clearly understand reader</option>
                                  <option :value="2">2 - Down the middle, can hear most of what is said</option>
                                  <option :value="3">3 - Can barely hear the reader due to noise</option>
                                </select>
                              </div>
                              <div class="col-md-12 mb-4">
                                <b>Consistency</b>
                                <div class="radio"><label><input type="radio" name="bg_noise_consistent" v-model="bg_noise_consistent" :value="true">Consistent noise at this level throughout the majority of the recording.</label></div>
                                <div class="radio"><label><input type="radio" name="bg_noise_consistent" v-model="bg_noise_consistent" :value="false">Inconsistent, such as a parent occasionally banging pots in the kitchen.</label></div>
                              </div>
                              <div class="col-md-12">
                                <b>Type</b>
                                <div>
                                  <multiselect v-model="bg_noise_tags" tag-placeholder="Add this as new tag" placeholder="Search or add a tag" :options="bg_noise_tags_options" :multiple="true" :taggable="true" @tag="addBGNoiseTag"></multiselect>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th class="cell-30">Reading clarity</th>
                          <td>
                            <select class="form-control" v-model="reading_clarity">
                              <option :value="0">0 - Barely understand anything they've said</option>
                              <option :value="1">1 - You can make it out, but takes a few rounds of listening</option>
                              <option :value="2">2 - Mostly clear, but not quite top marks</option>
                              <option :value="3">3 - Clearly spoken</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <th class="cell-30">Reading fluency</th>
                          <td>
                            <select class="form-control" v-model="reading_fluency">
                              <option :value="1">1 - Struggled with many words or relied on blended reading</option>
                              <option :value="2">2 - Somewhere in between</option>
                              <option :value="3">3 - Didn't really struggle maintaining a good pace of reading</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <th class="cell-30">Accent</th>
                          <td>
                            <div class="checkbox"><label><input v-model="has_strong_accent" type="checkbox" name="accent_strong">Has a strong &amp; noteworthy regional accent</label></div>
                            <multiselect v-if="has_strong_accent" v-model="reader_accent_group" tag-placeholder="Add this as new accent type" placeholder="Select or create an accent" :options="reader_accent_group_options" :multiple="false" :taggable="true" @tag="addAccentName"></multiselect>
                          </td>
                        </tr>
                        <tr>
                          <th class="cell-30">Tags</th>
                          <td>
                            <div>
                              <multiselect v-model="tags" tag-placeholder="Add this as new tag" placeholder="Search or add a tag" :options="tags_options" :multiple="true" :taggable="true" @tag="addTag"></multiselect>
                            </div>
                            <small>Optionally tag the batch name/number, or something important about the recording so you can find it later</small>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2" class="text-right">
                            <loading-button cssClasses="btn btn-primary" :isDisabled="!metadataDidChange" :isLoading="isLoading">Save Metadata</loading-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <!-- <pre>{{ JSON.stringify(recordingMetadata, null, 2) }}</pre> -->
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5" id="audioPlayerColumn">
          <div id="audioPlayerContainer" ref="audioPlayerContainer" :class="{'text-center':true, 'float': !showQrCode}">
            <audio-player @loadUrl="getSignedUrls()" :src="wavFile" :allowDownload="true"></audio-player>
            <qrcode :value="'id:'+recording.id" :options="{ width: 200, margin:0 }" class="qrcode" ref="qrcode" v-show="showQrCode"></qrcode>
          </div>
          <div ref="qrcodeIntersector" id="qrcodeIntersector"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="box box-info">
            <div class="box-header">
              <h3 class="box-title">Transcription</h3>
            </div>
            <div class="box-body">
              <textarea v-model="transcription" id="transcriptionInput" data-gramm="false" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"></textarea>
              <div class="text-right">
                <loading-button cssClasses="btn btn-primary btn-lg" :isDisabled="recording.transcription === transcription" :isLoading="isLoading" @click="saveTranscription">Save Transcription</loading-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </wrapper>
</template>

<script>
import Wrapper from '@/components/Wrapper';
import AudioPlayer from '@/components/AudioPlayer';
import LoadingButton from '@/components/utils/LoadingButton';
import api from '@/api';
import Multiselect from 'vue-multiselect';
import { copyTextFromElementEvent } from '@/utils.js';
import { isEqual, throttle } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'recording',
  components: {
    Wrapper, AudioPlayer, Multiselect, LoadingButton,
  },
  data() {
    return {
      recording: null,
      isHighlightedForTranscription: false,
      wavFile: null,
      showQrCode: true,
      transcription: '',

      reader_recordings_count: {
        total: null,
        transcribed: null,
        not_transcribed: null,
      },

      // Metadata attributes
      is_verified_child: null,
      bg_noise_level: null,
      bg_noise_consistent: null,
      bg_noise_tags: [],
      bg_noise_tags_options: [],
      reading_clarity: null,
      reading_fluency: null,
      has_strong_accent: false,
      reader_accent_group: '',
      reader_accent_group_options: [],
      tags: [],
      tags_options: [],

      originalMetadata: null,
      metadataDidChange: false,
    };
  },
  created() {
    api.get('admin/recordings_config')
      .then((response) => {
        this.bg_noise_tags_options = response.data.bg_noise_tags;
        this.reader_accent_group_options = response.data.accent_groups;
        this.tags_options = response.data.tags;
      });
  },
  mounted() {
    this.$store.dispatch('app/setGlobalLoading', true);

    api.get(`admin/recordings/${this.$route.params.id}`)
      .then((response) => {
        this.recording = response.data.recording;
        if (response.data.meta.reader_recordings_count) {
          this.reader_recordings_count = response.data.meta.reader_recordings_count;
        }

        // Hydrate the metadata
        Object.keys(this.recordingMetadata).forEach((k) => {
          if (Object.prototype.hasOwnProperty.call(this, k)) {
            this[k] = this.recording[k];
          }
        });

        this.transcription = this.recording.transcription || '';

        this.bg_noise_tags = this.bg_noise_tags === null ? [] : this.bg_noise_tags;
        this.tags = this.tags === null ? [] : this.tags;

        this.has_strong_accent = this.reader_accent_group !== null && this.reader_accent_group !== '';
        this.originalMetadata = { ...this.recordingMetadata };
      })
      .catch((error) => { this.$apiResponse.renderErrorMessage(error); })
      .finally(() => { this.$store.dispatch('app/setGlobalLoading', false); });

    this.throttledScroll = throttle(this.handleWindowScroll, 100);
    window.addEventListener('scroll', this.throttledScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.throttledScroll);
  },
  methods: {
    copyTextFromElementEvent,

    handleWindowScroll() {
      if (this.$refs.qrcode && this.$refs.qrcodeIntersector) {
        const qrBox = this.showQrCode ? this.$refs.qrcode.$el.getBoundingClientRect() : this.cachedQrBox;
        const intersectBox = this.$refs.qrcodeIntersector.getBoundingClientRect();
        const isIntersecting = qrBox.bottom >= intersectBox.top;
        if (isIntersecting === this.showQrCode) {
          this.$refs.audioPlayerContainer.style.width = isIntersecting ? `${Math.round(this.$refs.audioPlayerContainer.getBoundingClientRect().width)}px` : 'auto';
          this.showQrCode = !isIntersecting;
          this.cachedQrBox = qrBox;
        }
      }
    },
    saveMetadata() {
      return this.updateRecording(this.recordingMetadata).then(() => { this.metadataDidChange = false; });
    },
    saveTranscription() {
      return this.updateRecording({ transcription: this.transcription }).then(() => { this.transcriptionDidChange = false; });
    },
    togglePriority() {
      return this.updateRecording({ is_high_priority: !this.recording.is_high_priority });
    },
    updateRecording(data) {
      this.$store.dispatch('app/setGlobalLoading', true);
      return api.post(`admin/recordings/${this.$route.params.id}`, data)
        .then((response) => {
          this.recording = response.data.recording;
          this.originalMetadata = { ...this.recordingMetadata };
        })
        .catch((error) => { this.$apiResponse.renderErrorMessage(error); })
        .finally(() => { this.$store.dispatch('app/setGlobalLoading', false); });
    },
    getSignedUrls() {
      this.$store.dispatch('app/setGlobalLoading', true);
      api.get(`/admin/recordings/${this.$route.params.id}/urls`)
        .then((response) => { this.wavFile = response.data.urls.audio_file_wav; })
        .catch((error) => { this.$apiResponse.renderErrorMessage(error); })
        .finally(() => { this.$store.dispatch('app/setGlobalLoading', false); });
    },
    addBGNoiseTag(newTag) {
      newTag = this.sanitiseTag(newTag);
      this.bg_noise_tags_options.push(newTag);
      this.bg_noise_tags.push(newTag);
    },
    addTag(newTag) {
      newTag = this.sanitiseTag(newTag);
      this.tags_options.push(newTag);
      this.tags.push(newTag);
    },
    addAccentName(newName) {
      newName = newName.replace(' ', '-').replace(/[^\w\-_]/, '');
      this.reader_accent_group = newName;
      this.reader_accent_group_options.push(newName);
    },
    sanitiseTag(tag) {
      return tag
        .replaceAll('  ', ' ') // Replace double spaces with single spaces
        .replaceAll(' - ', '-') // Avoid extra hyphens when not needed
        .replaceAll(' ', '-') // Replace spaces with hyphens
        .replaceAll(/[^\w\-_]/g, ''); // Remove anything not alphanum, hyphen or underscore
    },
  },
  computed: {
    ...mapGetters(['isLoading']),
    stars() {
      const accuracy = this.recording.session !== null ? this.recording.session.range_accuracy : 0;
      if (accuracy >= 90) { return 3; }
      if (accuracy >= 75) { return 2; }
      if (accuracy >= 10) { return 1; }
      return 0;
    },
    greyStars() {
      return 3 - this.stars;
    },
    recordingMetadata() {
      return {
        is_verified_child: this.is_verified_child,
        bg_noise_level: this.bg_noise_level,
        bg_noise_consistent: this.bg_noise_consistent,
        bg_noise_tags: [...this.bg_noise_tags],
        reading_clarity: this.reading_clarity,
        reading_fluency: this.reading_fluency,
        has_strong_accent: this.has_strong_accent,
        reader_accent_group: this.reader_accent_group,
        tags: [...this.tags],
      };
    },
    canSeePII() {
      return this.$store.state.auth.user.email.includes('@auris.tech');
    },
  },
  watch: {
    recordingMetadata: {
      handler(newV) {
        this.metadataDidChange = !isEqual(newV, this.originalMetadata);
      },
      deep: true,
    },
    has_strong_accent(newV) {
      if (newV === false) {
        this.reader_accent_group = null;
      }
    },
  },
};
</script>

<style lang="scss">
#recordingAttributesTbl {
  th { white-space: nowrap; padding-right: 3em; }
  td { width: 100%; }
}
#audioPlayerColumn {
  display: flex;
  flex-direction: column;
  position: relative;
  .qrcode {
    background:#fff;
    width: 200px;
    padding: 25px;
    border-radius: 4px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, .07);
    margin-left: auto;
  }
}
#audioPlayerContainer {
  position: sticky;
  top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  &.float {
    position: fixed;
    z-index: 1;
  }
}
#qrcodeIntersector {
  bottom:20px;
  margin-bottom: 20px;
  background: transparent;
  position: absolute;
}
#transcriptionInput {
  width: 100%;
  resize: vertical;
  font-size: 16px;
  padding: 10px;
  min-height: 10em;
  border: 1px solid #ccc;
}
</style>
