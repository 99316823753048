<template>
  <div class="modal fade in" :class="'modal-'+stateClass" id="modal" tabindex="-1" role="dialog" aria-labelledby="modalLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('close')"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="myModalLabel">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer" v-if="showFooter">
          <slot name="footer"></slot>
          <button type="button" class="btn btn-default" data-dismiss="modal" @click="$emit('close')">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  inject: ['$validator'],
  props: {
    title: { required: true },
    showFooter: { default: true, type: Boolean },
    stateClass: { default: 'default', type: String, validator: (v) => ['default', 'primary', 'danger', 'warning', 'success'].includes(v) },
  },
};
</script>

<style>
.modal {
  display:block;
}
</style>
