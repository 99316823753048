<template>
  <ValidationObserver ref="subscription" tag="section">
    <form class="ui form form-horizontal" @submit.prevent="validateSubForm" autocomplete="off">
      <ValidationProvider name="max_readers" :rules="{ required: true, numeric: true }" v-slot="{errors}" slim>
        <div class="form-group" :class="{'has-error': errors.length > 0}">
          <label for="max_readers" class="col-sm-3 control-label">Number of pupils</label>
          <div class="col-sm-9 col-md-9">
            <div class="input-group width-100">
              <input class="form-control width-100" name="max_readers" placeholder="Pupils" type="text" autocomplete="off"
                     v-model="mutableValue.max_readers"
                     id="max_readers"
                     data-lpignore="true">
            </div>
            <span class="text-danger">{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider name="expire_at" rules="required" v-slot="{errors}" slim>
        <div class="form-group">
          <label for="expires_at" class="col-sm-3 control-label">Expires at</label>
          <div class="col-sm-9 col-md-9">
            <div class="input-group width-100">
              <DatePicker
                  v-model="mutableValue.expires_at"
                  mode="date"
                  :is-range="false"
                  :popover="{ placement: 'bottom-start', visibility: 'click' }"
                  :date="mutableValue.expires_at"
                  data-lpignore="true"
                  id="expires_at"
                  name="expires_at"
              >
              </DatePicker>
            </div>
            <span class="text-danger">{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <div class="form-group">
        <label for="payment_amount" class="col-sm-3 control-label">Renewal price</label>
        <div class="col-sm-9 col-md-9">
          <div class="renewal-price-group">
            <ValidationProvider name="payment_amount" :rules="{ required: true }" v-slot="{errors}" slim>
              <div :class="{'has-error': errors.length > 0}">
                <div class="input-group width-100">
                  <input class="form-control width-100" name="payment_amount" placeholder="£" type="text" autocomplete="off"
                         v-model="mutableValue.payment_amount"
                         id="payment_amount"
                         data-lpignore="true">
                </div>
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
            <ValidationProvider name="renewal_duration" v-slot="{errors}" rules="required" slim>
              <select class="form-control" name="renewal_duration" id="renewal_duration" v-model="mutableValue.renewal_duration_unit">
                <option v-for="option in durationOptions" v-bind:value="option.value" :key="option.value" :disabled="option.disabled" :selected="mutableValue.renewal_duration_unit === value">{{option.text}}</option>
              </select>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <ValidationProvider name="packages" v-slot="{errors}" rules="required" slim>
        <div class="available-packages form-group">
          <label class="col-sm-3 control-label">Packages</label>
          <div class="col-sm-19 col-md-9">
              <div class="checkbox" v-for="p in availablePackages" :key="p.id">
                <label>
                  <input type="checkbox" name="packages" v-model="mutableValue.packages" :value="p.id" /> {{ p.title }}
                </label>
              </div>
              <span class="text-danger">{{ errors[0] }}</span>
              <span class="text-danger mt-2" style="display: inline-block" v-if="displayPackagesWarning">Books within the removed packages will be immediately unassigned from all pupils
</span>
          </div>
        </div>
      </ValidationProvider>
      <div class="form-group" v-if="displayButtons">
        <div class="col-sm-offset-4 col-sm-10 col-md-8">
          <div class="row">
            <div class="col-md-2"><loading-button>Save</loading-button></div>
            <div class="col-md-2"><button class="btn btn-secondary" @click="$emit('cancel')">Cancel</button></div>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import LoadingButton from '@/components/utils/LoadingButton';
import FormMixin from '@/mixins/FormMixin';
import HelpersMixin from '@/mixins/HelpersMixin';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'school-subscription-form',
  props: {
    value: {
      required: false,
    },
    displayButtons: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [FormMixin, HelpersMixin],
  components: {
    LoadingButton, DatePicker, ValidationObserver, ValidationProvider,
  },
  data() {
    return {
      durationSelected: '',
      durationOptions: [
        { text: 'Select duration', value: '', disabled: true },
        { text: 'Monthly', value: 'month' },
        { text: 'Yearly', value: 'year' },
      ],
      availablePackages: [],
      totalOfSelectedPackagesAtStart: 0,
      displayPackagesWarning: false,
    };
  },
  mounted() {
    this.fetchAvailablePackages();
    this.totalOfSelectedPackagesAtStart = this.mutableValue.packages.length;
  },
  methods: {
    validateSubForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit('validated', this.form);
        }
      });
    },
    fetchAvailablePackages() {
      this.$store.dispatch('schools/getAvailablePackages')
        .then((availablePackages) => {
          this.availablePackages = availablePackages;
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
        });
    },
  },
  computed: {
    mutableValue() {
      return Object.assign(this.value);
    },
  },
  watch: {
    'mutableValue.packages': function (e) {
      if (this.totalOfSelectedPackagesAtStart > e.length) {
        this.displayPackagesWarning = true;
      } else {
        this.displayPackagesWarning = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .renewal-price-group {
    display: flex;
    input {
      height: 38px;
    }
    select {
      padding: 0 0.1em 0 1em;
    }
  }
  .available-packages {
    .checkbox input[type="checkbox"] {
      margin-left: -20px;
    }
  }
</style>
