<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  watch: {
    $route() {
      this.$store.dispatch('app/setGlobalLoading', false);
    },
  },
};
</script>
