/* eslint-disable import/prefer-default-export */

export function copyTextFromElement(el) {
  const s = window.getSelection();
  const r = document.createRange();
  r.selectNodeContents(el);
  s.removeAllRanges();
  s.addRange(r);
  document.execCommand('copy');
  s.removeAllRanges();
}

export function copyTextFromElementEvent(event) {
  return copyTextFromElement(event.target);
}

/**
 * Use to force a wait time in async, useful with Promise.all if you want to make something appear slower than it actually is.
 */
export function promiseWait(ms) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

/**
 * Uses the above function to force a promise to wait for a minimum amount of time
 * This helper method unwraps the promise result array to return the value the caller cares about, and discards the empty timeout value.
 */
export function promiseTakeAtleast(prm, ms) {
  return Promise.all([
    promiseWait(ms),
    prm,
  ]).then((v) => v[1]);
}
