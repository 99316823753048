import api from './index';
import qs from 'qs';

const serializeTableObjects = (items, key, value) => {
  const result = {};
  items.forEach((item) => {
    result[item[key]] = item[value];
  });
  return result;
};

const fetchUsers = (params) => (
  new Promise((resolve, reject) => {
    api.get('admin/users', {
      params,
      paramsSerializer: (parameters) => {
        parameters.sort = serializeTableObjects(parameters.sort, 'field', 'type');
        if (parameters.columnFilters && Object.keys(parameters.columnFilters).length) {
          parameters.filter = { ...parameters.columnFilters };
          delete parameters.columnFilters;
        }
        return qs.stringify(parameters);
      },
    }).then((res) => {
      resolve(res.data);
    }).catch((err) => {
      reject(err);
    });
  })
);

const fetchUserById = (id, params) => (
  new Promise((resolve, reject) => {
    api.get(`admin/users/${id}`, { params }).then((res) => {
      resolve(res.data);
    }).catch((err) => {
      reject(err);
    });
  })
);

const updateUser = (id, data, params) => (
  new Promise((resolve, reject) => {
    api.post(`admin/users/${id}`, { data, query: params }).then((res) => {
      resolve(res.data);
    }).catch((err) => {
      reject(err);
    });
  })
);

const modifyBetaAccessStatus = (id, action) => (
  new Promise((resolve, reject) => {
    api.post(`admin/users/${id}/beta_access`, { action }).then((res) => {
      resolve(res.data);
    }).catch((err) => {
      reject(err);
    });
  })
);

const handleUserActions = (id, action) => (
  new Promise((resolve, reject) => {
    api.post(`admin/users/${id}/actions`, { action }).then((res) => {
      resolve(res.data);
    }).catch((err) => {
      reject(err);
    });
  })
);

const deleteUser = (id) => (
  new Promise((resolve, reject) => {
    api.delete(`admin/users/${id}`).then((res) => {
      resolve(res);
    }).catch((err) => {
      reject(err);
    });
  })
);

const getUserMailLogs = (id) => (
  new Promise((resolve, reject) => {
    api.get(`admin/users/${id}/mail_logs`).then((res) => {
      resolve(res);
    }).catch((err) => {
      reject(err);
    });
  })
);

// user challenges api

const handleUserChallenge = (id, payload) => {
  const { slug, data } = payload;

  return new Promise((resolve, reject) => {
    api.post(`admin/challenges/${slug}/users/${id}`, data).then((res) => {
      resolve(res);
    }).catch((err) => {
      reject(err);
    });
  });
};

export {
  fetchUsers,
  fetchUserById,
  updateUser,
  modifyBetaAccessStatus,
  handleUserChallenge,
  handleUserActions,
  deleteUser,
  getUserMailLogs,
};
