<template>
  <div class="challenges-report-page">
    <div class="row">
      <div class="col-md-2">
        <flip-card id="db-readers-in-schools">
          <dash-box slot="front" title="Schools Registered" :value="totalOrgs.total" :active_value="totalOrgs.active" />
          <div slot="back" class="dashbox__help"><strong>{{totalOrgs.total.toLocaleString('en')}}</strong> schools have registered, <i class="fa fa-bolt"></i> <strong>{{ totalOrgs.active.toLocaleString('en') }}</strong> have at least 1 child who has read something.</div>
        </flip-card>
      </div>
      <div class="col-md-2">
        <flip-card id="db-readers-in-schools">
          <dash-box slot="front" title="Parents Registered" :value="totalUsers.total" :active_value="totalUsers.active" />
          <div slot="back" class="dashbox__help"><strong>{{totalUsers.total.toLocaleString('en')}}</strong> parents have registered, <i class="fa fa-bolt"></i> <strong>{{ totalUsers.active.toLocaleString('en') }}</strong> have at least 1 child who has read something.</div>
        </flip-card>
      </div>
      <div class="col-md-2">
        <flip-card id="db-readers-registered">
          <dash-box slot="front" title="Readers Registered" :value="totalReaders.total" :active_value="totalReaders.active" />
          <div slot="back" class="dashbox__help"><strong>{{totalReaders.total.toLocaleString('en')}}</strong> children are in the challenge, <i class="fa fa-bolt"></i> <strong>{{ totalReaders.active.toLocaleString('en') }}</strong> have read something.</div>
        </flip-card>
      </div>
      <div class="col-md-2">
        <flip-card id="db-readers-in-schools">
          <dash-box slot="front" title="Readers Associated to a School" :value="readersInOrgs.total" :active_value="readersInOrgs.active" />
          <div slot="back" class="dashbox__help"><strong>{{readersInOrgs.total.toLocaleString('en')}}</strong> children are associated with a school, <i class="fa fa-bolt"></i> <strong>{{ readersInOrgs.active.toLocaleString('en') }}</strong> have read something.</div>
        </flip-card>
      </div>
      <div class="col-md-2">
        <flip-card id="db-readers-not-in-school">
          <dash-box slot="front" title="Readers not in School" :value="readersNotInOrgs.total" :active_value="readersNotInOrgs.active" />
          <div slot="back" class="dashbox__help"><strong>{{readersNotInOrgs.total.toLocaleString('en')}}</strong> children are <strong><u>not</u></strong> associated with a school, <i class="fa fa-bolt"></i> <strong>{{ readersNotInOrgs.active.toLocaleString('en') }}</strong> have read something.</div>
        </flip-card>
      </div>
      <div class="col-md-2">
        <button @click="getCSV" class="btn btn-primary" style="width: 100%">Download CSV File</button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="report-header">
          <h2 style="font-weight: 200;color: #224450;text-shadow: 1px 1px 0 #ffffffb3;">Schools</h2>

          <div class="user-filters">
            <div class="user-filter user-filter__break">
              <div class="label-container">
                <label>Challenges:</label>
              </div>
              <select name="challenges" id="challenges" v-model="challenge" @change="refreshData">
                <option v-for="ch in challenges" :value="ch.slug" :key="ch.id">{{ ch.slug | uppercase }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <vue-good-table styleClass="vgt-table striped" theme="auris-admin" :columns="modifiedColumns" :rows="data"
          :totalRows="data.length" :pagination-options="{
            enabled: true,
            mode: 'records',
            perPage: 25,
            position: 'bottom',
            perPageDropdown: [10, 25, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page', // for 'pages' mode
            allLabel: 'All',
          }" :search-options="{
            enabled: true,
            placeholder: 'Search this table',
          }" @on-sort-change="handleSortChange">
        </vue-good-table>
      </div>
    </div>
  </div>
</template>
<script>
import { VueGoodTable } from 'vue-good-table';
import DashBox from '@/components/DashBox';
import moment from 'moment';
import { mapState } from 'vuex';
import FlipCard from '@/components/FlipCard';

const transformHeader = (s) => s.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()).replace(/[-_]+(.)/g, (_, c) => ` ${c.toUpperCase()}`);

const secondsToString = (seconds = 0) => {
  if (seconds < 60) {
    return `${seconds} ${seconds === 1 ? 'sec' : 'secs'}`;
  }
  const hours = Math.floor(seconds / 3600);
  const totalSeconds = seconds % 3600;
  const minutes = Math.floor(totalSeconds / 60);
  let string = '';
  if (hours >= 1) {
    string += `${hours} ${hours === 1 ? 'hr ' : 'hrs '}`;
  }
  if (minutes >= 1) {
    string += `${minutes} ${minutes === 1 ? 'min' : 'mins'}`;
  }

  return string.trim();
};

const numberRank = (i) => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return `${i} <sup>st</sup>`;
  }
  if (j === 2 && k !== 12) {
    return `${i} <sup>nd</sup>`;
  }
  if (j === 3 && k !== 13) {
    return `${i} <sup>rd</sup>`;
  }
  return `${i} <sup>th</sup>`;
};

class ReportItem {
  constructor(item) {
    this.id = item.id;
    this.rank = '';
    this.name = item.name || '';
    this.code = item.code || '';
    this.contact_name = item.contact_name || '';
    this.contact_email = item.contact_email || '';
    this.school_type = item.school_type ? item.school_type.toUpperCase() : '';
    this.governed_by = item.school_governed_by_name || '';
    this.region = item.region || '';
    this.registered_on = item.created_at || '';
    this.total_readers = item.total_readers || 0;
    this.active_readers = item.total_active_readers || 0;
    this.recently_active_readers = item.total_recent_active_readers || 0;
    this.stars = 0;
    this.books_read = 0;
    this.reading_time = '0 secs';
    this.reading_time_seconds = 0;
    if (item.leaderboard) {
      this.stars = item.leaderboard.total_stars || 0;
      this.books_read = item.leaderboard.total_books || 0;
      this.reading_time_seconds = item.leaderboard.total_reading_time || 0;
    }
    switch (this.school_type) {
      case 'MAT':
        this.school_type = 'Multi-Academy Trust';
        break;
      case 'LA':
        this.school_type = 'Local Authority';
        break;
      default:
        this.school_type = '';
        break;
    }

    this.reading_time = secondsToString(this.reading_time_seconds);
  }

  setRank(rank) {
    this.rank = numberRank(rank);
  }
}

export default {
  components: {
    VueGoodTable,
    DashBox,
    FlipCard,
  },
  data() {
    return {
      columns: [
        {
          label: 'Name',
          html: true,
          sort_column: 'name',
          sort_type: String,
          show_ranking: false,
          field: (row) => `<a href="/challenges/${this.challenge}/organisations/${row.id}">${row.name}</a>`,
          sortable: true,
          sortFn: this.tableSort,
          firstSortType: 'desc',
        },
        {
          label: 'School Type',
          field: 'school_type',
          sort_column: 'school_type',
          sort_type: String,
          show_ranking: false,
          sortFn: this.tableSort,
        },
        {
          label: 'Governed by',
          field: 'governed_by',
          sort_column: 'governed_by',
          sort_type: String,
          show_ranking: false,
          sortFn: this.tableSort,
        },
        {
          label: 'Registered On',
          field: 'registered_on',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'do MMMM yy @ hh:mm a',
          sort_column: 'registered_on',
          sort_type: Date,
          show_ranking: false,
          sortFn: this.tableSort,
        },
        {
          label: 'Total Readers',
          field: 'total_readers',
          type: 'number',
          sort_column: 'total_readers',
          sort_type: Number,
          show_ranking: false,
          sortFn: this.tableSort,
        },
        {
          label: 'Active Readers',
          field: 'active_readers',
          type: 'number',
          sort_column: 'active_readers',
          sort_type: Number,
          show_ranking: false,
          sortFn: this.tableSort,
        },
        {
          label: 'Stars',
          field: 'stars',
          type: 'number',
          sort_column: 'stars',
          sort_type: Number,
          show_ranking: true,
          sortFn: this.tableSort,
        },
        {
          label: 'Books',
          field: 'books_read',
          type: 'number',
          sort_column: 'books_read',
          sort_type: Number,
          show_ranking: true,
          sortFn: this.tableSort,
        },
        {
          label: 'Reading Time',
          field: 'reading_time',
          type: 'number',
          sort_column: 'reading_time_seconds',
          sort_type: Number,
          show_ranking: true,
          sortFn: this.tableSort,
        },
      ],
      data: [],
      readersInOrgs: 0,
      totalReaders: 0,
      totalUsers: 0,
      gettingCSV: false,
      isLoading: false,
      showRanking: false,
      challenge: 'uknrac24',
    };
  },
  computed: {
    ...mapState('challenges', ['challenges']),
    modifiedColumns() {
      const rankColumn = {
        label: 'Rank',
        field: 'rank',
        html: true,
        sortable: false,
      };
      return this.showRanking ? [rankColumn, ...this.columns] : this.columns;
    },
    totalOrgs() {
      return {
        total: this.data.length,
        active: this.data.filter((s) => s.active_readers > 0).length,
      };
    },
    readersNotInOrgs() {
      return {
        total: this.totalReaders.total - this.readersInOrgs.total,
        active: this.totalReaders.active - this.readersInOrgs.active,
      };
    },
  },
  methods: {
    refreshData() {
      this.isLoading = true;
      this.$store.dispatch('reports/getChallengesReport', this.challenge).then((response) => {
        const { data } = response;
        this.data = data.challenge_organisations.map((item) => new ReportItem(item));
        this.data = this.data.sort(this.initialNameSort);
        this.readersInOrgs = data.total_challenge_organisation_readers;
        this.totalReaders = data.total_challenge_readers;
        this.totalUsers = data.total_challenge_users;
      }).finally(() => {
        this.isLoading = false;
      });
    },
    getCSV() {
      const items = this.data;
      const header = Object.keys(items[0]); console.log(header);
      header.shift();
      const formattedHeader = header.map(transformHeader);
      const csv = [
        formattedHeader.join(','), // header row first
        ...items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName])).join(',')),
      ].join('\r\n');
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `challenges_report${moment().format('DD/MM/YYYY')}.csv`);
        link.style = 'visibility:hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    tableSort(x, y, col, rowX, rowY) {
      const { sort_type: sortType, sort_column: sortColumn } = col;
      if (sortType === String) {
        return this.stringSort(rowX, rowY, sortColumn);
      }
      if (sortType === Number) {
        return this.numberSort(rowX, rowY, sortColumn);
      }
      if (sortType === Date) {
        return this.dateSort(rowX, rowY, sortColumn);
      }
      return this.numberSort(rowX, rowY, sortColumn);
    },
    stringSort(rowX, rowY, sortColumn) {
      const name1 = rowX[sortColumn].toLowerCase();
      const name2 = rowY[sortColumn].toLowerCase();
      return (name1 < name2 ? -1 : (name1 > name2 ? 1 : 0));
    },
    numberSort(rowX, rowY, sortColumn) {
      const num1 = rowX[sortColumn];
      const num2 = rowY[sortColumn];
      return (num1 < num2 ? -1 : (num1 > num2 ? 1 : 0));
    },
    dateSort(rowX, rowY, sortColumn) {
      const date1 = Date.parse(rowX[sortColumn]);
      const date2 = Date.parse(rowY[sortColumn]);
      return (date1 < date2 ? -1 : (date1 > date2 ? 1 : 0));
    },
    initialNameSort(rowX, rowY) {
      const name1 = rowX.name.toLowerCase();
      const name2 = rowY.name.toLowerCase();
      return (name1 < name2 ? -1 : (name1 > name2 ? 1 : 0));
    },
    handleSortChange(params) {
      const { field, type } = params[0];
      const rankingEnabledFields = ['reading_time', 'books_read', 'stars'];
      if (rankingEnabledFields.includes(field) && type !== 'none') {
        this.showRanking = true;
        this.calculateRanking(field, type);
      } else {
        this.showRanking = false;
        if (type === 'none') {
          this.data = this.data.sort(this.initialNameSort);
        }
      }
    },
    calculateRanking(field, type) {
      const tempData = this.data;
      tempData.sort((a, b) => this.numberSort(a, b, field === 'reading_time' ? 'reading_time_seconds' : field));
      if (type === 'desc') {
        tempData.reverse();
      }
      for (let index = 0; index < tempData.length; index++) {
        tempData[index].setRank(index + 1);
      }
    },
    getChallenges() {
      if (this.challenges.length === 0) {
        this.$store.dispatch('challenges/getChallenges');
      }
    },
  },
  mounted() {
    this.refreshData();
    this.getChallenges();
  },
};
</script>
<style lang="scss" scoped>
.report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}
</style>
