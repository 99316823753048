import converter from 'number-to-words';
import moment from 'moment';

export default {
  methods: {
    digitToWord(num, capitalize = true) {
      const numToWord = converter.toWords(num);
      return capitalize ? this.capitalizeFirstLetter(numToWord) : numToWord;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formatCurrencyPlaces(amount) {
      return parseInt(amount.toString().split('.')[1], 10) === 0 ? parseInt(amount, 10) : amount;
    },
    formatSchoolTags(tags) {
      return Object.keys(tags).map((tag) => ({
        code: tag,
        name: tags[tag],
      }));
    },
    downloadFile(data, filename, type = 'text/csv;charset=utf-8;') {
      const blob = new Blob([data], { type });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style = 'visibility:hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    formatSeconds(n) {
      return moment.duration(n, 'seconds').format('D[d] H[h] m[m] s[s]');
    },
    formatDate(date, withHour = false, timestamp = false) {
      let mDate = moment(date);
      if (timestamp) {
        mDate = moment.unix(date);
      }
      if (withHour) {
        return mDate.format('Do MMMM \'YY @ h:mmA');
      }

      return mDate.format('Do MMMM \'YY');
    },
    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
    },
  },
  computed: {
    promocodePrettyDescription() {
      if (this.promocode.type === 'coupon') {
        // can be percentage or amount
        const validFrom = moment(this.promocode.valid_from);
        const validUntil = moment(this.promocode.valid_until);
        if (this.promocode.discount_type === 'percent_off') {
          return `${Math.round(this.promocode.discount_value)}% off from ${validFrom.format('DD/MM/YYYY')}${validUntil.isValid() ? ` until ${validUntil.format('DD/MM/YYYY')}` : ' with no expiry date'}`;
        }
        return `£${Math.round(this.promocode.discount_value)} off from ${validFrom.format('DD/MM/YYYY')}${validUntil.isValid() ? ` until ${validUntil.format('DD/MM/YYYY')}` : ' with no expiry date'}`;
      } if (this.promocode.type === 'free_trial') {
        return `${this.promocode.duration_length} ${this.promocode.duration_unit} free trial`;
      }
      return 'Not a clue';
    },
  },
};
