var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", { key: 0, ref: "user" }, [
    _c(
      "form",
      {
        staticClass: "ui form form-horizontal",
        attrs: { autocomplete: "off" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.validateForm($event)
          }
        }
      },
      [
        _c("ValidationProvider", {
          attrs: {
            name: "first_name",
            rules: { required: true, min: 2 },
            slim: ""
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var errors = ref.errors
                return [
                  _c(
                    "div",
                    {
                      staticClass: "form-group",
                      class: { "has-error": errors.length > 0 }
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "col-sm-2 control-label",
                          attrs: { for: "firstName" }
                        },
                        [_vm._v("First Name")]
                      ),
                      _c("div", { staticClass: "col-sm-10 col-md-8" }, [
                        _c("div", { staticClass: "input-group width-100" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.mutableValue.first_name,
                                expression: "mutableValue.first_name"
                              }
                            ],
                            staticClass: "form-control width-100",
                            attrs: {
                              name: "first_name",
                              placeholder: "First name",
                              type: "text",
                              autocomplete: "off",
                              id: "firstName"
                            },
                            domProps: { value: _vm.mutableValue.first_name },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.mutableValue,
                                  "first_name",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: errors.length > 0,
                                expression: "errors.length > 0"
                              }
                            ],
                            staticClass: "text-danger"
                          },
                          [_vm._v(_vm._s(errors[0]))]
                        )
                      ])
                    ]
                  )
                ]
              }
            }
          ])
        }),
        _c("ValidationProvider", {
          attrs: {
            name: "last_name",
            rules: { required: true, min: 2 },
            slim: ""
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var errors = ref.errors
                return [
                  _c(
                    "div",
                    {
                      staticClass: "form-group",
                      class: { "has-error": errors.length > 0 }
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "col-sm-2 control-label",
                          attrs: { for: "lastName" }
                        },
                        [_vm._v("Last Name")]
                      ),
                      _c("div", { staticClass: "col-sm-10 col-md-8" }, [
                        _c("div", { staticClass: "input-group width-100" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.mutableValue.last_name,
                                expression: "mutableValue.last_name"
                              }
                            ],
                            staticClass: "form-control width-100",
                            attrs: {
                              name: "last_name",
                              placeholder: "Last Name",
                              type: "text",
                              autocomplete: "off",
                              id: "lastName"
                            },
                            domProps: { value: _vm.mutableValue.last_name },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.mutableValue,
                                  "last_name",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: errors.length > 0,
                                expression: "errors.length > 0"
                              }
                            ],
                            staticClass: "text-danger"
                          },
                          [_vm._v(_vm._s(errors[0]))]
                        )
                      ])
                    ]
                  )
                ]
              }
            }
          ])
        }),
        _c("ValidationProvider", {
          attrs: {
            name: "email",
            rules: { required: true, email: true },
            slim: ""
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var errors = ref.errors
                return [
                  _c(
                    "div",
                    {
                      staticClass: "form-group",
                      class: { "has-error": errors.length > 0 }
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "col-sm-2 control-label",
                          attrs: { for: "email" }
                        },
                        [_vm._v("Email")]
                      ),
                      _c("div", { staticClass: "col-sm-10 col-md-8" }, [
                        _c("div", { staticClass: "input-group width-100" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.mutableValue.email,
                                expression: "mutableValue.email"
                              }
                            ],
                            staticClass: "form-control width-100",
                            attrs: {
                              name: "email",
                              placeholder: "Email",
                              type: "text",
                              autocomplete: "off",
                              id: "email"
                            },
                            domProps: { value: _vm.mutableValue.email },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.mutableValue,
                                  "email",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: errors.length > 0,
                                expression: "errors.length > 0"
                              }
                            ],
                            staticClass: "text-danger"
                          },
                          [_vm._v(_vm._s(errors[0]))]
                        )
                      ])
                    ]
                  )
                ]
              }
            }
          ])
        }),
        _vm.accountFields.weekly_digest_optin
          ? _c("ValidationProvider", {
              attrs: { name: "weekly_digest_optin", slim: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "form-group",
                            class: { "has-error": errors.length > 0 }
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "col-sm-2 control-label",
                                attrs: { for: "weekly_digest_optin" }
                              },
                              [_vm._v("Receive Weekly Digest")]
                            ),
                            _c("div", { staticClass: "col-sm-10 col-md-8" }, [
                              _c("div", { staticClass: "input-group" }, [
                                _c(
                                  "div",
                                  { staticClass: "input-group-addon" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.mutableValue
                                              .weekly_digest_optin,
                                          expression:
                                            "mutableValue.weekly_digest_optin"
                                        }
                                      ],
                                      staticClass: "form-control__checkbox",
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.mutableValue.weekly_digest_optin
                                        )
                                          ? _vm._i(
                                              _vm.mutableValue
                                                .weekly_digest_optin,
                                              null
                                            ) > -1
                                          : _vm.mutableValue.weekly_digest_optin
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$a =
                                              _vm.mutableValue
                                                .weekly_digest_optin,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.mutableValue,
                                                  "weekly_digest_optin",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.mutableValue,
                                                  "weekly_digest_optin",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.mutableValue,
                                              "weekly_digest_optin",
                                              $$c
                                            )
                                          }
                                        }
                                      }
                                    })
                                  ]
                                ),
                                _c("div", { staticClass: "form-control" })
                              ]),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: errors.length > 0,
                                      expression: "errors.length > 0"
                                    }
                                  ],
                                  staticClass: "text-danger"
                                },
                                [_vm._v(_vm._s(errors[0]))]
                              )
                            ])
                          ]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2457546276
              )
            })
          : _vm._e(),
        _vm.mutableValue.permissions && _vm.accountFields.permissions
          ? _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "col-sm-2 control-label" }, [
                _vm._v("Permissions")
              ]),
              _c("div", { staticClass: "col-sm-10 col-md-8" }, [
                _c("p", { staticClass: "permissions__title" }, [
                  _vm._v("Collection")
                ]),
                _c("table", { staticClass: "table table-bordered" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Key")]),
                      _c("th", { staticClass: "text-center" }, [
                        _vm._v("Include drafts")
                      ]),
                      _c("th", { staticClass: "text-center" }, [
                        _vm._v("Bypass Paywall")
                      ])
                    ])
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.collections, function(collection, i) {
                      return _c("tr", { key: i }, [
                        _c("td", [_vm._v(_vm._s(collection.key))]),
                        _c("td", { staticClass: "text-center" }, [
                          _c("input", {
                            attrs: {
                              type: "checkbox",
                              name: collection.key,
                              "data-type": "collection",
                              "data-key": collection.key,
                              "data-action": "include_drafts"
                            },
                            domProps: { checked: collection.include_drafts },
                            on: {
                              change: function($event) {
                                return _vm.handleChange($event)
                              }
                            }
                          })
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _c("input", {
                            attrs: {
                              type: "checkbox",
                              name: collection.key,
                              "data-type": "collection",
                              "data-key": collection.key,
                              "data-action": "bypass_paywall"
                            },
                            domProps: { checked: collection.bypass_paywall },
                            on: {
                              change: function($event) {
                                return _vm.handleChange($event)
                              }
                            }
                          })
                        ])
                      ])
                    }),
                    0
                  )
                ]),
                _c("p", { staticClass: "permissions__title" }, [
                  _vm._v("Publisher")
                ]),
                _c("table", { staticClass: "table table-bordered" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Key")]),
                      _c("th", { staticClass: "text-center" }, [
                        _vm._v("Include drafts")
                      ]),
                      _c("th", { staticClass: "text-center" }, [
                        _vm._v("Bypass Paywall")
                      ])
                    ])
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.publishers, function(publisher, i) {
                      return _c("tr", { key: i }, [
                        _c("td", [_vm._v(_vm._s(publisher.key))]),
                        _c("td", { staticClass: "text-center" }, [
                          _c("input", {
                            attrs: {
                              type: "checkbox",
                              name: publisher.key,
                              "data-type": "publisher",
                              "data-key": publisher.key,
                              "data-action": "include_drafts"
                            },
                            domProps: { checked: publisher.include_drafts },
                            on: {
                              change: function($event) {
                                return _vm.handleChange($event)
                              }
                            }
                          })
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _c("input", {
                            attrs: {
                              type: "checkbox",
                              name: publisher.key,
                              "data-type": "publisher",
                              "data-key": publisher.key,
                              "data-action": "bypass_paywall"
                            },
                            domProps: { checked: publisher.bypass_paywall },
                            on: {
                              change: function($event) {
                                return _vm.handleChange($event)
                              }
                            }
                          })
                        ])
                      ])
                    }),
                    0
                  )
                ])
              ])
            ])
          : _vm._e(),
        _vm.accountFields.password
          ? _c(
              "div",
              {
                staticClass: "form-group",
                class: { "has-error": _vm.errors.length > 0 }
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 control-label",
                    attrs: { for: "password" }
                  },
                  [_vm._v("Change Password")]
                ),
                _c("div", { staticClass: "col-sm-10 col-md-8" }, [
                  _c(
                    "div",
                    { staticClass: "input-group" },
                    [
                      _c("span", { staticClass: "input-group-addon" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.changePassword,
                              expression: "changePassword"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.changePassword)
                              ? _vm._i(_vm.changePassword, null) > -1
                              : _vm.changePassword
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.changePassword,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.changePassword = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.changePassword = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.changePassword = $$c
                              }
                            }
                          }
                        })
                      ]),
                      _c("ValidationProvider", {
                        attrs: { name: "country", slim: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var errors = ref.errors
                                return [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.mutableValue.password,
                                        expression: "mutableValue.password"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      name: "password",
                                      placeholder: "Password",
                                      type: "password",
                                      autocomplete: "off",
                                      disabled: !_vm.changePassword,
                                      id: "password"
                                    },
                                    domProps: {
                                      value: _vm.mutableValue.password
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.mutableValue,
                                          "password",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors.length > 0,
                                          expression: "errors.length > 0"
                                        }
                                      ],
                                      staticClass: "text-danger"
                                    },
                                    [_vm._v(_vm._s(errors[0]))]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          896853896
                        )
                      })
                    ],
                    1
                  )
                ])
              ]
            )
          : _vm._e(),
        _c("ValidationProvider", {
          attrs: { name: "country", slim: "" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var errors = ref.errors
                return [
                  _vm.accountFields.country
                    ? _c(
                        "div",
                        {
                          staticClass: "form-group",
                          class: { "has-error": errors.length > 0 }
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "col-sm-2 control-label",
                              attrs: { for: "country" }
                            },
                            [_vm._v("Country")]
                          ),
                          _c("div", { staticClass: "col-sm-10 col-md-8" }, [
                            _c("div", { staticClass: "input-group" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.mutableValue.country,
                                      expression: "mutableValue.country"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { id: "country" },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.mutableValue,
                                        "country",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                _vm._l(_vm.countries, function(country) {
                                  return _c(
                                    "option",
                                    {
                                      key: country["id"],
                                      domProps: { value: country["id"] }
                                    },
                                    [_vm._v(_vm._s(country["name"]))]
                                  )
                                }),
                                0
                              )
                            ]),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: errors.length > 0,
                                    expression: "errors.length > 0"
                                  }
                                ],
                                staticClass: "text-danger"
                              },
                              [_vm._v(_vm._s(errors[0]))]
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ]
              }
            }
          ])
        }),
        _c("div", { staticClass: "form-group" }, [
          _c(
            "label",
            { staticClass: "col-sm-2 control-label", attrs: { for: "region" } },
            [_vm._v("Region")]
          ),
          _c("div", { staticClass: "col-sm-10 col-md-8" }, [
            _vm.regions.length > 0
              ? _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.mutableValue.region,
                        expression: "mutableValue.region"
                      }
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.mutableValue,
                          "region",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(_vm.regions, function(group, key) {
                    return _c(
                      "optgroup",
                      {
                        key: key,
                        attrs: {
                          label:
                            group["group_name"] != ""
                              ? group["group_name"]
                              : "Choose a region"
                        }
                      },
                      _vm._l(group["values"], function(region, k) {
                        return _c(
                          "option",
                          { key: k, domProps: { value: region } },
                          [_vm._v(_vm._s(region))]
                        )
                      }),
                      0
                    )
                  }),
                  0
                )
              : _c("input", {
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    name: "region",
                    id: "region",
                    autocomplete: "off",
                    "data-vv-as": "region"
                  },
                  domProps: { value: _vm.mutableValue.region },
                  on: {
                    input: function($event) {
                      _vm.mutableValue.region = $event.target.value
                    }
                  }
                })
          ])
        ]),
        _c("div", { staticClass: "form-group" }, [
          _c(
            "div",
            { staticClass: "col-sm-offset-2 col-sm-10 col-md-8" },
            [_c("loading-button", [_vm._v("Submit")])],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }