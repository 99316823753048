<template>
  <wrapper>
    <section class="subscription-page" v-if="pageReady">
      <h2 class="text-center">{{user.full_name}}</h2>
      <div v-if="packages.length > 0">
        <div class="row">
          <div class="col-md-12" v-for="(pack, index) in filteredPackages" :key="index">
            <div class="decorator__line">
              <h3>{{ pack.title }}</h3>
              <hr>
            </div>
            <template v-if="pack.has_subscription">
              <div class="bg-warning p-3" style="border: 1px solid #ff922e">A free trial is already applied for this package</div>
            </template>
            <template v-else>
              <div class="row">
                <div class="col-md-12">
                  <subscription-package-with-form :s-package="pack" />
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="col-md-12" v-else>
        <div class="alert alert-warning">There are currently no subscription packages available for this user.
        </div>
      </div>
    </section>
  </wrapper>
</template>

<script>
import api from '@/api';
import Wrapper from '@/components/Wrapper';
import SubscriptionMixin from '@/mixins/SubscriptionMixin';
import SubscriptionPackageWithForm from '@/components/SubscriptionPackageWithForm';
import UserMixin from '@/mixins/UserMixin';

export default {
  name: 'add-subscription',
  mixins: [SubscriptionMixin, UserMixin],
  components: {
    SubscriptionPackageWithForm,
    Wrapper,
  },
  data() {
    return {
      pageReady: false,
      packages: [],
      userId: null,
      user: null,
    };
  },
  mounted() {
    this.getUser();
    this.getAvailableSubscriptions();
  },
  methods: {
    getUser() {
      const userId = this.$route.params.id;
      this.$store.dispatch('users/fetchUserById', { id: userId, params: { } })
        .then((response) => {
          const { user } = response;
          this.user = user;
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
        });
    },
    getAvailableSubscriptions() {
      const userId = this.$route.params.id;
      const url = `admin/users/${userId}/available_subscriptions`;
      api.get(url)
        .then((response) => {
          this.packages = response.data.packages;
          this.pageReady = true;
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
        });
    },
  },
  computed: {
    filteredPackages() {
      const filteredPackages = this.packages;
      filteredPackages.forEach((pack) => {
        const variants = pack.variants.sort((a, b) => ((a.max_devices > b.max_devices) ? 1 : -1));
        variants.forEach((variant) => variant.prices.sort((a, b) => ((a.duration_length > b.duration_length) ? 1 : -1)).sort((a, b) => ((a.duration_unit > b.duration_unit) ? 1 : -1)));
        pack.variants = variants;
        return pack;
      });

      return filteredPackages;
    },
  },
};
</script>
