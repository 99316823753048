import PromocodesView from '@/views/promocodes/Promocodes';
import AddPromocodeView from '@/views/promocodes/AddPromocode';
import PromocodeView from '@/views/promocodes/Promocode';

export default ([
  {
    path: '/promocodes',
    component: PromocodesView,
    name: 'Promocodes',
    meta: { description: 'Manage the promocodes', requiresAuth: true, authorize: ['users'] },
  },
  {
    path: '/promocodes/new',
    component: AddPromocodeView,
    name: 'Add Promocode',
    meta: { description: 'Add a new promocode', requiresAuth: true, authorize: ['users'] },
  },
  {
    path: '/promocodes/:id',
    component: PromocodeView,
    name: 'Promocode',
    meta: { description: 'View the details of the promocode', requiresAuth: true, authorize: ['users'] },
  },
]);
