<template>
  <form action="" class="form-horizontal subscription-form" @submit.prevent="validateForm">
    <div class="form-group" :class="{'has-error': errors.first('package_id')}">
      <label for="duration_length" class="col-sm-2 control-label">Choose a package</label>
      <div class="col-sm-10">
        <select class="form-control" name="package_id" v-model="mutableValue.package_id" v-validate="'required'">
          <option :value="predefinedPackage.id" v-for="(predefinedPackage, index) in packages" :key="index">{{ predefinedPackage.title }}
          </option>
        </select>
      </div>
      <div class="col-sm-10 col-sm-offset-2 help-block is-danger" v-show="errors.has('package_id')">
        {{ errors.first('package_id') }}
      </div>
    </div>
    <div class="form-group" :class="{'has-error': errors.first('max_devices')}">
      <label for="max_devices" class="col-sm-2 control-label">Max devices</label>
      <div class="col-sm-10">
        <input type="number" class="form-control" id="max_devices" name="max_devices" placeholder="Max devices"
               autocomplete="off"
               v-model="mutableValue.max_devices"
               v-validate="'required|numeric'">
      </div>
      <div class="col-sm-10 col-sm-offset-2 help-block is-danger" v-show="errors.has('max_devices')">
        {{ errors.first('max_devices') }}
      </div>
    </div>
    <div class="form-group" :class="{'has-error': errors.first('amount')}" v-if="!freeTrial">
      <label for="amount" class="col-sm-2 control-label">Amount</label>
      <div class="col-sm-10">
        <input type="number" class="form-control" id="amount" name="amount" placeholder="Amount" autocomplete="off"
               v-model="mutableValue.payment_amount"
               v-validate="'required|decimal'">
      </div>
      <div class="col-sm-10 col-sm-offset-2 help-block is-danger" v-show="errors.has('amount')">
        {{ errors.first('amount') }}
      </div>
    </div>
    <div class="form-group" :class="{'has-error': errors.first('duration_length')}">
      <label for="duration_length" class="col-sm-2 control-label">Duration</label>
      <div class="col-sm-2">
        <input type="number" class="form-control" id="duration_length" name="duration_length" placeholder="Length"
               autocomplete="off"
               v-model="mutableValue.renewal_duration_length"
               v-validate="'required|numeric'">
      </div>
      <div class="col-sm-2">
        <select class="form-control" name="duration_unit" v-model="mutableValue.renewal_duration_unit">
          <option :value="durationUnit" v-for="(durationUnit, index) in durationUnits" :key="index">{{ durationUnit }}</option>
        </select>
      </div>
      <div class="col-sm-10 col-sm-offset-2 help-block is-danger" v-show="errors.has('duration_length')">
        {{ errors.first('duration_length') }}
      </div>
    </div>
    <div class="box-footer">
      <loading-button class="col-md-offset-2">Submit</loading-button>
    </div>
  </form>
</template>

<script>
import SubscriptionMixin from '@/mixins/SubscriptionMixin';
import FormMixin from '@/mixins/FormMixin';
import LoadingButton from '@/components/utils/LoadingButton';

export default {
  name: 'subscription-form',
  props: ['value', 'packages', 'freeTrial'],
  mixins: [SubscriptionMixin, FormMixin],
  data() {
    return {
      loading: false,
      durationUnits: ['day', 'week', 'month', 'year'],
    };
  },
  components: {
    LoadingButton,
  },
  computed: {
    mutableValue() {
      return Object.assign(this.value);
    },
  },
};
</script>
