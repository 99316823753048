import _ from 'lodash';
import api from '@/api';
import config from '@/config';
import { isCancel } from 'axios';

const ChartLoaderTokens = [];
const roundToHalf = (value) => {
  const converted = parseFloat(value); // Make sure we have a number
  let dec = (converted - parseInt(converted, 10));
  dec = Math.round(dec * 10);
  if (dec === 5) { return (parseInt(converted, 10) + 0.5); }
  if ((dec < 3) || (dec > 7)) {
    return Math.round(converted);
  }
  return (parseInt(converted, 10) + 0.5);
};

export default {
  methods: {
    getChart(chart) {
      const cUrl = new URL(`${config.serverURI}reports`);
      const params = new URLSearchParams();
      params.set('name', chart.name);
      params.set('start_date', chart.startDate.toISOString());
      params.set('end_date', chart.endDate.toISOString());
      if (chart.schoolId || chart.classId) {
        params.set('enterprise_id', chart.schoolId);
        params.set('enterprise_group_id', chart.classId);
      }
      if (chart.output) {
        params.set('output', chart.output);
      }
      Object.keys(chart.params).forEach((key) => {
        params.set(`${key}`, chart.params[key]);
      });
      cUrl.search = params;

      const finalUrl = cUrl.toString();

      if (ChartLoaderTokens[chart.objId]) {
        ChartLoaderTokens[chart.objId].cancel('Cancelling request as we have a different one incoming');
      }

      ChartLoaderTokens[chart.objId] = api.CancelToken.source();

      return new Promise((resolve, reject) => {
        api.get(finalUrl, { cancelToken: ChartLoaderTokens[chart.objId].token })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            // catch and ignore cancellation warnings
            if (!isCancel(error)) {
              this.$apiResponse.renderErrorMessage(error);
            }
            reject(error);
          });
      });
    },
    fetchDataForCharts(charts) {
      _.forEach(charts, (chart) => {
        chart.fetchData()
          .then(() => {
            this.$nextTick(() => {
              chart.render();
            });
          })
          .catch((error) => {
            // catch and ignore cancellation warnings
            if (!isCancel(error)) {
              this.$apiResponse.renderErrorMessage(error);
            }
          });
      });
    },
    tooltipReadingTime(val, allowDecimal = true) {
      let label = '';
      label += label ? ': ' : '';
      if (val > 0 && val < 60) {
        return `${val} ${val === 1 ? ' sec' : 'secs'}`;
      }
      let mins = (val / 60).toFixed(2);
      if (!allowDecimal) {
        mins = Math.round(val / 60);
      }
      const hours = Math.floor(mins / 60);
      const remainingMins = mins - (hours * 60);
      label += hours > 0 ? hours + (hours > 1 ? ' hrs ' : ' hr ') : '';
      label += remainingMins > 0 ? remainingMins + (remainingMins > 1 ? ' mins ' : ' min ') : '';
      return label;
    },
    tooltipConversionRate(y, series) {
      if (series.seriesIndex === 2) {
        return `${y}%`;
      }

      return y;
    },
    yAxisReadingTime(value) {
      let label = '';
      label += label ? ': ' : '';
      if (value < 60 * 2) {
        return `${value} secs`;
      }
      const mins = roundToHalf(value / 60);
      if (mins < 60) {
        return `${mins} mins`;
      }
      const hours = roundToHalf(mins / 60);
      label += hours > 0 ? hours + (hours > 1 ? ' hrs ' : ' hr ') : '';
      return label;
    },
    yAxisInteger(value) {
      return value.toFixed(0);
    },
    xAxisSubscribers(value) {
      if (value) {
        const arrayOfStrings = value.split('-');
        if (value.includes(':')) {
          return value;
        }
        if (arrayOfStrings.length < 3) {
          // return week year
          const week = arrayOfStrings[0];
          const year = arrayOfStrings[1];
          return `${this.ordinal_suffix_of(week)} week of ${year}`;
        }
        if (arrayOfStrings.length === 3) {
          // return dd/mm/yyyy
          const day = arrayOfStrings[0];
          const month = arrayOfStrings[1];
          const year = arrayOfStrings[2];
          return `${day}/${month}/${year}`;
        }
        return value;
      }
      return value;
    },
    ordinal_suffix_of(i) {
      i = parseInt(i, 10);
      const j = i % 10;
      const k = i % 100;
      if (j === 1 && k !== 11) {
        return `${i}st`;
      }
      if (j === 2 && k !== 12) {
        return `${i}nd`;
      }
      if (j === 3 && k !== 13) {
        return `${i}rd`;
      }
      return `${i}th`;
    },
  },
};
