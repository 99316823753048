var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "main-footer" }, [
    _c("strong", [
      _vm._v("Copyright © " + _vm._s(_vm.year) + " "),
      _c("a", { attrs: { href: "http://auris.tech" } }, [_vm._v("Auris Tech")]),
      _vm._v(".")
    ]),
    _vm._v(" All rights reserved. ")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }