<template>
  <wrapper>
    <modal :title="`Delete ${user.full_name}`" v-if="modal.isVisible('deleteUser')" @close="modal.hide('deleteUser'); deletionString = null;" :show-footer="false">
      <p class="text-danger lead">This user will be permanently deleted. <br/> This action is irreversible.</p>
      <div class="input-group width-100">
        <input class="form-control width-100" name="full_name" :placeholder="`Enter '${user.full_name}' to confirm`" v-model="deletionString" type="text" autocomplete="off">
      </div>
      <div class="modal-footer">
        <slot name="footer"></slot>
        <loading-button type="button" class="btn btn-warning" data-dismiss="modal" @click="modal.hide('deleteUser'); deletionString = null;">Cancel</loading-button>
        <loading-button type="button" class="btn btn-primary btn-danger" data-dismiss="modal" @click="deleteUser" :disabled="deletionString !== user.full_name">Delete Permanently</loading-button>
      </div>
    </modal>
    <modal title="Zoho sync" v-if="modal.isVisible('syncZoho')" @close="modal.hide('syncZoho')" :show-footer="false">
      <p class="lead">The sync is happening. Please do not re-click on the button.</p>
    </modal>
    <modal title="Modify Dates" v-if="modal.isVisible('reset-dates')" :show-footer="false" @close="modal.hide('reset-dates')">
      <div class="modal__reset-dates">
        <DatePickerWithDropdown
            placeholder="Show data for..."
            reference="time_period"
            @date-range-change="handleChangeDateRange($event)"
            v-model="dateRange"
            :initialDateRange="dateRange"
            :max-date="maxDate"
            :show-drop-down="false"
            mode="dateTime"
        />
        <div class="mt-3 mb-3">
          <button class="btn btn-default mr-2" style="width: 100%" @click="resetChallengeDates">Reset start date to today</button>
        </div>
        <div class="modal-footer">
          <slot name="footer"></slot>
          <button class="btn btn-primary" @click="handleUserChallenge(currentChallengeSlug, 'reset-dates')">Save</button>
        </div>
      </div>
    </modal>
    <section class="user-page" v-if="user">

      <div class="flex justify-content-between align-items-center mb-6">
        <h2 class="m-0">{{ user.full_name }} <country-flag v-if="user.country" :country='user.country' size='normal'/><br><small><i class="fa fa-envelope" />{{ user.email }}</small></h2>
        <div class="page-actions">
          <button class="btn btn-primary" @click="$router.push(`${user.id}/edit`)">Update account details</button>
          <confirmation-button :show-modal="showConfirmationModal['reset-password']" @close="showConfirmationModal['reset-password'] = false"
            @click="handleResetPassword" :hide-modal-footer="true">
            <template #button>Reset password</template>
            <template #modal>
              <p>An email containing a password reset link will be emailed to this user. Press continue below to send that email</p>
              <div class="flex justify-content-end">
                <button class="btn btn-success" @click="handleResetPasswordContinue" :disabled="resetLoading">Continue</button>
              </div>
            </template>
          </confirmation-button>
          <button class="btn btn-danger" @click="modal.show('deleteUser')">Delete account</button>
          <button class="btn" v-if="user.user_type === 'enterprise'" @click="syncZoho">Sync Zoho</button>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="box box-info mb-6">
            <div class="box-body">
              <table class="table table-striped">
                <tbody>
                <tr><th>Registered</th><td><span v-if="user.created_at">{{ formatDate(user.created_at, true) }}</span><span v-else>Unknown</span></td></tr>
                <tr><th>Last Seen</th><td><span v-if="user.last_seen_at">{{ formatDate(user.last_seen_at, true) }}</span><span v-else>Unknown</span></td></tr>
                <tr><th>Digest Opted In</th><td>{{ user.weekly_digest_optin_at ? 'Yes' : 'No' }} <a @click="toggleStatus('weekly_digest_optin')">{{ user.weekly_digest_optin_at ? '[opt out]' : '[opt in]' }}</a></td></tr>
                <tr><th>Marketing Opted In</th><td>{{ user.marketing_optin_at ? 'Yes' : 'No' }} <a @click="toggleStatus('marketing_optin')">{{ user.marketing_optin_at ? '[opt out]' : '[opt in]' }}</a></td></tr>
                <tr><th>Beta Program</th><td>{{ user.beta_access_status === 'opted_in' ? 'Opted In' : 'Opted Out' }} <a @click="toggleStatus('beta_access_status')">{{ user.beta_access_status === 'opted_in' ? '[opt out]' : '[opt in]' }}</a><router-link tag="a"  :to="'/users/' + this.user.id + '/recordings/'" v-if="user.recordings_count > 0">[view {{ user.recordings_count }} recordings]</router-link></td></tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="user.readers">
            <h3>Reader Profiles</h3>
            <div v-if="user.readers.length === 0" class="alert alert-warning">The user has not yet created any reader profiles</div>
            <box-with-icons v-for="reader in user.readers" :key="reader.id" @click="$router.push(`${user.id}/readers/${reader.id}`)" class="reader-profile__wrap">
              <div class="reader-profile">
                <img alt="avatar" :src="'/img/fonetti_avatars/' + reader.avatar + '.png'" class="avatar" />
                <div>
                  <a @click="$router.push(`${user.id}/readers/${reader.id}`)">{{ reader.name }} ({{ reader.username }})</a><br>
                  Age: {{ reader.age }}
                </div>
              </div>
            </box-with-icons>
          </div>
        </div>
        <div class="col-md-6">
          <template v-if="this.user.challenges">
          <h3 class="mt-0">Challenges</h3>
          <box-with-icons v-for="challenge in userChallenges"
               class="user-challenge"
               :key="challenge.id"
               :icons="challenge.has_joined ? [{
                 bgColor: challenge.in_progress ? iconColors.green : iconColors.red,
                 icon: challenge.in_progress ? 'hourglass' : 'ban',
               }] : []">
            <div class="joined" v-if="challenge.has_joined">
              <div>
                <p class="flex gap-3"><strong>{{ challenge.name }}</strong> <small class="badge bg-light-blue">{{ challenge.slug }}</small></p>
                <p class="mb-2">{{ formatDate(challenge.pivot.created_at) }} - {{ formatDate(challenge.pivot.ends_at) }}</p>
                <p v-if="challenge.in_progress">{{ challenge.days_remaining }} days remaining</p>
              </div>
              <div class="actions" v-if="challenge.in_progress">
                <confirmation-button :show-modal="showConfirmationModal['resend-welcome-email']" @close="showConfirmationModal['resend-welcome-email'] = false" @click="handleUserChallenge(challenge.slug, 'resend-welcome-email')">
                  <template #button>Resend Welcome Email</template>
                  <template #modal>Email has been sent</template>
                </confirmation-button>
                <button class="btn btn-info" @click="startResetDates(challenge)">Modify Dates</button>
              </div>
            </div>

            <div class="user-challenge not_joined" v-else>
              <p class="flex gap-3"><strong>{{ challenge.name }}</strong> <small class="badge bg-light-blue">{{ challenge.slug }}</small></p>
              <div class="actions">
                <loading-button class="btn btn-info" @click="handleUserChallenge(challenge.slug, 'enrol')">Register into challenge</loading-button>
              </div>
            </div>
          </box-with-icons>
          </template>
          <template v-if="this.user.subscriptions">
          <h3>
            Subscriptions
            <button class="btn btn-primary" @click="$router.push(`/users/${user.id}/subscriptions/new`)">Add free subscription</button>
          </h3>
          <box-with-icons v-for="subscription in userSubscriptions"
               :key="subscription.id"
               :top="5"
               class="user-subscription"
               :icons="[
                 {
                   bgColor: subscription.status_icon_bg,
                   icon: subscription.status_icon,
                   tooltip: subscription.status_tooltip,
                 },
                 {
                   bgColor: subscription.provider_icon_bg,
                   icon: subscription.provider_icon,
                   tooltip: subscription.provider_tooltip,
                 }
               ]">
            <p class="title">{{ subscription.title }} <span v-if="!subscription.has_expired" class="badge badge-success" style="margin-bottom: 3px; margin-left: 5px">subscribed</span></p>
            <p class="mb-2"><span v-if="subscription.payment_provider !== 'manual'">{{ subscription.duration_string }} | </span>{{ subscription.devices }}<span v-if="subscription.payment_provider !== 'manual'"> | {{ subscription.payment_amount }}</span></p>
            <p>{{ formatDate(subscription.start_date) }} - {{ formatDate(subscription.expires_at) }}</p>
            <small>Subscription ID: {{ subscription.payment_provider_subscription_id }}</small>
          </box-with-icons>
          </template>
          <h3>Mail Logs</h3>
          <div class="user-mail-logs__idle" v-if="hideMailLogs">
            <a @click="getUserMailLogs">Fetch entries</a>
          </div>
          <div class="user-mail-logs" v-else>
            <table class="table table-striped table-bordered">
              <thead>
              <tr>
                <th>ID</th>
                <th>Subject</th>
                <th>Sent On</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(log, k) in this.mailLogs" :key="k">
                <td>
                  <confirmation-button :show-modal="showConfirmationModal['copy-to-clipboard']" @close="showConfirmationModal['copy-to-clipboard'] = false" @click="startCopyToClipboard(log.id)">
                    <template #button>#</template>
                    <template #modal>Copied to clipboard: {{ log.id }}</template>
                  </confirmation-button>
                </td>
                <td>{{ log.subject }}</td>
                <td>{{ formatDate(log.date, true, true) }}</td>
                <td>
                  <div class="wrap" style="text-align: center">
                    <span v-for="(status, k) in log.statuses" :key="k" class="badge badge-pill" :class="getMailLogBadgeClass(status)">{{ status }}</span>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </wrapper>
</template>

<script>
import moment from 'moment';
import Wrapper from '@/components/Wrapper';
import UserMixin from '@/mixins/UserMixin';
import BoxWithIcons from '@/components/BoxWithIcons';
import CountryFlag from 'vue-country-flag';
import ConfirmationButton from '@/components/utils/ConfirmationButton';
import ModalJS from '@/components/utils/Modal';
import Modal from '@/classes/Modal';
import DatePickerWithDropdown from '@/components/DatePickerWithDropdown';
import LoadingButton from '@/components/utils/LoadingButton';
import HelpersMixin from '@/mixins/HelpersMixin';
import { mapState } from 'vuex';

const include = 'challenges,subscriptions';

export default {
  name: 'user',
  components: {
    LoadingButton,
    Wrapper,
    CountryFlag,
    BoxWithIcons,
    ConfirmationButton,
    ModalJS, // eslint-disable-line
    modal: ModalJS,
    DatePickerWithDropdown,
  },
  mixins: [UserMixin, HelpersMixin],
  data() {
    return {
      availableChallenges: [],
      showConfirmationModal: {
        'resend-welcome-email': false,
        'reset-password': false,
        'copy-to-clipboard': false,
      },
      modal: new Modal({
        resetDates: false,
        deleteUser: false,
        syncZoho: false,
      }),
      dateRange: {
        start: new Date(),
        end: new Date(),
      },
      maxDate: moment().add(1, 'year').toDate(),
      currentChallengeSlug: null,
      currentChallenge: null,
      includes: 'challenges,subscriptions,readers',
      deletionString: null,
      mailLogs: [],
      hideMailLogs: true,
      iconColors: {
        green: '#d4edda',
        red: '#f8d7da',
      },
      resetLoading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getUser();
      this.$store.dispatch('challenges/getChallenges').then((challenges) => {
        this.availableChallenges = challenges;
      });
    });
  },
  methods: {
    startResetDates(challenge) {
      this.dateRange = {
        start: moment.utc(challenge.pivot.created_at).toDate(),
        end: moment.utc(challenge.pivot.ends_at).toDate(),
      };
      this.currentChallenge = challenge;
      this.currentChallengeSlug = challenge.slug;
      this.modal.show('reset-dates');
    },
    handleChangeDateRange(newRange) {
      this.dateRange = newRange;
    },
    toggleStatus(field) {
      const data = {};
      switch (field) {
        case 'beta_access_status':
          data.beta_access_status = this.user.beta_access_status === 'opted_in' ? 'opted_out' : 'opted_in';
          break;
        case 'weekly_digest_optin':
          data.weekly_digest_optin = this.user.weekly_digest_optin_at === null;
          break;
        case 'marketing_optin':
          data.receive_marketing_emails = this.user.marketing_optin_at === null;
          break;
        default:
          break;
      }

      const payload = {
        userId: this.user.id,
        data,
        params: {
          include: this.includes,
        },
      };

      this.$store.dispatch('users/updateUser', payload).catch((err) => {
        this.$apiResponse.renderErrorMessage(err);
      });
    },
    handleUserChallenge(slug, action) {
      this.$store.dispatch('app/setGlobalLoading', true);
      const data = { action };
      if (action === 'reset-dates') {
        data.start_date = this.dateRange.start;
        data.end_date = this.dateRange.end;
      }

      this.$store.dispatch('users/handleUserChallenge', {
        id: this.user.id,
        data: { data, slug },
      }).then(() => {
        if (action === 'reset-dates') {
          this.modal.hide('resetDates');
        } else {
          this.showConfirmationModal[action] = true;
        }
        this.getUser(this.user.id, this.includes);
      }).catch((error) => {
        this.$apiResponse.renderErrorMessage(error);
      }).finally(() => this.$store.dispatch('app/setGlobalLoading', false));
    },
    handleUser(action) {
      const data = {
        id: this.user.id,
        action,
      };
      this.$store.dispatch('users/handleUserActions', data).then(() => {
        this.showConfirmationModal[action] = true;
      }).catch((error) => {
        this.$apiResponse.renderErrorMessage(error);
      });
    },
    handleResetPassword() {
      const action = 'reset-password';
      this.showConfirmationModal[action] = true;
    },
    handleResetPasswordContinue() {
      this.resetLoading = true;
      const action = 'reset-password';
      const data = {
        id: this.user.id,
        action,
      };
      this.$store.dispatch('users/handleUserActions', data).then(() => {
        this.$apiResponse.renderSuccessMessage({}, 'Password reset email has been sent.');
        this.showConfirmationModal[action] = false;
      }).catch((error) => {
        this.$apiResponse.renderErrorMessage(error);
      }).finally(() => {
        this.resetLoading = false;
      });
    },
    deleteUser() {
      this.$store.dispatch('app/setGlobalLoading', true);
      this.$store.dispatch('users/deleteUser', { id: this.user.id }).then(() => {
        this.$router.push('/users');
      }).catch((error) => {
        this.$apiResponse.renderErrorMessage(error);
      }).finally(() => {
        this.$store.dispatch('app/setGlobalLoading', false);
      });
    },
    getUserMailLogs() {
      this.$store.dispatch('app/setGlobalLoading', true);
      this.$store.dispatch('users/getUserMailLogs', this.user.id).then((response) => {
        this.mailLogs = response.logs;
        this.hideMailLogs = false;
      }).catch((error) => {
        this.$apiResponse.renderErrorMessage(error);
      }).finally(() => this.$store.dispatch('app/setGlobalLoading', false));
    },
    startCopyToClipboard(value) {
      this.copyToClipboard(value);
      this.showConfirmationModal['copy-to-clipboard'] = true;
    },
    getMailLogBadgeClass(status) {
      switch (status) {
        case 'failed':
          return 'badge-danger';
        case 'opened':
          return 'badge-info';
        case 'delivered':
          return 'badge-success';
        default:
          return 'badge-secondary';
      }
    },
    resetChallengeDates() {
      const { days_remaining: daysRemaining } = this.currentChallenge;
      this.dateRange = {
        start: new Date(),
        end: moment().add(daysRemaining, 'days').toDate(),
      };
    },
    getUser() {
      const userId = this.$route.params.userid;
      this.$store.dispatch('users/fetchUserById', { id: userId, params: { include } })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
        });
    },
    syncZoho() {
      const payload = {
        userId: this.user.id,
        data: {
          action: 'sync-zoho',
        },
      };
      this.$store.dispatch('users/syncZoho', payload).then(() => {
        this.modal.show('syncZoho');
      }).catch((err) => {
        this.$apiResponse.renderErrorMessage(err);
      });
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.users.user,
    }),
    userChallenges() {
      const userChallenges = [];
      if (this.user.challenges === undefined) {
        return userChallenges;
      }
      this.availableChallenges.forEach((challenge) => {
        const userChallenge = (this.user.challenges.length > 0) ? this.user.challenges.find((c) => c.id === challenge.id) : null;
        challenge.has_joined = false;
        if (userChallenge) {
          challenge.has_joined = true;
          const endDate = moment(userChallenge.pivot.ends_at);
          const current = moment().startOf('day');
          challenge.days_remaining = Math.round(moment.duration(endDate.diff(current)).asDays());
          challenge.in_progress = challenge.days_remaining > 0;
          challenge.pivot = userChallenge.pivot;
        }
        userChallenges.push(challenge);
      }, this);

      return userChallenges;
    },
    userSubscriptions() {
      const userSubscriptions = [];
      if (this.user.subscriptions === undefined) {
        return userSubscriptions;
      }
      this.user.subscriptions.forEach((subscription) => {
        const userSub = {};
        userSub.title = subscription.package_title;
        switch (subscription.renewal_duration_unit) {
          case 'day':
            userSub.duration_string = 'Daily';
            break;
          case 'week':
            userSub.duration_string = 'Weekly';
            break;
          case 'month':
            userSub.duration_string = 'Monthly';
            break;
          case 'year':
            userSub.duration_string = 'Yearly';
            break;
          default:
            userSub.duration_string = 'Unknown';
            break;
        }
        userSub.devices = subscription.max_devices > 1 ? 'Multi-Device' : 'Single-Device';
        userSub.start_date = subscription.created_at;
        userSub.expires_at = subscription.expires_at;
        userSub.has_expired = subscription.has_expired;
        userSub.payment_provider_subscription_id = subscription.payment_provider_subscription_id;
        userSub.payment_provider = subscription.payment_provider;

        userSub.provider_icon = 'gear';
        userSub.provider_tooltip = 'Manual subscription';
        userSub.provider_icon_bg = '#ffffff';
        if (subscription.payment_provider === 'itunes') {
          userSub.provider_icon = 'apple';
          userSub.provider_tooltip = 'iTunes subscription';
        }
        if (subscription.payment_provider === 'google') {
          userSub.provider_icon = 'android';
          userSub.provider_tooltip = 'Google subscription';
        }
        if (subscription.payment_provider === 'stripe') {
          userSub.provider_icon = 's';
          userSub.provider_tooltip = 'Stripe subscription';
        }

        subscription.in_progress = !subscription.has_expired;
        userSub.status_icon = !subscription.has_expired ? 'rotate-right' : 'ban';
        userSub.status_icon_bg = subscription.renewal_status === 'active' ? this.iconColors.green : this.iconColors.red;
        userSub.payment_amount = `£${subscription.payment_amount}`;

        let statusTooltip = '';
        if (subscription.renewal_status === 'active' && !subscription.has_expired) {
          statusTooltip = 'Renewal is active';
        }
        if (subscription.renewal_status !== 'active') {
          if (!subscription.has_expired) {
            statusTooltip = 'Subscription will not renew';
          } else {
            statusTooltip = 'Renewal has been cancelled ';
          }
        }
        userSub.status_tooltip = statusTooltip;

        userSubscriptions.push(userSub);
      }, this);

      return userSubscriptions;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .modal .input-inner {
  font-size: 18px !important;
}
.normal-flag {
  margin: 0 0 -10px -5px!important;
}
a {
  cursor: pointer;
}
h2 {
  margin-bottom: 30px;
}
h3 {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  button {
    padding-top: 3px;
  }
}
h2 small {
  display: block;
}
ul {
  list-style: none;
}
img.avatar {
  border-radius: 50%;
  width: 50px;
  border: 1px solid #D2D6DE;
  margin-right: 10px;
}
.reader-profile {
  display: flex;
  align-items: center;
  &__wrap {
    cursor: pointer;
    &:hover {
      background-color: #f8f8f8;
    }
  }
}
.user-challenge {
  margin-bottom: 35px;
}
.user-subscription {
  margin-bottom: 10px;
}
.user-challenge:last-child, .user-subscription:last-child {
  margin-bottom: 0;
}
.user-challenge {
  .joined {
    display: flex;
    justify-content: space-between;

    .actions {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .not_joined {
    .actions {
      text-align: center;
    }
  }
}
.user-subscription {
  .title {
    font-weight: bold;
  }
}
.page-actions {
  display: flex;
  justify-content: flex-end;
  button {
    margin: 0 5px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.user-mail-logs {
  background: white;
  padding: 5px;
  &__idle {
    background-color: #E3E3E3;
    padding: 5px;
    text-align: center;
  }
  table {
    margin-bottom: 0;
  }
}
.modal {
  &__reset-dates {
    text-align: center;
  }
}
.datepicker-wrapper {
  margin: 0 auto;
}
</style>
