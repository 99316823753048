var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("wrapper", [
    _c("section", { staticClass: "subscription-page" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12 col-sm-12 col-xs-12 box-tools" }, [
          _vm.user
            ? _c(
                "div",
                { staticClass: "pull-right" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        to: "/users/" + _vm.user.id + "/subscriptions/new"
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-plus" }),
                      _vm._v(" Add a free trial subscription ")
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ])
      ]),
      _c(
        "div",
        { staticClass: "row" },
        [
          _vm.subscriptions.length === 0
            ? _c("div", { staticClass: "col-md-12" }, [
                _c("div", { staticClass: "alert alert-info" }, [
                  _vm._v(" No subscriptions ")
                ])
              ])
            : _vm._e(),
          _vm._l(_vm.subscriptions, function(subscription) {
            return _c(
              "div",
              { key: subscription.id, staticClass: "col-md-6" },
              [
                _c("div", { staticClass: "box" }, [
                  subscription.package
                    ? _c("div", { staticClass: "box-header" }, [
                        _c("h3", { staticClass: "box-title" }, [
                          _vm._v(
                            "Package " +
                              _vm._s(subscription.package.type) +
                              ": " +
                              _vm._s(subscription.package.title)
                          )
                        ])
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "box-body no-padding table-responsive" },
                    [
                      _c("table", { staticClass: "table table-borderless" }, [
                        _c("tbody", [
                          _c("tr", [
                            _c("th", { staticClass: "cell-25" }, [
                              _vm._v("Renewal Status")
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(subscription.renewal_status))
                            ])
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("Expiry Date")]),
                            _c("td", [_vm._v(_vm._s(subscription.expires_at))])
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("Max Readers")]),
                            _c("td", [_vm._v(_vm._s(subscription.max_readers))])
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("Duration")]),
                            _c("td", [
                              _vm._v(
                                _vm._s(subscription.renewal_duration_length) +
                                  " " +
                                  _vm._s(
                                    _vm.pluralize(
                                      subscription.renewal_duration_length,
                                      subscription.renewal_duration_unit
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("Price")]),
                            _c("td", [
                              _vm._v(_vm._s(subscription.payment_amount) + "£")
                            ])
                          ])
                        ])
                      ])
                    ]
                  )
                ])
              ]
            )
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }