import api from '@/api';

export default {
  data() {
    return {};
  },
  methods: {
    getSchoolSubscriptions(schoolId) {
      return new Promise((resolve) => {
        api.get(`admin/enterprises/${schoolId}/subscriptions`)
          .then((response) => {
            resolve(response.data.subscriptions);
          })
          .catch((error) => {
            this.$apiResponse.renderErrorMessage(error);
          });
      });
    },
    getUserSubscriptions(userId) {
      return new Promise((resolve, reject) => {
        api.get(`admin/users/${userId}/subscriptions`)
          .then((response) => {
            resolve(response.data.subscriptions);
          })
          .catch((error) => {
            this.$apiResponse.renderErrorMessage(error);
            reject(error);
          });
      });
    },
  },
};
