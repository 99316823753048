var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ValidationObserver",
    { ref: "subscription", attrs: { tag: "section" } },
    [
      _c(
        "form",
        {
          staticClass: "ui form form-horizontal",
          attrs: { autocomplete: "off" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.validateSubForm($event)
            }
          }
        },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "max_readers",
              rules: { required: true, numeric: true },
              slim: ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var errors = ref.errors
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "form-group",
                        class: { "has-error": errors.length > 0 }
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "col-sm-3 control-label",
                            attrs: { for: "max_readers" }
                          },
                          [_vm._v("Number of pupils")]
                        ),
                        _c("div", { staticClass: "col-sm-9 col-md-9" }, [
                          _c("div", { staticClass: "input-group width-100" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.mutableValue.max_readers,
                                  expression: "mutableValue.max_readers"
                                }
                              ],
                              staticClass: "form-control width-100",
                              attrs: {
                                name: "max_readers",
                                placeholder: "Pupils",
                                type: "text",
                                autocomplete: "off",
                                id: "max_readers",
                                "data-lpignore": "true"
                              },
                              domProps: { value: _vm.mutableValue.max_readers },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.mutableValue,
                                    "max_readers",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _c("span", { staticClass: "text-danger" }, [
                            _vm._v(_vm._s(errors[0]))
                          ])
                        ])
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c("ValidationProvider", {
            attrs: { name: "expire_at", rules: "required", slim: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var errors = ref.errors
                  return [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-sm-3 control-label",
                          attrs: { for: "expires_at" }
                        },
                        [_vm._v("Expires at")]
                      ),
                      _c("div", { staticClass: "col-sm-9 col-md-9" }, [
                        _c(
                          "div",
                          { staticClass: "input-group width-100" },
                          [
                            _c("DatePicker", {
                              attrs: {
                                mode: "date",
                                "is-range": false,
                                popover: {
                                  placement: "bottom-start",
                                  visibility: "click"
                                },
                                date: _vm.mutableValue.expires_at,
                                "data-lpignore": "true",
                                id: "expires_at",
                                name: "expires_at"
                              },
                              model: {
                                value: _vm.mutableValue.expires_at,
                                callback: function($$v) {
                                  _vm.$set(_vm.mutableValue, "expires_at", $$v)
                                },
                                expression: "mutableValue.expires_at"
                              }
                            })
                          ],
                          1
                        ),
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ])
                    ])
                  ]
                }
              }
            ])
          }),
          _c("div", { staticClass: "form-group" }, [
            _c(
              "label",
              {
                staticClass: "col-sm-3 control-label",
                attrs: { for: "payment_amount" }
              },
              [_vm._v("Renewal price")]
            ),
            _c("div", { staticClass: "col-sm-9 col-md-9" }, [
              _c(
                "div",
                { staticClass: "renewal-price-group" },
                [
                  _c("ValidationProvider", {
                    attrs: {
                      name: "payment_amount",
                      rules: { required: true },
                      slim: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              { class: { "has-error": errors.length > 0 } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "input-group width-100" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.mutableValue.payment_amount,
                                          expression:
                                            "mutableValue.payment_amount"
                                        }
                                      ],
                                      staticClass: "form-control width-100",
                                      attrs: {
                                        name: "payment_amount",
                                        placeholder: "£",
                                        type: "text",
                                        autocomplete: "off",
                                        id: "payment_amount",
                                        "data-lpignore": "true"
                                      },
                                      domProps: {
                                        value: _vm.mutableValue.payment_amount
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.mutableValue,
                                            "payment_amount",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                ),
                                _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(_vm._s(errors[0]))
                                ])
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("ValidationProvider", {
                    attrs: {
                      name: "renewal_duration",
                      rules: "required",
                      slim: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.mutableValue.renewal_duration_unit,
                                    expression:
                                      "mutableValue.renewal_duration_unit"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  name: "renewal_duration",
                                  id: "renewal_duration"
                                },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.mutableValue,
                                      "renewal_duration_unit",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              _vm._l(_vm.durationOptions, function(option) {
                                return _c(
                                  "option",
                                  {
                                    key: option.value,
                                    attrs: { disabled: option.disabled },
                                    domProps: {
                                      value: option.value,
                                      selected:
                                        _vm.mutableValue
                                          .renewal_duration_unit === _vm.value
                                    }
                                  },
                                  [_vm._v(_vm._s(option.text))]
                                )
                              }),
                              0
                            ),
                            _c("span", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(errors[0]))
                            ])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ])
          ]),
          _c("ValidationProvider", {
            attrs: { name: "packages", rules: "required", slim: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var errors = ref.errors
                  return [
                    _c(
                      "div",
                      { staticClass: "available-packages form-group" },
                      [
                        _c("label", { staticClass: "col-sm-3 control-label" }, [
                          _vm._v("Packages")
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-sm-19 col-md-9" },
                          [
                            _vm._l(_vm.availablePackages, function(p) {
                              return _c(
                                "div",
                                { key: p.id, staticClass: "checkbox" },
                                [
                                  _c("label", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.mutableValue.packages,
                                          expression: "mutableValue.packages"
                                        }
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        name: "packages"
                                      },
                                      domProps: {
                                        value: p.id,
                                        checked: Array.isArray(
                                          _vm.mutableValue.packages
                                        )
                                          ? _vm._i(
                                              _vm.mutableValue.packages,
                                              p.id
                                            ) > -1
                                          : _vm.mutableValue.packages
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$a = _vm.mutableValue.packages,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = p.id,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.mutableValue,
                                                  "packages",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.mutableValue,
                                                  "packages",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.mutableValue,
                                              "packages",
                                              $$c
                                            )
                                          }
                                        }
                                      }
                                    }),
                                    _vm._v(" " + _vm._s(p.title) + " ")
                                  ])
                                ]
                              )
                            }),
                            _c("span", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(errors[0]))
                            ]),
                            _vm.displayPackagesWarning
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "text-danger mt-2",
                                    staticStyle: { display: "inline-block" }
                                  },
                                  [
                                    _vm._v(
                                      "Books within the removed packages will be immediately unassigned from all pupils "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _vm.displayButtons
            ? _c("div", { staticClass: "form-group" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-offset-4 col-sm-10 col-md-8" },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-2" },
                        [_c("loading-button", [_vm._v("Save")])],
                        1
                      ),
                      _c("div", { staticClass: "col-md-2" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            on: {
                              click: function($event) {
                                return _vm.$emit("cancel")
                              }
                            }
                          },
                          [_vm._v("Cancel")]
                        )
                      ])
                    ])
                  ]
                )
              ])
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }