var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "wrapper",
    [
      _c("user-attribute-filters", {
        staticClass: "justify-content-end",
        attrs: { "show-user-type": false },
        on: { select: _vm.onChallengeFilter }
      }),
      _c("div", [
        _vm._v(
          "Note: To avoid excessive load on our databases, we only show the latest active 100 readers below."
        )
      ]),
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-header with-border" }, [
          _c("h3", { staticClass: "box-title" }, [
            _vm._v("Points per attribute")
          ])
        ]),
        _c("div", { staticClass: "box-body" }, [
          _c("p", [
            _c("em", [
              _vm._v("To implement a simplified "),
              _c("b", [_vm._v("points per star")]),
              _vm._v(
                " set all these to the same number. Otherwise set them differently to award different levels of accuracy."
              )
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-3 col-lg-2" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "per1StarInput" } }, [
                  _vm._v("Per 1 Star")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.points.per_1star,
                      expression: "points.per_1star"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "number",
                    id: "per1StarInput",
                    step: "1",
                    min: "0"
                  },
                  domProps: { value: _vm.points.per_1star },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.points, "per_1star", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "col-sm-3 col-lg-2" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "per2StarInput" } }, [
                  _vm._v("Per 2 Stars")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.points.per_2stars,
                      expression: "points.per_2stars"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "number",
                    id: "per2StarInput",
                    step: "1",
                    min: "0"
                  },
                  domProps: { value: _vm.points.per_2stars },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.points, "per_2stars", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "col-sm-3 col-lg-2" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "per3StarInput" } }, [
                  _vm._v("Per 3 Stars")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.points.per_3stars,
                      expression: "points.per_3stars"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "number",
                    id: "per3StarInput",
                    step: "1",
                    min: "0"
                  },
                  domProps: { value: _vm.points.per_3stars },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.points, "per_3stars", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "col-sm-3 col-lg-2" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "perBookReadInput" } }, [
                  _vm._v("Per Book Read")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.points.per_read,
                      expression: "points.per_read"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "number",
                    id: "perBookReadInput",
                    step: "1",
                    min: "0"
                  },
                  domProps: { value: _vm.points.per_read },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.points, "per_read", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "col-sm-3 col-lg-2" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "perMinuteInput" } }, [
                  _vm._v("Per Minute of Reading "),
                  _c("small", [_vm._v("(rounded down)")])
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.points.per_minute,
                      expression: "points.per_minute"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "number",
                    id: "perMinuteInput",
                    step: "1",
                    min: "0"
                  },
                  domProps: { value: _vm.points.per_minute },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.points, "per_minute", $event.target.value)
                    }
                  }
                })
              ])
            ])
          ])
        ])
      ]),
      _c(
        "section",
        { staticClass: "leaderboard-page" },
        [
          _c("vue-good-table", {
            attrs: {
              styleClass: "vgt-table striped",
              theme: "auris-admin",
              "line-numbers": true,
              columns: _vm.columns,
              rows: _vm.rows,
              totalRows: _vm.totalRecords,
              "pagination-options": {
                enabled: true,
                mode: "records",
                perPage: 25,
                position: "bottom",
                perPageDropdown: [10, 25, 50, 100],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: "next",
                prevLabel: "prev",
                rowsPerPageLabel: "Rows per page",
                ofLabel: "of",
                pageLabel: "page", // for 'pages' mode
                allLabel: "All"
              },
              "sort-options": {
                enabled: true,
                initialSortBy: { field: "score", type: "desc" }
              }
            },
            scopedSlots: _vm._u([
              {
                key: "table-row",
                fn: function(props) {
                  return [
                    props.column.field == "actions"
                      ? [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-link user-link",
                              attrs: { "data-id": props.row.id },
                              on: {
                                click: function($event) {
                                  return _vm.goToAdminPage($event)
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fa fa-edit" }),
                              _vm._v(" Manage")
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-link delete",
                              attrs: { "data-id": props.row.id },
                              on: {
                                click: function($event) {
                                  return _vm.beginDeleteAdmin({
                                    id: props.row.id
                                  })
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fa fa-trash" }),
                              _vm._v("Delete ")
                            ]
                          )
                        ]
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("p", [_vm._v("Scoring could also:")]),
      _c("ul", [
        _c("li", [
          _vm._v(
            "Reduce number of points for subsequent 3 star reads to deter children reading the same simple and short book over and over just for points."
          )
        ]),
        _c("li", [
          _vm._v(
            'Add multipliers for "special events"; 2x on Easter Egg Hunt books, 1.5x on books higher than your suggested lexile reading level, 5x on Enid Blyton (and therefore paying for)'
          )
        ]),
        _c("li", [
          _vm._v(
            "3 stars equals >90% accuracy, maybe a 1.25x multipler if you get >98% (all words turned green)"
          )
        ]),
        _c("li", [
          _vm._v("1.1x multipler if you read the entire book in 1 sitting")
        ]),
        _c("li", [
          _vm._v(
            'Modify reading time points to the page count; deincentivise purposely going slow, could even make it a negative if they try to "play the system"'
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }