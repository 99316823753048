var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "flipcard",
        _vm.flipped ? "flipcard--flipped" : "",
        _vm.flipping ? "flipcard--flipping" : ""
      ]
    },
    [
      _c("span", {
        staticClass: "flipcard__flipbtn",
        on: {
          click: function($event) {
            _vm.flipped = !_vm.flipped
          }
        }
      }),
      _c(
        "div",
        {
          staticClass: "flipcard__inner",
          on: {
            transitionstart: function($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              _vm.flipping = true
            },
            transitionend: function($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              _vm.flipping = false
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "flipcard__front" },
            [_vm._t("front", [_vm._v("This is the front of the card")])],
            2
          ),
          _c(
            "div",
            { staticClass: "flipcard__back" },
            [_vm._t("back", [_vm._v("This is the back of the card")])],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }