var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.modal.isVisible("code")
        ? _c(
            "modal",
            {
              attrs: {
                title: "Warning: Ambiguous Promocode",
                "show-footer": false
              },
              on: { close: _vm.cancel }
            },
            [
              _c("p", { staticClass: "text-danger lead" }, [
                _vm._v(
                  "The code you supplied does not contain the current year. "
                )
              ]),
              _c("p", [
                _vm._v(
                  "Once a code has been created, you can never use that code again for another purpose. It is recommended to add a unique component to the code to avoid this, such as the current year."
                )
              ]),
              _c("p", [
                _vm._v(
                  "Check the box below to continue anyway, otherwise press cancel to amend it."
                )
              ]),
              _c("form", { staticClass: "ui form form-horizontal" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group checkbox",
                    class: { "has-error": _vm.confirmCodeCheckHasFailed }
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.confirmCodeIsChecked,
                          expression: "confirmCodeIsChecked"
                        }
                      ],
                      attrs: { id: "confirm", type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.confirmCodeIsChecked)
                          ? _vm._i(_vm.confirmCodeIsChecked, null) > -1
                          : _vm.confirmCodeIsChecked
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.confirmCodeIsChecked,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.confirmCodeIsChecked = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.confirmCodeIsChecked = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.confirmCodeIsChecked = $$c
                          }
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "confirm" } }, [
                      _vm._v(
                        "I understand the consequences of not using a sufficiently unique code."
                      )
                    ])
                  ]
                )
              ]),
              _c(
                "div",
                { staticClass: "modal-footer" },
                [
                  _vm._t("footer"),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning",
                      attrs: { type: "button", "data-dismiss": "modal" },
                      on: { click: _vm.cancel }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button", "data-dismiss": "modal" },
                      on: { click: _vm.confirm }
                    },
                    [_vm._v("Confirm")]
                  )
                ],
                2
              )
            ]
          )
        : _vm._e(),
      _c(
        "form",
        {
          staticClass: "form-horizontal",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.validateForm($event)
            }
          }
        },
        [
          _c("fieldset", [
            _c("legend", [_vm._v("General")]),
            _c(
              "div",
              {
                staticClass: "form-group",
                class: { "has-error": _vm.errors.first("target_type") }
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 control-label",
                    attrs: { for: "target_type" }
                  },
                  [_vm._v("Target Type")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.mutableValue.target_type,
                          expression: "mutableValue.target_type"
                        }
                      ],
                      ref: "target_type",
                      staticClass: "form-control",
                      attrs: { name: "target_type", id: "target_type" },
                      on: {
                        change: [
                          function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.mutableValue,
                              "target_type",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          _vm.onTargetTypeChange
                        ]
                      }
                    },
                    [
                      _c("option", { attrs: { value: "subscription" } }, [
                        _vm._v("Subscription")
                      ]),
                      _c("option", { attrs: { value: "purchase" } }, [
                        _vm._v("Purchase")
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("target_type"),
                          expression: "errors.has('target_type')"
                        }
                      ],
                      staticClass: "help-block is-danger"
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.errors.first("target_type")) + " "
                      )
                    ]
                  )
                ])
              ]
            ),
            _c(
              "div",
              {
                staticClass: "form-group form-group-lg",
                class: { "has-error": _vm.errors.first("code") }
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 control-label",
                    attrs: { for: "code" }
                  },
                  [_vm._v("Promotion Code")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.mutableValue.code,
                        expression: "mutableValue.code"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|alpha_dash",
                        expression: "'required|alpha_dash'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "code",
                      name: "code",
                      placeholder: "Example: Easter2020",
                      type: "text",
                      autocomplete: "off"
                    },
                    domProps: { value: _vm.mutableValue.code },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.mutableValue, "code", $event.target.value)
                      }
                    }
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("code"),
                          expression: "errors.has('code')"
                        }
                      ],
                      staticClass: "help-block is-danger"
                    },
                    [_vm._v(" " + _vm._s(_vm.errors.first("code")) + " ")]
                  )
                ])
              ]
            ),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "col-sm-2 control-label",
                  attrs: { for: "type" }
                },
                [_vm._v("Type")]
              ),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "select",
                  {
                    directives: [
                      { name: "validate", rawName: "v-validate" },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.mutableValue.type,
                        expression: "mutableValue.type"
                      }
                    ],
                    ref: "type",
                    staticClass: "form-control",
                    attrs: { name: "type", id: "type" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.mutableValue,
                          "type",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _vm.displayOnlyFor("subscription")
                      ? _c("option", { attrs: { value: "free_trial" } }, [
                          _vm._v("Free Trial")
                        ])
                      : _vm._e(),
                    _c("option", { attrs: { value: "coupon" } }, [
                      _vm._v("Coupon")
                    ])
                  ]
                )
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.mutableValue.type == "free_trial",
                      expression: "mutableValue.type == 'free_trial'"
                    }
                  ],
                  staticClass: "col-sm-8"
                },
                [_vm._m(0)]
              )
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.mutableValue.type != "free_trial",
                    expression: "mutableValue.type != 'free_trial'"
                  }
                ],
                staticClass: "form-group"
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 control-label",
                    attrs: { for: "discount-type" }
                  },
                  [_vm._v("Discount type")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "btn-group btn-group-toggle col-sm-2",
                    attrs: { "data-toggle": "buttons" },
                    on: {
                      click: function($event) {
                        return _vm.handleChangeDiscountType($event)
                      }
                    }
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "btn btn-secondary",
                        class: { active: _vm.discountIsPercentage }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.mutableValue.dicount_type,
                              expression: "mutableValue.dicount_type"
                            }
                          ],
                          attrs: {
                            type: "radio",
                            name: "discount-type",
                            id: "percentage",
                            value: "percent_off"
                          },
                          domProps: {
                            checked: _vm._q(
                              _vm.mutableValue.dicount_type,
                              "percent_off"
                            )
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(
                                _vm.mutableValue,
                                "dicount_type",
                                "percent_off"
                              )
                            }
                          }
                        }),
                        _vm._v("Percentage ")
                      ]
                    ),
                    _c(
                      "label",
                      {
                        staticClass: "btn btn-secondary",
                        class: { active: !_vm.discountIsPercentage }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.mutableValue.discount_type,
                              expression: "mutableValue.discount_type"
                            }
                          ],
                          attrs: {
                            type: "radio",
                            name: "discount-type",
                            id: "amount",
                            value: "amount_off"
                          },
                          domProps: {
                            checked: _vm._q(
                              _vm.mutableValue.discount_type,
                              "amount_off"
                            )
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(
                                _vm.mutableValue,
                                "discount_type",
                                "amount_off"
                              )
                            }
                          }
                        }),
                        _vm._v("Amount ")
                      ]
                    )
                  ]
                )
              ]
            ),
            _vm.mutableValue.type !== "free_trial" &&
            _vm.discountIsPercentage === true
              ? _c(
                  "div",
                  {
                    staticClass: "form-group",
                    class: { "has-error": _vm.errors.first("discount_value") }
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-2 control-label",
                        attrs: { for: "discount_value" }
                      },
                      [_vm._v("Discount percentage")]
                    ),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c("div", { staticClass: "input-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.mutableValue.discount_value,
                              expression: "mutableValue.discount_value"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value:
                                "required_if:type,coupon|decimal:2|min_value:0|max_value:100",
                              expression:
                                "'required_if:type,coupon|decimal:2|min_value:0|max_value:100'"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "discount_value",
                            name: "discount_value",
                            placeholder: "00",
                            disabled: _vm.mutableValue.type === "free_trial",
                            type: "number"
                          },
                          domProps: { value: _vm.mutableValue.discount_value },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.mutableValue,
                                "discount_value",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("div", { staticClass: "input-group-addon" }, [
                          _vm._v("%")
                        ])
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("discount_value"),
                            expression: "errors.has('discount_value')"
                          }
                        ],
                        staticClass: "col-sm-8 help-block is-danger"
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.errors.first("discount_value")) + " "
                        )
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _vm.mutableValue.type !== "free_trial" &&
            _vm.discountIsPercentage === false
              ? _c(
                  "div",
                  {
                    staticClass: "form-group",
                    class: { "has-error": _vm.errors.first("discount_value") }
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-2 control-label",
                        attrs: { for: "discount_value" }
                      },
                      [_vm._v("Discount amount")]
                    ),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c("div", { staticClass: "input-group" }, [
                        _c("div", { staticClass: "input-group-addon" }, [
                          _vm._v("£")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.mutableValue.discount_value,
                              expression: "mutableValue.discount_value"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|decimal:2",
                              expression: "'required|decimal:2'"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "discount_value",
                            name: "discount_value",
                            placeholder: "00",
                            disabled: _vm.mutableValue.type === "free_trial",
                            type: "number",
                            step: ".01"
                          },
                          domProps: { value: _vm.mutableValue.discount_value },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.mutableValue,
                                "discount_value",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("discount_value"),
                            expression: "errors.has('discount_value')"
                          }
                        ],
                        staticClass: "col-sm-8 help-block is-danger"
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.errors.first("discount_value")) + " "
                        )
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _vm.displayOnlyFor("subscription")
              ? _c(
                  "div",
                  {
                    staticClass: "form-group",
                    class: { "has-error": _vm.errors.first("duration_length") }
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-2 control-label",
                        attrs: { for: "duration_length" }
                      },
                      [_vm._v("Duration")]
                    ),
                    _c("div", { staticClass: "col-sm-1" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.mutableValue.duration_length,
                            expression: "mutableValue.duration_length"
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|numeric",
                            expression: "'required|numeric'"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "duration_length",
                          name: "duration_length",
                          placeholder: "0",
                          type: "number"
                        },
                        domProps: { value: _vm.mutableValue.duration_length },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.mutableValue,
                              "duration_length",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.mutableValue.duration_unit,
                              expression: "mutableValue.duration_unit"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { name: "duration_unit", id: "duration_unit" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.mutableValue,
                                "duration_unit",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "months" } }, [
                            _vm._v("Months")
                          ]),
                          _c("option", { attrs: { value: "years" } }, [
                            _vm._v("Years")
                          ])
                        ]
                      )
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.mutableValue.type === "free_trial" &&
                              _vm.showFreeTrialDurationError,
                            expression:
                              "mutableValue.type === 'free_trial' && showFreeTrialDurationError"
                          }
                        ],
                        staticClass: "col-sm-7"
                      },
                      [_vm._m(1)]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("duration_length"),
                            expression: "errors.has('duration_length')"
                          }
                        ],
                        staticClass: "col-sm-7 help-block is-danger"
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.errors.first("duration_length")) +
                            " "
                        )
                      ]
                    )
                  ]
                )
              : _vm._e()
          ]),
          _c("fieldset", [
            _c("legend", [_vm._v("Restrictions")]),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "col-sm-2 control-label",
                  attrs: { for: "valid_from" }
                },
                [_vm._v("Valid From")]
              ),
              _c(
                "div",
                { staticClass: "col-sm-2" },
                [
                  _c("DatePicker", {
                    attrs: {
                      popover: {
                        placement: "bottom-start",
                        visibility: "click"
                      },
                      mode: "date",
                      "is-range": false
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var inputValue = ref.inputValue
                          var inputEvents = ref.inputEvents
                          return [
                            _c(
                              "input",
                              _vm._g(
                                {
                                  staticClass: "form-control",
                                  domProps: { value: inputValue }
                                },
                                inputEvents
                              )
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.mutableValue.valid_from,
                      callback: function($$v) {
                        _vm.$set(_vm.mutableValue, "valid_from", $$v)
                      },
                      expression: "mutableValue.valid_from"
                    }
                  })
                ],
                1
              ),
              _c(
                "label",
                {
                  staticClass: "col-sm-2 form-control-static text-center",
                  attrs: { for: "valid_until" }
                },
                [
                  _vm._v("Until "),
                  _c("i", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value:
                          "This field specifies the last day the coupon can be entered, not the date when the applied promotion ends.",
                        expression:
                          "'This field specifies the last day the coupon can be entered, not the date when the applied promotion ends.'"
                      }
                    ],
                    staticClass: "fa fa-question-circle"
                  })
                ]
              ),
              _c(
                "div",
                { staticClass: "col-sm-2" },
                [
                  _c("DatePicker", {
                    attrs: {
                      popover: {
                        placement: "bottom-start",
                        visibility: "click"
                      },
                      mode: "date",
                      "is-range": false
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var inputValue = ref.inputValue
                          var inputEvents = ref.inputEvents
                          return [
                            _c(
                              "input",
                              _vm._g(
                                {
                                  staticClass: "form-control",
                                  domProps: { value: inputValue }
                                },
                                inputEvents
                              )
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.mutableValue.valid_until,
                      callback: function($$v) {
                        _vm.$set(_vm.mutableValue, "valid_until", $$v)
                      },
                      expression: "mutableValue.valid_until"
                    }
                  }),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showValidUntilError,
                          expression: "showValidUntilError"
                        }
                      ],
                      staticClass: "text-sm form-control-static text-danger"
                    },
                    [
                      _vm._v(
                        " The Valid Until date cannot be set before Valid From. "
                      )
                    ]
                  )
                ],
                1
              ),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.mutableValue.valid_from !== null,
                        expression: "mutableValue.valid_from !== null"
                      }
                    ],
                    staticClass: "btn btn-link",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.validUntilPlusOneYear()
                      }
                    }
                  },
                  [_vm._v("+1 year")]
                )
              ])
            ]),
            _vm.displayOnlyFor("subscription")
              ? _c(
                  "div",
                  {
                    staticClass: "form-group",
                    class: { "has-error": _vm.errors.first("valid_options") }
                  },
                  [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c("div", { staticClass: "input-group" }, [
                        _c("div", { staticClass: "input-group-addon" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.validPricingOptionsRestricted,
                                expression: "validPricingOptionsRestricted"
                              }
                            ],
                            attrs: {
                              type: "checkbox",
                              id: "restrict_pricing_options"
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.validPricingOptionsRestricted
                              )
                                ? _vm._i(
                                    _vm.validPricingOptionsRestricted,
                                    null
                                  ) > -1
                                : _vm.validPricingOptionsRestricted
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.validPricingOptionsRestricted,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.validPricingOptionsRestricted = $$a.concat(
                                        [$$v]
                                      ))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.validPricingOptionsRestricted = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.validPricingOptionsRestricted = $$c
                                }
                              }
                            }
                          })
                        ]),
                        _c(
                          "label",
                          {
                            staticClass: "form-control",
                            attrs: { for: "valid_options" }
                          },
                          [
                            _vm._v(
                              "Check to specify which packages this coupon can be used with"
                            )
                          ]
                        )
                      ]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.mutableValue.valid_options,
                              expression: "mutableValue.valid_options"
                            }
                          ],
                          staticClass: "form-control",
                          staticStyle: { height: "100px" },
                          attrs: {
                            name: "valid_options",
                            id: "valid_options",
                            multiple: "",
                            disabled: !_vm.validPricingOptionsRestricted
                          },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.mutableValue,
                                "valid_options",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        _vm._l(_vm.pricingOptions, function(opt, key) {
                          return _c(
                            "option",
                            { key: key, domProps: { value: key } },
                            [_vm._v(_vm._s(opt))]
                          )
                        }),
                        0
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm.displayOnlyFor("subscription")
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        staticClass: "form-group",
                        class: {
                          "has-error": _vm.errors.first("min_user_created_at")
                        }
                      },
                      [
                        _c("div", { staticClass: "col-xs-12" }, [
                          _c(
                            "label",
                            { attrs: { for: "restrict_min_user_created_at" } },
                            [_vm._v("Earliest User Registration Date")]
                          ),
                          _c(
                            "div",
                            { staticClass: "input-group" },
                            [
                              _c("div", { staticClass: "input-group-addon" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.minUserCreatedAtRestricted,
                                      expression: "minUserCreatedAtRestricted"
                                    }
                                  ],
                                  attrs: {
                                    id: "restrict_min_user_created_at",
                                    type: "checkbox"
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.minUserCreatedAtRestricted
                                    )
                                      ? _vm._i(
                                          _vm.minUserCreatedAtRestricted,
                                          null
                                        ) > -1
                                      : _vm.minUserCreatedAtRestricted
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.minUserCreatedAtRestricted,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.minUserCreatedAtRestricted = $$a.concat(
                                              [$$v]
                                            ))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.minUserCreatedAtRestricted = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.minUserCreatedAtRestricted = $$c
                                      }
                                    }
                                  }
                                })
                              ]),
                              _c("DatePicker", {
                                attrs: {
                                  popover: {
                                    placement: "bottom-start",
                                    visibility: "click"
                                  },
                                  mode: "date",
                                  "is-range": false
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var inputValue = ref.inputValue
                                        var inputEvents = ref.inputEvents
                                        return [
                                          _c(
                                            "input",
                                            _vm._g(
                                              {
                                                staticClass: "form-control",
                                                attrs: {
                                                  placeholder: _vm.minUserCreatedAtRestricted
                                                    ? "Select minimum registration date"
                                                    : "Enable to restrict user registration date"
                                                },
                                                domProps: { value: inputValue }
                                              },
                                              inputEvents
                                            )
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1053847715
                                ),
                                model: {
                                  value: _vm.mutableValue.min_user_created_at,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.mutableValue,
                                      "min_user_created_at",
                                      $$v
                                    )
                                  },
                                  expression: "mutableValue.min_user_created_at"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("min_user_created_at"),
                                  expression:
                                    "errors.has('min_user_created_at')"
                                }
                              ],
                              staticClass: "help-block is-danger"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.errors.first("min_user_created_at")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        staticClass: "form-group",
                        class: {
                          "has-error": _vm.errors.first(
                            "min_subscription_created_at"
                          )
                        }
                      },
                      [
                        _c("div", { staticClass: "col-xs-12" }, [
                          _c(
                            "label",
                            {
                              attrs: {
                                for: "restrict_min_subscription_created_at"
                              }
                            },
                            [_vm._v("Earliest Subscription Starting Date")]
                          ),
                          _c(
                            "div",
                            { staticClass: "input-group" },
                            [
                              _c("div", { staticClass: "input-group-addon" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.minSubscriptionCreatedAtRestricted,
                                      expression:
                                        "minSubscriptionCreatedAtRestricted"
                                    }
                                  ],
                                  attrs: {
                                    id: "restrict_min_subscription_created_at",
                                    type: "checkbox"
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.minSubscriptionCreatedAtRestricted
                                    )
                                      ? _vm._i(
                                          _vm.minSubscriptionCreatedAtRestricted,
                                          null
                                        ) > -1
                                      : _vm.minSubscriptionCreatedAtRestricted
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a =
                                          _vm.minSubscriptionCreatedAtRestricted,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.minSubscriptionCreatedAtRestricted = $$a.concat(
                                              [$$v]
                                            ))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.minSubscriptionCreatedAtRestricted = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.minSubscriptionCreatedAtRestricted = $$c
                                      }
                                    }
                                  }
                                })
                              ]),
                              _c("DatePicker", {
                                attrs: {
                                  popover: {
                                    placement: "bottom-start",
                                    visibility: "click"
                                  },
                                  mode: "date",
                                  "is-range": false
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var inputValue = ref.inputValue
                                        var inputEvents = ref.inputEvents
                                        return [
                                          _c(
                                            "input",
                                            _vm._g(
                                              {
                                                staticClass: "form-control",
                                                attrs: {
                                                  placeholder: _vm.minSubscriptionCreatedAtRestricted
                                                    ? "Select minimum start date"
                                                    : "Enable to restrict subscription start date"
                                                },
                                                domProps: { value: inputValue }
                                              },
                                              inputEvents
                                            )
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  308684291
                                ),
                                model: {
                                  value:
                                    _vm.mutableValue
                                      .min_subscription_created_at,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.mutableValue,
                                      "min_subscription_created_at",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "mutableValue.min_subscription_created_at"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has(
                                    "min_subscription_created_at"
                                  ),
                                  expression:
                                    "errors.has('min_subscription_created_at')"
                                }
                              ],
                              staticClass: "help-block is-danger"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.errors.first(
                                      "min_subscription_created_at"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  ])
                ])
              : _vm._e(),
            _vm.displayOnlyFor("subscription")
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("div", { staticClass: "col-sm-12" }, [
                        _c("div", { staticClass: "input-group" }, [
                          _c("div", { staticClass: "input-group-addon" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.mutableValue
                                      .exclude_current_subscribers,
                                  expression:
                                    "mutableValue.exclude_current_subscribers"
                                }
                              ],
                              attrs: {
                                type: "checkbox",
                                name: "exclude_current_subscribers",
                                id: "exclude_current_subscribers",
                                value: "true"
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.mutableValue.exclude_current_subscribers
                                )
                                  ? _vm._i(
                                      _vm.mutableValue
                                        .exclude_current_subscribers,
                                      "true"
                                    ) > -1
                                  : _vm.mutableValue.exclude_current_subscribers
                              },
                              on: {
                                change: function($event) {
                                  var $$a =
                                      _vm.mutableValue
                                        .exclude_current_subscribers,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "true",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.mutableValue,
                                          "exclude_current_subscribers",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.mutableValue,
                                          "exclude_current_subscribers",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.mutableValue,
                                      "exclude_current_subscribers",
                                      $$c
                                    )
                                  }
                                }
                              }
                            })
                          ]),
                          _c(
                            "label",
                            {
                              staticClass: "form-control form-control-static",
                              attrs: { for: "exclude_current_subscribers" }
                            },
                            [_vm._v("Exclude existing subscribers")]
                          )
                        ])
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("div", { staticClass: "col-xs-12" }, [
                        _c("div", { staticClass: "input-group" }, [
                          _c("div", { staticClass: "input-group-addon" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.mutableValue
                                      .only_first_time_subscribers,
                                  expression:
                                    "mutableValue.only_first_time_subscribers"
                                }
                              ],
                              attrs: {
                                type: "checkbox",
                                name: "only_first_time_subscribers",
                                id: "only_first_time_subscribers",
                                value: "true"
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.mutableValue.only_first_time_subscribers
                                )
                                  ? _vm._i(
                                      _vm.mutableValue
                                        .only_first_time_subscribers,
                                      "true"
                                    ) > -1
                                  : _vm.mutableValue.only_first_time_subscribers
                              },
                              on: {
                                change: function($event) {
                                  var $$a =
                                      _vm.mutableValue
                                        .only_first_time_subscribers,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "true",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.mutableValue,
                                          "only_first_time_subscribers",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.mutableValue,
                                          "only_first_time_subscribers",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.mutableValue,
                                      "only_first_time_subscribers",
                                      $$c
                                    )
                                  }
                                }
                              }
                            })
                          ]),
                          _c(
                            "label",
                            {
                              staticClass: "form-control form-control-static",
                              attrs: { for: "only_first_time_subscribers" }
                            },
                            [_vm._v("Only allow first time subscribers")]
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              : _vm._e()
          ]),
          _c("fieldset", [
            _c("legend", [_vm._v("Usage limits")]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group",
                    class: { "has-error": _vm.errors.first("max_uses_global") }
                  },
                  [
                    _c("div", { staticClass: "col-xs-12" }, [
                      _c(
                        "label",
                        { attrs: { for: "restrict_max_uses_global" } },
                        [_vm._v("Global Redemption Limit")]
                      ),
                      _c("div", { staticClass: "input-group" }, [
                        _c("div", { staticClass: "input-group-addon" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.maxUsesGlobalRestricted,
                                expression: "maxUsesGlobalRestricted"
                              }
                            ],
                            attrs: {
                              id: "restrict_max_uses_global",
                              type: "checkbox"
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.maxUsesGlobalRestricted
                              )
                                ? _vm._i(_vm.maxUsesGlobalRestricted, null) > -1
                                : _vm.maxUsesGlobalRestricted
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.maxUsesGlobalRestricted,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.maxUsesGlobalRestricted = $$a.concat(
                                        [$$v]
                                      ))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.maxUsesGlobalRestricted = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.maxUsesGlobalRestricted = $$c
                                }
                              }
                            }
                          })
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.mutableValue.max_uses_global,
                              expression: "mutableValue.max_uses_global"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|numeric",
                              expression: "'required|numeric'"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "max_uses_global",
                            name: "max_uses_global",
                            placeholder: _vm.maxUsesGlobalRestricted
                              ? "Enter number of times this coupon can be used"
                              : "Enable to limit maximum redemptions",
                            type: "number",
                            disabled: !_vm.maxUsesGlobalRestricted
                          },
                          domProps: { value: _vm.mutableValue.max_uses_global },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.mutableValue,
                                "max_uses_global",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("max_uses_global"),
                              expression: "errors.has('max_uses_global')"
                            }
                          ],
                          staticClass: "help-block is-danger"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.errors.first("max_uses_global")) +
                              " "
                          )
                        ]
                      )
                    ])
                  ]
                )
              ]),
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group",
                    class: {
                      "has-error": _vm.errors.first("max_uses_per_user")
                    }
                  },
                  [
                    _c("div", { staticClass: "col-xs-12" }, [
                      _c(
                        "label",
                        { attrs: { for: "restrict_max_uses_per_user" } },
                        [_vm._v("Per User Redemption Limit")]
                      ),
                      _c("div", { staticClass: "input-group" }, [
                        _c("div", { staticClass: "input-group-addon" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.maxUsesPerUserRestricted,
                                expression: "maxUsesPerUserRestricted"
                              }
                            ],
                            attrs: {
                              id: "restrict_max_uses_per_user",
                              type: "checkbox"
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.maxUsesPerUserRestricted
                              )
                                ? _vm._i(_vm.maxUsesPerUserRestricted, null) >
                                  -1
                                : _vm.maxUsesPerUserRestricted
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.maxUsesPerUserRestricted,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.maxUsesPerUserRestricted = $$a.concat(
                                        [$$v]
                                      ))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.maxUsesPerUserRestricted = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.maxUsesPerUserRestricted = $$c
                                }
                              }
                            }
                          })
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.mutableValue.max_uses_per_user,
                              expression: "mutableValue.max_uses_per_user"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|numeric",
                              expression: "'required|numeric'"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "max_uses_per_user",
                            name: "max_uses_per_user",
                            placeholder: _vm.maxUsesPerUserRestricted
                              ? "Enter number of times a user can repeat this code"
                              : "Enable to limit user repeat redemptions",
                            type: "number",
                            disabled: !_vm.maxUsesPerUserRestricted
                          },
                          domProps: {
                            value: _vm.mutableValue.max_uses_per_user
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.mutableValue,
                                "max_uses_per_user",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("max_uses_per_user"),
                              expression: "errors.has('max_uses_per_user')"
                            }
                          ],
                          staticClass: "help-block is-danger"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.errors.first("max_uses_per_user")) +
                              " "
                          )
                        ]
                      )
                    ])
                  ]
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "box-footer" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "div",
                { staticClass: "col-xs-12" },
                [
                  _c(
                    "loading-button",
                    {
                      staticClass: "btn-block btn-lg",
                      attrs: { disabled: _vm.errors.any() }
                    },
                    [_vm._v("Submit")]
                  )
                ],
                1
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-sm form-control-static text-danger" }, [
      _c("em", [
        _vm._v(
          "Free trials are currently only supported for Stripe subscriptions. The user will have to provide payment details during signup."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-sm form-control-static text-danger" }, [
      _c("em", [_vm._v("Free trials cannot last longer than 1 year.")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }