var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "users-report-page" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-4" }, [
        _c("div", { staticClass: "info-box" }, [
          _vm._m(0),
          _c("div", { staticClass: "info-box-content" }, [
            _c("span", { staticClass: "info-box-text" }, [
              _vm._v("Total User Registrations")
            ]),
            _c("span", { staticClass: "info-box-number" }, [
              _vm._v(_vm._s(_vm.charts.userRegistrations.total()))
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "col-md-8" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "align-items": "flex-end",
              gap: "1em"
            }
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  gap: "0.5em"
                }
              },
              [
                _c("label", [_vm._v("Filter by date:")]),
                _c("DatePickerWithDropdown", {
                  attrs: {
                    reference: "time_period",
                    value: _vm.dateRangeModel,
                    initialDateRange: _vm.dateRangeModel
                  },
                  on: {
                    "date-range-change": function($event) {
                      return _vm.handleChangeDateRange($event, "fetchCharts")
                    }
                  }
                })
              ],
              1
            ),
            _c("user-attribute-filters", {
              attrs: { "show-user-type": false },
              on: { select: _vm.onFilter }
            })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12 charts-container" },
        [
          _c("chart", {
            staticClass: "chart-full",
            attrs: { chart: _vm.charts.userRegistrations }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-12 charts-container" },
        [
          _c("chart", {
            staticClass: "mr-5",
            attrs: { chart: _vm.charts.readersPerUser }
          }),
          _c("chart", { attrs: { chart: _vm.charts.readerProfileAges } })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "info-box-icon bg-aqua" }, [
      _c("i", { staticClass: "fa fa-users" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }