import LoginView from '@/views/Login';
import LogoutView from '@/views/Logout';
import { clearLocalStorage } from './guards';

export default ([
  {
    path: '/logout',
    component: LogoutView,
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    component: LoginView,
    meta: { guestsOnly: true },
    beforeEnter: clearLocalStorage,
  },
]);
