<template>
  <ValidationObserver :key=0 ref="contact">
    <div class="contact-form">
      <!--Contact Name-->
      <ValidationProvider name="contact_name" rules="required" v-slot="{errors}" slim>
        <div class="form-group" :class="{'has-error': errors.length > 0}">
          <label :for="fieldPrefix + 'contact_name'" class="col-sm-2 control-label">Contact Name</label>
          <div class="col-sm-10 input-group">
            <span class="input-group-addon"><i class="fa fa-user"></i></span>
            <input type="text" class="form-control" :name="fieldPrefix + 'contact_name'" :id="fieldPrefix + 'contact_name'"
              autocomplete="off" v-model="mutableValue.contact_name" data-vv-as="Contact Name">
          </div>
          <div class="col-sm-10 col-sm-offset-2 help-block is-danger" v-show="errors.length > 0">
            {{ errors[0] }}
          </div>
        </div>
      </ValidationProvider>
      <!--Contact Job Role-->
      <ValidationProvider name="job_role" rules="required" v-slot="{errors}" slim>
        <div class="form-group" :class="{'has-error': errors.length > 0}">
          <label :for="fieldPrefix + 'job_role'" class="col-sm-2 control-label">Job Role</label>
          <div class="col-sm-10 input-group">
            <span class="input-group-addon"><i class="fa fa-user"></i></span>
            <input type="text" class="form-control" :name="fieldPrefix + 'job_role'" :id="fieldPrefix + 'job_role'"
              autocomplete="off" v-model="mutableValue.job_role" data-vv-as="Job Role" v-validate="'required'">
          </div>
          <div class="col-sm-10 col-sm-offset-2 help-block is-danger" v-show="errors.length > 0">
            {{ errors[0] }}
          </div>
        </div>
      </ValidationProvider>
      <!--Address-->
      <ValidationProvider name="address_line_1" rules="required" v-slot="{errors}" slim>
        <div class="form-group" :class="{'has-error': errors.length > 0}">
          <label :for="fieldPrefix + 'address_line_1'" class="col-sm-2 control-label">Address Line 1</label>
          <div class="col-sm-10 input-group">
            <span class="input-group-addon"><i class="fa fa-home"></i></span>
            <input type="text" class="form-control" :name="fieldPrefix + 'address_line_1'"
              :id="fieldPrefix + 'address_line_1'" autocomplete="off" v-model="mutableValue.address_line_1"
              data-vv-as="Address Line 1">
          </div>
          <div class="col-sm-10 col-sm-offset-2 help-block is-danger" v-show="errors.length > 0">
            {{ errors[0] }}
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider name="address_line_2" v-slot="{errors}" slim>
        <div class="form-group" :class="{'has-error': errors.length > 0}">
          <label :for="fieldPrefix + 'address_line_2'" class="col-sm-2 control-label">Address Line 2</label>
          <div class="col-sm-10 input-group">
            <span class="input-group-addon"><i class="fa fa-home"></i></span>
            <input type="text" class="form-control" :name="fieldPrefix + 'address_line_2'"
              :id="fieldPrefix + 'address_line_2'" autocomplete="off" v-model="mutableValue.address_line_2"
              data-vv-as="Address Line 2">
          </div>
          <div class="col-sm-10 col-sm-offset-2 help-block is-danger" v-show="errors.length > 0">
            {{ errors[0] }}
          </div>
        </div>
      </ValidationProvider>
      <!--Postcode -->
      <ValidationProvider name="postcode" rules="required" v-slot="{errors}" slim>
        <div class="form-group" :class="{'has-error': errors.length > 0}">
          <label :for="fieldPrefix + 'postcode'" class="col-sm-2 control-label">Postcode</label>
          <div class="col-sm-10 input-group">
            <span class="input-group-addon"><i class="fa fa-home"></i></span>
            <input type="text" class="form-control" :name="fieldPrefix + 'postcode'" :id="fieldPrefix + 'postcode'"
              autocomplete="off" v-model="mutableValue.postcode" data-vv-as="Postcode" v-validate="{ required: true }">
          </div>
          <div class="col-sm-10 col-sm-offset-2 help-block is-danger" v-show="errors.length > 0">
            {{ errors[0] }}
          </div>
        </div>
      </ValidationProvider>
      <!--Country -->
      <ValidationProvider name="countryCode" rules="required" v-slot="{errors}" slim>
        <div class="form-group" :class="{'has-error': errors.length > 0}">
          <label :for="fieldPrefix + 'countryCode'" class="col-sm-2 control-label">Country</label>
          <div class="col-sm-10 input-group">
            <select class="form-control" :name="fieldPrefix + 'countryCode'" :id="fieldPrefix + 'countryCode'"
              autocomplete="off" v-model="mutableValue.country_code" data-vv-as="Country">
              <option v-for="country in countries" :value="country['id']" :key="country['id']">{{ country['name'] }}</option>
            </select>
          </div>
          <div class="col-sm-10 col-sm-offset-2 help-block is-danger" v-show="errors.length > 0">
            {{ errors[0] }}
          </div>
        </div>
      </ValidationProvider>
      <!--Region -->
      <div class="form-group">
        <label :for="fieldPrefix + 'region'" class="col-sm-2 control-label">Region</label>
        <div class="col-sm-10 input-group">
          <select v-if="regions.length > 0" class="form-control" :name="fieldPrefix + 'region'"
            :id="fieldPrefix + 'region'" autocomplete="off" v-model="mutableValue.region"
            data-vv-as="Region">
            <optgroup :label="group['group_name'] != '' ? group['group_name'] : 'Choose a region'"
              v-for="(group, key) in regions" :key="key">
              <option v-for="(region, k) in group['values']" :value="region" :key="k">{{ region }}</option>
            </optgroup>
          </select>
          <input v-else type="text" class="form-control" :name="fieldPrefix + 'region'" :id="fieldPrefix + 'region'" @input="mutableValue.region = $event.target.value"
            autocomplete="off" :value="mutableValue.region" data-vv-as="region">
        </div>
      </div>
      <!--City -->
      <ValidationProvider name="city" v-slot="{errors}" slim>
        <div class="form-group" :class="{ 'has-error':  errors.length > 0 }">
          <label :for="fieldPrefix + 'city'" class="col-sm-2 control-label">City</label>
          <div class="col-sm-10 input-group">
            <span class="input-group-addon"><i class="fa fa-city"></i></span>
            <input type="text" class="form-control" :name="fieldPrefix + 'city'" :id="fieldPrefix + 'city'"
              autocomplete="off" v-model="mutableValue.city" data-vv-as="city">
          </div>
          <div class="col-sm-10 col-sm-offset-2 help-block is-danger" v-show="errors.length > 0">
            {{ errors[0] }}
          </div>
        </div>
      </ValidationProvider>
      <!--County -->
      <ValidationProvider name="county" v-slot="{errors}" slim>
        <div class="form-group" :class="{ 'has-error':  errors.length > 0 }">
          <label :for="fieldPrefix + 'county'" class="col-sm-2 control-label">County</label>
          <div class="col-sm-10 input-group">
            <span class="input-group-addon"><i class="fa fa-city"></i></span>
            <input type="text" class="form-control" :name="fieldPrefix + 'county'" :id="fieldPrefix + 'county'"
              autocomplete="off" v-model="mutableValue.county" data-vv-as="county">
          </div>
          <div class="col-sm-10 col-sm-offset-2 help-block is-danger" v-show="errors.length > 0">
            {{ errors[0] }}
          </div>
        </div>
      </ValidationProvider>
      <!--Phone -->
      <ValidationProvider name="phone" v-slot="{errors}" :rules="{ required: true, regex: /^[+0-9]+$/ }" slim>
        <div class="form-group" :class="{ 'has-error':  errors.length > 0 }">
          <label :for="fieldPrefix + 'phone'" class="col-sm-2 control-label">Phone</label>
          <div class="col-sm-10 input-group">
            <span class="input-group-addon"><i class="fa fa-phone"></i></span>
            <input type="text" class="form-control" :name="fieldPrefix + 'phone'" :id="fieldPrefix + 'phone'"
              autocomplete="off" v-model="mutableValue.telephone" data-vv-as="Phone">
          </div>
          <div class="col-sm-10 col-sm-offset-2 help-block is-danger" v-show="errors.length > 0">
            {{ errors[0] }}
          </div>
        </div>
      </ValidationProvider>
      <!--Email -->
      <ValidationProvider name="email" :rules="{ required: true, email: true }" v-slot="{errors}" slim>
        <div class="form-group" :class="{ 'has-error':  errors.length > 0 }">
          <label :for="fieldPrefix + 'email'" class="col-sm-2 control-label">Email</label>
          <div class="col-sm-10 input-group">
            <span class="input-group-addon"><i class="fa fa-envelope"></i></span>
            <input type="email" class="form-control" :name="fieldPrefix + 'email'" :id="fieldPrefix + 'email'"
              autocomplete="off" v-model="mutableValue.email" data-vv-as="Email">
          </div>
          <div class="col-sm-10 col-sm-offset-2 help-block is-danger" v-show="errors.length > 0">
            {{ errors[0] }}
          </div>
        </div>
      </ValidationProvider>
    </div>
  </ValidationObserver>
</template>

<script>
import _ from 'lodash';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import CountriesMixin from '@/mixins/CountriesMixin';

export default {
  name: 'contact-form',
  props: ['value', 'prefix', 'countries'],
  inject: ['$validator'],
  mixins: [CountriesMixin],
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      regions: [],
      hasInitialRegion: false,
      mutableValue: {
        address_line_1: null,
        address_line_2: null,
        country_code: null,
        region: null,
      },
    };
  },
  async mounted() {
    this.mutableValue = { ...this.value };
    this.hasInitialRegion = this.mutableValue.region !== null;
    this.updateRegions(this.mutableValue.country_code);
  },
  computed: {
    fieldPrefix() {
      if (_.isEmpty(this.prefix)) {
        return '';
      }

      return `${this.prefix}_`;
    },
  },
  watch: {
    'mutableValue.country_code': function (countryCode) {
      if (!this.hasInitialRegion) {
        this.mutableValue.region = null;
      }
      this.hasInitialRegion = false;
      this.updateRegions(countryCode);
    },
    mutableValue: {
      handler(newValue) {
        this.$emit('updated', newValue);
      },
      deep: true,
    },
  },
};
</script>
