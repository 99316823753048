<template>
  <wrapper>
    <section class="promocodes-page">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 box-tools">
          <div class="pull-right">
            <button class="btn btn-primary" @click="$router.push('/promocodes/new')"><i class="fa fa-plus"></i> Add a
              promocode
            </button>
          </div>
        </div>
      </div>
      <vue-good-table
        styleClass="vgt-table striped"
        theme="auris-admin"
        :columns="columns"
        :rows="rows"
        :totalRows="totalRecords"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: 10,
          position: 'bottom',
          perPageDropdown: [10, 25, 50, 100],
          dropdownAllowAll: false,
          setCurrentPage: 1,
          nextLabel: 'next',
          prevLabel: 'prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page', // for 'pages' mode
          allLabel: 'All',
        }"
        :search-options="{
          enabled: true,
          trigger: 'key-up',
          skipDiacritics: true,
          placeholder: 'Search this table',
        }"
      >
        <template slot="table-row" slot-scope="props">
          <template v-if="props.column.field == 'actions'">
            <button @click="viewPromocode($event)" class="btn btn-link user-link" :data-id="props.row.id"><i class="fa fa-eye"></i> View</button>
          </template>
        </template>
      </vue-good-table>
    </section>
  </wrapper>
</template>

<script>
import moment from 'moment';
import Wrapper from '@/components/Wrapper';
import api from '@/api';
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';
import { mapState } from 'vuex';

export default {
  name: 'promocodes',
  components: {
    Wrapper,
    VueGoodTable,
  },
  data() {
    return {
      rows: [],
      columns: [
        {
          label: 'Code',
          field: 'code',
        },
        {
          label: 'Target',
          field: 'target_type',
        },
        {
          label: 'Description',
          field: 'description',
        },
        {
          label: 'Redemptions',
          field: 'global_redemptions_count',
          type: 'number',
        },
        {
          label: 'Creation Date',
          field: 'created_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'do MMMM yyyy',
        },
        {
          label: 'Actions',
          field: 'actions',
          tdClass: 'details-control',
          sortable: false,
        },
      ],
      totalRecords: 0,
      previousSearchQuery: null,
    };
  },
  methods: {
    promocodeDescription(code) {
      if (code.type === 'coupon') {
        if (code.target_type === 'subscription') {
          // can only have percentage
          return `${Math.round(code.discount_value)}% off for ${code.duration_length} ${code.duration_unit}`;
        }
        // can be percentage or amount
        const validFrom = moment(code.valid_from);
        const validUntil = moment(code.valid_until);
        if (code.discount_type === 'percent_off') {
          return `${Math.round(code.discount_value)}% off from ${validFrom.format('Do MMM YYYY')}${validUntil.isValid() ? ` until ${validUntil.format('Do MMM YYYY')}` : ' with no expiry date'}`;
        }
        return `£${Math.round(code.discount_value)} off from ${validFrom.format('Do MMM YYYY')}${validUntil.isValid() ? ` until ${validUntil.format('Do MMM YYYY')}` : ' with no expiry date'}`;
      } if (code.type === 'free_trial') {
        return `${code.duration_length} ${code.duration_unit} free trial`;
      }
      return 'Not a clue';
    },
    promocodeRedemptions(code) {
      return `${code.global_redemptions_count}${(code.max_uses_global > 0 ? ` / ${code.max_uses_global}` : '')}`;
    },
    viewPromocode(e) {
      const { id } = e.target.dataset;
      this.$router.push(`/promocodes/${id}`);
    },
  },
  mounted() {
    this.$store.commit('SET_CURRENT_USER', null);
    this.$store.commit('SET_CURRENT_SCHOOL', null);
    this.$store.dispatch('app/setGlobalLoading', true);
    this.$nextTick(() => {
      api.get('admin/promocodes')
        .then((response) => {
          this.$store.dispatch('app/setGlobalLoading', false);
          const { promocodes } = response.data;
          // format the description and the date.
          this.rows = promocodes.map((p) => {
            p.description = this.promocodeDescription(p);
            return p;
          });
        })
        .catch((error) => {
          this.$store.dispatch('app/setGlobalLoading', false);
          this.$apiResponse.renderErrorMessage(error);
        });
    });
  },
  computed: {
    ...mapState({
      usersVuex: (state) => state.users.users,
      totalVuex: (state) => state.users.totalRecords,
      serverParams: (state) => state.users.serverParams,
      tableLoaded: (state) => state.users.tableLoaded,
    }),
  },
};
</script>
