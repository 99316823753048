import Contact from './Contact';

export default class {
  constructor(data = {}) {
    this.id = data.id || null;
    this.name = data.name || '';
    this.urn = data.urn || '';
    this.users = data.users || [];
    this.groups = data.groups || [];
    this.readers_count = data.readers_count || 0;
    this.subscription = data.subscription || null;
    this.terms_accepted_at = data.terms_accepted_at || null;
    this.can_enable_recordings = data.can_enable_recordings || null;
    this.recording_enabled = data.recording_enabled || false;
    this.teachers = data.users;
    this.billingContact = new Contact(data.contacts.find((contact) => contact.type === 'billing') || {});
    this.primaryContact = new Contact(data.contacts.find((contact) => contact.type === 'primary') || {});
    this.billingSameAsPrimary = this.primaryContact.isTheSame(this.billingContact);
    this.contacts = data.contacts || [];
    this.tags = [];
    if (data.tags) {
      this.tags = Object.keys(data.tags).map((tag) => ({
        code: tag,
        name: data.tags[tag],
      }));
    }
  }

  getFormObject() {
    return {
      id: this.id,
      name: this.name,
      urn: this.urn,
      billingSameAsPrimary: this.billingSameAsPrimary,
      primaryContact: this.primaryContact.getFormObject(),
      billingContact: this.billingContact.getFormObject(),
    };
  }
}
