<template>
  <footer class="main-footer">
    <strong>Copyright &copy; {{year}} <a href="http://auris.tech">Auris Tech</a>.</strong> All rights reserved.
  </footer>
</template>

<script>
export default {
  name: 'f-footer',
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
