var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-filters" }, [
    _vm.showReaderType
      ? _c("div", { staticClass: "user-filter user-filter__break" }, [
          _vm._m(0),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.readerType,
                  expression: "readerType"
                }
              ],
              attrs: { name: "readerType", id: "readerType" },
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.readerType = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.onSelect
                ]
              }
            },
            [
              _c("option", { attrs: { value: "all" } }, [_vm._v("All")]),
              _c("option", { attrs: { value: "standard" } }, [_vm._v("Home")]),
              _c("option", { attrs: { value: "enterprise" } }, [
                _vm._v("School")
              ])
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "label-container" }, [
      _c("label", [_vm._v("Reader Type:")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }