<template>
  <div class="users-report-page">
    <div class="row">
      <div class="col-md-4">
        <div class="info-box">
          <span class="info-box-icon bg-aqua">
            <i class="fa fa-users"></i>
          </span>
          <div class="info-box-content">
            <span class="info-box-text">Total User Registrations</span>
            <span class="info-box-number">{{ charts.userRegistrations.total() }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div style="display:flex; flex-direction:column; align-items: flex-end; gap: 1em;">
          <div style="display: flex; align-items:center; gap: 0.5em;">
            <label>Filter by date:</label>
            <DatePickerWithDropdown
              reference="time_period"
              @date-range-change="handleChangeDateRange($event, 'fetchCharts')"
              :value="dateRangeModel"
              :initialDateRange="dateRangeModel"
            />
          </div>
          <user-attribute-filters @select="onFilter" :show-user-type="false"></user-attribute-filters>
        </div>
      </div>
    </div>

  <div class="row">
    <div class="col-md-12 charts-container">
      <chart :chart="charts.userRegistrations" class="chart-full"></chart>
    </div>
    <div class="col-md-12 charts-container">
      <chart :chart="charts.readersPerUser" class="mr-5"></chart>
      <chart :chart="charts.readerProfileAges"></chart>
    </div>
  </div>

  </div>
</template>

<script>
import _ from 'lodash';
import ChartMixin from '@/mixins/ChartMixin';
import DateRangeMixin from '@/mixins/DateRangeMixin';
import { Chart, ChartCanvas, ChartConfig } from '@/plugins/Chart';
import DatePickerWithDropdown from '@/components/DatePickerWithDropdown';
import UserAttributeFilters from '@/components/UserAttributeFilters';

export default {
  name: 'users-report',
  mixins: [ChartMixin, DateRangeMixin],
  components: {
    chart: ChartCanvas,
    DatePickerWithDropdown,
    UserAttributeFilters,
  },
  mounted() {
    this.fetchCharts();
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      challenge: 'all',
      charts: {
        userRegistrations: new Chart(
          'user-registrations',
          new ChartConfig().withAnnotationOnYAxis('Avg').isTimeSeries().withTitle('User Registrations')
            .withCustomTool({
              icon: "<i class='fa fa-search'></i>",
              index: -1,
              title: 'View underlying data',
              class: 'view-data-icon',
              click: () => {
                this.$router.push({ path: '/users', query: { registered_date_range: true, exclude_auris: true } });
              },
            }),
        ),
        readersPerUser: new Chart(
          'readers-per-user',
          new ChartConfig().isType('donut').withTitle('Readers Per User'),
        ),
        readerProfileAges: new Chart(
          'reader-profile-ages',
          new ChartConfig().isType('donut').withTitle('Reader Profile Ages'),
        ),
      },
    };
  },
  created() {
    Chart.setCallback(this.getChart);
  },
  methods: {
    onFilter(e) {
      this.challenge = e.challenge;
      this.fetchCharts();
    },
    fetchCharts() {
      _.forEach(this.charts, (chart) => {
        chart.setStartDate(this.dateRange.startDate).setEndDate(this.dateRange.endDate);
        chart.setOutput(this.output);
        chart.setParams({ challenge: this.challenge, user_type: 'standard' });
      });
      this.fetchDataForCharts(this.charts);
    },
  },
};
</script>
