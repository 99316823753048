var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "date-input-container",
      class: { "is-disabled": !_vm.dateRangePickerIsEnabled }
    },
    [
      _c("div", { staticClass: "checkbox-locator" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.dateRangePickerIsEnabled,
              expression: "dateRangePickerIsEnabled"
            }
          ],
          attrs: { id: "drp-registered-checkbox", type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.dateRangePickerIsEnabled)
              ? _vm._i(_vm.dateRangePickerIsEnabled, null) > -1
              : _vm.dateRangePickerIsEnabled
          },
          on: {
            change: function($event) {
              var $$a = _vm.dateRangePickerIsEnabled,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.dateRangePickerIsEnabled = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.dateRangePickerIsEnabled = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.dateRangePickerIsEnabled = $$c
              }
            }
          }
        })
      ]),
      _c("DatePicker", {
        attrs: {
          mode: "date",
          "is-range": true,
          popover: { placement: "bottom-end", visibility: "click" },
          "min-date": _vm.minDate,
          "max-date": _vm.maxDate
        },
        on: { input: _vm.handleDateRangeUpdated },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var inputValue = ref.inputValue
              var inputEvents = ref.inputEvents
              return [
                _c(
                  "input",
                  _vm._g(
                    {
                      staticClass: "input-inner",
                      attrs: { disabled: !_vm.dateRangePickerIsEnabled },
                      domProps: { value: inputValue.start }
                    },
                    inputEvents.start
                  )
                ),
                _c(
                  "svg",
                  {
                    staticClass: "w-4 h-4 mx-2",
                    class: { "hidden-visually": !_vm.dateRangePickerIsEnabled },
                    attrs: {
                      fill: "none",
                      viewBox: "0 0 24 24",
                      stroke: "currentColor"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        "stroke-width": "2",
                        d: "M14 5l7 7m0 0l-7 7m7-7H3"
                      }
                    })
                  ]
                ),
                _c(
                  "input",
                  _vm._g(
                    {
                      staticClass: "input-inner input-inner__right",
                      attrs: { disabled: !_vm.dateRangePickerIsEnabled },
                      domProps: { value: inputValue.end }
                    },
                    inputEvents.end
                  )
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.dateRange,
          callback: function($$v) {
            _vm.dateRange = $$v
          },
          expression: "dateRange"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }