var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("wrapper", [
    _vm.pageReady
      ? _c("section", { staticClass: "subscription-page" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "tabs" }, [
              _c(
                "div",
                { staticClass: "btn-group", attrs: { role: "group" } },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      class: { active: _vm.tab === "predefined" },
                      on: {
                        click: function($event) {
                          return _vm.show("predefined")
                        }
                      }
                    },
                    [_vm._v("Predefined ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      class: { active: _vm.tab === "custom" },
                      on: {
                        click: function($event) {
                          return _vm.show("custom")
                        }
                      }
                    },
                    [_vm._v(" Custom ")]
                  )
                ]
              )
            ])
          ]),
          _vm.packages.length > 0
            ? _c("div", [
                _vm.tab === "predefined"
                  ? _c(
                      "div",
                      { staticClass: "row" },
                      _vm._l(_vm.filteredPackages, function(pack, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "col-md-4" },
                          [
                            _c(
                              "div",
                              { staticClass: "box box-primary box-solid" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "box-header with-border" },
                                  [
                                    _c("h3", { staticClass: "box-title" }, [
                                      _vm._v(_vm._s(pack.title))
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "box-tools pull-right" },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-box-tool",
                                            attrs: {
                                              type: "button",
                                              "data-widget": "collapse"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-minus"
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "box-body subscription-plan" },
                                  _vm._l(pack.variants, function(
                                    variant,
                                    index
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "subscription-plan__inner"
                                      },
                                      [
                                        _vm._l(variant.prices, function(
                                          price,
                                          pindex
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: pindex,
                                              class: {
                                                "subscription-plan__last":
                                                  pindex ===
                                                  variant.prices.length - 1
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "subscription-plan__duration"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      price.duration_length
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        price.duration_unit
                                                      )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "subscription-plan__price"
                                                },
                                                [
                                                  _c("sup", [_vm._v("£")]),
                                                  _vm._v(
                                                    " " + _vm._s(price.price)
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "subscription-plan__description"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      price.duration_length
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        price.duration_unit
                                                      ) +
                                                      " / " +
                                                      _vm._s(
                                                        variant.max_devices
                                                      ) +
                                                      " maximum devices "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn bg-maroon subscription-plan__button",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.addSubscription(
                                                        pack.id,
                                                        variant.id,
                                                        price.id
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("Choose this plan ")]
                                              )
                                            ]
                                          )
                                        }),
                                        index !== pack.variants.length - 1
                                          ? _c("hr", {
                                              staticClass:
                                                "subscription-plan__divider"
                                            })
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  }),
                                  0
                                )
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm.tab === "custom"
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c(
                          "div",
                          { staticClass: "box box-primary box-solid" },
                          [
                            _c("div", { staticClass: "box-header" }, [
                              _c("h3", { staticClass: "box-title" }, [
                                _vm._v("Create a custom package")
                              ])
                            ]),
                            _c(
                              "div",
                              { staticClass: "box-body" },
                              [
                                _c("subscription-form", {
                                  attrs: {
                                    packages: _vm.packages,
                                    "free-trial": _vm.object === "user"
                                  },
                                  on: { validated: _vm.addCustomSubscription },
                                  model: {
                                    value: _vm.form,
                                    callback: function($$v) {
                                      _vm.form = $$v
                                    },
                                    expression: "form"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ])
                    ])
                  : _vm._e()
              ])
            : _c("div", { staticClass: "col-md-12" }, [
                _c("div", { staticClass: "alert alert-warning" }, [
                  _vm._v(
                    "There are currently no subscription packages available for this " +
                      _vm._s(_vm.object) +
                      " "
                  )
                ])
              ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }