var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("wrapper", [
    _c(
      "section",
      { staticClass: "admins-page" },
      [
        _vm.modal.isVisible("delete")
          ? _c(
              "modal",
              {
                attrs: { title: "Delete Admin" },
                on: {
                  close: function($event) {
                    return _vm.modal.hide("delete")
                  }
                }
              },
              [
                _c("p", { staticClass: "text-danger lead" }, [
                  _vm._v("This admin will be permanently deleted. "),
                  _c("br"),
                  _vm._v(" This action is irreversible.")
                ]),
                _c(
                  "div",
                  { staticClass: "modal-footer" },
                  [
                    _vm._t("footer"),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-warning",
                        attrs: { type: "button", "data-dismiss": "modal" },
                        on: {
                          click: function($event) {
                            return _vm.modal.hide("delete")
                          }
                        }
                      },
                      [_vm._v("Cancel")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-danger",
                        attrs: { type: "button", "data-dismiss": "modal" },
                        on: { click: _vm.deleteAdmin }
                      },
                      [_vm._v("Delete Permanently")]
                    )
                  ],
                  2
                )
              ]
            )
          : _vm._e(),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12 col-sm-12 col-xs-12 box-tools" },
            [
              _c("div", { staticClass: "pull-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/admins/new")
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fa fa-plus" }),
                    _vm._v(" Add an admin ")
                  ]
                )
              ])
            ]
          )
        ]),
        _c("vue-good-table", {
          attrs: {
            styleClass: "vgt-table striped",
            theme: "auris-admin",
            columns: _vm.columns,
            rows: _vm.admins,
            totalRows: _vm.totalRecords,
            "pagination-options": {
              enabled: true,
              mode: "records",
              perPage: 10,
              position: "bottom",
              perPageDropdown: [10, 25, 50, 100],
              dropdownAllowAll: false,
              setCurrentPage: 1,
              nextLabel: "next",
              prevLabel: "prev",
              rowsPerPageLabel: "Rows per page",
              ofLabel: "of",
              pageLabel: "page", // for 'pages' mode
              allLabel: "All"
            },
            "search-options": {
              enabled: true,
              trigger: "key-up",
              skipDiacritics: true,
              placeholder: "Search this table"
            }
          },
          scopedSlots: _vm._u([
            {
              key: "table-row",
              fn: function(props) {
                return [
                  props.column.field == "actions"
                    ? [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-link user-link",
                            attrs: { "data-id": props.row.id },
                            on: {
                              click: function($event) {
                                return _vm.goToAdminPage($event)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-edit" }),
                            _vm._v(" Manage")
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-link delete",
                            attrs: { "data-id": props.row.id },
                            on: {
                              click: function($event) {
                                return _vm.beginDeleteAdmin({
                                  id: props.row.id
                                })
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-trash" }),
                            _vm._v("Delete ")
                          ]
                        )
                      ]
                    : _vm._e()
                ]
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }