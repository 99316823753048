var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "subscribers-report-page" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12 mb-1-25" },
        [
          _c("DatePickerWithDropdown", {
            attrs: {
              placeholder: "Show data for...",
              reference: "time_period",
              value: _vm.dateRangeModel,
              initialDateRange: _vm.dateRangeModel
            },
            on: {
              "date-range-change": function($event) {
                return _vm.handleChangeDateRange($event, "dateRangeUpdated")
              }
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c(
            "vue-good-table",
            {
              attrs: {
                styleClass: "vgt-table striped",
                theme: "auris-admin",
                columns: _vm.columns,
                rows: _vm.rows,
                totalRows: _vm.totalRecords,
                "pagination-options": {
                  enabled: true,
                  mode: "records",
                  perPage: 10,
                  position: "bottom",
                  perPageDropdown: [10, 25, 50, 100],
                  dropdownAllowAll: false,
                  setCurrentPage: 1,
                  nextLabel: "next",
                  prevLabel: "prev",
                  rowsPerPageLabel: "Rows per page",
                  ofLabel: "of",
                  pageLabel: "page", // for 'pages' mode
                  allLabel: "All"
                },
                "search-options": {
                  enabled: true,
                  trigger: "key-up",
                  skipDiacritics: true,
                  placeholder: "Search this table"
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "table-row",
                  fn: function(props) {
                    return [
                      props.column.field === "week.units"
                        ? _c(
                            "div",
                            [
                              _c("p", [_vm._v(_vm._s(props.row.week.units))]),
                              _c("PercentageChange", {
                                attrs: {
                                  percentage: parseInt(
                                    props.row.week.percentage_change
                                  )
                                }
                              })
                            ],
                            1
                          )
                        : props.column.field === "month.units"
                        ? _c(
                            "div",
                            [
                              _c("p", [_vm._v(_vm._s(props.row.month.units))]),
                              _c("PercentageChange", {
                                attrs: {
                                  percentage: parseInt(
                                    props.row.month.percentage_change
                                  )
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  }
                }
              ])
            },
            [
              _c("template", { slot: "loadingContent" }, [
                _c("div", { staticClass: "loadingContent" }, [
                  _c("i", { staticClass: "fa fa-refresh fa-spin" })
                ])
              ])
            ],
            2
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12 mt-1" }, [
        _c(
          "div",
          { staticClass: "charts-container" },
          [_c("chart", { attrs: { chart: _vm.charts.purchasesOverTime } })],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }