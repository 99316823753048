var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("wrapper", [
    _c(
      "section",
      { staticClass: "school-page" },
      [
        _vm.modal.isVisible("subscription")
          ? _c(
              "modal",
              {
                attrs: { title: "School Subscription", "show-footer": false },
                on: { close: _vm.handleCancelSubChanges }
              },
              [
                _c("school-subscription-form", {
                  attrs: { value: _vm.subscription },
                  on: {
                    validated: function($event) {
                      return _vm.handleSubChanges($event)
                    },
                    cancel: _vm.handleCancelSubChanges
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.modal.isVisible("syncZoho")
          ? _c(
              "modal",
              {
                attrs: { title: "Zoho sync", "show-footer": false },
                on: {
                  close: function($event) {
                    return _vm.modal.hide("syncZoho")
                  }
                }
              },
              [
                _c("p", { staticClass: "lead" }, [
                  _vm._v(
                    "The sync is happening. Please do not re-click on the button."
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm.modal.isVisible("loginas")
          ? _c(
              "modal",
              {
                attrs: {
                  title: "WARNING",
                  "show-footer": true,
                  "state-class": "danger"
                },
                on: { close: _vm.handleCloseLoginAs }
              },
              [
                _c("p", [
                  _vm._v(
                    "You will automatically be logged into the School Portal as "
                  ),
                  _c("b", [_vm._v(_vm._s(_vm.willLoginAs.full_name))]),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.willLoginAs.enterprise_user_role === "admin"
                          ? "an Administrator"
                          : "a Teacher"
                      ) +
                      " of "
                  ),
                  _c("b", [_vm._v(_vm._s(this.currentSchool.name))]),
                  _vm._v(".")
                ]),
                _c("p", [
                  _vm._v("You should "),
                  _c("b", [_vm._v("never")]),
                  _vm._v(
                    " use this feature without the prior consent of a school administrator. You will be viewing the information of teachers and pupils at this school."
                  )
                ]),
                _c("template", { slot: "footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger",
                      on: {
                        click: function($event) {
                          return _vm.loginAs(_vm.willLoginAs.id)
                        }
                      }
                    },
                    [_vm._v("Continue to School Portal")]
                  )
                ])
              ],
              2
            )
          : _vm._e(),
        _vm.modal.isVisible("setupLink")
          ? _c(
              "modal",
              {
                attrs: { title: "School Setup Link", "show-footer": true },
                on: { close: _vm.handleCloseSetupLink }
              },
              [
                _c("p", [
                  _vm._v(
                    "Send the URL below to the school administrator to complete setup."
                  )
                ]),
                _c("input", {
                  attrs: { id: "schoolSetupLink", type: "text" },
                  domProps: { value: _vm.schoolSetupLink.url }
                }),
                _c("template", { slot: "footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger",
                      on: { click: _vm.copyUrl }
                    },
                    [_vm._v("Copy Url")]
                  )
                ])
              ],
              2
            )
          : _vm._e(),
        _c("div", { staticClass: "multi-select-outer-container" }, [
          _c(
            "div",
            { staticClass: "multi-select-container" },
            [
              _c("multiselect", {
                ref: "schoolTags",
                staticClass: "school-tags",
                attrs: {
                  options: _vm.availableTags,
                  placeholder: "Search or add a tag",
                  tagPlaceholder: "Click here to add a tag",
                  searchable: true,
                  multiple: true,
                  taggable: true,
                  label: "name",
                  "track-by": "code"
                },
                on: { tag: _vm.addTag },
                model: {
                  value: _vm.schoolTags,
                  callback: function($$v) {
                    _vm.schoolTags = $$v
                  },
                  expression: "schoolTags"
                }
              })
            ],
            1
          ),
          _c(
            "label",
            {
              staticClass: "multi-select-label",
              on: {
                click: function($event) {
                  return _vm.openMultiselect()
                }
              }
            },
            [_vm._v("Click here to add a tag")]
          )
        ]),
        _vm.currentSchool
          ? _c("div", { staticClass: "row match-height" }, [
              _c("div", { staticClass: "col-6 col-lg-3" }, [
                _c(
                  "fieldset",
                  { staticClass: "stats-box stats-box-border white" },
                  [
                    _c("legend", { staticClass: "stats-box-legend" }, [
                      _vm._v("Basic Stats")
                    ]),
                    _c("ul", { staticClass: "stats-box-list" }, [
                      _c("li", { staticClass: "stats-box-list-item" }, [
                        _c("span", [_vm._v("Teachers")]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.currentSchool.teachers.length))
                        ])
                      ]),
                      _c("li", { staticClass: "stats-box-list-item" }, [
                        _c("span", [_vm._v("Classes")]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.currentSchool.groups.length))
                        ])
                      ]),
                      _c("li", { staticClass: "stats-box-list-item" }, [
                        _c("span", [_vm._v("Pupils")]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.currentSchool.readers_count))
                        ])
                      ])
                    ])
                  ]
                )
              ]),
              _c("div", { staticClass: "col-6 col-lg-3" }, [
                _c(
                  "fieldset",
                  { staticClass: "stats-box stats-box-border white" },
                  [
                    _c("legend", { staticClass: "stats-box-legend" }, [
                      _vm._v("Subscription")
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "stats-box subscription-status",
                        class: {
                          "active-subscription": _vm.subscriptionIsActive,
                          "inactive-subscription": !_vm.subscriptionIsActive
                        }
                      },
                      [_c("p", [_vm._v(_vm._s(_vm.subscriptionStatusString))])]
                    ),
                    _vm.subscriptionIsActive
                      ? _c("ul", { staticClass: "stats-box-list mb-0" }, [
                          _c("li", { staticClass: "stats-box-list-item" }, [
                            _vm._v(_vm._s(_vm.subscriptionMaxReadersString))
                          ]),
                          _c("li", { staticClass: "stats-box-list-item" }, [
                            _vm._v(_vm._s(_vm.subscriptionDurationString))
                          ]),
                          _c("li", { staticClass: "stats-box-list-item" }, [
                            _vm._v(_vm._s(_vm.subscriptionExpiryString))
                          ])
                        ])
                      : _vm._e(),
                    _vm.subscriptionIsActive
                      ? _c(
                          "div",
                          { staticClass: "stats-box-button text-center" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                on: {
                                  click: function($event) {
                                    return _vm.modal.show("subscription")
                                  }
                                }
                              },
                              [_vm._v("Change Subscription")]
                            )
                          ]
                        )
                      : _c(
                          "div",
                          { staticClass: "stats-box-button text-center" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                on: {
                                  click: function($event) {
                                    return _vm.modal.show("subscription")
                                  }
                                }
                              },
                              [_vm._v("Add Subscription")]
                            )
                          ]
                        ),
                    _vm.subscriptionStatusString === "Expired"
                      ? _c("div", { staticClass: "text-center" }, [
                          _c("p", [
                            _vm._v(_vm._s(_vm.subscriptionExpiryString))
                          ])
                        ])
                      : _vm._e()
                  ]
                )
              ]),
              _c("div", { staticClass: "col-6 col-lg-3" }, [
                !_vm.schoolHasAcceptedTerms
                  ? _c(
                      "fieldset",
                      { staticClass: "stats-box stats-box-border white" },
                      [
                        _c("legend", { staticClass: "stats-box-legend" }, [
                          _vm._v("Complete Setup")
                        ]),
                        _c("p", { staticClass: "stats-box-text" }, [
                          _c("strong", [
                            _vm._v("This school has not yet completed setup.")
                          ]),
                          _vm._v(
                            " Click the button below to generate a URL you can send to the school administrator."
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "stats-box-button text-center" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                on: {
                                  click: function($event) {
                                    return _vm.createSetupToken()
                                  }
                                }
                              },
                              [_vm._v("Create Setup Link")]
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c(
                  "div",
                  { staticClass: "stats-box stats-box-links" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        on: {
                          click: function($event) {
                            return _vm.$router.push(
                              "/schools/" +
                                _vm.currentSchool.id +
                                "/reports/books"
                            )
                          }
                        }
                      },
                      [_vm._v("View Books Report")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        on: {
                          click: function($event) {
                            return _vm.$router.push(
                              "/schools/" +
                                _vm.currentSchool.id +
                                "/reports/reading"
                            )
                          }
                        }
                      },
                      [_vm._v("View Reading Report")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        on: {
                          click: function($event) {
                            return _vm.$router.push(
                              _vm.currentSchool.id + "/edit"
                            )
                          }
                        }
                      },
                      [_vm._v("Update School Information")]
                    ),
                    _c(
                      "loading-button",
                      {
                        staticClass: "btn btn-flex",
                        attrs: { isLoading: _vm.recordingsButtonIsLoading },
                        on: {
                          click: function($event) {
                            return _vm.handleRecordingsButtonClick()
                          }
                        }
                      },
                      [
                        !_vm.recordingsButtonIsLoading
                          ? _c("div", {
                              class:
                                "recording-indicator" +
                                (_vm.currentSchool.can_enable_recordings
                                  ? " can-enable"
                                  : "") +
                                (_vm.currentSchool.recording_enabled
                                  ? " enabled"
                                  : "")
                            })
                          : _vm._e(),
                        _vm._v(_vm._s(_vm.recordingsButtonString))
                      ]
                    )
                  ],
                  1
                )
              ])
            ])
          : _vm._e(),
        _c("div", { staticClass: "row match-height" }, [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c(
                "vue-good-table",
                {
                  attrs: {
                    styleClass: "vgt-table striped",
                    theme: "auris-admin",
                    columns: _vm.tables.users.columns,
                    rows: _vm.tables.users.rows,
                    totalRows: _vm.tables.users.rows.length,
                    "pagination-options": {
                      enabled: true,
                      mode: "records",
                      perPage: 10,
                      position: "bottom",
                      perPageDropdown: [10, 25, 50, 100],
                      dropdownAllowAll: false,
                      setCurrentPage: 1,
                      nextLabel: "next",
                      prevLabel: "prev",
                      rowsPerPageLabel: "Rows per page",
                      ofLabel: "of",
                      pageLabel: "page", // for 'pages' mode
                      allLabel: "All"
                    },
                    "search-options": {
                      enabled: false,
                      trigger: "key-up",
                      skipDiacritics: true,
                      placeholder: "Search this table"
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "table-row",
                      fn: function(props) {
                        return [
                          props.column.field == "actions"
                            ? _c("span", [
                                props.row.enterprise_user_role !== "admin"
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-link user-link",
                                        attrs: { title: "promote to admin" },
                                        on: {
                                          click: function($event) {
                                            return _vm.setAsAdmin(
                                              props.row.id,
                                              true
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["fas", "arrow-circle-up"]
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                props.row.enterprise_user_role === "admin"
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-link user-link",
                                        attrs: { title: "demote from admin" },
                                        on: {
                                          click: function($event) {
                                            return _vm.setAsAdmin(
                                              props.row.id,
                                              false
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["fas", "arrow-circle-down"]
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-link user-link",
                                    attrs: { title: "edit user" },
                                    on: {
                                      click: function($event) {
                                        return _vm.$router.push(
                                          "/users/" + props.row.id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["fas", "user"] }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-link user-link",
                                    attrs: {
                                      title: "login as user",
                                      xxclick: "loginAs(props.row.id)"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.warnLoginAs(props.row.id)
                                      }
                                    }
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["fas", "user-secret"] }
                                    })
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          props.column.field == "app_last_seen_at"
                            ? _c("span", { staticClass: "wrap" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.timeAgo(props.row.app_last_seen_at)
                                    )
                                ),
                                _c("br"),
                                _c("small", [
                                  _c("i", [
                                    _vm._v(
                                      _vm._s(
                                        props.formattedRow[props.column.field]
                                      )
                                    )
                                  ])
                                ])
                              ])
                            : props.column.field == "sp_last_seen_at"
                            ? _c("span", { staticClass: "wrap" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.timeAgo(props.row.sp_last_seen_at)
                                    )
                                ),
                                _c("br"),
                                _c("small", [
                                  _c("i", [
                                    _vm._v(
                                      _vm._s(
                                        props.formattedRow[props.column.field]
                                      )
                                    )
                                  ])
                                ])
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      props.formattedRow[props.column.field]
                                    ) +
                                    " "
                                )
                              ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("template", { slot: "loadingContent" }, [
                    _c("div", { staticClass: "loadingContent" }, [
                      _c("i", { staticClass: "fa fa-refresh fa-spin" })
                    ])
                  ])
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c(
                "vue-good-table",
                {
                  attrs: {
                    styleClass: "vgt-table striped",
                    theme: "auris-admin",
                    columns: _vm.tables.classes.columns,
                    rows: _vm.tables.classes.rows,
                    totalRows: _vm.tables.classes.rows.length,
                    "pagination-options": {
                      enabled: true,
                      mode: "records",
                      perPage: 10,
                      position: "bottom",
                      perPageDropdown: [10, 25, 50, 100],
                      dropdownAllowAll: false,
                      setCurrentPage: 1,
                      nextLabel: "next",
                      prevLabel: "prev",
                      rowsPerPageLabel: "Rows per page",
                      ofLabel: "of",
                      pageLabel: "page", // for 'pages' mode
                      allLabel: "All"
                    },
                    "search-options": {
                      enabled: false,
                      trigger: "key-up",
                      skipDiacritics: true,
                      placeholder: "Search this table"
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "table-row",
                      fn: function(props) {
                        return [
                          props.column.field == "actions"
                            ? _c("span", [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-link user-link",
                                    attrs: { title: "view book usage" },
                                    on: {
                                      click: function($event) {
                                        return _vm.$router.push(
                                          "/schools/" +
                                            _vm.currentSchool.id +
                                            "/classes/" +
                                            props.row.id +
                                            "/reports/books"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["fas", "book"] }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-link user-link",
                                    attrs: { title: "view reading report" },
                                    on: {
                                      click: function($event) {
                                        return _vm.$router.push(
                                          "/schools/" +
                                            _vm.currentSchool.id +
                                            "/classes/" +
                                            props.row.id +
                                            "/reports/reading"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["fas", "book-open"] }
                                    })
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          props.column.field == "subscription.expires_at"
                            ? _c("span", { staticClass: "wrap" }, [
                                _c(
                                  "span",
                                  { class: _vm.getCellClass(props.row) },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          props.row.subscription
                                            ? _vm.formatDate(
                                                props.row.subscription
                                                  .expires_at
                                              )
                                            : null
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      props.formattedRow[props.column.field]
                                    ) +
                                    " "
                                )
                              ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("template", { slot: "loadingContent" }, [
                    _c("div", { staticClass: "loadingContent" }, [
                      _c("i", { staticClass: "fa fa-refresh fa-spin" })
                    ])
                  ])
                ],
                2
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row match-height" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("vue-good-table", {
                attrs: {
                  styleClass: "vgt-table striped",
                  theme: "auris-admin",
                  columns: _vm.tables.invites.columns,
                  rows: _vm.tables.invites.rows,
                  totalRows: _vm.tables.invites.rows.length,
                  "pagination-options": {
                    enabled: true,
                    mode: "records",
                    perPage: 10,
                    position: "bottom",
                    perPageDropdown: [10, 25, 50, 100],
                    dropdownAllowAll: false,
                    setCurrentPage: 1,
                    nextLabel: "next",
                    prevLabel: "prev",
                    rowsPerPageLabel: "Rows per page",
                    ofLabel: "of",
                    pageLabel: "page", // for 'pages' mode
                    allLabel: "All"
                  },
                  "search-options": {
                    enabled: false,
                    trigger: "key-up",
                    skipDiacritics: true,
                    placeholder: "Search this table"
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "table-row",
                    fn: function(props) {
                      return [
                        props.column.field == "expires_at"
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatExpiryDate(
                                      props.row.expires_at,
                                      props.row.status
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          : props.column.field == "status"
                          ? _c(
                              "span",
                              {
                                class:
                                  props.row.status === "accepted"
                                    ? "text-success"
                                    : "text-danger"
                              },
                              [_vm._v(" " + _vm._s(props.row.status) + " ")]
                            )
                          : _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    props.formattedRow[props.column.field]
                                  ) +
                                  " "
                              )
                            ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row match-height" }, [
          _vm.currentSchool && _vm.currentSchool.primaryContact
            ? _c("div", { staticClass: "col-md-6" }, [
                _c(
                  "fieldset",
                  { staticClass: "stats-box stats-box-border white" },
                  [
                    _c("legend", { staticClass: "stats-box-legend" }, [
                      _vm._v("Primary Contact")
                    ]),
                    _c("h2", { staticClass: "stats-box-heading" }, [
                      _vm._v(_vm._s(_vm.currentSchool.primaryContact.name))
                    ]),
                    _c("ul", { staticClass: "stats-box-list" }, [
                      _vm.currentSchool.primaryContact.job_role
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _vm._v(
                              _vm._s(_vm.currentSchool.primaryContact.job_role)
                            )
                          ])
                        : _vm._e(),
                      _vm.currentSchool.primaryContact.email
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.getMailTo(
                                    _vm.currentSchool.primaryContact.email
                                  )
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.currentSchool.primaryContact.email)
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm.currentSchool.primaryContact.phone
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _vm._v(
                              _vm._s(_vm.currentSchool.primaryContact.phone)
                            )
                          ])
                        : _vm._e(),
                      _vm.currentSchool.primaryContact.address.line1
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _vm._v(
                              _vm._s(
                                _vm.currentSchool.primaryContact.address.line1
                              )
                            )
                          ])
                        : _vm._e(),
                      _vm.currentSchool.primaryContact.address.line2
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _vm._v(
                              _vm._s(
                                _vm.currentSchool.primaryContact.address.line2
                              )
                            )
                          ])
                        : _vm._e(),
                      _vm.currentSchool.primaryContact.address.line3
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _vm._v(
                              _vm._s(
                                _vm.currentSchool.primaryContact.address.line3
                              )
                            )
                          ])
                        : _vm._e(),
                      _vm.currentSchool.primaryContact.address.line4
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _vm._v(
                              _vm._s(
                                _vm.currentSchool.primaryContact.address.line4
                              )
                            )
                          ])
                        : _vm._e(),
                      _vm.currentSchool.primaryContact.address.postcode
                        ? _c("li", { staticClass: "stats-box-list-item" }, [
                            _vm._v(
                              _vm._s(
                                _vm.currentSchool.primaryContact.address
                                  .postcode
                              )
                            )
                          ])
                        : _vm._e()
                    ])
                  ]
                )
              ])
            : _vm._e(),
          _vm.currentSchool && _vm.currentSchool.billingContact
            ? _c("div", { staticClass: "col-md-6" }, [
                _c(
                  "fieldset",
                  { staticClass: "stats-box stats-box-border white" },
                  [
                    _c("legend", { staticClass: "stats-box-legend" }, [
                      _vm._v("Billing Contact")
                    ]),
                    _c("div", { staticClass: "stats-box-address" }, [
                      _c("h2", { staticClass: "stats-box-heading" }, [
                        _vm._v(_vm._s(_vm.currentSchool.billingContact.name))
                      ]),
                      _c("ul", { staticClass: "stats-box-list" }, [
                        _vm.currentSchool.billingContact.job_role
                          ? _c("li", { staticClass: "stats-box-list-item" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.currentSchool.billingContact.job_role
                                )
                              )
                            ])
                          : _vm._e(),
                        _vm.currentSchool.billingContact.email
                          ? _c("li", { staticClass: "stats-box-list-item" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.getMailTo(
                                      _vm.currentSchool.billingContact.email
                                    )
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.currentSchool.billingContact.email
                                    )
                                  )
                                ]
                              )
                            ])
                          : _vm._e(),
                        _vm.currentSchool.billingContact.phone
                          ? _c("li", { staticClass: "stats-box-list-item" }, [
                              _vm._v(
                                _vm._s(_vm.currentSchool.billingContact.phone)
                              )
                            ])
                          : _vm._e(),
                        _vm.currentSchool.billingContact.address.line1
                          ? _c("li", { staticClass: "stats-box-list-item" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.currentSchool.billingContact.address.line1
                                )
                              )
                            ])
                          : _vm._e(),
                        _vm.currentSchool.billingContact.address.line2
                          ? _c("li", { staticClass: "stats-box-list-item" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.currentSchool.billingContact.address.line2
                                )
                              )
                            ])
                          : _vm._e(),
                        _vm.currentSchool.billingContact.address.line3
                          ? _c("li", { staticClass: "stats-box-list-item" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.currentSchool.billingContact.address.line3
                                )
                              )
                            ])
                          : _vm._e(),
                        _vm.currentSchool.billingContact.address.line4
                          ? _c("li", { staticClass: "stats-box-list-item" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.currentSchool.billingContact.address.line4
                                )
                              )
                            ])
                          : _vm._e(),
                        _vm.currentSchool.billingContact.address.postcode
                          ? _c("li", { staticClass: "stats-box-list-item" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.currentSchool.billingContact.address
                                    .postcode
                                )
                              )
                            ])
                          : _vm._e()
                      ])
                    ])
                  ]
                )
              ])
            : _vm._e()
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }