<template>
  <div>
    <modal title="Warning: Ambiguous Promocode" v-if="modal.isVisible('code')" @close="cancel" :show-footer="false">
      <p class="text-danger lead">The code you supplied does not contain the current year. </p>
      <p>Once a code has been created, you can never use that code again for another purpose. It is recommended to add a unique component to the code to avoid this, such as the current year.</p>
      <p>Check the box below to continue anyway, otherwise press cancel to amend it.</p>
      <form class="ui form form-horizontal">
        <div class="form-group checkbox" :class="{'has-error': confirmCodeCheckHasFailed}">
          <input id="confirm" type="checkbox" v-model="confirmCodeIsChecked">
          <label for="confirm">I understand the consequences of not using a sufficiently unique code.</label>
        </div>
      </form>
      <div class="modal-footer">
        <slot name="footer"></slot>
        <button type="button" class="btn btn-warning" data-dismiss="modal" @click="cancel">Cancel</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" @click="confirm">Confirm</button>
      </div>
    </modal>
    <form @submit.prevent="validateForm" class="form-horizontal">
      <fieldset>
        <legend>General</legend>
        <div class="form-group" :class="{'has-error': errors.first('target_type')}">
          <label for="target_type" class="col-sm-2 control-label">Target Type</label>
          <div class="col-sm-10">
            <select name="target_type" ref="target_type" class="form-control" id="target_type" v-model="mutableValue.target_type" @change="onTargetTypeChange">
              <option value="subscription">Subscription</option>
              <option value="purchase">Purchase</option>
            </select>
            <div class="help-block is-danger" v-show="errors.has('target_type')">
              {{ errors.first('target_type') }}
            </div>
          </div>
        </div>
        <div class="form-group form-group-lg" :class="{'has-error': errors.first('code')}">
          <label for="code" class="col-sm-2 control-label">Promotion Code</label>
          <div class="col-sm-10">
            <input class="form-control" id="code" name="code" placeholder="Example: Easter2020" type="text" v-model="mutableValue.code"
                   v-validate="'required|alpha_dash'" autocomplete="off">
            <div class="help-block is-danger" v-show="errors.has('code')">
              {{ errors.first('code') }}
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="type" class="col-sm-2 control-label">Type</label>
          <div class="col-sm-2">
            <select v-validate ref="type" name="type" id="type" class="form-control" v-model="mutableValue.type">
              <option value="free_trial" v-if="displayOnlyFor('subscription')">Free Trial</option>
              <option value="coupon">Coupon</option>
            </select>
          </div>
          <div class="col-sm-8" v-show="mutableValue.type == 'free_trial'">
            <p class="text-sm form-control-static text-danger"><em>Free trials are currently only supported for Stripe subscriptions. The user will have to provide payment details during signup.</em></p>
          </div>
        </div>

        <div class="form-group" v-show="mutableValue.type != 'free_trial'">
          <label for="discount-type" class="col-sm-2 control-label">Discount type</label>
          <div class="btn-group btn-group-toggle col-sm-2" data-toggle="buttons" @click="handleChangeDiscountType($event)">
            <label class="btn btn-secondary" v-bind:class="{ active: discountIsPercentage}">
              <input type="radio" name="discount-type" id="percentage" value="percent_off" v-model="mutableValue.dicount_type">Percentage
            </label>
            <label class="btn btn-secondary" v-bind:class="{ active: !discountIsPercentage}">
              <input type="radio" name="discount-type" id="amount" value="amount_off" v-model="mutableValue.discount_type">Amount
            </label>
          </div>
        </div>

        <div class="form-group" :class="{'has-error': errors.first('discount_value')}" v-if="mutableValue.type !== 'free_trial' && discountIsPercentage === true">
          <label for="discount_value" class="col-sm-2 control-label">Discount percentage</label>
          <div class="col-sm-2">
            <div class="input-group">
              <input class="form-control" id="discount_value" name="discount_value" placeholder="00" :disabled="mutableValue.type === 'free_trial'"
                    type="number" v-model="mutableValue.discount_value"
                    v-validate="'required_if:type,coupon|decimal:2|min_value:0|max_value:100'">
              <div class="input-group-addon">%</div>
            </div>
          </div>
          <div class="col-sm-8 help-block is-danger" v-show="errors.has('discount_value')">
            {{ errors.first('discount_value') }}
          </div>
        </div>

        <div class="form-group" :class="{'has-error': errors.first('discount_value')}" v-if="mutableValue.type !== 'free_trial' && discountIsPercentage === false">
          <label for="discount_value" class="col-sm-2 control-label">Discount amount</label>
          <div class="col-sm-2">
            <div class="input-group">
              <div class="input-group-addon">£</div>
              <input class="form-control" id="discount_value" name="discount_value" placeholder="00" :disabled="mutableValue.type === 'free_trial'"
                    type="number" v-model="mutableValue.discount_value" step=".01"
                     v-validate="'required|decimal:2'">

            </div>
          </div>
          <div class="col-sm-8 help-block is-danger" v-show="errors.has('discount_value')">
            {{ errors.first('discount_value') }}
          </div>
        </div>

        <div class="form-group" :class="{'has-error': errors.first('duration_length')}" v-if="displayOnlyFor('subscription')">
          <label for="duration_length" class="col-sm-2 control-label">Duration</label>
          <div class="col-sm-1">
            <input class="form-control" id="duration_length" name="duration_length" placeholder="0"
                   type="number" v-model="mutableValue.duration_length"
                   v-validate="'required|numeric'">
          </div>
          <div class="col-sm-2">
            <select name="duration_unit" id="duration_unit" class="form-control" v-model="mutableValue.duration_unit">
              <option value="months">Months</option>
              <option value="years">Years</option>
            </select>
          </div>
          <div class="col-sm-7" v-show="mutableValue.type === 'free_trial' && showFreeTrialDurationError">
            <p class="text-sm form-control-static text-danger"><em>Free trials cannot last longer than 1 year.</em></p>
          </div>
          <div class="col-sm-7 help-block is-danger" v-show="errors.has('duration_length')">
            {{ errors.first('duration_length') }}
          </div>
        </div>
      </fieldset>
      <fieldset>
        <legend>Restrictions</legend>
        <div class="form-group">
          <label for="valid_from" class="col-sm-2 control-label">Valid From</label>
          <div class="col-sm-2">
             <DatePicker
              v-model="mutableValue.valid_from"
              :popover="{ placement: 'bottom-start', visibility: 'click' }"
              mode="date"
              :is-range="false"
            >
              <template v-slot="{ inputValue, inputEvents}">
                  <input
                    class="form-control"
                    :value="inputValue"
                    v-on="inputEvents"
                  />
              </template>
             </DatePicker>
          </div>
          <label for="valid_until" class="col-sm-2 form-control-static text-center">Until <i class="fa fa-question-circle" v-tooltip="'This field specifies the last day the coupon can be entered, not the date when the applied promotion ends.'"></i></label>
          <div class="col-sm-2">
             <DatePicker
              v-model="mutableValue.valid_until"
              :popover="{ placement: 'bottom-start', visibility: 'click' }"
              mode="date"
              :is-range="false"
            >
              <template v-slot="{ inputValue, inputEvents}">
                  <input
                    class="form-control"
                    :value="inputValue"
                    v-on="inputEvents"
                  />
              </template>
            </DatePicker>
            <p class="text-sm form-control-static text-danger" v-show="showValidUntilError">
              The Valid Until date cannot be set before Valid From.
            </p>
          </div>
          <div class="col-sm-2">
            <button type="button" class="btn btn-link" v-show="mutableValue.valid_from !== null" @click="validUntilPlusOneYear()">+1 year</button>
          </div>
        </div>
        <div class="form-group" :class="{'has-error': errors.first('valid_options')}" v-if="displayOnlyFor('subscription')">
          <div class="col-sm-12">
            <div class="input-group">
              <div class="input-group-addon">
                <input type="checkbox" id="restrict_pricing_options" v-model="validPricingOptionsRestricted" />
              </div>
              <label for="valid_options" class="form-control">Check to specify which packages this coupon can be used with</label>
            </div>
            <select name="valid_options" id="valid_options" style="height:100px"
                    class="form-control" v-model="mutableValue.valid_options" multiple :disabled="!validPricingOptionsRestricted">
              <option :value="key" :key="key" v-for="(opt, key) in pricingOptions">{{ opt }}</option>
            </select>
          </div>
        </div>
        <div class="row" v-if="displayOnlyFor('subscription')">
          <div class="col-sm-6">
            <div class="form-group" :class="{'has-error': errors.first('min_user_created_at')}">
              <div class="col-xs-12">
                <label for="restrict_min_user_created_at">Earliest User Registration Date</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <input id="restrict_min_user_created_at" type="checkbox" v-model="minUserCreatedAtRestricted"/>
                  </div>
                  <DatePicker
                    v-model="mutableValue.min_user_created_at"
                    :popover="{ placement: 'bottom-start', visibility: 'click' }"
                    mode="date"
                    :is-range="false"
                  >
                    <template v-slot="{ inputValue, inputEvents}">
                      <input
                        class="form-control"
                        :value="inputValue"
                        v-on="inputEvents"
                        :placeholder="minUserCreatedAtRestricted ? 'Select minimum registration date' : 'Enable to restrict user registration date'"
                      />
                    </template>
                  </DatePicker>
                </div>
                <div class="help-block is-danger" v-show="errors.has('min_user_created_at')">
                  {{ errors.first('min_user_created_at') }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group" :class="{'has-error': errors.first('min_subscription_created_at')}">
              <div class="col-xs-12">
                <label for="restrict_min_subscription_created_at">Earliest Subscription Starting Date</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <input id="restrict_min_subscription_created_at" type="checkbox" v-model="minSubscriptionCreatedAtRestricted" />
                  </div>
                  <DatePicker
                    v-model="mutableValue.min_subscription_created_at"
                    :popover="{ placement: 'bottom-start', visibility: 'click' }"
                    mode="date"
                    :is-range="false"
                  >
                    <template v-slot="{ inputValue, inputEvents}">
                      <input
                        class="form-control"
                        :value="inputValue"
                        v-on="inputEvents"
                        :placeholder="minSubscriptionCreatedAtRestricted ? 'Select minimum start date' : 'Enable to restrict subscription start date'"
                      />
                    </template>
                  </DatePicker>
                </div>
                <div class="help-block is-danger" v-show="errors.has('min_subscription_created_at')">
                  {{ errors.first('min_subscription_created_at') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="displayOnlyFor('subscription')">
          <div class="col-sm-6">
            <div class="form-group">
              <div class="col-sm-12">
                <div class="input-group">
                    <div class="input-group-addon">
                      <input type="checkbox" name="exclude_current_subscribers" id="exclude_current_subscribers" value="true"
                            v-model="mutableValue.exclude_current_subscribers" />
                    </div>
                    <label for="exclude_current_subscribers" class="form-control form-control-static">Exclude existing subscribers</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <div class="col-xs-12">
                <div class="input-group">
                    <div class="input-group-addon">
                      <input type="checkbox" name="only_first_time_subscribers" id="only_first_time_subscribers" value="true"
                            v-model="mutableValue.only_first_time_subscribers" />
                    </div>
                    <label for="only_first_time_subscribers" class="form-control form-control-static">Only allow first time subscribers</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset>
        <legend>Usage limits</legend>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group" :class="{'has-error': errors.first('max_uses_global')}">
              <div class="col-xs-12">
                <label for="restrict_max_uses_global">Global Redemption Limit</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <input id="restrict_max_uses_global" type="checkbox" v-model="maxUsesGlobalRestricted" />
                  </div>
                  <input class="form-control" id="max_uses_global" name="max_uses_global" :placeholder="maxUsesGlobalRestricted ? 'Enter number of times this coupon can be used' : 'Enable to limit maximum redemptions'" type="number" v-model="mutableValue.max_uses_global" v-validate="'required|numeric'" :disabled="!maxUsesGlobalRestricted">
                </div>
                <div class="help-block is-danger" v-show="errors.has('max_uses_global')">
                  {{ errors.first('max_uses_global') }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group" :class="{'has-error': errors.first('max_uses_per_user')}">
              <div class="col-xs-12">
                <label for="restrict_max_uses_per_user">Per User Redemption Limit</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <input id="restrict_max_uses_per_user" type="checkbox" v-model="maxUsesPerUserRestricted" />
                  </div>
                  <input class="form-control" id="max_uses_per_user" name="max_uses_per_user" :placeholder="maxUsesPerUserRestricted ? 'Enter number of times a user can repeat this code' : 'Enable to limit user repeat redemptions'" type="number" v-model="mutableValue.max_uses_per_user" v-validate="'required|numeric'" :disabled="!maxUsesPerUserRestricted">
                </div>
                <div class="help-block is-danger" v-show="errors.has('max_uses_per_user')">
                    {{ errors.first('max_uses_per_user') }}
                  </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
      <div class="box-footer">
        <div class="form-group">
          <div class="col-xs-12">
            <loading-button class="btn-block btn-lg" :disabled="errors.any()">Submit</loading-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import moment from 'moment';
import VTooltip from 'v-tooltip';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import FormMixin from '@/mixins/FormMixin';
import LoadingButton from '@/components/utils/LoadingButton';
import ModalJS from '@/components/utils/Modal';
import Modal from '@/classes/Modal';

export default {
  name: 'promocode-form',
  props: ['value', 'pricingOptions'],
  mixins: [FormMixin],
  components: {
    LoadingButton,
    DatePicker,
    ModalJS, // eslint-disable-line
    modal: ModalJS,
    VTooltip, // eslint-disable-line
  },
  data() {
    return {
      modal: new Modal({
        code: false,
      }),
      confirmCodeIsChecked: false,
      confirmCodeCheckHasFailed: true,
      minUserCreatedAtRestricted: false,
      minSubscriptionCreatedAtRestricted: false,
      min_subscription_created_at: null,
      validPricingOptionsRestricted: false,
      maxUsesGlobalRestricted: false,
      maxUsesPerUserRestricted: true,
      showFreeTrialDurationError: false,
      showValidUntilError: false,
      discountIsPercentage: true,
    };
  },
  methods: {
    handleChangeDiscountType(e) {
      const discountType = e.target.getElementsByTagName('input')[0].value;
      this.mutableValue.discount_type = discountType;
      if (discountType === 'percent_off') {
        this.discountIsPercentage = true;
      } else {
        this.discountIsPercentage = false;
      }
    },
    validateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (!this.validateFreeTrialDuration(this.mutableValue.duration_unit, this.mutableValue.duration_length)) {
            this.showFreeTrialDurationError = true;
            return;
          }
          // check if valid_until is before valid_from
          if (!this.validateValidUntil(this.mutableValue.valid_from, this.mutableValue.valid_until)) {
            this.showValidUntilError = true;
            return;
          }
          // check if the code contains the current year
          if (this.mutableValue.code && !this.mutableValue.code.includes(moment().year())) {
            this.modal.show('code');
          } else {
            this.$emit('validated');
          }
        }
      });
    },
    validUntilPlusOneYear() {
      this.mutableValue.valid_until = moment(this.mutableValue.valid_until === null ? this.mutableValue.valid_from : this.mutableValue.valid_until).add(1, 'year').toDate();
    },
    validateFreeTrialDuration(durationUnit, durationLength) {
      if (this.mutableValue.type === 'free_trial') {
        if ((durationUnit === 'months' && durationLength > 12)
          || (durationUnit === 'years' && durationLength > 1)) {
          return false;
        }
      }
      return true;
    },
    validateValidUntil(validFrom, validUntil) {
      if (validUntil === null) {
        return true;
      }
      return moment(validUntil).isAfter(validFrom);
    },
    cancel() {
      this.modal.hide('code');
    },
    confirm() {
      if (!this.confirmCodeIsChecked) {
        return;
      }
      this.modal.hide('code');
      this.$emit('validated');
    },
    onTargetTypeChange(event) {
      // eslint-disable-next-line prefer-destructuring
      const value = event.target.mutableValue;
      if (value === 'purchase') {
        this.mutableValue.type = 'coupon';
      }
      this.discountIsPercentage = true;
    },
    displayOnlyFor(type) {
      return type === this.mutableValue.target_type;
    },
  },
  computed: {
    mutableValue() {
      return Object.assign(this.value);
    },
  },
  watch: {
    isChecked() {
      this.confirmCodeCheckHasFailed = !this.confirmCodeIsChecked;
    },
    'mutableValue.type': function (val) {
      if (val === 'free_trial') {
        this.mutableValue.discount_value = null;
      }
    },
    'mutableValue.duration_unit': function (val) {
      this.showFreeTrialDurationError = !this.validateFreeTrialDuration(val, this.mutableValue.duration_length);
    },
    'mutableValue.duration_length': function (val) {
      this.showFreeTrialDurationError = !this.validateFreeTrialDuration(this.mutableValue.duration_unit, val);
    },
    'mutableValue.valid_from': function (val) {
      this.showValidUntilError = !this.validateValidUntil(val, this.mutableValue.valid_until);
    },
    'mutableValue.valid_until': function (val) {
      this.showValidUntilError = !this.validateValidUntil(this.mutableValue.valid_from, val);
    },
    minUserCreatedAtRestricted() {
      this.mutableValue.min_user_created_at = null;
    },
    minSubscriptionCreatedAtRestricted() {
      this.mutableValue.min_subscription_created_at = null;
    },
    validPricingOptionsRestricted() {
      this.mutableValue.valid_options = [];
    },
    maxUsesGlobalRestricted() {
      this.mutableValue.max_uses_global = null;
    },
    maxUsesPerUserRestricted() {
      this.mutableValue.max_uses_per_user = null;
    },
  },
};
</script>

<style lang="scss">

.btn-group {
    .btn {
      background-color: #bdbdbd;
    }
    .active {
      background-color: #24abc9;
      color: white;
    }
  }

.form-control-override-readonly {
  background-color:#fff !important;
}

// Not sure if this is universal, but it works for what I needed on this page
.vdp-datepicker__clear-button {
  position: absolute;
  top: 7px; // .form-control padding-top
  right:0;
}
</style>
