<template>
  <wrapper>
    <section class="content dashboard-page" :class="isLoading ? 'is-loading' : 'has-loaded'">

      <div class="dashboard-compare">
        <span>Compare to previous</span>
        <select class="dashboard-compare__select" v-model="compareToLastYear">
          <option :value="false">period</option>
          <option :value="true">year</option>
        </select>
      </div>

      <h2>Home Users</h2>
      <div class="dashbox__grid">
        <DashBox id="dashbox-total-home-users" title="Total Home Users" :value="stats.home.total_users.value">
          <DashBoxVelocityTable slot="table" :periods="stats.home.total_users.periods" :compareToLastYear="compareToLastYear" />
        </DashBox>

        <FlipCard id="dashbox-active-home-users">
          <DashBox slot="front" title="Active Home Users" :value="stats.home.active_users.value" />
          <div slot="back" class="dashbox__help"><strong>{{stats.home.active_users.value.toLocaleString('en')}} home users</strong> have been seen on the Fonetti platform in the <strong>past 7 days</strong>.</div>
        </FlipCard>

        <DashBox
          id="dashbox-subscribers"
          title="Subscribers"
          :value="stats.home.subscribers.value"
          :conversion="stats.home.subscribers.conversion_rate"
        >
          <template slot="table">
              <!--<DashBoxVelocityTable :periods="stats.home.subscribers.periods" :compareToLastYear="compareToLastYear" />-->
              <table class="dashbox__table">
                <tbody>
                  <tr>
                    <td>Android</td>
                    <td><i class="fa fa-fw fa-user"></i>{{stats.home.subscribers.providers.android.single}}</td>
                    <td><i class="fa fa-fw fa-users"></i>{{stats.home.subscribers.providers.android.multi}}</td>
                  </tr>
                  <tr>
                    <td>iOS</td>
                    <td><i class="fa fa-fw fa-user"></i>{{stats.home.subscribers.providers.ios.single}}</td>
                    <td><i class="fa fa-fw fa-users"></i>{{stats.home.subscribers.providers.ios.multi}}</td>
                  </tr>
                  <tr>
                    <td>Stripe</td>
                    <td><i class="fa fa-fw fa-user"></i>{{stats.home.subscribers.providers.stripe.single}}</td>
                    <td><i class="fa fa-fw fa-users"></i>{{stats.home.subscribers.providers.stripe.multi}}</td>
                  </tr>
                </tbody>
            </table>
          </template>
        </DashBox>
        <DashBox id="dashbox-home-total-readers" title="Total Home Readers" :value="stats.home.total_readers.value">
          <DashBoxVelocityTable slot="table" :periods="stats.home.total_readers.periods" :compareToLastYear="compareToLastYear" />
        </DashBox>
        <FlipCard id="dashbox-home-active-readers">
          <DashBox slot="front" title="Active Home Readers" :value="stats.home.active_readers.value" />
          <div slot="back" class="dashbox__help"><strong>{{stats.home.active_readers.value.toLocaleString('en')}} home readers</strong> have opened a book in the <strong>past 7 days</strong>.</div>
        </FlipCard>
        <DashBox id="dashbox-home-read-time" title="Reading Time" :value="format_seconds(stats.home.reading_time.value)">
          <DashBoxVelocityTable slot="table" :periods="stats.home.reading_time.periods" :compareToLastYear="compareToLastYear" numType="seconds" />
        </DashBox>
        <DashBox id="dashbox-home-books-read" title="Books Read" :value="stats.home.books_read.value">
          <DashBoxVelocityTable slot="table" :periods="stats.home.books_read.periods" :compareToLastYear="compareToLastYear" />
        </DashBox>
      </div>

      <h2>Schools</h2>
      <div class="dashbox__grid">
        <DashBox id="dashbox-total-schools" title="Total Schools" :value="stats.schools.total_schools.value" />
        <FlipCard id="dashbox-total-teachers">
          <DashBox slot="front" title="Total Teachers" :value="stats.schools.total_teachers.value" :active_value="stats.schools.total_teachers.active" />
          <div slot="back" class="dashbox__help"><i class="fa fa-bolt"></i> <strong>{{stats.schools.total_teachers.active.toLocaleString('en')}}</strong> active teachers have logged in, in the <strong>past 7 days</strong>.</div>
        </FlipCard>
        <DashBox id="dashbox-total-classes" title="Total Classes" :value="stats.schools.total_classes.value" />
        <FlipCard id="dashbox-total-pupils">
          <DashBox slot="front" title="Total Pupils" :value="stats.schools.total_pupils.value" :active_value="stats.schools.total_pupils.active" />
          <div slot="back" class="dashbox__help"><i class="fa fa-bolt"></i> <strong>{{stats.schools.total_pupils.active.toLocaleString('en')}}</strong> active pupils have opened a book in the <strong>past 7 days</strong>.</div>
        </FlipCard>
        <DashBox id="dashbox-school-read-time" title="Reading Time" :value="format_seconds(stats.schools.reading_time.value)">
          <DashBoxVelocityTable slot="table" :periods="stats.schools.reading_time.periods" :compareToLastYear="compareToLastYear" numType="seconds" />
        </DashBox>
        <DashBox id="dashbox-school-books-read" title="Books Read" :value="stats.schools.books_read.value">
          <DashBoxVelocityTable slot="table" :periods="stats.schools.books_read.periods" :compareToLastYear="compareToLastYear" />
        </DashBox>
      </div>

    </section>
  </wrapper>
</template>

<script>
import moment from 'moment';
import momentDuration from 'moment-duration-format'; // eslint-disable-line
import api from '@/api';
import Wrapper from '@/components/Wrapper';
import DashBox from '@/components/DashBox';
import FlipCard from '@/components/FlipCard';
import DashBoxVelocityTable from '@/components/DashBoxVelocityTable';

const VelocityJsonTemplate = {
  this_week: {
    current_value: 0, previous_value: 0, last_year_value: 0,
  },
};

export default {
  name: 'dashboard',
  components: {
    Wrapper,
    DashBox,
    FlipCard,
    DashBoxVelocityTable,
  },
  data() {
    return {
      isLoading: false,
      compareToLastYear: false,
      stats: {
        home: {
          total_users: {
            value: 0,
            periods: { ...VelocityJsonTemplate },
          },
          active_users: {
            value: 0,
          },
          total_readers: {
            value: 0,
            periods: { ...VelocityJsonTemplate },
          },
          active_readers: {
            value: 0,
          },
          subscribers: {
            value: 0,
            periods: { ...VelocityJsonTemplate },
            conversion_rate: 0,
            providers: {
              android: {
                single: 0,
                multi: 0,
              },
              ios: {
                single: 0,
                multi: 0,
              },
              stripe: {
                single: 0,
                multi: 0,
              },
            },
          },
          reading_time: {
            value: 0,
            periods: { ...VelocityJsonTemplate },
          },
          books_read: {
            value: 0,
            periods: { ...VelocityJsonTemplate },
          },
        },
        schools: {
          total_schools: {
            value: 0,
          },
          total_teachers: {
            value: 0,
            active: 0,
          },
          total_classes: {
            value: 0,
          },
          total_pupils: {
            value: 0,
            active: 0,
          },
          reading_time: {
            value: 0,
            periods: { ...VelocityJsonTemplate },
          },
          books_read: {
            value: 0,
            periods: { ...VelocityJsonTemplate },
          },
        },
      },
    };
  },
  mounted() {
    this.loadStats();
  },
  methods: {
    loadStats() {
      this.isLoading = true;
      api.get('/reports/dashboard').then((response) => {
        const stats = response.data.data;
        this.hydrateStatsFromApiResponse(stats);
        this.isLoading = false;
      });
    },
    hydrateStatsFromApiResponse(stats) {
      this.stats.home.total_users.value = stats.total_home_users.total;
      this.stats.home.total_users.periods = stats.total_home_users.periods;
      this.stats.home.active_users.value = stats.active_home_users;

      this.stats.home.total_readers.value = stats.total_home_readers.total;
      this.stats.home.total_readers.periods = stats.total_home_readers.periods;
      this.stats.home.active_readers.value = stats.active_home_readers;

      this.stats.home.subscribers.value = stats.active_subscribers.total;
      this.stats.home.subscribers.conversion_rate = stats.active_subscribers.conversion_rate;
      this.stats.home.subscribers.periods = stats.active_subscribers.periods;
      this.stats.home.subscribers.providers.android.single = stats.active_subscribers_by_provider.android.single;
      this.stats.home.subscribers.providers.android.multi = stats.active_subscribers_by_provider.android.multi;
      this.stats.home.subscribers.providers.ios.single = stats.active_subscribers_by_provider.ios.single;
      this.stats.home.subscribers.providers.ios.multi = stats.active_subscribers_by_provider.ios.multi;
      this.stats.home.subscribers.providers.stripe.single = stats.active_subscribers_by_provider.stripe.single;
      this.stats.home.subscribers.providers.stripe.multi = stats.active_subscribers_by_provider.stripe.multi;

      this.stats.home.reading_time.value = stats.reading_time_for_home_readers.total;
      this.stats.home.reading_time.periods = stats.reading_time_for_home_readers.periods;

      this.stats.home.books_read.value = stats.books_read_for_home_readers.total;
      this.stats.home.books_read.periods = stats.books_read_for_home_readers.periods;

      this.stats.schools.total_schools.value = stats.total_schools;

      this.stats.schools.total_teachers.value = stats.total_teachers.total;
      this.stats.schools.total_teachers.active = stats.total_teachers.active;

      this.stats.schools.total_classes.value = stats.total_classes;

      this.stats.schools.total_pupils.value = stats.total_pupils.total;
      this.stats.schools.total_pupils.active = stats.total_pupils.active;

      this.stats.schools.reading_time.value = stats.reading_time_for_school_readers.total;
      this.stats.schools.reading_time.periods = stats.reading_time_for_school_readers.periods;

      this.stats.schools.books_read.value = stats.books_read_for_school_readers.total;
      this.stats.schools.books_read.periods = stats.books_read_for_school_readers.periods;
    },
    format_seconds(n) {
      return moment.duration(n, 'seconds').format('D[d] H[h] m[m]');
    },
  },
};
</script>

<style lang="scss">
.dashboard-page {
  h2 {
    font-weight: 200; color: #224450; text-shadow: 1px 1px 0 #ffffffb3;
    margin-top: 1em;
    &:first-of-type { margin-top: 0; }
  }
  &.is-loading {
    h2, .dashbox, .flipcard, .dashboard-compare {
      visibility: hidden;
      opacity:0;
    }
  }
  h2 {
    transition: 0.5s opacity;
  }
  .dashbox, .flipcard {
    transition: 1s opacity;
  }

  @for $i from 1 through 20 {
    .dashbox, .flipcard {
      &:nth-child(#{$i}) {
        transition-delay: #{0.2 * $i}s;
      }
    }
  }
}

.dashbox__grid {
  display: grid;
  column-gap: 1em;
  row-gap: 1em;
  grid-auto-columns: min-content;
  grid-auto-rows: min-content;

  /* Home Stats */
  #dashbox-total-home-users   { grid-row: 1 / 3; grid-column: 1 / 2; }
  #dashbox-active-home-users  { grid-row: 4 / 5; grid-column: 3 / 4; }
  #dashbox-subscribers        { grid-row: 1 / 3; grid-column: 3 / 4; }
  #dashbox-home-total-readers { grid-row: 3 / 5; grid-column: 1 / 2; }
  #dashbox-home-active-readers{ grid-row: 3 / 4; grid-column: 3 / 4; }
  #dashbox-home-read-time     { grid-row: 1 / 3; grid-column: 2 / 3; }
  #dashbox-home-books-read    { grid-row: 3 / 5; grid-column: 2 / 3; }

  /* School Stats */
  #dashbox-total-schools      { grid-row: 1 / 3; grid-column: 1 / 2; }
  #dashbox-total-teachers     { grid-row: 3 / 5; grid-column: 1 / 2; }
  #dashbox-total-classes      { grid-row: 5 / 7; grid-column: 1 / 2; }
  #dashbox-total-pupils       { grid-row: 7 / 9; grid-column: 1 / 2; }
  #dashbox-school-read-time   { grid-row: 1 / 5; grid-column: 2 / 3; }
  #dashbox-school-books-read  { grid-row: 5 / 9; grid-column: 2 / 3; }

  @media screen and (max-width: 1160px) {
    #dashbox-subscribers, #dashbox-home-active-readers, #dashbox-active-home-users { grid-row: auto; grid-column: auto; }
  }
}

.dashbox__help {
  padding:0.75em;
  background: #3c6394;
  color: white;
  font-family: 'Roboto';
  width:100%;
  height: 100%;
  box-shadow: 2px 2px 3px 0px #c7d5e6;
  i.fa {
    margin-right:0;
  }
}

.dashboard-compare {
  text-align: center;
  font-family: 'Roboto Slab';
  font-size: 1.4em;
  font-weight: 400;
  &__select {
    background: transparent;
    outline: none;
    border-width: 0;
  }
}

</style>
