var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-page" }, [
    _c(
      "div",
      { staticClass: "login-box" },
      [
        _vm._m(0),
        _c("action-messages"),
        _c(
          "div",
          { staticClass: "login-box-body" },
          [
            _c("login-form", {
              on: { validated: _vm.login },
              model: {
                value: _vm.form,
                callback: function($$v) {
                  _vm.form = $$v
                },
                expression: "form"
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: " login-logo" }, [
      _c("a", { attrs: { href: "/" } }, [
        _c("img", {
          staticClass: "logo",
          attrs: { src: "/img/logo.png", alt: "Logo" }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }