<template>
  <form @submit.prevent="validateForm">
    <div class="form-group" :class="{'has-error': errors.first('email')}">
      <input type="email" name="email" class="form-control" placeholder="Email"
             v-model="mutableValue.email"
             v-validate="'required|email'">
      <span v-show="errors.has('email')" class="help-block is-danger">{{ errors.first('email') }}</span>
    </div>
    <div class="form-group" :class="{'has-error': errors.first('password')}">
      <input type="password" name="password" class="form-control" placeholder="Password"
             v-model="mutableValue.password"
             v-validate="'required'">
      <span v-show="errors.has('password')" class="help-block is-danger">{{ errors.first('password') }}</span>
    </div>
    <div class="row">
      <div class="col-xs-4">
        <loading-button class="btn-block btn-flat" :disabled="errors.any()">Sign In</loading-button>
      </div>
    </div>
  </form>
</template>

<script>
import FormMixin from '@/mixins/FormMixin';
import LoadingButton from '@/components/utils/LoadingButton';

export default {
  name: 'login-form',
  props: ['value'],
  mixins: [FormMixin],
  components: {
    LoadingButton,
  },
  computed: {
    mutableValue() {
      return Object.assign(this.value);
    },
  },
};
</script>
