import api from '@/api';
import School from '@/classes/School';

export default ({
  namespaced: true,
  state: () => ({
    schools: [],
    metaData: null,
    currentSchool: null,
    availableTags: [],
    schoolModified: false,
  }),
  mutations: {
    setTableData(state, payload) {
      const { enterprises, meta } = payload;
      state.schools = enterprises;
      state.metaData = meta;
      state.currentSchool = null;
      state.schoolModified = false;
    },
    setCurrentSchool(state, school) {
      if (!school) {
        state.currentSchool = null;
        return;
      }
      state.schoolModified = false;
      state.currentSchool = new School(school);
    },
    setAvailableTags(state, tags) {
      if (!tags) {
        state.availableTags = [];
        return;
      }
      state.availableTags = Object.keys(tags).map((tag) => ({
        code: tag,
        name: tags[tag],
      }));
    },
    setSchoolModified(state, modified) {
      state.schoolModified = modified;
    },
  },
  actions: {
    createSchool(context, data) {
      return new Promise((resolve, reject) => {
        api.post('admin/enterprises/setup', data).then((response) => {
          const { data: school } = response;
          resolve(school);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getSchools({ commit }, params, isFilter = false) {
      return new Promise((resolve, reject) => {
        api.get('admin/enterprises', { params }).then((response) => {
          if (!isFilter) {
            commit('setTableData', response.data);
          }
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getSchoolById({ commit }, id) {
      return new Promise((resolve, reject) => {
        api.get(`admin/enterprises/${id}`).then((response) => {
          const { enterprise } = response.data;
          commit('setCurrentSchool', enterprise);
          resolve(enterprise);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    exportSchools(context, data) {
      return new Promise((resolve, reject) => {
        api.post('admin/enterprises/export', data).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    updateSchool(context, payload) {
      const { id: schoolId, data } = payload;
      return new Promise((resolve, reject) => {
        api.post(`admin/enterprises/${schoolId}`, data).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    updateSchoolContacts(context, payload) {
      const { id: schoolId, data } = payload;
      return new Promise((resolve, reject) => {
        api.post(`admin/enterprises/${schoolId}/contacts`, data).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getAvailableTags({ commit }) {
      return new Promise((resolve, reject) => {
        api.get('admin/enterprises/available_tags').then((response) => {
          const { available_tags: availableTags } = response.data;
          commit('setAvailableTags', availableTags);
          resolve(availableTags);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getAvailablePackages() {
      return new Promise((resolve, reject) => {
        api.get('admin/enterprises/subscriptions/available_packages').then((response) => {
          const { packages: availablePackages } = response.data;
          resolve(availablePackages);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    deleteSchoolById(context, schoolId) {
      return api.delete(`admin/enterprises/${schoolId}`);
    },
    createSetupToken(context, schoolId) {
      return new Promise((resolve, reject) => {
        api.get(`admin/enterprises/${schoolId}/setuptoken`).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getInviteData(context, schoolId) {
      return new Promise((resolve, reject) => {
        api.get(`admin/enterprises/${schoolId}/invites`).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    handleSubChanges(context, payload) {
      const { id: schoolId, data } = payload;
      return new Promise((resolve, reject) => {
        api.post(`admin/enterprises/${schoolId}/subscription`, data).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    setUserAdminStatus(context, payload) {
      const { schoolId, userId, admin } = payload;
      return new Promise((resolve, reject) => {
        api.post(`admin/enterprises/${schoolId}/users/${userId}/setadmin`, { admin }).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    destroyTableState({ commit }) {
      const payload = {
        enterprises: [],
        metaData: null,
      };
      commit('setTableData', payload);
      commit('setAvailableTags', null);
    },
    destroyCurrentSchool({ commit }) {
      commit('setCurrentSchool', null);
    },
    schoolModified({ commit }, modified) {
      commit('setSchoolModified', modified);
    },
  },
});
