import axios from 'axios';
import config from '../config';
import store from '@/store';

axios.defaults.baseURL = config.serverURI;

axios.interceptors.request.use((config) => { // eslint-disable-line no-shadow
  if (store.state.auth.token != null) {
    config.headers['X-Auris-Token'] = store.state.auth.token;
  } else {
    delete config.headers['X-Auris-Token'];
  }
  return config;
});

export default axios;
