var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "schools-page" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-8" }, [_c("create-school-wizard")], 1)
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }