<template>
<div class="user-filters">
  <div class="user-filter user-filter__break" v-if="showChallenges">
    <div class="label-container" v-if="showLabels">
      <label>Challenges:</label>
    </div>

    <select name="challenges" id="challenges" v-model="challenge" @change="onSelect">
      <option value="all">No filter applied</option>
      <option value="none">Outside of challenge</option>
      <optgroup label="Select a Challenge">
        <option v-for="ch in challenges" :value="ch.slug" :key="ch.id">{{ ch.slug | uppercase }}</option>
      </optgroup>
    </select>
</div>
  <div class="user-filter user-filter__break" v-if="showUserType">
    <div class="label-container" v-if="showLabels">
      <label>User Type:</label>
    </div>
    <select name="userType" id="userType" v-model="userType" @change="onSelect">
      <option value="all">All</option>
      <option value="standard">Home user</option>
      <option value="enterprise">Teacher</option>
    </select>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'user-attribute-filters',
  props: {
    cssStyles: String,
    showChallenges: {
      type: Boolean,
      default: true,
    },
    showUserType: {
      type: Boolean,
      default: true,
    },
    showLabels: {
      type: Boolean,
      default: true,
    },
    filteredChallenge: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      userType: 'all',
      challenge: 'all',
    };
  },
  computed: {
    ...mapState('challenges', ['challenges']),
  },
  mounted() {
    if (this.filteredChallenge) {
      this.challenge = this.filteredChallenge;
    }
    if (this.challenges.length === 0) {
      this.$store.dispatch('challenges/getChallenges');
    }
  },
  methods: {
    onSelect() {
      const obj = {};
      if (this.showChallenges) {
        obj.challenge = this.challenge ?? 'all';
      }
      if (this.showUserType) {
        obj.userType = this.userType ?? 'all';
      }
      this.$emit('select', obj);
    },
  },
};
</script>
