var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("wrapper", [
    _c("section", { staticClass: "users-page" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _vm.showTable
            ? _c(
                "div",
                { staticClass: "user-filters" },
                [
                  _c("user-attribute-filters", {
                    attrs: {
                      "filtered-challenge": _vm.filteredChallenge,
                      "css-styles": "display:flex; margin-right: 10px",
                      "show-user-type": false
                    },
                    on: { select: _vm.onFilter }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _vm.showTable
              ? _c(
                  "vue-good-table",
                  {
                    attrs: {
                      styleClass: "vgt-table striped",
                      theme: "auris-admin",
                      mode: "remote",
                      columns: _vm.columns,
                      rows: _vm.rows,
                      totalRows: _vm.totalRecords,
                      "pagination-options": _vm.paginationOptions,
                      "search-options": {
                        enabled: false
                      },
                      "sort-options": {
                        enabled: true
                      },
                      isLoading: _vm.loadingTableData
                    },
                    on: {
                      "on-page-change": _vm.onPageChange,
                      "on-sort-change": _vm.onSortChange,
                      "on-column-filter": _vm.onColumnFilter,
                      "on-per-page-change": _vm.onPerPageChange
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "table-row",
                          fn: function(props) {
                            return [
                              props.column.field === "last_seen_at"
                                ? [
                                    props.formattedRow.last_seen_at
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatDate(
                                                props.formattedRow.last_seen_at,
                                                true
                                              )
                                            )
                                          )
                                        ])
                                      : _c("span", [_vm._v("Never")])
                                  ]
                                : props.column.field === "actions"
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-link user-link",
                                      attrs: { "data-id": props.row.id },
                                      on: {
                                        click: function($event) {
                                          return _vm.goToUserPage($event)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", { staticClass: "fa fa-edit" }),
                                      _vm._v(" Manage")
                                    ]
                                  )
                                : _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          props.formattedRow[props.column.field]
                                        ) +
                                        " "
                                    )
                                  ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      44229615
                    )
                  },
                  [
                    _c("template", { slot: "loadingContent" }, [
                      _c("div", { staticClass: "loadingContent" }, [
                        _c("i", { staticClass: "fa fa-refresh fa-spin" })
                      ])
                    ])
                  ],
                  2
                )
              : _vm._e()
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }