<template>
  <div></div>
</template>

<script>

export default {
  name: 'logout',
  created() {
    this.$store.dispatch('auth/logout').finally(() => {
      this.$router.push('/login');
    });
  },
};
</script>
