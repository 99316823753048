var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "subscribers-report-page" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-2" }, [
        _c("div", { staticClass: "info-box" }, [
          _vm._m(0),
          _c("div", { staticClass: "info-box-content" }, [
            _c("span", { staticClass: "info-box-text" }, [
              _vm._v("New Subscribers")
            ]),
            _c("span", { staticClass: "info-box-number" }, [
              _vm._v(
                _vm._s(
                  _vm.charts.newSubscribers.total("combined") === null
                    ? 0
                    : _vm.charts.newSubscribers.total("combined")
                )
              )
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "col-md-2" }, [
        _c("div", { staticClass: "info-box" }, [
          _vm._m(1),
          _vm.output === "both"
            ? _c("div", { staticClass: "info-box-content" }, [
                _c("span", { staticClass: "info-box-text" }, [
                  _vm._v("Stripe - Single")
                ]),
                _c("span", { staticClass: "info-box-number" }, [
                  _vm._v(_vm._s(_vm.getTotal("stripe - single")))
                ])
              ])
            : _c("div", { staticClass: "info-box-content" }, [
                _c("span", { staticClass: "info-box-text" }, [
                  _vm._v("Stripe")
                ]),
                _c("span", { staticClass: "info-box-number" }, [
                  _vm._v(_vm._s(_vm.getTotal("stripe")))
                ])
              ])
        ])
      ]),
      _c("div", { staticClass: "col-md-2" }, [
        _c("div", { staticClass: "info-box" }, [
          _vm._m(2),
          _vm.output === "both"
            ? _c("div", { staticClass: "info-box-content" }, [
                _c("span", { staticClass: "info-box-text" }, [
                  _vm._v("Stripe - Family")
                ]),
                _c("span", { staticClass: "info-box-number" }, [
                  _vm._v(_vm._s(_vm.getTotal("stripe - family")))
                ])
              ])
            : _c("div", { staticClass: "info-box-content" }, [
                _c("span", { staticClass: "info-box-text" }, [
                  _vm._v("iTunes")
                ]),
                _c("span", { staticClass: "info-box-number" }, [
                  _vm._v(_vm._s(_vm.getTotal("itunes")))
                ])
              ])
        ])
      ]),
      _c("div", { staticClass: "col-md-2" }, [
        _c("div", { staticClass: "info-box" }, [
          _vm._m(3),
          _vm.output === "both"
            ? _c("div", { staticClass: "info-box-content" }, [
                _c("span", { staticClass: "info-box-text" }, [
                  _vm._v("iTunes - Single")
                ]),
                _c("span", { staticClass: "info-box-number" }, [
                  _vm._v(_vm._s(_vm.getTotal("itunes - single")))
                ])
              ])
            : _c("div", { staticClass: "info-box-content" }, [
                _c("span", { staticClass: "info-box-text" }, [
                  _vm._v("Single")
                ]),
                _c("span", { staticClass: "info-box-number" }, [
                  _vm._v(_vm._s(_vm.getTotal("single")))
                ])
              ])
        ])
      ]),
      _c("div", { staticClass: "col-md-2" }, [
        _c("div", { staticClass: "info-box" }, [
          _vm._m(4),
          _vm.output === "both"
            ? _c("div", { staticClass: "info-box-content" }, [
                _c("span", { staticClass: "info-box-text" }, [
                  _vm._v("iTunes - Family")
                ]),
                _c("span", { staticClass: "info-box-number" }, [
                  _vm._v(_vm._s(_vm.getTotal("itunes - family")))
                ])
              ])
            : _c("div", { staticClass: "info-box-content" }, [
                _c("span", { staticClass: "info-box-text" }, [
                  _vm._v("Family")
                ]),
                _c("span", { staticClass: "info-box-number" }, [
                  _vm._v(_vm._s(_vm.getTotal("family")))
                ])
              ])
        ])
      ])
    ]),
    _c("div", { staticClass: "row mt-3" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c(
          "div",
          { staticStyle: { display: "flex", gap: "2em" } },
          [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  gap: "0.5em",
                  "white-space": "nowrap"
                }
              },
              [
                _c("label", [_vm._v("Filter by date:")]),
                _c("DatePickerWithDropdown", {
                  attrs: {
                    reference: "time_period",
                    initialDateRange: _vm.dateRangeModel
                  },
                  on: {
                    "date-range-change": function($event) {
                      return _vm.handleChangeDateRange(
                        $event,
                        "dateRangeUpdated"
                      )
                    }
                  },
                  model: {
                    value: _vm.dateRangeModel,
                    callback: function($$v) {
                      _vm.dateRangeModel = $$v
                    },
                    expression: "dateRangeModel"
                  }
                })
              ],
              1
            ),
            _c("user-attribute-filters", {
              attrs: { "show-user-type": false },
              on: { select: _vm.onFilter }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "chart-output col-md-6" }, [
        _c("ul", [
          _c("li", [_vm._v("Segment by:")]),
          _c(
            "li",
            {
              class: { active: _vm.output === "package" },
              on: {
                click: function($event) {
                  return _vm.choose("package")
                }
              }
            },
            [_vm._v("Package")]
          ),
          _c(
            "li",
            {
              class: { active: _vm.output === "provider" },
              on: {
                click: function($event) {
                  return _vm.choose("provider")
                }
              }
            },
            [_vm._v("Provider")]
          ),
          _c(
            "li",
            {
              class: { active: _vm.output === "both" },
              on: {
                click: function($event) {
                  return _vm.choose("both")
                }
              }
            },
            [_vm._v("Both")]
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "charts-container col-md-12" },
        [_c("chart", { attrs: { chart: _vm.charts.activeSubscribers } })],
        1
      ),
      _c(
        "div",
        { staticClass: "charts-container col-md-12" },
        [
          _c("chart", {
            staticClass: "mr-5",
            attrs: { chart: _vm.charts.newSubscribers }
          }),
          _c("chart", { attrs: { chart: _vm.charts.lostSubscribers } })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "info-box-icon bg-aqua" }, [
      _c("i", { staticClass: "fa fa-user" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "info-box-icon bg-yellow" }, [
      _c("i", { staticClass: "fa fa-flag" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "info-box-icon bg-green" }, [
      _c("i", { staticClass: "fa fa-flag" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "info-box-icon bg-red" }, [
      _c("i", { staticClass: "fa fa-flag" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "info-box-icon bg-fuchsia" }, [
      _c("i", { staticClass: "fa fa-flag" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }