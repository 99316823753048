var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("wrapper", [
    _c(
      "section",
      { staticClass: "schools-page" },
      [
        _vm.modal.isVisible("deleteItem")
          ? _c(
              "modal",
              {
                attrs: {
                  title: "Delete " + _vm.itemToBeDeleted.name,
                  "show-footer": false
                },
                on: { close: _vm.cancelDeleteItem }
              },
              [
                _c("p", { staticClass: "text-danger lead" }, [
                  _vm._v(
                    "This " +
                      _vm._s(_vm.itemToBeDeleted.type) +
                      " will be permanently deleted. "
                  ),
                  _c("br"),
                  _vm._v(" This action is irreversible.")
                ]),
                _c("div", { staticClass: "input-group width-100" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.itemToBeDeleted.inputString,
                        expression: "itemToBeDeleted.inputString"
                      }
                    ],
                    staticClass: "form-control width-100",
                    attrs: {
                      name: "first_name",
                      placeholder:
                        "Enter '" + _vm.itemToBeDeleted.name + "' to confirm",
                      type: "text",
                      autocomplete: "off"
                    },
                    domProps: { value: _vm.itemToBeDeleted.inputString },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.itemToBeDeleted,
                          "inputString",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _c(
                  "div",
                  { staticClass: "modal-footer" },
                  [
                    _vm._t("footer"),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-warning",
                        attrs: { type: "button", "data-dismiss": "modal" },
                        on: { click: _vm.cancelDeleteItem }
                      },
                      [_vm._v("Cancel")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-danger",
                        attrs: {
                          type: "button",
                          "data-dismiss": "modal",
                          disabled:
                            _vm.itemToBeDeleted.inputString !==
                            _vm.itemToBeDeleted.name
                        },
                        on: {
                          click: function($event) {
                            return _vm.deleteItem(_vm.deleteItemApiCall)
                          }
                        }
                      },
                      [_vm._v("Delete Permanently")]
                    )
                  ],
                  2
                )
              ]
            )
          : _vm._e(),
        _c("div", { staticClass: "create-school-button-locator mb-3" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary mr-3",
              on: { click: _vm.exportSchools }
            },
            [
              _vm.isExporting
                ? _c("i", { staticClass: "fa fa-spinner fa-spin" })
                : _vm._e(),
              _vm._v("Export")
            ]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              on: {
                click: function($event) {
                  return _vm.$router.push("/schools/create")
                }
              }
            },
            [_vm._v(" Create new school ")]
          )
        ]),
        _vm.metaData
          ? _c(
              "div",
              { staticClass: "headline-stats mb-5" },
              [
                _c("DashBox", {
                  attrs: {
                    id: "dashbox-total-schools",
                    title: "Total Schools",
                    value: _vm.metaData.total
                  }
                }),
                _c("DashBox", {
                  attrs: {
                    id: "dashbox-subscribed-schools",
                    title: "Subscribed Schools",
                    value: _vm.metaData.num_subscribed
                  }
                }),
                _c("DashBox", {
                  attrs: {
                    id: "dashbox-unsubscribed-schools",
                    title: "Unsubscribed Schools",
                    value: _vm.metaData.num_unsubscribed
                  }
                }),
                _c("DashBox", {
                  attrs: {
                    id: "dashbox-ending-schools",
                    title: "Subscriptions Ending",
                    value: _vm.metaData.num_subscriptions_ending
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12 mb-5" }, [
            _c("div", { staticClass: "user-filters" }, [
              _c(
                "div",
                {
                  staticClass: "user-filter user-filter__break",
                  class: { disabled: !_vm.enabledReadingTimeDateRange }
                },
                [
                  _c("div", { staticClass: "label-container" }, [
                    _c("label", { attrs: { for: "drp-registered-checkbox" } }, [
                      _vm._v("Reading Time Between:")
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-md-5" },
                    [
                      _c("date-range-picker-with-toggle", {
                        attrs: {
                          minDate: new Date(2018, 0, 1),
                          maxDate: _vm.endOfToday,
                          dateRange: _vm.readingTimeDateRange
                        },
                        on: {
                          "date-range-change": function($event) {
                            _vm.readingTimeDateRange = $event
                          },
                          "picker-enabled-change": function($event) {
                            _vm.enabledReadingTimeDateRange = $event
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "user-filter user-filter__break",
                  class: { disabled: !_vm.enabledCreatedOnDateRange }
                },
                [
                  _c("div", { staticClass: "label-container" }, [
                    _c("label", { attrs: { for: "drp-registered-checkbox" } }, [
                      _vm._v("Created On:")
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-md-5" },
                    [
                      _c("date-range-picker-with-toggle", {
                        attrs: {
                          minDate: new Date(2018, 0, 1),
                          maxDate: _vm.endOfToday,
                          dateRange: _vm.createdOnDateRange
                        },
                        on: {
                          "date-range-change": function($event) {
                            _vm.createdOnDateRange = $event
                          },
                          "picker-enabled-change": function($event) {
                            _vm.enabledCreatedOnDateRange = $event
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "user-filter user-filter__break",
                  class: { disabled: !_vm.enabledExpiresOnDateRange }
                },
                [
                  _c("div", { staticClass: "label-container" }, [
                    _c("label", { attrs: { for: "drp-registered-checkbox" } }, [
                      _vm._v("Expires on:")
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-md-5" },
                    [
                      _c("date-range-picker-with-toggle", {
                        attrs: {
                          minDate: new Date(2018, 0, 1),
                          maxDate: _vm.endOfToday,
                          dateRange: _vm.expiresOnDateRange
                        },
                        on: {
                          "date-range-change": function($event) {
                            _vm.expiresOnDateRange = $event
                          },
                          "picker-enabled-change": function($event) {
                            _vm.enabledExpiresOnDateRange = $event
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "user-filter user-filter__break",
                  class: { disabled: !_vm.enabledLatestActivityDateRange }
                },
                [
                  _c("div", { staticClass: "label-container" }, [
                    _c("label", { attrs: { for: "drp-registered-checkbox" } }, [
                      _vm._v("Latest Activity:")
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-md-5" },
                    [
                      _c("date-range-picker-with-toggle", {
                        attrs: {
                          minDate: new Date(2018, 0, 1),
                          maxDate: _vm.endOfToday,
                          dateRange: _vm.latestActivityDateRange
                        },
                        on: {
                          "date-range-change": function($event) {
                            _vm.latestActivityDateRange = $event
                          },
                          "picker-enabled-change": function($event) {
                            _vm.enabledLatestActivityDateRange = $event
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c(
              "div",
              { staticStyle: { display: "flex", gap: "20px" } },
              [
                _c("div", { staticClass: "user-filters" }, [
                  _c("div", { staticClass: "user-filter user-filter__break" }, [
                    _c("div", { staticClass: "label-container" }, [
                      _c("label", [_vm._v("Subscription Status:")])
                    ]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.subscriptionStatus,
                            expression: "subscriptionStatus"
                          }
                        ],
                        attrs: {
                          name: "subscriptionStatus",
                          id: "subscriptionStatus"
                        },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.subscriptionStatus = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      [
                        _c("option", { attrs: { value: "any" } }, [
                          _vm._v("Any")
                        ]),
                        _c("option", { attrs: { value: "subscribed" } }, [
                          _vm._v("Subscribed")
                        ]),
                        _c("option", { attrs: { value: "not_subscribed" } }, [
                          _vm._v("Not Subscribed")
                        ])
                      ]
                    )
                  ])
                ]),
                _c("multiselect", {
                  attrs: {
                    options: _vm.availableTags,
                    multiple: true,
                    label: "name",
                    "track-by": "code",
                    placeholder: "Filter by tags"
                  },
                  model: {
                    value: _vm.selectedSchoolTags,
                    callback: function($$v) {
                      _vm.selectedSchoolTags = $$v
                    },
                    expression: "selectedSchoolTags"
                  }
                })
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c(
                "vue-good-table",
                {
                  attrs: {
                    styleClass: "vgt-table striped",
                    theme: "auris-admin",
                    columns: _vm.columns,
                    rows: _vm.filteredRows,
                    totalRows: _vm.totalRecords,
                    "pagination-options": {
                      enabled: true,
                      mode: "records",
                      perPage: 10,
                      position: "bottom",
                      perPageDropdown: [10, 25, 50, 100],
                      dropdownAllowAll: false,
                      setCurrentPage: 1,
                      nextLabel: "next",
                      prevLabel: "prev",
                      rowsPerPageLabel: "Rows per page",
                      ofLabel: "of",
                      pageLabel: "page", // for 'pages' mode
                      allLabel: "All"
                    },
                    "search-options": {
                      enabled: true,
                      trigger: "key-up",
                      skipDiacritics: true,
                      placeholder: "Search this table"
                    },
                    "sort-options": {
                      enabled: true,
                      initialSortBy: { field: "name", type: "asc" }
                    },
                    isLoading: _vm.isLoading
                  },
                  on: {
                    "update:isLoading": function($event) {
                      _vm.isLoading = $event
                    },
                    "update:is-loading": function($event) {
                      _vm.isLoading = $event
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "table-row",
                      fn: function(props) {
                        return [
                          props.column.field == "actions"
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-link user-link",
                                  attrs: { "data-id": props.row.id },
                                  on: {
                                    click: function($event) {
                                      return _vm.goToSchoolPage($event)
                                    }
                                  }
                                },
                                [
                                  _c("i", { staticClass: "fa fa-edit" }),
                                  _vm._v(" Manage")
                                ]
                              )
                            : _vm._e(),
                          props.column.field == "actions"
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-link delete",
                                  attrs: { "data-id": props.row.id },
                                  on: {
                                    click: function($event) {
                                      return _vm.beginDeleteItem({
                                        name: props.row.name,
                                        id: props.row.id,
                                        type: "school"
                                      })
                                    }
                                  }
                                },
                                [
                                  _c("i", { staticClass: "fa fa-trash" }),
                                  _vm._v("Delete")
                                ]
                              )
                            : _vm._e(),
                          props.column.field == "subscription.expires_at"
                            ? _c("span", { staticClass: "wrap" }, [
                                _c(
                                  "span",
                                  { class: _vm.getCellClass(props.row) },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          props.formattedRow[props.column.field]
                                            ? null
                                            : "N/A"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          props.column.field == "latest_activity"
                            ? _c("span", { staticClass: "wrap" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.timeAgo(props.row.latest_activity)
                                    )
                                ),
                                _c("br"),
                                _c("small", [
                                  _c("i", [
                                    _vm._v(
                                      _vm._s(
                                        props.formattedRow[
                                          props.column.latest_activity
                                        ]
                                      )
                                    )
                                  ])
                                ])
                              ])
                            : props.column.field == "latest_reader_activity"
                            ? _c("span", { staticClass: "wrap" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.timeAgo(
                                        props.row.latest_reader_activity
                                      )
                                    )
                                ),
                                _c("br"),
                                _c("small", [
                                  _c("i", [
                                    _vm._v(
                                      _vm._s(
                                        props.formattedRow[
                                          props.column.latest_reader_activity
                                        ]
                                      )
                                    )
                                  ])
                                ])
                              ])
                            : props.column.field ==
                              "reading_time.total_reading_time"
                            ? _c("span", { staticClass: "wrap" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatTime(
                                        props.row.reading_time
                                          .total_reading_time
                                      )
                                    )
                                ),
                                _c("br"),
                                _c("small", [
                                  _c("i", { staticClass: "fa fa-user" })
                                ]),
                                _vm._v(
                                  _vm._s(props.row.reading_time.readers_count) +
                                    " "
                                )
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      props.formattedRow[props.column.field]
                                    ) +
                                    " "
                                )
                              ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("template", { slot: "loadingContent" }, [
                    _c("div", { staticClass: "loadingContent" }, [
                      _c("i", { staticClass: "fa fa-refresh fa-spin" })
                    ])
                  ])
                ],
                2
              )
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }