import api from '@/api';

export default {
  computed: {
    isSuperAdmin() {
      const { user } = this.$store.state;
      if (user && user.allowed_access) {
        return user.allowed_access.is_super_admin;
      }

      return false;
    },
  },
  methods: {
    // getUser(id, includes = null) {
    //   return new Promise((resolve) => {
    //     let url = `admin/users/${id}`;
    //     if (includes) {
    //       url = `admin/users/${id}?include=${includes}`;
    //     }
    //     api.get(url)
    //       .then((response) => {
    //         resolve(response.data.user);
    //       })
    //       .catch((error) => {
    //         this.$apiResponse.renderErrorMessage(error);
    //       });
    //   });
    // },
    changeBetaAccessStatus(id, action) {
      return new Promise((resolve) => {
        api.post(`admin/users/${id}/beta_access`, { action })
          .then((response) => {
            resolve(response.data.user);
          })
          .catch((error) => {
            this.$apiResponse.renderErrorMessage(error);
          });
      });
    },
    updateUser(id, data, includes = null) {
      return new Promise((resolve) => {
        let url = `admin/users/${id}`;
        if (includes) {
          url = `admin/users/${id}?include=${includes}`;
        }
        api.post(url, data)
          .then((response) => {
            resolve(response.data.user);
          })
          .catch((error) => {
            this.$apiResponse.renderErrorMessage(error);
          });
      });
    },
  },
};
