import _ from 'lodash';

export default class Modal {
  constructor(modals) {
    this.modals = modals;
  }

  show(modal) {
    this.modals[_.camelCase(modal)] = true;
  }

  hide(modal) {
    this.modals[_.camelCase(modal)] = false;
  }

  isVisible(modal) {
    return this.modals[_.camelCase(modal)];
  }
}
