<template>
  <div class="chart-box-wrapper">
    <div class="chart-box">
      <div :class="'apexchart-' + chart.name"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'chart',
  props: {
    chart: {
      required: true,
    },
  },
};
</script>

<style>
  @import url('../assets/scss/main.scss');
</style>
