<template>
  <div class="login-page">
    <div class="login-box">
      <div class=" login-logo">
        <a href="/"><img src="/img/logo.png" alt="Logo" class="logo"></a>
      </div>
      <action-messages></action-messages>
      <div class="login-box-body">
        <!-- <p class="login-box-msg">Sign in to start your sessions</p> -->
        <login-form v-model="form" @validated="login"></login-form>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
// import Form from '@/classes/Form';
import LoginForm from '@/components/forms/LoginForm';
import LogoutMixin from '@/mixins/LogoutMixin';

export default {
  name: 'login',
  mixins: [LogoutMixin],
  components: {
    LoginForm,
    'login-form': LoginForm,
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
        is_admin: true,
      },
    };
  },
  mounted() {
    const $html = $('html');
    $html.addClass('login-page');
  },
  methods: {
    login() {
      this.$store.dispatch('app/setGlobalLoading', true);
      this.$store.dispatch('auth/login', this.form).then(() => {
        this.$router.push('/');
      }).catch((error) => {
        this.$store.dispatch('app/setGlobalLoading', false);
        this.$apiResponse.renderErrorMessage(error, error.message);
      });
      // this.form.post('auth')
      //   .then((data) => {
      //     if (data.user) {
      //       this.$store.commit('SET_USER', data.user);
      //       this.$store.commit('SET_TOKEN', data.meta.token);
      //       this.$store.commit('SET_ACCOUNT_TYPE', data.meta.account_type);
      //       if (window.localStorage) {
      //         window.localStorage.setItem('user', JSON.stringify(data.user));
      //         window.localStorage.setItem('token', data.meta.token);
      //         window.localStorage.setItem('account_type', data.meta.account_type);
      //       }
      //     }

      //     this.$router.push('/');
      //   })
      //   .catch((error) => {
      //     this.$store.dispatch('app/setGlobalLoading', false);
      //     this.$apiResponse.renderErrorMessage(error, error.error === 'Login information not recognised' ? 'Email / Password incorrect. Please try again.' : null);
      //   });
    },
  },
};
</script>
