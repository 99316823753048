<template>
  <wrapper>
    <section class="promocode-page">
      <div class="row">
        <div class="col-md-12">
          <div class="text-right">
            <button class="btn btn-primary" @click="$router.push('/promocodes')">Back to overview</button>
          </div>
        </div>
      </div>
      <template v-if="promocode">
        <div class="row">
          <div class="col-md-6">
            <div class="box box-info">
              <div class="box-header">
                <h3 class="box-title">General</h3>
              </div>
              <div class="box-body">
                <table class="table table-striped">
                  <tbody>
                  <tr>
                    <th>Description</th>
                    <td>{{ promocodePrettyDescription }}</td>
                  </tr>
                  <tr v-if="promocode.type !== 'free_trial'">
                    <th>Discount Value</th>
                    <td>{{ promocode.discount_value }}</td>
                  </tr>
                  <tr v-if="displayOnlyFor('subscription')">
                    <th>Duration Length</th>
                    <td>{{ promocode.duration_length }}</td>
                  </tr>
                  <tr v-if="displayOnlyFor('subscription')">
                    <th>Duration Unit</th>
                    <td>{{ promocode.duration_unit }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="box box-info">
              <div class="box-header">
                <h3 class="box-title">Restrictions</h3>
              </div>
              <div class="box-body">
                <table class="table table-striped">
                  <tbody>
                  <tr>
                    <th>Valid From</th>
                    <td>{{ formatDate(promocode.valid_from) }}</td>
                  </tr>
                  <tr>
                    <th>Valid Until</th>
                    <td>{{ formatDate(promocode.valid_until) }}</td>
                  </tr>
                  <tr>
                    <th>Pricing Options</th>
                    <td v-html="formatPricingOptions(promocode.valid_options)"></td>
                  </tr>
                  <tr v-if="displayOnlyFor('subscription')">
                    <th>Min User Created At</th>
                    <td>{{ formatDate(promocode.min_user_created_at) }}</td>
                  </tr>
                  <tr v-if="displayOnlyFor('subscription')">
                    <th>Min Subscription Created At</th>
                    <td>{{ formatDate(promocode.min_subscription_created_at) }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="box box-info">
              <div class="box-header">
                <h3 class="box-title">Usage limits</h3>
              </div>
              <div class="box-body">
                <table class="table table-striped">
                  <tbody>
                  <tr>
                    <th>Max Uses Global</th>
                    <td>{{ promocode.max_uses_global }}</td>
                  </tr>
                  <tr>
                    <th>Max Uses Per User</th>
                    <td>{{ promocode.max_uses_per_user }}</td>
                  </tr>
                  <tr v-if="displayOnlyFor('subscription')">
                    <th>First Time Subscribers Only</th>
                    <td>{{ formatBool(promocode.only_first_time_subscribers) }}</td>
                  </tr>
                  <tr v-if="displayOnlyFor('subscription')">
                    <th>Exclude Current Subscribers</th>
                    <td>{{ formatBool(promocode.exclude_current_subscribers) }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="box box-info">
              <div class="box-header">
                <h3 class="box-title">Web links</h3>
              </div>
              <div class="box-body">
                <div><strong>Pretty URL</strong></div>
                <div><span class="lead">{{promotionCodePrettyUrl(promocode)}}</span> &nbsp; &nbsp; <a :href="promotionCodePrettyUrl(promocode)" :title="promotionCodePrettyUrl(promocode)" target="_blank"><i class="fa fa-external-link"></i></a></div>
                <div><em>Use this when sharing the link in plain text such as on Twitter or Facebook.</em></div>
                <p>&nbsp;</p>
                <div><strong>Effective URL</strong> &nbsp; &nbsp; <span class="label label-success">Preferred</span></div>
                <div><span class="lead">{{promotionCodeRealUrl(promocode)}}</span> &nbsp; &nbsp; <a :href="promotionCodeRealUrl(promocode)" :title="promotionCodeRealUrl(promocode)" target="_blank"><i class="fa fa-external-link"></i></a></div>
                <div><em>Use this whenever the link is not visible, such as in QR codes, Buttons or URL minifiers.</em></div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </section>
  </wrapper>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import api from '@/api';
import Wrapper from '@/components/Wrapper';
import PromocodeMixin from '@/mixins/PromocodeMixin';
import HelpersMixin from '@/mixins/HelpersMixin';
import { mapState } from 'vuex';

export default {
  name: 'promocode',
  components: {
    Wrapper,
  },
  mixins: [PromocodeMixin, HelpersMixin],
  data() {
    return {
      promocode: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getPromocodeData();
    });
  },
  methods: {
    getPromocodeData() {
      api.get(`admin/promocodes/${this.$route.params.id}`)
        .then((result) => {
          this.promocode = result.data.promocode;
        });
    },
    formatDate(date) {
      if (date === null) {
        return '';
      }
      return moment(date).format('MMM D, YYYY');
    },
    formatBool(value) {
      if (value === 1) {
        return 'Yes';
      }

      return 'No';
    },
    formatPricingOptions(values) {
      let html = '<ul>';
      html += '</ul>';
      _.each(values, (value) => {
        html += `<li>${this.textifyPricingOption(value)}</li>`;
      });
      return html;
    },
    displayOnlyFor(type) {
      return type === this.promocode.target_type;
    },
  },
  computed: {
    ...mapState({
      usersVuex: (state) => state.users.users,
      totalVuex: (state) => state.users.totalRecords,
      serverParams: (state) => state.users.serverParams,
      tableLoaded: (state) => state.users.tableLoaded,
    }),
  },
};
</script>
