var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.errorMsg || _vm.successMsg
    ? _c("div", { staticClass: "action-messages" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errorMsg,
                expression: "errorMsg"
              }
            ],
            staticClass: "alert alert-danger",
            attrs: { role: "alert" }
          },
          [
            _c("i", { staticClass: "fa fa-exclamation-triangle" }),
            _vm._v(" "),
            _c("span", { domProps: { innerHTML: _vm._s(_vm.errorMsg) } })
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.successMsg,
                expression: "successMsg"
              }
            ],
            staticClass: "alert alert-success",
            attrs: { role: "alert" }
          },
          [
            _c("i", { staticClass: "fa fa-check" }),
            _vm._v(" "),
            _c("span", { domProps: { innerHTML: _vm._s(_vm.successMsg) } })
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }