<template>
  <wrapper>
    <section class="users-page">
      <div class="row">
        <div class="col-md-12">
          <div class="user-filters" v-if="showTable">
            <user-attribute-filters :filtered-challenge="filteredChallenge" @select="onFilter" v-bind:css-styles="'display:flex; margin-right: 10px'" :show-user-type="false"></user-attribute-filters>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <vue-good-table
              v-if="showTable"
              styleClass="vgt-table striped"
              theme="auris-admin"
              mode="remote"
              :columns="columns"
              :rows="rows"
              :totalRows="totalRecords"
              :pagination-options="paginationOptions"
              :search-options="{
                enabled: false,
              }"
              :sort-options="{
                  enabled: true,
              }"
              :isLoading="loadingTableData"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
          >
          <template slot="table-row" slot-scope="props">
            <template v-if="props.column.field === 'last_seen_at'" class="wrap">
              <span v-if="props.formattedRow.last_seen_at">{{ formatDate(props.formattedRow.last_seen_at, true) }}</span>
              <span v-else>Never</span>
            </template>
            <button v-else-if="props.column.field === 'actions'" @click="goToUserPage($event)" class="btn btn-link user-link" :data-id="props.row.id"><i class="fa fa-edit"></i> Manage</button>
            <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>
          </template>
          <template slot="loadingContent">
            <div class="loadingContent">
                <i class="fa fa-refresh fa-spin"></i>
            </div>
          </template>
          </vue-good-table>
        </div>
      </div>
    </section>
  </wrapper>
</template>

<script>
import $ from 'jquery';
import { mapGetters, mapState } from 'vuex';
import { debounce, findIndex } from 'lodash';
import Wrapper from '@/components/Wrapper';
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';
import UserAttributeFilters from '@/components/UserAttributeFilters';
import HelpersMixin from '@/mixins/HelpersMixin';

export default {
  name: 'users',
  mixins: [HelpersMixin],
  components: {
    VueGoodTable,
    Wrapper,
    UserAttributeFilters,
  },
  data() {
    return {
      isLoading: false,
      previousSearchQuery: null,
      searchPerformed: false,
      loadingTableData: false,
      loadingTableState: false,
      showTable: false,
      prevRoute: null,
      paginationOptions: {
        enabled: true,
        perPage: 20,
        setCurrentPage: 1,
      },
      users: [],
      query: {},
      // excludeAuris: false,
      columns: [
        {
          label: 'Name',
          field: 'full_name',
          filterOptions: {
            enabled: true,
            customFilter: true,
          },
        },
        {
          label: 'Email',
          field: 'email',
          // tdClass: 'sensitive',
          filterOptions: {
            enabled: true,
            customFilter: true,
          },
        },
        {
          label: 'Registered',
          field: 'created_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'do LLLL yyyy',
          width: '180px',
        },
        {
          label: 'Last Seen',
          field: 'last_seen_at',
          width: '200px',
          filterOptions: {
            enabled: true,
            placeholder: 'Both',
            filterDropdownItems: [{ value: 'some', text: 'Has Been Seen' }, { value: 'never', text: 'Never Been Seen' }],
          },
        },
        {
          label: 'Subscription',
          field: 'subscription',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Any',
            filterDropdownItems: [{ value: 'subscribed', text: 'Subscribed' }, { value: 'not_subscribed', text: 'Not Subscribed' }],
          },
        },
        {
          label: 'Type',
          field: 'user_type',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Any',
            filterDropdownItems: [{ value: 'enterprise', text: 'Teacher' }, { value: 'standard', text: 'Parent' }],
          },
        },
        {
          label: 'Actions',
          field: 'actions',
          tdClass: 'details-control',
          sortable: false,
        },
      ],
      filteredChallenge: 'all',
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'User') {
      this.$store.dispatch('users/destroyTableState');
    }
    next(true);
  },
  mounted() {
    this.loadTableState();
    this.$store.commit('SET_CURRENT_USER', null);
    this.$store.commit('SET_CURRENT_SCHOOL', null);
    this.$store.dispatch('app/setGlobalLoading', true);

    this.$nextTick(() => {
      // Start the view with the table hidden, then show it. This delays the creation of the component.
      // Needed because I couldn't set the pagination properties early enough in the execution flow so that VGT would pick it up
      // Needing to only set these pagination settings when prevRoute is a single recording view causes this problem as beforeRouteEnter fires after created() and the VGT pagination init
      this.showTable = true;
    });
  },
  methods: {
    onFilter(e) {
      if (e.challenge === 'all') {
        this.updateParams({ page: 1, challenge: 'all' });
      } else {
        this.updateParams({ challenge: e.challenge, page: 1 });
      }
    },
    onSearch: debounce(function (e) {
      if (e.searchTerm !== this.previousSearchQuery && e.searchTerm !== '') {
        this.searchPerformed = true;
        this.updateParams({ search: e.searchTerm, page: 1 });
      } else {
        this.searchPerformed = false;
      }
      this.previousSearchQuery = e.searchTerm;
    }, 500),
    updateParams(newProps) {
      this.$store.dispatch('users/setServerParams', newProps);
      this.loadItems();
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
    },
    onSortChange(params) {
      this.updateParams({
        sort: params,
        page: 1,
      });
    },
    onColumnFilter(params) {
      this.updateParams({ ...params, page: 1 });
    },
    loadItems() {
      this.loadingTableData = true;
      this.isLoading = true;
      this.$store.dispatch('users/fetchUsers', this.serverParams).catch((err) => {
        this.$apiResponse.renderErrorMessage(err);
        throw err;
      }).finally(() => {
        this.isLoading = false;
        this.loadingTableData = false;
      });
    },
    goToUserPage(e) {
      const userId = $(e.currentTarget).data('id');
      this.$router.push(`/users/${userId}`);
    },
    attachEventToUserButton() {
      $('.user-link').on('click', this.goToUserPage);
    },
    loadTableState() {
      this.loadingTableData = false;
      if (!this.tableLoaded) {
        this.loadItems();
      } else {
        this.filteredChallenge = this.serverParams.challenge ?? 'all';

        // Custom filters
        Object.keys(this.serverParams.columnFilters || {}).forEach((f) => {
          const foundIndex = findIndex(this.columns, { field: f });
          if (foundIndex) {
            this.$set(this.columns[foundIndex].filterOptions, 'filterValue', this.serverParams.columnFilters[f]);
          }
        });
        // Paging
        if (this.serverParams.perPage) {
          this.$set(this.paginationOptions, 'perPage', this.serverParams.perPage);
        }
        if (this.serverParams.page) {
          this.$set(this.paginationOptions, 'setCurrentPage', this.serverParams.page);
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      usersTableState: 'getUsersTableState',
    }),
    ...mapState({
      rows: (state) => state.users.users,
      totalRecords: (state) => state.users.totalRecords,
      serverParams: (state) => state.users.serverParams,
      tableLoaded: (state) => state.users.tableLoaded,
    }),
  },
};
</script>
