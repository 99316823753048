var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("f-navbar"),
      _c("f-sidebar"),
      _c("div", { staticClass: "content-wrapper" }, [
        !_vm.$route.meta.noPageTitle
          ? _c("section", { staticClass: "content-header" }, [
              _c("h1", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.pageHeading ||
                        _vm.$route.meta.title ||
                        _vm.$route.name.toUpperCase()
                    ) +
                    " "
                ),
                _c("small", [_vm._v(_vm._s(_vm.$route.meta.description))])
              ])
            ])
          : _vm._e(),
        _c(
          "section",
          { staticClass: "content" },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [_c("action-messages")],
                1
              )
            ]),
            _vm._t("default")
          ],
          2
        )
      ]),
      _c("f-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }