var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-filters" }, [
    _vm.showChallenges
      ? _c("div", { staticClass: "user-filter user-filter__break" }, [
          _vm.showLabels
            ? _c("div", { staticClass: "label-container" }, [
                _c("label", [_vm._v("Challenges:")])
              ])
            : _vm._e(),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.challenge,
                  expression: "challenge"
                }
              ],
              attrs: { name: "challenges", id: "challenges" },
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.challenge = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.onSelect
                ]
              }
            },
            [
              _c("option", { attrs: { value: "all" } }, [
                _vm._v("No filter applied")
              ]),
              _c("option", { attrs: { value: "none" } }, [
                _vm._v("Outside of challenge")
              ]),
              _c(
                "optgroup",
                { attrs: { label: "Select a Challenge" } },
                _vm._l(_vm.challenges, function(ch) {
                  return _c(
                    "option",
                    { key: ch.id, domProps: { value: ch.slug } },
                    [_vm._v(_vm._s(_vm._f("uppercase")(ch.slug)))]
                  )
                }),
                0
              )
            ]
          )
        ])
      : _vm._e(),
    _vm.showUserType
      ? _c("div", { staticClass: "user-filter user-filter__break" }, [
          _vm.showLabels
            ? _c("div", { staticClass: "label-container" }, [
                _c("label", [_vm._v("User Type:")])
              ])
            : _vm._e(),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userType,
                  expression: "userType"
                }
              ],
              attrs: { name: "userType", id: "userType" },
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.userType = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.onSelect
                ]
              }
            },
            [
              _c("option", { attrs: { value: "all" } }, [_vm._v("All")]),
              _c("option", { attrs: { value: "standard" } }, [
                _vm._v("Home user")
              ]),
              _c("option", { attrs: { value: "enterprise" } }, [
                _vm._v("Teacher")
              ])
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }