<template>
  <button :class="cssClasses" :disabled="loading || isDisabled" @click="$emit('click')">
    <span v-if="loading"><i class="fa fa-spinner fa-spin"></i></span>
    <slot></slot>
  </button>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'LoadingButton',
  props: {
    cssClasses: {
      type: String,
      default: 'btn btn-primary',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('app', {
      isGloballyLoading: (state) => state.isLoading,
    }),
    loading() {
      return this.isLoading || this.isGloballyLoading;
    },
  },
};
</script>

<style>
.fa {
  margin-right: 10px;
}
</style>
