import Vue from 'vue';
import _ from 'lodash';
import VeeValidate, { Validator } from 'vee-validate';
import FloatingVue from 'floating-vue';
import APIResponse from '@/plugins/APIResponse';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowCircleDown, faArrowCircleUp, faBook, faBookOpen, faEdit, faUser, faUserSecret, faTrash, faExchangeAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import router from '@/router';
import store from '@/store';
import App from '@/App';
import CountryFlag from 'vue-country-flag';
import './assets/scss/main.scss';
import 'floating-vue/dist/style.css';
import validationDictionary from '@/config/validation_dictionary';

library.add(faUserSecret);
library.add(faUser);
library.add(faArrowCircleUp);
library.add(faArrowCircleDown);
library.add(faBook);
library.add(faBookOpen);
library.add(faEdit);
library.add(faTrash);
library.add(faExchangeAlt);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;

Vue.use(FloatingVue);
Vue.component(VueQrcode.name, VueQrcode);

Vue.component('country-flag', CountryFlag);

Vue.filter('uppercase', (value) => {
  if (!value) return '';
  return value.toUpperCase();
});

Vue.use(APIResponse, { router });
Vue.use(VeeValidate);
Validator.localize(validationDictionary);

router.beforeEach((to, from, next) => {
  let { authorize } = to.meta;
  const { isSuperAdmin } = to.meta;
  const parent = to.matched.find((record) => record.meta.authorize);
  if (!authorize && parent) {
    authorize = parent.meta.authorize;
  }
  const currentUser = router.app.$store.state.auth.user;
  if (to.matched.some((record) => record.meta.requiresAuth) && (_.isEmpty(router.app.$store.state.auth.token) || router.app.$store.state.auth.accountType !== 'admin')) {
    next({
      path: '/login',
      query: { redirect: to.fullPath },
    });
  } else if (to.matched.some((record) => record.meta.guestsOnly) && !_.isEmpty(router.app.$store.state.auth.token) && router.app.$store.state.auth.accountType === 'admin') {
    next({
      path: '/',
      query: { redirect: to.fullPath },
    });
  } else if (authorize) {
    if (currentUser && currentUser.allowed_access && currentUser.allowed_access.categories
        && (currentUser.allowed_access.categories.some(function (x) { // eslint-disable-line
          return authorize.includes(x);
        })
            || currentUser.allowed_access.categories.includes('all'))
    ) {
      next();
    } else {
      next('/');
    }
  } else if (isSuperAdmin) {
    if (currentUser && currentUser.allowed_access && currentUser.allowed_access.is_super_admin) {
      next();
    } else {
      next('/');
    }
  } else {
    next();
  }
});

// Null out the page title on every route change
router.beforeEach((to, from, next) => {
  router.app.$store.commit('SET_PAGE_HEADING_TITLE', null);
  next();
});

// Check local storage to handle refreshes
if (window.localStorage) {
  const localUserString = window.localStorage.getItem('user') || 'null';
  const localUser = JSON.parse(localUserString);
  const currentSchoolString = window.localStorage.getItem('current_school') || null;

  if (localUser && store.state.auth.user !== localUser) {
    store.dispatch('auth/restoreAuth', {
      user: localUser,
      token: window.localStorage.getItem('token'),
      accountType: window.localStorage.getItem('account_type'),
    });
  }

  if (currentSchoolString) {
    store.commit('SET_CURRENT_SCHOOL', JSON.parse(currentSchoolString));
  }
}

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount('#app');
