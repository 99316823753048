<template>
  <wrapper>
  <!-- Main content -->
    <section class="account-page">
      <div class="row">
        <div class="col-md-12">
          <div class="box box-info">
            <!-- Input Addons -->
            <div class="box-header with-border">
              <h3 class="box-title">Edit admin</h3>
            </div>
            <div class="box-body">
              <account-form v-model="form" @validated="updateAccount" :apiErrors="apiErrors"></account-form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </wrapper>
  <!-- /.content -->
</template>

<script>
import Wrapper from '@/components/Wrapper';
import Form from '@/classes/Form';
import AdminForm from '@/components/forms/AdminForm';
import { mapState } from 'vuex';

export default {
  name: 'account',
  components: {
    // AdminForm,
    'account-form': AdminForm,
    Wrapper,
  },
  data() {
    return {
      section: 'Account',
      // admin: null,
      form: new Form({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        allowed_access: { categories: {}, is_super_admin: false },
      }),
      apiErrors: null,
    };
  },
  computed: {
    ...mapState({
      admin: (state) => state.admins.activeAdmin,
    }),
  },
  mounted() {
    this.$nextTick(() => {
      const { id } = this.$route.params;
      this.$store.dispatch('admins/getAdminById', id).then(() => {
        this.form.first_name = this.admin.first_name;
        this.form.last_name = this.admin.last_name;
        this.form.email = this.admin.email;
        const allowedAccess = this.admin.allowed_access;
        const formattedAllowedAccess = { categories: {}, is_super_admin: allowedAccess.is_super_admin };
        if (allowedAccess && allowedAccess.categories) {
          // eslint-disable-next-line array-callback-return
          allowedAccess.categories.forEach((cat) => {
            formattedAllowedAccess.categories[cat] = true;
          });
        }
        this.form.allowed_access = formattedAllowedAccess;
      });
    });
  },
  methods: {
    updateAccount() {
      this.$store.dispatch('app/setGlobalLoading', true);

      if (!this.form.password || this.form.password.trim().length === 0) {
        delete this.form.password;
      }

      const temp = this.form.allowed_access.categories;
      this.form.allowed_access.categories = Object.keys(this.form.allowed_access.categories).filter((x) => this.form.allowed_access.categories[x]);

      this.form.post(`admin/admins/${this.admin.id}`)
        .then((response) => {
          this.$store.dispatch('app/setGlobalLoading', false);
          this.$apiResponse.renderSuccessMessage(response, 'Admin details updated');
          this.form.allowed_access.categories = temp;
        })
        .catch((error) => {
          if (error.errors) {
            const entries = Object.entries(error.errors);
            this.apiErrors = entries;
            this.$apiResponse.renderErrorMessage(error, 'An error has occurred please see below.');
          } else {
            this.$apiResponse.renderErrorMessage(error);
          }
          this.$store.dispatch('app/setGlobalLoading', false);
        });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'Admins') {
      this.$store.dispatch('admins/destroyTableState');
    }
    next(true);
  },
};
</script>
