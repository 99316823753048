import APIResponse from './APIResponse';
import ActionMessages from './components/ActionMessages';

export default {
  install(Vue, options) {
    const APIResponseEventBus = new Vue({
      data: {
        errorMsg: null,
        successMsg: null,
      },
    });
    window.APIResponseEventBus = APIResponseEventBus;

    const apiResponse = new APIResponse(Vue, options);
    apiResponse.init(APIResponseEventBus);
    Vue.prototype.$apiResponse = apiResponse;
    Vue.component('action-messages', ActionMessages);
  },
};
