<template>
  <wrapper>
    <section class="reports-page">
      <router-view></router-view>
    </section>
  </wrapper>
</template>

<script>
import Wrapper from '@/components/Wrapper';

export default {
  name: 'reports',
  components: {
    Wrapper,
  },
};
</script>
