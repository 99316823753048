var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("wrapper", [
    _c(
      "section",
      { staticClass: "recordings-page" },
      [
        _c(
          "div",
          { staticClass: "flex justify-content-between top-filters" },
          [
            _c("user-attribute-filters", {
              attrs: { "show-user-type": false },
              on: { select: _vm.onChallengeFilter }
            }),
            _c("school-attribute-filters", {
              staticClass: "ml-5",
              on: { select: _vm.onSchoolFilter }
            }),
            _c("div", { staticClass: "user-filters ml-5" }, [
              _c(
                "div",
                { staticClass: "user-filter user-filter__break" },
                [
                  _c("div", { staticClass: "label-container" }, [
                    _c("label", [_vm._v("Tags:")])
                  ]),
                  _c("multiselect", {
                    attrs: {
                      placeholder: "Search for a tag",
                      options: _vm.filterTagOptions,
                      multiple: true
                    },
                    on: { input: _vm.loadItems },
                    model: {
                      value: _vm.serverParams.filterTags,
                      callback: function($$v) {
                        _vm.$set(_vm.serverParams, "filterTags", $$v)
                      },
                      expression: "serverParams.filterTags"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "user-filters ml-5" }, [
              _c("div", { staticClass: "user-filter user-filter__break" }, [
                _c("div", { staticClass: "label-container" }, [
                  _c("label", [_vm._v("Transcription status:")])
                ]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.serverParams.transcriptionStatus,
                        expression: "serverParams.transcriptionStatus"
                      }
                    ],
                    attrs: { name: "transcriptionStatus" },
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.serverParams,
                            "transcriptionStatus",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.loadItems
                      ]
                    }
                  },
                  [
                    _c("option", { attrs: { value: "any" } }, [_vm._v("Any")]),
                    _c("option", { attrs: { value: "transcribed" } }, [
                      _vm._v("Transcribed")
                    ]),
                    _c("option", { attrs: { value: "not_transcribed" } }, [
                      _vm._v("Not Transcribed")
                    ]),
                    _c("option", { attrs: { value: "pending" } }, [
                      _vm._v("Marked for Transcription")
                    ])
                  ]
                )
              ])
            ]),
            _c(
              "div",
              { staticClass: "transcription-filters user-filters ml-5" },
              [
                _c("div", { staticClass: "label-container" }, [
                  _c("label", { attrs: { for: "transcribed" } }, [
                    _vm._v("Hide Transcribed Recordings:")
                  ])
                ]),
                _c("input", {
                  attrs: {
                    type: "number",
                    name: "transcibed",
                    id: "transcribed",
                    min: "0"
                  },
                  on: { change: _vm.onHideTranscribedChanged }
                })
              ]
            ),
            _c(
              "div",
              { staticClass: "transcription-filters user-filters ml-5" },
              [
                _c("div", { staticClass: "label-container" }, [
                  _c("label", { attrs: { for: "transcribed" } }, [
                    _vm._v("Hide if shorter than (secs):")
                  ])
                ]),
                _c("input", {
                  attrs: {
                    type: "number",
                    name: "transcibed",
                    id: "transcribed",
                    min: "0"
                  },
                  on: { change: _vm.onHideShorterThan }
                })
              ]
            )
          ],
          1
        ),
        _vm.showTable
          ? _c(
              "vue-good-table",
              {
                attrs: {
                  styleClass: "vgt-table striped",
                  theme: "auris-admin",
                  mode: "remote",
                  columns: _vm.columns,
                  rows: _vm.rows,
                  totalRows: _vm.totalRecords,
                  "pagination-options": _vm.paginationOptions,
                  "search-options": {
                    enabled: false
                  },
                  "sort-options": {
                    enabled: true,
                    initialSortBy: _vm.serverParams.sort[0]
                  },
                  isLoading: _vm.loadingTableData
                },
                on: {
                  "on-page-change": _vm.onPageChange,
                  "on-sort-change": _vm.onSortChange,
                  "on-column-filter": _vm.onColumnFilter,
                  "on-per-page-change": _vm.onPerPageChange,
                  "on-search": _vm.onSearchChange
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "table-row",
                      fn: function(props) {
                        return [
                          props.column.field == "actions"
                            ? _c(
                                "router-link",
                                {
                                  attrs: { to: "/recordings/" + props.row.id }
                                },
                                [
                                  _c("i", { staticClass: "fa fa-eye" }),
                                  _vm._v("view")
                                ]
                              )
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "column-filter",
                      fn: function(props) {
                        return [
                          props.column.filterOptions &&
                          props.column.filterOptions.customFilter
                            ? _c(
                                "div",
                                { staticClass: "column-filter-datepicker" },
                                [
                                  _c("DatePickerWithDropdown", {
                                    attrs: { reference: "time_period" },
                                    model: {
                                      value:
                                        props.column.filterOptions.filterValue,
                                      callback: function($$v) {
                                        _vm.$set(
                                          props.column.filterOptions,
                                          "filterValue",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "props.column.filterOptions.filterValue"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3838388293
                )
              },
              [
                _c("template", { slot: "loadingContent" }, [
                  _c("div", { staticClass: "loadingContent" }, [
                    _c("i", { staticClass: "fa fa-refresh fa-spin" })
                  ])
                ]),
                _c(
                  "div",
                  { attrs: { slot: "table-actions" }, slot: "table-actions" },
                  [
                    _c("div", { staticClass: "button-group" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default btn-sm dropdown-toggle",
                          attrs: { type: "button", "data-toggle": "dropdown" }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-cog",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "caret" })
                        ]
                      ),
                      _c(
                        "ul",
                        {
                          staticClass: "dropdown-menu",
                          staticStyle: { top: "auto", left: "auto", right: "0" }
                        },
                        _vm._l(_vm.columns, function(column, index) {
                          return _c("li", { key: index }, [
                            _c(
                              "a",
                              {
                                staticClass: "small",
                                attrs: { href: "#", tabIndex: "-1" },
                                on: {
                                  "!click": function($event) {
                                    $event.stopPropagation()
                                    return _vm.toggleColumn(index, $event)
                                  }
                                }
                              },
                              [
                                _c("input", {
                                  attrs: { type: "checkbox" },
                                  domProps: { checked: !column.hidden }
                                }),
                                _vm._v(" " + _vm._s(column.label))
                              ]
                            )
                          ])
                        }),
                        0
                      )
                    ])
                  ]
                )
              ],
              2
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }