var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "ui form form-horizontal",
      attrs: { autocomplete: "off" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.validateForm($event)
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "form-group",
          class: { "has-error": _vm.errors.has("first_name") }
        },
        [
          _c(
            "label",
            {
              staticClass: "col-sm-2 control-label",
              attrs: { for: "firstName" }
            },
            [_vm._v("First Name")]
          ),
          _c("div", { staticClass: "col-sm-10 col-md-8" }, [
            _c("div", { staticClass: "input-group width-100" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.mutableValue.first_name,
                    expression: "mutableValue.first_name"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { required: true, min: 2 },
                    expression: "{ required: true, min: 2 }"
                  }
                ],
                staticClass: "form-control width-100",
                attrs: {
                  name: "first_name",
                  placeholder: "First name",
                  type: "text",
                  autocomplete: "off",
                  id: "firstName"
                },
                domProps: { value: _vm.mutableValue.first_name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.mutableValue,
                      "first_name",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("first_name"),
                    expression: "errors.has('first_name')"
                  }
                ],
                staticClass: "text-danger"
              },
              [_vm._v(_vm._s(_vm.errors.first("first_name")))]
            )
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "form-group",
          class: { "has-error": _vm.errors.has("last_name") }
        },
        [
          _c(
            "label",
            {
              staticClass: "col-sm-2 control-label",
              attrs: { for: "lastName" }
            },
            [_vm._v("Last Name")]
          ),
          _c("div", { staticClass: "col-sm-10 col-md-8" }, [
            _c("div", { staticClass: "input-group width-100" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.mutableValue.last_name,
                    expression: "mutableValue.last_name"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { required: true, min: 2 },
                    expression: "{ required: true, min: 2 }"
                  }
                ],
                staticClass: "form-control width-100",
                attrs: {
                  name: "last_name",
                  placeholder: "Last Name",
                  type: "text",
                  autocomplete: "off",
                  id: "lastName"
                },
                domProps: { value: _vm.mutableValue.last_name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.mutableValue, "last_name", $event.target.value)
                  }
                }
              })
            ]),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("last_name"),
                    expression: "errors.has('last_name')"
                  }
                ],
                staticClass: "text-danger"
              },
              [_vm._v(_vm._s(_vm.errors.first("last_name")))]
            )
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "form-group",
          class: { "has-error": _vm.errors.has("email") }
        },
        [
          _c(
            "label",
            { staticClass: "col-sm-2 control-label", attrs: { for: "email" } },
            [_vm._v("Email")]
          ),
          _c("div", { staticClass: "col-sm-10 col-md-8" }, [
            _c("div", { staticClass: "input-group width-100" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.mutableValue.email,
                    expression: "mutableValue.email"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|email",
                    expression: "'required|email'"
                  }
                ],
                staticClass: "form-control width-100",
                attrs: {
                  name: "email",
                  placeholder: "Email",
                  type: "text",
                  autocomplete: "off",
                  id: "email"
                },
                domProps: { value: _vm.mutableValue.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.mutableValue, "email", $event.target.value)
                  }
                }
              })
            ]),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("email"),
                    expression: "errors.has('email')"
                  }
                ],
                staticClass: "text-danger"
              },
              [_vm._v(_vm._s(_vm.errors.first("email")))]
            )
          ])
        ]
      ),
      _vm.mutableValue.allowed_access &&
      _vm.mutableValue.allowed_access.categories
        ? _c("div", { staticClass: "form-group" }, [
            _c("label", { staticClass: "col-sm-2 control-label" }, [
              _vm._v("Allow access")
            ]),
            _c(
              "div",
              { staticClass: "col-sm-10 col-md-8" },
              [
                _vm._l(_vm.availableAccessCategories, function(access, key) {
                  return [
                    _c("div", { key: key, staticClass: "input-group" }, [
                      _c("div", { staticClass: "input-group-addon" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.mutableValue.allowed_access.categories[
                                  access.name
                                ],
                              expression:
                                "mutableValue.allowed_access.categories[access.name]"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.mutableValue.allowed_access.categories[
                                access.name
                              ]
                            )
                              ? _vm._i(
                                  _vm.mutableValue.allowed_access.categories[
                                    access.name
                                  ],
                                  null
                                ) > -1
                              : _vm.mutableValue.allowed_access.categories[
                                  access.name
                                ]
                          },
                          on: {
                            change: function($event) {
                              var $$a =
                                  _vm.mutableValue.allowed_access.categories[
                                    access.name
                                  ],
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.mutableValue.allowed_access
                                        .categories,
                                      access.name,
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.mutableValue.allowed_access
                                        .categories,
                                      access.name,
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.mutableValue.allowed_access.categories,
                                  access.name,
                                  $$c
                                )
                              }
                            }
                          }
                        })
                      ]),
                      _c("label", { staticClass: "form-control" }, [
                        _vm._v(_vm._s(access.title))
                      ])
                    ])
                  ]
                }),
                _c("div", { staticClass: "input-group" }, [
                  _c("div", { staticClass: "input-group-addon" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.mutableValue.allowed_access.is_super_admin,
                          expression:
                            "mutableValue.allowed_access.is_super_admin"
                        }
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(
                          _vm.mutableValue.allowed_access.is_super_admin
                        )
                          ? _vm._i(
                              _vm.mutableValue.allowed_access.is_super_admin,
                              null
                            ) > -1
                          : _vm.mutableValue.allowed_access.is_super_admin
                      },
                      on: {
                        change: function($event) {
                          var $$a =
                              _vm.mutableValue.allowed_access.is_super_admin,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.mutableValue.allowed_access,
                                  "is_super_admin",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.mutableValue.allowed_access,
                                  "is_super_admin",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.mutableValue.allowed_access,
                              "is_super_admin",
                              $$c
                            )
                          }
                        }
                      }
                    })
                  ]),
                  _c("label", { staticClass: "form-control" }, [
                    _vm._v("Is super admin")
                  ])
                ])
              ],
              2
            )
          ])
        : _vm._e(),
      _vm.isNew
        ? _c(
            "div",
            {
              staticClass: "form-group",
              class: { "has-error": _vm.errors.has("password") }
            },
            [
              _c(
                "label",
                {
                  staticClass: "col-sm-2 control-label",
                  attrs: { for: "password_new" }
                },
                [_vm._v("Password")]
              ),
              _c("div", { staticClass: "col-sm-10 col-md-8" }, [
                _c("div", { staticClass: "input-group width-100" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.mutableValue.password,
                        expression: "mutableValue.password"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: { required: true, min: 7 },
                        expression: "{ required: true, min: 7 }"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      name: "password",
                      placeholder: "Password",
                      type: "password",
                      autocomplete: "off",
                      id: "password_new"
                    },
                    domProps: { value: _vm.mutableValue.password },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.mutableValue,
                          "password",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("password"),
                        expression: "errors.has('password')"
                      }
                    ],
                    staticClass: "text-danger"
                  },
                  [_vm._v(_vm._s(_vm.errors.first("password")))]
                )
              ])
            ]
          )
        : _c(
            "div",
            {
              staticClass: "form-group",
              class: { "has-error": _vm.errors.has("password") }
            },
            [
              _c(
                "label",
                {
                  staticClass: "col-sm-2 control-label",
                  attrs: { for: "password" }
                },
                [_vm._v("Change Password")]
              ),
              _c("div", { staticClass: "col-sm-10 col-md-8" }, [
                _c("div", { staticClass: "input-group" }, [
                  _c("span", { staticClass: "input-group-addon" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.changePassword,
                          expression: "changePassword"
                        }
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.changePassword)
                          ? _vm._i(_vm.changePassword, null) > -1
                          : _vm.changePassword
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.changePassword,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.changePassword = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.changePassword = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.changePassword = $$c
                          }
                        }
                      }
                    })
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.mutableValue.password,
                        expression: "mutableValue.password"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: { min: 7 },
                        expression: "{ min: 7 }"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      name: "password",
                      placeholder: "Password",
                      type: "password",
                      autocomplete: "off",
                      disabled: !_vm.changePassword,
                      id: "password"
                    },
                    domProps: { value: _vm.mutableValue.password },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.mutableValue,
                          "password",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("password"),
                        expression: "errors.has('password')"
                      }
                    ],
                    staticClass: "text-danger"
                  },
                  [_vm._v(_vm._s(_vm.errors.first("password")))]
                )
              ])
            ]
          ),
      _c("div", { staticClass: "form-group" }, [
        _c(
          "div",
          { staticClass: "col-sm-offset-2 col-sm-10 col-md-8" },
          [_c("loading-button", [_vm._v("Submit")])],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }