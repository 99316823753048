import Vue from 'vue';
import Router from 'vue-router';
import { restrictAccess } from '@/routes/guards';

import DashboardView from '@/views/Dashboard';

import LeaderboardView from '@/views/Leaderboard';
import ChallengeOrganisationView from '@/views/ChallengeOrganisation';
import UserRoutes from '@/routes/users';
import ReportRoutes from '@/routes/reports';
import SchoolRoutes from '@/routes/schools';
import AuthRoutes from '@/routes/auth';
import PromoCodeRoutes from '@/routes/promocodes';
import RecordingRoutes from '@/routes/recordings';
import AdminRoutes from '@/routes/admins';
// import NotFoundView from '@/views/404';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: DashboardView,
      name: 'Dashboard',
      beforeEnter: restrictAccess,
      meta: { requiresAuth: true, noPageTitle: true },
    },
    {
      path: '/challenges/:challengeId/organisations/:orgId',
      component: ChallengeOrganisationView,
      name: 'Challenge Organisation',
      meta: { description: 'View this challenge organisation', requiresAuth: true, authorize: ['schools'] },
    },
    ...UserRoutes,
    ...ReportRoutes,
    ...SchoolRoutes,
    ...AuthRoutes,
    ...PromoCodeRoutes,
    ...RecordingRoutes,
    ...AdminRoutes,
    {
      path: '/leaderboard',
      component: LeaderboardView,
      name: 'Leaderboard',
      meta: { description: 'Leaderboard', requiresAuth: true },
    },

  ],
});

// router.beforeEach(manageAccess);

export default router;
