import moment from 'moment';
import ApexCharts from 'apexcharts';
import { uniqueId } from 'lodash';

export default class Chart {
  constructor(name, config) {
    this.objId = uniqueId('chart_');
    this.name = name;
    this.rawData = [];
    this.data = [];
    this.config = config;
    this.apexChart = null;
    this.hasYaxisAnnotation = false;
    this.totalValue = null;
    this.seriesType = {};
    this.seriesName = {};
    this.schoolId = '';
    this.classId = '';
    this.startDate = moment().startOf('month');
    this.endDate = moment().endOf('month');
    this.output = null;
    this.params = {};
  }

  static setCallback(cb) {
    this.callback = cb;
    return this;
  }

  setClassId(id) {
    this.classId = id;
    return this;
  }

  setSchoolId(id) {
    this.schoolId = id;
  }

  setParams(params) {
    this.params = params;
  }

  setStartDate(date) {
    this.startDate = date;
    return this;
  }

  setEndDate(date) {
    this.endDate = date;
    return this;
  }

  setOutput(output) {
    this.output = output;
    return this;
  }

  setSeriesTypes(types) {
    this.seriesType = types;
    return this;
  }

  setSeriesNames(names) {
    this.seriesName = names;
    return this;
  }

  formatSeries(series) {
    if (series.length === 0) {
      return series;
    }
    switch (this.config.type) {
      case 'donut':
        return series[0].data;
      case 'mixed':
        series.forEach((s) => {
          s.type = this.seriesType[s.name];
          s.name = this.seriesName[s.name];
        });
        return series;
      case 'line':
        series.forEach((s) => {
          if (s.name === 'combined') {
            s.type = 'area';
          }
        });
        return series;
      default:
        return series;
    }
  }

  total(key = null) {
    if (key === null || this.totalValue === null) {
      return this.totalValue;
    }

    const total = this.totalValue[key];
    return total === undefined ? 0 : total;
  }

  render() {
    if (this.apexChart !== null) {
      // options contain series
      this.apexChart.clearAnnotations();
      if (this.hasYaxisAnnotation) {
        this.apexChart.addYaxisAnnotation(this.config.getYaxisAnnotation());
      }
      this.apexChart.updateOptions(this.config.get(), false, true);
    } else {
      const chart = new ApexCharts(document.querySelector(`.apexchart-${this.name}`), this.config.get());
      chart.render();
      if (this.hasYaxisAnnotation) {
        chart.addYaxisAnnotation(this.config.getYaxisAnnotation(), false);
      }
      this.apexChart = chart;
    }
  }

  fetchData() {
    return new Promise((resolve, reject) => {
      Chart.callback(this)
        .then((response) => {
          const chartData = response.data.chart ? response.data.chart : [];
          this.rawData = chartData;
          this.config.setLabels(chartData.labels);
          this.data = this.formatSeries(chartData.series);
          this.config.setSeries(this.data);
          this.hasYaxisAnnotation = false;
          if (chartData.average) {
            this.hasYaxisAnnotation = true;
            this.config.setAnnotationOnYaxisValue(chartData.average);
          }
          if (Object.prototype.hasOwnProperty.call(chartData, 'total')) {
            this.totalValue = chartData.total;
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
