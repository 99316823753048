import { logout as logoutAPI } from '@/api/auth';
import api from '@/api';

export default ({
  namespaced: true,
  state: () => ({
    user: null,
    token: null,
    accountType: null,
  }),
  mutations: {
    setAuth(state, payload) {
      state.user = payload.user;
      state.token = payload.token;
      state.accountType = payload.accountType;
    },
  },
  actions: {
    login({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api.post('auth', payload).then((response) => {
          const { user } = response.data;
          const { token, account_type: accountType } = response.data.meta;
          if (user) {
            commit('setAuth', {
              user,
              token,
              accountType,
            });
            window.localStorage.setItem('user', JSON.stringify(user));
            window.localStorage.setItem('token', token);
            window.localStorage.setItem('account_type', accountType);
            resolve(user);
          } else {
            reject(new Error('An error occured'));
          }
        }).catch((error) => {
          const { error: err } = error.response.data;
          reject(new Error(err === 'Login information not recognised' ? 'Email / Password incorrect. Please try again.' : null));
        });
      });
    },
    restoreAuth({ commit }, payload) {
      return new Promise((resolve) => {
        commit('setAuth', payload);
        resolve();
      });
    },
    loginWithUserId(context, userId) {
      return api.post('admin/auth/login_as', { user_id: userId });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        logoutAPI().finally(() => {
          commit('setAuth', {
            user: null,
            token: null,
            accountType: null,
          });
          window.localStorage.setItem('user', null);
          window.localStorage.setItem('token', null);
          window.localStorage.setItem('account_type', null);
          resolve(1);
        });
      });
    },
  },
  getters: {
    isSuperAdmin(state) {
      const { user } = state;
      return user && user.allowed_access && user.allowed_access.is_super_admin;
    },
  },
});
