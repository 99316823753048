var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("wrapper", [
    _vm.school
      ? _c("section", { staticClass: "edit-school-page" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "box box-info" }, [
                _c(
                  "div",
                  { staticClass: "box-body" },
                  [
                    _c("school-form", {
                      on: {
                        validated: _vm.editSchool,
                        updated: _vm.handleUpdate
                      },
                      model: {
                        value: _vm.school,
                        callback: function($$v) {
                          _vm.school = $$v
                        },
                        expression: "school"
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }