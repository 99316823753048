import SchoolsView from '@/views/schools/Schools';
import SchoolView from '@/views/schools/School';
import EditSchoolView from '@/views/schools/EditSchool';
import CreateSchoolView from '@/views/schools/Create';
import AddSubscriptionView from '@/views/AddSubscription';
import AccountView from '@/views/Account';
import ReportsView from '@/views/Reports';
import BooksUsageView from '@/views/reports/BooksUsage';
import ReadingReportView from '@/views/reports/ReadingReport';

export default ([
  {
    path: '/schools',
    component: SchoolsView,
    name: 'Schools',
    meta: {
      description: 'Manage the different schools registered in the system',
      requiresAuth: true,
      authorize: ['schools'],
    },
  },
  {
    path: '/schools/',
    alias: '/schools',
    component: ReportsView,
    name: 'SchoolReports',
    meta: { requiresAuth: true, authorize: ['schools'] },
    children: [
      {
        path: ':schoolId/reports/books',
        alias: '',
        component: BooksUsageView,
        name: 'Reports: School Books Usage',
        meta: { requiresAuth: true },
      },
      {
        path: ':schoolId/classes/:classId/reports/books',
        alias: '',
        component: BooksUsageView,
        name: 'Reports: Class Books Usage',
        meta: { requiresAuth: true },
      },
      {
        path: ':schoolId/reports/reading',
        component: ReadingReportView,
        name: 'Reports: School Reading',
        meta: { requiresAuth: true },
      },
      {
        path: ':schoolId/classes/:classId/reports/reading',
        component: ReadingReportView,
        name: 'Reports: Class Reading',
        meta: { requiresAuth: true },
      },
      {
        path: 'create',
        component: CreateSchoolView,
        name: 'Create School',
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/schools/:schoolId',
    component: SchoolView,
    name: 'School',
    meta: { description: 'Manage the details of the school', requiresAuth: true, authorize: ['schools'] },
  },
  {
    path: '/schools/:schoolId/edit',
    component: EditSchoolView,
    name: 'Edit School',
    meta: { description: 'Edit the details of the school', requiresAuth: true, authorize: ['schools'] },
  },
  {
    path: '/schools/:schoolId/teachers/:userid',
    component: AccountView,
    name: 'Teacher Account',
    meta: {
      description: 'Manage the account of this user',
      requiresAuth: true,
      context: 'school',
      authorize: ['schools'],
    },
  },
  {
    path: '/schools/:schoolId/subscriptions/new',
    component: AddSubscriptionView,
    name: 'Add School Subscription',
    meta: {
      description: 'Add a subscription for the school',
      requiresAuth: true,
      object: 'school',
      authorize: ['schools'],
    },
  },
]);
