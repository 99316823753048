var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("wrapper", [
    _c("section", { staticClass: "account-page" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "box box-info" }, [
            _c("div", { staticClass: "box-header with-border" }, [
              _c("h3", { staticClass: "box-title" }, [_vm._v("Add admin")])
            ]),
            _c(
              "div",
              { staticClass: "box-body" },
              [
                _c("account-form", {
                  attrs: { isNew: true, apiErrors: _vm.apiErrors },
                  on: { validated: _vm.createAdmin },
                  model: {
                    value: _vm.form,
                    callback: function($$v) {
                      _vm.form = $$v
                    },
                    expression: "form"
                  }
                })
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }