import api from '@/api';

export default {
  methods: {
    destroyData() {
      this.$store.commit('SET_USER', null);
      this.$store.commit('SET_TOKEN', null);
      this.$store.commit('SET_ACCOUNT_TYPE', null);
      this.$store.commit('SET_CURRENT_SCHOOL', null);
      if (window.localStorage) {
        window.localStorage.removeItem('user');
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('account_type');
        window.localStorage.removeItem('current_school');
      }
    },
    logout() {
      api.delete('auth')
        .then(() => {
          this.destroyData();
          this.$router.push('/login');
        })
        .catch(() => {
          this.destroyData();
          this.$router.push('/login');
        });
    },
  },
};
