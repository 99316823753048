var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "wrapper",
    [
      _vm.modal.isVisible("handleCertificate")
        ? _c(
            "modal",
            {
              attrs: { title: "Certificate PDF", "show-footer": false },
              on: {
                close: function($event) {
                  return _vm.modal.hide("handleCertificate")
                }
              }
            },
            [
              !_vm.currentMilestone.has_achieved_milestone
                ? _c("div", { staticClass: "text-center mb-5" }, [
                    _c("p", { staticClass: "fs-9" }, [
                      _vm._v("This reader has not yet achieved this milestone.")
                    ]),
                    _c("p", [
                      _vm._v("You may send it anyway, but it "),
                      _c("b", [_c("u", [_vm._v("will not")])]),
                      _vm._v(
                        " mark the milestone as completed, and the email won't make any sense."
                      )
                    ])
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-evenly"
                  }
                },
                [
                  _c(
                    "loading-button",
                    {
                      staticClass: "btn-danger",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.handleCertificateAction("send-certificate")
                        }
                      }
                    },
                    [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.currentMilestone.has_achieved_milestone
                              ? "Send"
                              : "Send anyway"
                          )
                        }
                      })
                    ]
                  ),
                  _c(
                    "loading-button",
                    {
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.handleCertificateAction(
                            "download-certificate"
                          )
                        }
                      }
                    },
                    [_vm._v("Download only")]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.modal.isVisible("updateReader")
        ? _c(
            "modal",
            {
              attrs: { title: "Update Reader", "show-footer": false },
              on: {
                close: function($event) {
                  return _vm.modal.hide("updateReader")
                }
              }
            },
            [
              _c("reader-form", {
                on: { validated: _vm.updateReader },
                model: {
                  value: _vm.form,
                  callback: function($$v) {
                    _vm.form = $$v
                  },
                  expression: "form"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.modal.isVisible("deleteReader")
        ? _c(
            "modal",
            {
              attrs: {
                title: "Delete " + _vm.reader.name,
                "show-footer": false
              },
              on: {
                close: function($event) {
                  _vm.modal.hide("deleteReader")
                  _vm.deletionString = null
                }
              }
            },
            [
              _c("p", { staticClass: "text-danger lead" }, [
                _vm._v("This reader will be permanently deleted. "),
                _c("br"),
                _vm._v(" This action is irreversible.")
              ]),
              _c("div", { staticClass: "input-group width-100" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.deletionString,
                      expression: "deletionString"
                    }
                  ],
                  staticClass: "form-control width-100",
                  attrs: {
                    name: "full_name",
                    placeholder: "Enter '" + _vm.reader.name + "' to confirm",
                    type: "text",
                    autocomplete: "off"
                  },
                  domProps: { value: _vm.deletionString },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.deletionString = $event.target.value
                    }
                  }
                })
              ]),
              _c(
                "div",
                { staticClass: "modal-footer" },
                [
                  _vm._t("footer"),
                  _c(
                    "loading-button",
                    {
                      staticClass: "btn btn-warning",
                      attrs: { type: "button", "data-dismiss": "modal" },
                      on: {
                        click: function($event) {
                          _vm.modal.hide("deleteReader")
                          _vm.deletionString = null
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "loading-button",
                    {
                      staticClass: "btn btn-primary btn-danger",
                      attrs: {
                        type: "button",
                        "data-dismiss": "modal",
                        disabled: _vm.deletionString !== _vm.reader.name
                      },
                      on: { click: _vm.deleteReader }
                    },
                    [_vm._v("Delete Permanently")]
                  )
                ],
                2
              )
            ]
          )
        : _vm._e(),
      _vm.reader
        ? _c("section", { staticClass: "reader-page" }, [
            _c(
              "div",
              {
                staticClass:
                  "flex justify-content-between align-items-center mb-6"
              },
              [
                _c(
                  "h2",
                  { staticClass: "m-0" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "User",
                            params: { userid: _vm.reader.user_id }
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.reader.manager.full_name))]
                    ),
                    _vm._v(
                      " > Readers > " +
                        _vm._s(_vm.reader.name) +
                        " (" +
                        _vm._s(_vm.reader.username) +
                        ")"
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "page-actions" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        on: {
                          click: function($event) {
                            return _vm.modal.show("updateReader")
                          }
                        }
                      },
                      [_vm._v("Edit Reader")]
                    ),
                    _c(
                      "loading-button",
                      {
                        staticClass: "btn btn-danger",
                        on: {
                          click: function($event) {
                            return _vm.modal.show("deleteReader")
                          }
                        }
                      },
                      [_vm._v("Delete Reader")]
                    )
                  ],
                  1
                )
              ]
            ),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "box box-info" }, [
                  _c("div", { staticClass: "box-body" }, [
                    _c("table", { staticClass: "table table-striped" }, [
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [_vm._v("Age")]),
                          _c("td", [_vm._v(_vm._s(_vm.reader.age))])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Created")]),
                          _c("td", [
                            _vm.reader.created_at
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatDate(_vm.reader.created_at)
                                    )
                                  )
                                ])
                              : _c("span", [_vm._v("Unknown")])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _vm.readerChallenges.length > 0
                    ? _c("h3", { staticClass: "mt-0" }, [_vm._v("Challenges")])
                    : _vm._e(),
                  _vm._l(_vm.readerChallenges, function(challenge, i) {
                    return _c(
                      "box-with-icons",
                      { key: i, staticClass: "mb-4" },
                      [
                        _c("div", { staticClass: "reader-challenge" }, [
                          _c("p", { staticClass: "flex gap-3" }, [
                            _c("strong", [_vm._v(_vm._s(challenge.name))]),
                            _vm._v(" "),
                            _c(
                              "small",
                              { staticClass: "badge bg-light-blue" },
                              [_vm._v(_vm._s(challenge.slug))]
                            )
                          ]),
                          _c("table", { staticClass: "table" }, [
                            _c("thead", [
                              _c("tr", [
                                _c("th"),
                                _c("th", [
                                  _c("i", { staticClass: "fa fa-book" })
                                ]),
                                _c("th", [
                                  _c("i", { staticClass: "fa fa-star" })
                                ]),
                                _c("th", [
                                  _c("i", { staticClass: "fa fa-clock-o" })
                                ])
                              ])
                            ]),
                            _c(
                              "tbody",
                              _vm._l(challenge.majorMilestones, function(
                                milestone,
                                j
                              ) {
                                return _c("tr", { key: j }, [
                                  _c("th", [
                                    _vm._v("M " + _vm._s(milestone.sort))
                                  ]),
                                  _c(
                                    "td",
                                    {
                                      class:
                                        challenge.reader_stats
                                          .total_books_read >=
                                        milestone.config.total_books_read.count
                                          ? "success"
                                          : "danger"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            challenge.reader_stats
                                              .total_books_read
                                          ) +
                                          " / " +
                                          _vm._s(
                                            milestone.config.total_books_read
                                              .count
                                          )
                                      )
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      class:
                                        challenge.reader_stats
                                          .total_stars_earned >=
                                        milestone.config.total_stars_earned
                                          .count
                                          ? "success"
                                          : "danger"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            challenge.reader_stats
                                              .total_stars_earned
                                          ) +
                                          " / " +
                                          _vm._s(
                                            milestone.config.total_stars_earned
                                              .count
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      class:
                                        challenge.reader_stats
                                          .total_reading_time >=
                                        milestone.config.total_reading_time
                                          .count
                                          ? "success"
                                          : "danger"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            challenge.reader_stats
                                              .total_reading_time
                                          ) +
                                          " / " +
                                          _vm._s(
                                            milestone.config.total_reading_time
                                              .count
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    [
                                      _c("confirmation-button", {
                                        attrs: {
                                          "show-modal":
                                            _vm.showConfirmationModal[
                                              "handle-certificate"
                                            ]
                                        },
                                        on: {
                                          close: function($event) {
                                            _vm.showConfirmationModal[
                                              "handle-certificate"
                                            ] = false
                                          },
                                          click: function($event) {
                                            return _vm.startCertificateHandling(
                                              milestone,
                                              challenge
                                            )
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "button",
                                              fn: function() {
                                                return [
                                                  _c("span", {
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        milestone.has_achieved_milestone
                                                          ? "Resend PDF"
                                                          : "Send PDF"
                                                      )
                                                    }
                                                  })
                                                ]
                                              },
                                              proxy: true
                                            },
                                            {
                                              key: "modal",
                                              fn: function() {
                                                return [
                                                  _vm._v(
                                                    "An email containing the certificate has been sent to the user and we've BCC'd you in to it."
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ])
                              }),
                              0
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "flex gap-3 my-2" },
                            _vm._l(challenge.minorMilestones, function(
                              milestone,
                              j
                            ) {
                              return _c(
                                "div",
                                { key: j },
                                [
                                  _c("confirmation-button", {
                                    attrs: {
                                      btnClasses: milestone.has_achieved_milestone
                                        ? "btn btn-primary"
                                        : "btn btn-danger",
                                      "show-modal":
                                        _vm.showConfirmationModal[
                                          "handle-certificate"
                                        ]
                                    },
                                    on: {
                                      close: function($event) {
                                        _vm.showConfirmationModal[
                                          "handle-certificate"
                                        ] = false
                                      },
                                      click: function($event) {
                                        return _vm.startCertificateHandling(
                                          milestone,
                                          challenge
                                        )
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "button",
                                          fn: function() {
                                            return [
                                              _c("span", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    milestone.name
                                                  )
                                                }
                                              })
                                            ]
                                          },
                                          proxy: true
                                        },
                                        {
                                          key: "modal",
                                          fn: function() {
                                            return [
                                              _vm._v(
                                                "An email containing the certificate has been sent to the user and we've BCC'd you in to it."
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _c(
                            "div",
                            { staticClass: "mt-4 flex gap-3" },
                            [
                              _vm.editingChallengeOrg === null ||
                              _vm.editingChallengeOrg.challenge !== challenge
                                ? [
                                    _c("div", [
                                      _c("span", [
                                        _vm._v(
                                          "Associated with School: " +
                                            _vm._s(
                                              challenge.organisation
                                                ? challenge.organisation.name +
                                                    " (" +
                                                    challenge.organisation
                                                      .region +
                                                    ")"
                                                : "None"
                                            )
                                        )
                                      ])
                                    ]),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "loading-button",
                                          {
                                            staticClass: "btn-xs",
                                            attrs: {
                                              isLoading:
                                                _vm.isLoadingChallengeOrgs
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.editChallengeOrg(
                                                  challenge
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-pencil"
                                            }),
                                            _vm._v("Edit")
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                : [
                                    _c("div", [
                                      _c("p", [_vm._v("Associate with: ")]),
                                      _c(
                                        "div",
                                        { staticClass: "flex gap-3" },
                                        [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.editingChallengeOrg
                                                      .selected,
                                                  expression:
                                                    "editingChallengeOrg.selected"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.editingChallengeOrg,
                                                    "selected",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "option",
                                                { attrs: { value: "" } },
                                                [_vm._v("-- No School --")]
                                              ),
                                              _vm._l(
                                                _vm.allChallengeOrgs[
                                                  _vm.editingChallengeOrg
                                                    .challenge.slug
                                                ],
                                                function(org) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      key: org.id,
                                                      domProps: {
                                                        value: org.id
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(org.name) +
                                                          " (" +
                                                          _vm._s(org.region) +
                                                          ")"
                                                      )
                                                    ]
                                                  )
                                                }
                                              )
                                            ],
                                            2
                                          ),
                                          _c(
                                            "loading-button",
                                            {
                                              staticClass: "btn-sm",
                                              attrs: {
                                                isLoading:
                                                  _vm.isSavingChallengeOrg
                                              },
                                              on: {
                                                click: _vm.saveChallengeOrg
                                              }
                                            },
                                            [_vm._v("Save")]
                                          ),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-sm",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function($event) {
                                                  _vm.editingChallengeOrg = null
                                                }
                                              }
                                            },
                                            [_vm._v("Cancel")]
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  ]
                            ],
                            2
                          )
                        ])
                      ]
                    )
                  })
                ],
                2
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c(
                  "div",
                  [
                    _c("h3", [_vm._v("Latest reading activity")]),
                    _c(
                      "vue-good-table",
                      {
                        attrs: {
                          styleClass: "vgt-table striped",
                          theme: "auris-admin",
                          columns: _vm.columns,
                          rows: _vm.sessions,
                          "pagination-options": {
                            enabled: true,
                            perPage: 10
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "table-row",
                              fn: function(props) {
                                return [
                                  props.column.field === "start_time"
                                    ? _c("span", { staticClass: "wrap" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatDate(
                                                props.formattedRow.start_time
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    : props.column.field === "accuracy"
                                    ? _c("span", { staticClass: "wrap" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              props.formattedRow.accuracy
                                            ) +
                                            "% "
                                        )
                                      ])
                                    : props.column.field ===
                                      "reading_time_seconds"
                                    ? _c("span", { staticClass: "wrap" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatSeconds(
                                                props.formattedRow
                                                  .reading_time_seconds
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    : props.column.field ===
                                      "has_completed_book"
                                    ? _c("span", { staticClass: "wrap" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              props.formattedRow
                                                .has_completed_book
                                                ? "Complete"
                                                : "In progress"
                                            ) +
                                            " "
                                        )
                                      ])
                                    : props.column.field === "recording" &&
                                      props.formattedRow.recording
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-link user-link",
                                          on: {
                                            click: function($event) {
                                              return _vm.$router.push(
                                                "/recordings/" +
                                                  props.formattedRow.recording
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("[recording]")]
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              props.formattedRow[
                                                props.column.field
                                              ]
                                            ) +
                                            " "
                                        )
                                      ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          581985519
                        )
                      },
                      [
                        _c("template", { slot: "loadingContent" }, [
                          _c("div", { staticClass: "loadingContent" }, [
                            _c("i", { staticClass: "fa fa-refresh fa-spin" })
                          ])
                        ])
                      ],
                      2
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }