var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "books-usage-report-page" },
    [
      _c("div", { staticClass: "row mb-1-25 report__filters" }, [
        _c("div", { staticClass: "col-md-12 center" }, [
          _c("div", { staticClass: "row match-height" }, [
            _c("div", { staticClass: "col-md-2" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "user-filter" }, [
                  _vm._m(0),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.dataType,
                          expression: "dataType"
                        }
                      ],
                      attrs: { name: "dataType", id: "dataType" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.dataType = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "both" } }, [
                        _vm._v("All")
                      ]),
                      _c("option", { attrs: { value: "home" } }, [
                        _vm._v("Home")
                      ]),
                      _c("option", { attrs: { value: "school" } }, [
                        _vm._v("School")
                      ])
                    ]
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "col-md-2" }, [
              _c("div", { staticClass: "row" }, [
                _vm._m(1),
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c("vue-tags-input", {
                      attrs: {
                        id: "authors",
                        tags: _vm.selectedPublishers,
                        addOnlyFromAutocomplete: true,
                        placeholder: "Choose publishers",
                        "autocomplete-items": _vm.filteredPublishers,
                        "autocomplete-min-length": 0
                      },
                      on: {
                        "tags-changed": function(newPublishers) {
                          return (_vm.selectedPublishers = newPublishers)
                        }
                      },
                      model: {
                        value: _vm.publisher,
                        callback: function($$v) {
                          _vm.publisher = $$v
                        },
                        expression: "publisher"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "col-md-2" }, [
              _c("div", { staticClass: "row" }, [
                _vm._m(2),
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c("vue-tags-input", {
                      attrs: {
                        id: "publishers",
                        tags: _vm.selectedAuthors,
                        addOnlyFromAutocomplete: true,
                        placeholder: "Choose authors",
                        "autocomplete-items": _vm.filteredAuthors,
                        "autocomplete-min-length": 0
                      },
                      on: {
                        "tags-changed": function(newAuthors) {
                          return (_vm.selectedAuthors = newAuthors)
                        }
                      },
                      model: {
                        value: _vm.author,
                        callback: function($$v) {
                          _vm.author = $$v
                        },
                        expression: "author"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "col-md-2" }, [
              _c("div", { staticClass: "row" }, [
                _vm._m(3),
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c("vue-tags-input", {
                      attrs: {
                        id: "collections",
                        tags: _vm.selectedCollections,
                        addOnlyFromAutocomplete: true,
                        placeholder: "Choose collections",
                        "autocomplete-items": _vm.filteredCollections,
                        "autocomplete-min-length": 0
                      },
                      on: {
                        "tags-changed": function(newCollections) {
                          return (_vm.selectedCollections = newCollections)
                        }
                      },
                      model: {
                        value: _vm.collection,
                        callback: function($$v) {
                          _vm.collection = $$v
                        },
                        expression: "collection"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "col-md-2" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _vm._m(4),
                  _c("DatePickerWithDropdown", {
                    attrs: {
                      reference: "time_period",
                      value: _vm.dateRangeModel,
                      initialDateRange: _vm.dateRangeModel
                    },
                    on: {
                      "date-range-change": function($event) {
                        return _vm.handleChangeDateRange(
                          $event,
                          "getReportData"
                        )
                      }
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "col-md-2 download-button-locator" }, [
              _c(
                "button",
                { staticClass: "btn btn-primary", on: { click: _vm.getCSV } },
                [
                  _vm.gettingCSV
                    ? _c("i", { staticClass: "fa fa-spinner fa-spin" })
                    : _vm._e(),
                  _vm._v("Download CSV File")
                ]
              )
            ])
          ])
        ])
      ]),
      !_vm.onSchoolPage
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-2" },
              [
                _c("user-attribute-filters", {
                  attrs: { "show-user-type": false },
                  on: { select: _vm.onFilterUserAttributes }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-2" },
              [
                _c("reader-attribute-filters", {
                  on: { select: _vm.onFilterReaderAttributes }
                })
              ],
              1
            ),
            _c("div", { staticClass: "col-md-8 text-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-warning btn-sm",
                  on: {
                    click: function($event) {
                      return _vm.modal.show("usageTableExplainer")
                    }
                  }
                },
                [
                  _c("b", [_vm._v("Explain columns")]),
                  _vm._v(" "),
                  _c("i", { staticClass: "fa fa-question-circle ml-2 mr-0" })
                ]
              )
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12 center" },
          [
            _c(
              "vue-good-table",
              {
                attrs: {
                  styleClass: "vgt-table striped",
                  theme: "auris-admin",
                  columns: _vm.columns,
                  rows: _vm.rows,
                  totalRows: _vm.totalRecords,
                  "pagination-options": {
                    enabled: true,
                    mode: "records",
                    perPage: 25,
                    position: "bottom",
                    perPageDropdown: [10, 25, 50, 100],
                    dropdownAllowAll: false,
                    setCurrentPage: 1,
                    nextLabel: "next",
                    prevLabel: "prev",
                    rowsPerPageLabel: "Rows per page",
                    ofLabel: "of",
                    pageLabel: "page", // for 'pages' mode
                    allLabel: "All"
                  },
                  "search-options": {
                    enabled: true,
                    trigger: "key-up",
                    skipDiacritics: true,
                    placeholder: "Search this table"
                  },
                  isLoading: _vm.isLoading
                },
                on: {
                  "update:isLoading": function($event) {
                    _vm.isLoading = $event
                  },
                  "update:is-loading": function($event) {
                    _vm.isLoading = $event
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "table-row",
                    fn: function(props) {
                      return [
                        props.column.field == "actions"
                          ? _c(
                              "router-link",
                              { attrs: { to: "/recordings/" + props.row.id } },
                              [_c("i", { staticClass: "fa fa-eye" })]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              },
              [
                _c("template", { slot: "loadingContent" }, [
                  _c("div", { staticClass: "loadingContent" }, [
                    _c("i", { staticClass: "fa fa-refresh fa-spin" })
                  ])
                ])
              ],
              2
            )
          ],
          1
        )
      ]),
      _vm.modal.isVisible("usageTableExplainer")
        ? _c(
            "modal",
            {
              attrs: { title: "Usage Table Columns", "show-footer": false },
              on: {
                close: function($event) {
                  return _vm.modal.hide("usageTableExplainer")
                }
              }
            },
            [
              _c("ul", [
                _c("li", [
                  _c("b", [_vm._v("Opens:")]),
                  _vm._v(
                    " The number of times a child opened the book and started to read."
                  )
                ]),
                _c("li", [
                  _c("b", [_vm._v("Completed:")]),
                  _vm._v(" The number of times the book was completed.")
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("b", [_vm._v("Uniques:")]),
                  _vm._v(
                    " The number of children who have attempted to read the book (may or may not have completed it)."
                  )
                ]),
                _c("li", [
                  _c("b", [
                    _vm._v("Benchmarked Reading Times"),
                    _c("sup", [_vm._v("*")])
                  ]),
                  _vm._v(": "),
                  _c("ul", [
                    _c("li", [
                      _c("b", [_vm._v("Slow:")]),
                      _vm._v(
                        ' Any session that takes longer than this, is considered a "slow read".'
                      )
                    ]),
                    _c("li", [
                      _c("b", [_vm._v("Fast:")]),
                      _vm._v(
                        ' Any session that completes quicker than this, is considered a "fast read".'
                      )
                    ]),
                    _c("li", [
                      _c("b", [_vm._v("Average:")]),
                      _vm._v(
                        " Our benchmark of the average time it takes for a child to complete this book."
                      )
                    ])
                  ])
                ])
              ]),
              _c("p", [
                _c("sup", [_vm._v("*")]),
                _vm._v(
                  " The benchmark times shown are always the latest data, they are not affected by the date range selection. These are updated regularly, using our own reading data. Books need at least 5 children to have read them, before we will start benchmarking."
                )
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "label-container" }, [
      _c("label", [_vm._v("Subscription Status:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12" }, [
      _c("label", { attrs: { for: "publishers" } }, [_vm._v("Publishers")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12" }, [
      _c("label", { attrs: { for: "publishers" } }, [_vm._v("Authors")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12" }, [
      _c("label", { attrs: { for: "publishers" } }, [_vm._v("Collections")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12" }, [
      _c("label", [_vm._v("Filter by date")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }