<template>
  <div class="custom-box" @click="$emit('click')">
    <div v-for="(icon, i) in icons"
         :class="'icon icon-' + i "
         :style="'background-color:' + icon.bgColor + '; top: ' + top + 'px;'"
         v-tooltip="{ content: icon.tooltip, html: true }"
         :key="i">
      <i :class="'fa fa-' + icon.icon" />
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'box-with-icons',
  props: {
    icons: {
      type: Array,
    },
    top: {
      type: Number,
      required: false,
      default: -25,
    },
  },
};
</script>

<style lang="scss" scoped>
$box-color-bad: rgb(211, 31, 31);
$box-color-good: rgb(20, 165, 56);

.custom-box {
  position: relative;
  height:100%;
  width:100%;
  min-width:250px;

  font-size: 16px;
  line-height: 1;

  padding:.5em;
  box-shadow: 2px 2px 3px 0px #c7d5e6;
  background: white;
  color: #5e5e5e;

  margin-bottom: 5px;
}
.icon {
  border-radius: 50%;
  border: 1px solid #000;
  color: #000;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 5px;
  width: 30px;
  height: 30px;
  text-align: center;
}
.icon .fa {
  padding-left: 10px;
}
.icon-1 {
  right: 40px;
}
</style>
