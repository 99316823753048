var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "custom-box",
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _vm._l(_vm.icons, function(icon, i) {
        return _c(
          "div",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: { content: icon.tooltip, html: true },
                expression: "{ content: icon.tooltip, html: true }"
              }
            ],
            key: i,
            class: "icon icon-" + i,
            style:
              "background-color:" + icon.bgColor + "; top: " + _vm.top + "px;"
          },
          [_c("i", { class: "fa fa-" + icon.icon })]
        )
      }),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }