<template>
  <div class="books-report-page">
    <div class="row">
      <div class="col-md-3">
        <FlipCard id="dashbox-active-home-users">
          <DashBox slot="front" title="Reading Sessions" :value="startedReadingSessionsTotal" />
          <div slot="back" class="dashbox__help"><strong>{{startedReadingSessionsTotal.toLocaleString('en')}} reading sessions</strong> have been <strong>started</strong> in the selected time period. <strong>{{completedReadingSessionsTotal}}</strong> were complete reads.</div>
        </FlipCard>
      </div>
      <div class="col-md-3">
        <DashBox id="dashbox-total-reading-time" title="Reading Time" :value="readingTimeTotal" />
      </div>
      <div class="col-md-6">
        <div  style="display:flex; flex-direction:column; align-items: flex-end; gap: 1em;">
          <div style="display: flex; align-items:center; gap: 0.5em;">
            <label>Filter by date:</label>
              <DatePickerWithDropdown
                placeholder="Show data for..."
                reference="time_period"
                @date-range-change="handleChangeDateRange($event, 'fetchCharts')"
                :value="dateRangeModel"
                :initialDateRange="dateRangeModel"
              />
          </div>
          <div style="display: flex; align-items:center; gap: 2em;">
            <user-attribute-filters @select="onFilterUserAttributes" :show-user-type="false"></user-attribute-filters>
            <reader-attribute-filters @select="onFilterReaderAttributes"></reader-attribute-filters>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-md-12 charts-container">
        <chart :chart="charts.readingSessions"></chart>
      </div>
    </div>
   <div class="row">
    <div class="col-md-12 charts-container">
      <chart :chart="charts.readingTime" class="chart-featured mr-5"></chart>
      <chart :chart="charts.readingLevels"></chart>
    </div>
   </div>

  </div>
</template>

<script>
import _ from 'lodash';
import ChartMixin from '@/mixins/ChartMixin';
import DateRangeMixin from '@/mixins/DateRangeMixin';
import SchoolMixin from '@/mixins/SchoolMixin';
import { Chart, ChartCanvas, ChartConfig } from '@/plugins/Chart';
import DatePickerWithDropdown from '@/components/DatePickerWithDropdown';
import UserAttributeFilters from '@/components/UserAttributeFilters';
import ReaderAttributeFilters from '@/components/ReaderAttributeFilters';
import DashBox from '@/components/DashBox';
import FlipCard from '@/components/FlipCard';

const chartColors = {
  'Level 1': '#bd2f9e',
  'Level 2': '#c33a2c',
  'Level 3': '#f9e106',
  'Level 4': '#5ac9ee',
  'Level 5': '#00954f',
  'Level 6': '#f88124',
};

export default {
  name: 'books-report',
  mixins: [ChartMixin, DateRangeMixin, SchoolMixin],
  components: {
    chart: ChartCanvas,
    DatePickerWithDropdown,
    UserAttributeFilters,
    ReaderAttributeFilters,
    DashBox,
    FlipCard,
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      updateTotal: 1,
      charts: {
        readingSessions: new Chart(
          'reading-sessions',
          new ChartConfig().isTimeSeries().withTitle('Reading Sessions'),
        ),
        readingTime: new Chart(
          'reading-time-over-time',
          new ChartConfig().isTimeSeries()
            .withTitle('Reading Time')
            .withLabelFormatter((val) => this.tooltipReadingTime(val, false))
            .niceScale()
            .withYAxisFormatter(this.yAxisReadingTime)
            .disableNegativeYAxis(),
        ),
        readingLevels: new Chart(
          'reading-levels',
          new ChartConfig().isType('donut').withTitle('Reading Levels').setColors(chartColors),
        ),
      },
      challenge: 'all',
      readerType: 'all',
    };
  },
  created() {
    Chart.setCallback(this.getChart);
  },
  mounted() {
    this.fetchCharts();
    this.loadPageHeadings('Reading Report');
  },
  methods: {
    onFilterUserAttributes(e) {
      this.challenge = e.challenge;
      this.fetchCharts();
    },
    onFilterReaderAttributes(e) {
      this.readerType = e.readerType;
      this.fetchCharts();
    },
    fetchCharts() {
      _.forEach(this.charts, (chart) => {
        chart.setStartDate(this.dateRange.startDate).setEndDate(this.dateRange.endDate);
        chart.setOutput(this.output);
        if (this.$route.params.schoolId || this.$route.params.classId) {
          chart.setSchoolId(this.$route.params.schoolId || '');
          chart.setClassId(this.$route.params.classId || '');
        }
        chart.setParams({ challenge: this.challenge, user_type: 'all', reader_type: this.readerType });
      });
      this.fetchDataForCharts(this.charts);
    },
  },
  computed: {
    startedReadingSessionsTotal() {
      const value = this.charts.readingSessions.total('started');
      return value ?? 0;
    },
    completedReadingSessionsTotal() {
      const value = this.charts.readingSessions.total('completed');
      return value ?? 0;
    },
    readingTimeTotal() {
      return this.charts.readingTime.total() === null ? 0 : this.tooltipReadingTime(this.charts.readingTime.total(), false);
    },
  },
  watch: {
    $route() {
      this.$router.go();
    },
  },
};
</script>
