<template>
  <div class="subscription-package-with-form">
    <div>
      <free-trial-subscription-form v-model="form" @validated="addCustomSubscription(form)" />
    </div>
    <div>
      <div class="predefined-buttons" v-for="(variant, i) in sPackage.variants" :key="i">
        <loading-button v-for="price in variant.prices" :key="price.id" @click="addPredefinedSubscription(sPackage.id, variant.id, price.id)">{{ displayPredefinedOption(variant, price)}}</loading-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import FreeTrialSubscriptionForm from '@/components/forms/FreeTrialSubscriptionForm';
import LoadingButton from '@/components/utils/LoadingButton';
import pluralize from 'pluralize';
import Form from '@/classes/Form';

export default {
  name: 'subscription-package-with-form',
  props: {
    sPackage: {
      required: true,
    },
  },
  components: {
    LoadingButton,
    FreeTrialSubscriptionForm,
  },
  data() {
    return {
      form: new Form({
        package_id: this.sPackage.id,
        max_devices: null,
        payment_amount: 0,
        renewal_duration_length: '',
        renewal_duration_unit: 'day',
      }),
      userId: null,
    };
  },
  mounted() {
    this.userId = this.$route.params.id;
  },
  methods: {
    addCustomSubscription(form) {
      this.$store.dispatch('app/setGlobalLoading', true);
      const url = `admin/users/${this.userId}/subscriptions`;
      form.max_readers = 10;
      api.post(url, form)
        .then(() => {
          this.$router.back();
        })
        .catch((error) => {
          this.$store.dispatch('app/setGlobalLoading', false);
          this.$apiResponse.renderErrorMessage(error);
        });
    },
    addPredefinedSubscription(packageId, packageVariantId, packageVariantPriceId) {
      this.$store.dispatch('app/setGlobalLoading', true);
      const url = `admin/users/${this.userId}/subscriptions`;
      api.post(url, {
        package_id: packageId,
        package_variant_id: packageVariantId,
        package_variant_price_id: packageVariantPriceId,
      })
        .then(() => {
          this.$router.back();
        })
        .catch((error) => {
          this.$store.dispatch('app/setGlobalLoading', false);
          this.$apiResponse.renderErrorMessage(error);
        });
    },
    displayPredefinedOption(variant, price) {
      const devices = variant.max_devices > 1 ? 'Multi Device' : 'Single Device';
      const durationUnit = pluralize(price.duration_unit, price.duration_length);
      return `${price.duration_length} ${durationUnit} - ${devices}`;
    },
  },
};
</script>
<style lang="scss">
.subscription-package-with-form {
  display: flex;
  align-items: center;
  justify-content: center;
  > * { padding: 0.5em; }
  > *:first-child {
    border-right: 1px solid #ababab;
    padding-right: 2.5em;
    margin-right: 2em;
    box-shadow: 1px 0px rgba(255,255,255,0.8);
    // background:red;
    // &:after {
      // content: "";
      // display: block;
      // height: 100%;
      // width: 1px;
      // background-color: #ababab;

    // }
  }
}
.predefined-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  margin-bottom: 1em;
}
</style>
