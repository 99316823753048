var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "header main-header" }, [
    _c(
      "a",
      {
        staticClass: "logo header__logo",
        attrs: { href: "#" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.$router.push("/")
          }
        }
      },
      [_vm._m(0)]
    ),
    _c(
      "nav",
      {
        staticClass: "top-navbar navbar navbar-static-top",
        attrs: { role: "navigation" }
      },
      [
        _vm._m(1),
        _c("div", { staticClass: "top-navbar__dropdown" }, [
          _c("ul", { staticClass: "nav navbar-nav" }, [
            _c("li", { staticClass: "dropdown user-menu user" }, [
              _c(
                "a",
                {
                  staticClass: "dropdown-toggle",
                  attrs: { href: "#", "data-toggle": "dropdown" }
                },
                [_vm._v(" " + _vm._s(_vm.activeUser.email) + " ")]
              ),
              _c("ul", { staticClass: "dropdown-menu" }, [
                _c(
                  "li",
                  [
                    _c("router-link", { attrs: { to: "/logout" } }, [
                      _c("i", { staticClass: "fa fa-circle-o text-red" }),
                      _c("span", [_vm._v(" Logout")])
                    ])
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "header__logo--lg" }, [
      _c("img", {
        staticClass: "header__logo-image",
        attrs: { src: "/img/logo.png", alt: "Logo" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "sidebar-toggle",
        attrs: { href: "#", "data-toggle": "offcanvas", role: "button" }
      },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("Toggle navigation")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }