export default {
  data() {
    return {
      countriesLoaded: false,
    };
  },
  methods: {
    getCountries(currentCountryCode) {
      this.$store.dispatch('app/fetchCountries').then((response) => {
        const { countries } = response.data;
        this.countries = countries;
        this.countriesLoaded = true;
        this.updateRegions(currentCountryCode);
      }).catch((error) => {
        this.$apiResponse.renderErrorMessage(error);
      });
    },
    updateRegions(countryCode = null) {
      const selectedCountry = this.countries.find((country) => country.id === countryCode);
      this.regions = selectedCountry && selectedCountry.regions ? selectedCountry.regions.groups : [];
    },
  },
};
