var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade in",
      class: "modal-" + _vm.stateClass,
      attrs: {
        id: "modal",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "modalLabel"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "button",
              {
                staticClass: "close",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  "aria-label": "Close"
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            ),
            _c(
              "h4",
              { staticClass: "modal-title", attrs: { id: "myModalLabel" } },
              [_vm._v(_vm._s(_vm.title))]
            )
          ]),
          _c("div", { staticClass: "modal-body" }, [_vm._t("default")], 2),
          _vm.showFooter
            ? _c(
                "div",
                { staticClass: "modal-footer" },
                [
                  _vm._t("footer"),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button", "data-dismiss": "modal" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ],
                2
              )
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }