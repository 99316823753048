var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("wrapper", [
    _vm.pageReady
      ? _c(
          "section",
          { staticClass: "promocode-page" },
          [
            _c("promocode-form", {
              attrs: { "pricing-options": _vm.pricingOptions },
              on: { validated: _vm.addPromocode },
              model: {
                value: _vm.form,
                callback: function($$v) {
                  _vm.form = $$v
                },
                expression: "form"
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }