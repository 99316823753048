var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    { staticClass: "dvt" },
    [
      _vm._l(
        ["this_week", "this_month", "last_7_days", "last_30_days"],
        function(periodKey) {
          return [
            _vm.periods[periodKey]
              ? _c("tr", { key: periodKey, staticClass: "dvt__row" }, [
                  _c("td", { staticClass: "dvt__period" }, [
                    _vm._v(_vm._s(_vm.labelForPeriod(periodKey)))
                  ]),
                  _c("td", { staticClass: "dvt__num" }, [
                    _vm._v(
                      _vm._s(
                        _vm.format_numtype(_vm.periods[periodKey].current_value)
                      )
                    )
                  ]),
                  _vm._m(0, true),
                  _c("td", { staticClass: "dvt__prev_num" }, [
                    _vm._v(
                      _vm._s(
                        _vm.format_numtype(
                          _vm.getPreviousValue(_vm.periods[periodKey])
                        )
                      )
                    )
                  ]),
                  _c(
                    "td",
                    {
                      class:
                        "dvt__perc dvt__perc--" +
                        (_vm.getChangePercentage(_vm.periods[periodKey]) < 0
                          ? "down"
                          : "up")
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.format_number(
                            _vm.getChangePercentage(_vm.periods[periodKey])
                          )
                        ) + "%"
                      )
                    ]
                  )
                ])
              : _vm._e()
          ]
        }
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "dvt__compare_icon" }, [
      _c("i", { staticClass: "fa fa-exchange-alt" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }