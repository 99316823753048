import api from '@/api';

export default ({
  namespaced: true,
  state: () => ({
    challenges: [],
  }),
  mutations: {
    setChallenges(state, challenges) {
      state.challenges = challenges;
    },
  },
  actions: {
    getChallenges({ commit }) {
      return new Promise((resolve, reject) => {
        api.get('challenges').then((response) => {
          const { challenges } = response.data;
          commit('setChallenges', challenges);
          resolve(challenges);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getChallengeOrganisations(context, challengeSlug) {
      return new Promise((resolve, reject) => {
        api.get(`admin/challenges/${challengeSlug}/organisations/`).then((response) => {
          const { organisations } = response.data;
          resolve(organisations);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    getChallengeOrganisation(context, payload) {
      return new Promise((resolve, reject) => {
        const { challengeSlug, orgId } = payload;
        api.get(`admin/challenges/${challengeSlug}/organisations/${orgId}`).then((response) => {
          const { organisation } = response.data;
          resolve(organisation);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    resetPassword(context, payload) {
      return new Promise((resolve, reject) => {
        const { challengeSlug, orgId, data } = payload;
        api.post(`admin/challenges/${challengeSlug}/organisations/${orgId}/reset-password`, data).then((response) => {
          const { new_password: newPassword } = response.data.metadata;
          resolve(newPassword);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    updateChallengeOrganisation(context, payload) {
      return new Promise((resolve, reject) => {
        const { challengeSlug, orgId, data } = payload;
        api.post(`admin/challenges/${challengeSlug}/organisations/${orgId}`, data).then((response) => {
          const { organisation } = response.data;
          resolve(organisation);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    challengeOrganisationRegenAssets(context, payload) {
      return new Promise((resolve, reject) => {
        const { challengeSlug, orgId } = payload;
        api.post(`admin/challenges/${challengeSlug}/organisations/${orgId}/regen-assets`).then((response) => {
          const { organisation } = response.data;
          resolve(organisation);
        }).catch((err) => {
          reject(err);
        });
      });
    },
  },
});
