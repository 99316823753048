export default {
  methods: {
    validateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit('validated');
        }
      });
    },
  },
};
