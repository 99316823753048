var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashbox" },
    [
      _c("span", { staticClass: "dashbox__title" }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _c("div", { staticClass: "dashbox__value_row" }, [
        _c("span", { staticClass: "dashbox__value" }, [
          _vm._v(_vm._s(_vm.format_number(_vm.value)))
        ]),
        _vm.conversion !== null
          ? _c("span", { staticClass: "dashbox__conv" }, [
              _vm._v(_vm._s(_vm.format_number(_vm.conversion)) + "%")
            ])
          : _vm._e(),
        _vm.active_value !== null
          ? _c("span", { staticClass: "dashbox__active_value" }, [
              _vm._v(_vm._s(_vm.format_number(_vm.active_value)))
            ])
          : _vm._e()
      ]),
      _vm._t("table")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }