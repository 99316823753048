<template>
  <form class="ui form form-horizontal" @submit.prevent="validateForm" autocomplete="off">
    <div class="form-group" :class="{ 'has-error': errors.has('first_name') }">
      <label for="firstName" class="col-sm-2 control-label">First Name</label>
      <div class="col-sm-10 col-md-8">
        <div class="input-group width-100">
          <input class="form-control width-100" name="first_name" placeholder="First name" type="text" autocomplete="off"
                 v-model="mutableValue.first_name"
                 v-validate="{ required: true, min: 2 }"
                 id="firstName">
        </div>
        <span v-show="errors.has('first_name')" class="text-danger">{{ errors.first('first_name') }}</span>
      </div>
    </div>

    <div class="form-group" :class="{ 'has-error': errors.has('last_name') }">
      <label for="lastName" class="col-sm-2 control-label">Last Name</label>
      <div class="col-sm-10 col-md-8">
        <div class="input-group width-100">
          <input class="form-control width-100" name="last_name" placeholder="Last Name" type="text" autocomplete="off"
                 v-model="mutableValue.last_name"
                 v-validate="{ required: true, min: 2 }"
                 id="lastName">
        </div>
        <span v-show="errors.has('last_name')" class="text-danger">{{ errors.first('last_name') }}</span>
      </div>
    </div>

    <div class="form-group" :class="{ 'has-error': errors.has('email') }">
      <label for="email" class="col-sm-2 control-label">Email</label>
      <div class="col-sm-10 col-md-8">
        <div class="input-group width-100">
          <input class="form-control width-100" name="email" placeholder="Email" type="text" autocomplete="off"
                 v-model="mutableValue.email"
                 v-validate="'required|email'"
                 id="email">
        </div>
        <span v-show="errors.has('email')" class="text-danger">{{ errors.first('email') }}</span>
      </div>
    </div>

    <div class="form-group" v-if="mutableValue.allowed_access && mutableValue.allowed_access.categories">
      <label class="col-sm-2 control-label">Allow access</label>
      <div class="col-sm-10 col-md-8">
        <template v-for="(access, key) in availableAccessCategories">
          <div class="input-group" :key="key">
            <div class="input-group-addon">
              <input type="checkbox" v-model="mutableValue.allowed_access.categories[access.name]" />
            </div>
            <label class="form-control">{{ access.title }}</label>
          </div>
        </template>
        <div class="input-group">
          <div class="input-group-addon">
            <input type="checkbox" v-model="mutableValue.allowed_access.is_super_admin" />
          </div>
          <label class="form-control">Is super admin</label>
        </div>
      </div>
    </div>

    <div class="form-group" :class="{ 'has-error': errors.has('password') }" v-if="isNew">
      <label for="password_new" class="col-sm-2 control-label">Password</label>
      <div class="col-sm-10 col-md-8">
        <div class="input-group width-100">
          <input class="form-control" name="password" placeholder="Password" type="password" autocomplete="off"
                 v-model="mutableValue.password"
                 v-validate="{ required: true, min: 7 }"
                 id="password_new">
        </div>
        <span v-show="errors.has('password')" class="text-danger">{{ errors.first('password') }}</span>
      </div>
    </div>
    <div class="form-group" :class="{ 'has-error': errors.has('password') }" v-else>
      <label for="password" class="col-sm-2 control-label">Change Password</label>
      <div class="col-sm-10 col-md-8">
        <div class="input-group">
          <span class="input-group-addon">
            <input type="checkbox" v-model="changePassword">
          </span>
          <input class="form-control" name="password" placeholder="Password" type="password" autocomplete="off" :disabled="!changePassword"
                 v-model="mutableValue.password"
                 v-validate="{ min: 7 }"
                 id="password">
        </div>
        <span v-show="errors.has('password')" class="text-danger">{{ errors.first('password') }}</span>
      </div>
    </div>
    <div class="form-group">
      <div class="col-sm-offset-2 col-sm-10 col-md-8">
        <loading-button>Submit</loading-button>
      </div>
    </div>
  </form>
</template>

<script>
import LoadingButton from '@/components/utils/LoadingButton';
import FormMixin from '@/mixins/FormMixin';

export default {
  name: 'admin-form',
  props: ['value', 'apiErrors', 'isNew'],
  mixins: [FormMixin],
  components: { LoadingButton },
  data() {
    return {
      changePassword: false,
      availableAccessCategories: [
        {
          name: 'schools',
          title: 'Schools',
        },
        {
          name: 'users',
          title: 'Users',
        },
        {
          name: 'reports',
          title: 'Reports',
        },
        {
          name: 'recordings',
          title: 'Recordings',
        },
        {
          name: 'all',
          title: 'All',
        },
      ],
    };
  },
  watch: {
    apiErrors() {
      if (this.apiErrors) {
        this.apiErrors.forEach((item) => {
          this.$validator.errors.add({
            field: item[0],
            msg: item[1][0],
          });
        });
      }
    },
  },
  computed: {
    mutableValue() {
      return Object.assign(this.value);
    },
  },
};
</script>
