export default {
  data() {
    return {
      itemToBeDeleted: {
        id: null,
        name: null,
        type: null,
        inputString: null,
      },
    };
  },
  methods: {
    beginDeleteItem({ id, name, type }) {
      if (!id || !name || !type) {
        throw new Error('id, name and type must be provided for the item to be deleted');
      }
      this.itemToBeDeleted = { id, name, type };
      this.modal.show('deleteItem');
    },
    cancelDeleteItem() {
      this.itemToBeDeleted = { id: null, name: null };
      this.modal.hide('deleteItem');
    },
    deleteItem(apiCall) {
      apiCall();
    },
  },
};
