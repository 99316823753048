<template>
  <div class="subscribers-report-page">
    <div class="row">
        <div class="col-md-12 mb-1-25">
          <DatePickerWithDropdown
            placeholder="Show data for..."
            reference="time_period"
            @date-range-change="handleChangeDateRange($event, 'dateRangeUpdated')"
            :value="dateRangeModel"
            :initialDateRange="dateRangeModel"
          />
        </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <vue-good-table
          styleClass="vgt-table striped"
          theme="auris-admin"
          :columns="columns"
          :rows="rows"
          :totalRows="totalRecords"
          :pagination-options="{
            enabled: true,
            mode: 'records',
            perPage: 10,
            position: 'bottom',
            perPageDropdown: [10, 25, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page', // for 'pages' mode
            allLabel: 'All',
          }"
          :search-options="{
            enabled: true,
            trigger: 'key-up',
            skipDiacritics: true,
            placeholder: 'Search this table',
          }"
        >
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field === 'week.units'">
            <p>{{props.row.week.units}}</p>
            <PercentageChange v-bind:percentage="parseInt(props.row.week.percentage_change)" />
          </div>
          <div v-else-if="props.column.field === 'month.units'">
            <p>{{props.row.month.units}}</p>
            <PercentageChange v-bind:percentage="parseInt(props.row.month.percentage_change)" />
          </div>
        </template>
        <template slot="loadingContent">
          <div class="loadingContent">
              <i class="fa fa-refresh fa-spin"></i>
          </div>
        </template>
        </vue-good-table>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-1">
        <div class="charts-container">
          <chart :chart="charts.purchasesOverTime"></chart>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import _ from 'lodash';
import ChartMixin from '@/mixins/ChartMixin';
import DateRangeMixin from '@/mixins/DateRangeMixin';
import StatisticsMixin from '@/mixins/StatisticsMixin';
import PercentageChange from '@/components/PercentageChange';
import { Chart, ChartCanvas, ChartConfig } from '@/plugins/Chart';
import DatePickerWithDropdown from '@/components/DatePickerWithDropdown';
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';

const chartColors = {
  'Stripe - Units': '#008FFB', 'iTunes - Units': '#00E396', 'Combined - Units': '#FEB019', 'Stripe - Revenue': '#FA1F19', 'iTunes - Revenue': '#BF17E3', 'Combined - Revenue': '#333333',
};

export default {
  name: 'purchases',
  mixins: [ChartMixin, StatisticsMixin, DateRangeMixin],
  components: {
    chart: ChartCanvas,
    PercentageChange,
    DatePickerWithDropdown,
    VueGoodTable,
  },
  data() {
    return {
      charts: {
        purchasesOverTime: new Chart(
          'purchases-over-time',
          new ChartConfig()
            .withTitle('Purchases Over Time')
            .setColors(chartColors)
            .isTimeSeries()
            .withYAxisFormatter(this.yAxisInteger)
            .withLabelFormatter(this.yAxisInteger),
        ),
      },
      rows: [],
      columns: [
        {
          label: 'Product',
          field: 'title',
        },
        {
          label: 'Sold Last Week',
          field: 'week.previous_units',
          type: 'number',
        },
        {
          label: 'Sold This Week',
          field: 'week.units',
          type: 'number',
        },
        {
          label: 'Sold Last Month',
          field: 'month.previous_units',
          type: 'number',
        },
        {
          label: 'Sold This Month',
          field: 'month.units',
          type: 'number',
        },
        {
          label: 'Sold In Date Range',
          field: 'range.units',
          type: 'number',
        },
      ],
      totalRecords: 0,
      purchases: [],
    };
  },
  beforeMount() {
    this.getReportData();
    this.fetchCharts();
  },
  mounted() {
    this.$nextTick(() => {
      const payload = { startDate: this.dateRange.startDate, endDate: this.dateRange.endDate };
      this.$store.dispatch('reports/getPurchases', payload).then((response) => {
        this.purchases = response.data;
      });
    });
  },
  created() {
    Chart.setCallback(this.getChart);
  },
  methods: {
    getReportData() {
      const payload = { startDate: this.dateRange.startDate, endDate: this.dateRange.endDate };
      this.$store.dispatch('reports/getPurchases', payload).then((response) => {
        this.rows = response.data;
      });
    },
    initChartToGetTotals() {
      const chart = new Chart('purchases-over-time');
      chart.setStartDate(this.dateRange.startDate).setEndDate(this.dateRange.endDate);
      this.getChart(chart)
        .then((response) => {
          this.doubleChartForTotals = response.data.chart.total === null ? 0 : response.data.chart.total;
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
        });
    },
    dateRangeUpdated() {
      this.getReportData();
      this.fetchCharts();
    },
    choose(output) {
      this.output = output;
      this.fetchCharts();
      this.initChartToGetTotals();
    },
    fetchCharts() {
      _.forEach(this.charts, (chart) => {
        chart.setStartDate(this.dateRange.startDate).setEndDate(this.dateRange.endDate);
        chart.setOutput(this.output);
      });
      this.fetchDataForCharts(this.charts);
    },
  },
};
</script>
