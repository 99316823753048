<template>
  <wrapper>
    <modal title="Certificate PDF" v-if="modal.isVisible('handleCertificate')" @close="modal.hide('handleCertificate')"
      :show-footer="false">
      <div v-if="!currentMilestone.has_achieved_milestone" class="text-center mb-5">
        <p class="fs-9">This reader has not yet achieved this milestone.</p>
        <p>You may send it anyway, but it <b><u>will not</u></b> mark the milestone as completed, and the email won't
          make any sense.</p>
      </div>
      <div style="display: flex; justify-content: space-evenly">
        <loading-button type="button" @click="handleCertificateAction('send-certificate')" class="btn-danger"><span
            v-text="currentMilestone.has_achieved_milestone ? 'Send' : 'Send anyway'"></span></loading-button>
        <loading-button type="button" @click="handleCertificateAction('download-certificate')">Download
          only</loading-button>
      </div>
    </modal>
    <modal title="Update Reader" v-if="modal.isVisible('updateReader')" @close="modal.hide('updateReader')"
      :show-footer="false">
      <reader-form v-model="form" @validated="updateReader" />
    </modal>
    <modal :title="`Delete ${reader.name}`" v-if="modal.isVisible('deleteReader')"
      @close="modal.hide('deleteReader'); deletionString = null;" :show-footer="false">
      <p class="text-danger lead">This reader will be permanently deleted. <br /> This action is irreversible.</p>
      <div class="input-group width-100">
        <input class="form-control width-100" name="full_name" :placeholder="`Enter '${reader.name}' to confirm`"
          v-model="deletionString" type="text" autocomplete="off">
      </div>
      <div class="modal-footer">
        <slot name="footer"></slot>
        <loading-button type="button" class="btn btn-warning" data-dismiss="modal"
          @click="modal.hide('deleteReader'); deletionString = null;">Cancel</loading-button>
        <loading-button type="button" class="btn btn-primary btn-danger" data-dismiss="modal" @click="deleteReader"
          :disabled="deletionString !== reader.name">Delete Permanently</loading-button>
      </div>
    </modal>
    <section class="reader-page" v-if="reader">
      <div class="flex justify-content-between align-items-center mb-6">
        <h2 class="m-0"><router-link :to="{ name: 'User', params: { userid: reader.user_id } }">{{ reader.manager.full_name
            }}</router-link> > Readers > {{ reader.name }} ({{ reader.username }})</h2>
        <div class="page-actions">
          <button class="btn btn-primary" @click="modal.show('updateReader')">Edit Reader</button>
          <loading-button class="btn btn-danger" @click="modal.show('deleteReader')">Delete Reader</loading-button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="box box-info">
            <div class="box-body">
              <table class="table table-striped">
                <tbody>
                  <tr>
                    <th>Age</th>
                    <td>{{ reader.age }}</td>
                  </tr>
                  <tr>
                    <th>Created</th>
                    <td><span v-if="reader.created_at">{{ formatDate(reader.created_at) }}</span><span
                        v-else>Unknown</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <h3 v-if="readerChallenges.length > 0" class="mt-0">Challenges</h3>
          <box-with-icons v-for="(challenge, i) in readerChallenges" :key="i" class="mb-4">
            <div class="reader-challenge">
              <p class="flex gap-3"><strong>{{ challenge.name }}</strong> <small class="badge bg-light-blue">{{
                  challenge.slug }}</small></p>
              <table class="table">
                <thead>
                  <tr>
                    <th></th>
                    <th><i class="fa fa-book" /></th>
                    <th><i class="fa fa-star" /></th>
                    <th><i class="fa fa-clock-o" /></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(milestone, j) in challenge.majorMilestones" :key="j">
                    <th>M {{ milestone.sort }}</th>
                    <td
                      :class="(challenge.reader_stats.total_books_read >= milestone.config.total_books_read.count) ? 'success' : 'danger'">
                      {{ challenge.reader_stats.total_books_read }} / {{ milestone.config.total_books_read.count }}</td>
                    <td
                      :class="(challenge.reader_stats.total_stars_earned >= milestone.config.total_stars_earned.count) ? 'success' : 'danger'">
                      {{ challenge.reader_stats.total_stars_earned }} / {{ milestone.config.total_stars_earned.count }}
                    </td>
                    <td
                      :class="(challenge.reader_stats.total_reading_time >= milestone.config.total_reading_time.count) ? 'success' : 'danger'">
                      {{ challenge.reader_stats.total_reading_time }} / {{ milestone.config.total_reading_time.count }}
                    </td>
                    <td>
                      <confirmation-button :show-modal="showConfirmationModal['handle-certificate']"
                        @close="showConfirmationModal['handle-certificate'] = false"
                        @click="startCertificateHandling(milestone, challenge)">
                        <template #button><span
                            v-text="milestone.has_achieved_milestone ? 'Resend PDF' : 'Send PDF'"></span></template>
                        <template #modal>An email containing the certificate has been sent to the user and we've BCC'd
                          you in to it.</template>
                      </confirmation-button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="flex gap-3 my-2">
                <div v-for="(milestone, j) in challenge.minorMilestones" :key="j">
                  <confirmation-button
                    :btnClasses="milestone.has_achieved_milestone ? 'btn btn-primary' : 'btn btn-danger'"
                    :show-modal="showConfirmationModal['handle-certificate']"
                    @close="showConfirmationModal['handle-certificate'] = false"
                    @click="startCertificateHandling(milestone, challenge)">
                    <template #button><span v-text="milestone.name"></span></template>
                    <template #modal>An email containing the certificate has been sent to the user and we've BCC'd you
                      in to it.</template>
                  </confirmation-button>
                </div>
              </div>
              <div class="mt-4 flex gap-3">
                <template v-if="editingChallengeOrg === null || editingChallengeOrg.challenge !== challenge">
                  <div>
                    <span>Associated with School: {{ challenge.organisation ? `${challenge.organisation.name}
                      (${challenge.organisation.region})` : "None" }}</span>
                  </div>
                  <div>
                    <loading-button class="btn-xs" :isLoading="isLoadingChallengeOrgs"
                      @click="editChallengeOrg(challenge)"><i class="fa fa-pencil"></i>Edit</loading-button>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <p>Associate with: </p>
                    <div class="flex gap-3">
                      <select v-model="editingChallengeOrg.selected" class="form-control">
                        <option value="">-- No School --</option>
                        <option v-for="org in allChallengeOrgs[editingChallengeOrg.challenge.slug]" :key="org.id"
                          :value="org.id">{{ org.name }} ({{ org.region }})</option>
                      </select>
                      <loading-button class="btn-sm" :isLoading="isSavingChallengeOrg"
                        @click="saveChallengeOrg">Save</loading-button>
                      <button type="button" class="btn btn-sm" @click="editingChallengeOrg = null">Cancel</button>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </box-with-icons>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div>
            <h3>Latest reading activity</h3>
            <vue-good-table styleClass="vgt-table striped" theme="auris-admin" :columns="columns" :rows="sessions"
              :pagination-options="{
                enabled: true,
                perPage: 10,
              }">
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'start_time'" class="wrap">
                  {{ formatDate(props.formattedRow.start_time) }}
                </span>
                <span v-else-if="props.column.field === 'accuracy'" class="wrap">
                  {{ props.formattedRow.accuracy }}%
                </span>
                <span v-else-if="props.column.field === 'reading_time_seconds'" class="wrap">
                  {{ formatSeconds(props.formattedRow.reading_time_seconds) }}
                </span>
                <span v-else-if="props.column.field === 'has_completed_book'" class="wrap">
                  {{ props.formattedRow.has_completed_book ? 'Complete' : 'In progress' }}
                </span>
                <button v-else-if="props.column.field === 'recording' && props.formattedRow.recording"
                  @click="$router.push(`/recordings/${props.formattedRow.recording}`)"
                  class="btn btn-link user-link">[recording]</button>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              <template slot="loadingContent">
                <div class="loadingContent">
                  <i class="fa fa-refresh fa-spin"></i>
                </div>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </section>
  </wrapper>
</template>

<script>
import moment from 'moment';
import Wrapper from '@/components/Wrapper';
import BoxWithIcons from '@/components/BoxWithIcons';
import ModalJS from '@/components/utils/Modal';
import Modal from '@/classes/Modal';
import LoadingButton from '@/components/utils/LoadingButton';
import ConfirmationButton from '@/components/utils/ConfirmationButton';
import HelpersMixin from '@/mixins/HelpersMixin';
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';
import ReaderForm from '@/components/forms/ReaderForm';
import Form from '@/classes/Form';

export default {
  name: 'reader',
  mixins: [HelpersMixin],
  components: {
    ReaderForm,
    ConfirmationButton,
    LoadingButton,
    BoxWithIcons,
    Wrapper,
    ModalJS, // eslint-disable-line
    modal: ModalJS,
    VueGoodTable,
  },
  data() {
    return {
      reader: null,
      modal: new Modal({
        handleCertificate: false,
        deleteReader: false,
        updateReader: false,
      }),
      showConfirmationModal: {
        'handle-certificate': false,
      },
      currentChallenge: null,
      currentMilestone: null,
      sessions: [],
      deletionString: null,
      editingChallengeOrg: { challenge: null, current: null, selected: null },
      isLoadingChallengeOrgs: false,
      isSavingChallengeOrg: false,
      allChallengeOrgs: {},
      form: new Form({
        name: '',
        age: null,
      }),
      columns: [
        {
          label: 'Book',
          field: 'book_title',
          width: '200px',
        },
        {
          label: 'Level',
          field: 'book_reading_level',
          type: 'number',
        },
        {
          label: 'Date',
          field: 'start_time',
          type: 'string',
          width: '200px',
        },
        {
          label: 'Time',
          field: 'reading_time_seconds',
          type: 'number',
        },
        {
          label: 'Stars',
          field: 'stars',
          type: 'number',
        },
        {
          label: 'Accuracy',
          field: 'accuracy',
          type: 'decimal',
        },
        {
          label: 'Status',
          field: 'has_completed_book',
          type: 'boolean',
          width: '100px',
        },
        {
          label: '',
          field: 'recording',
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getReader();
      this.getReaderSessions();
    });
  },
  methods: {
    formatDate(date) {
      return moment(date).format('Do MMMM YY @ h:mmA');
    },
    async getReader() {
      const payload = {
        userId: this.$route.params.userid,
        readerId: this.$route.params.readerid,
      };

      await this.$store.dispatch('users/fetchUserReader', payload).then((reader) => {
        this.reader = reader;
        this.form.name = reader.name;
        this.form.age = reader.age;
      }).catch((err) => {
        this.$apiResponse.renderErrorMessage(err);
      });
    },
    getReaderSessions() {
      const payload = {
        userId: this.$route.params.userid,
        readerId: this.$route.params.readerid,
      };

      this.$store.dispatch('users/fetchUserReaderSessions', payload).then((sessions) => {
        this.sessions = sessions;
      }).catch((err) => {
        this.$apiResponse.renderErrorMessage(err);
      });
    },

    startCertificateHandling(milestone, challenge) {
      this.currentMilestone = milestone;
      this.currentChallenge = challenge;
      this.modal.show('handleCertificate');
    },

    handleCertificateAction(action) {
      this.$store.dispatch('app/setGlobalLoading', true);

      const payload = {
        userId: this.$route.params.userid,
        readerId: this.$route.params.readerid,
        data: {
          action,
          reward_id: this.currentMilestone.reward_id,
          challenge_slug: this.currentChallenge.slug,
        },
      };

      this.$store.dispatch('users/sendCertificateAction', payload).then((response) => {
        if (action === 'send-certificate') {
          this.showConfirmationModal['handle-certificate'] = true;
        }
        if (action === 'download-certificate') {
          this.downloadFile(response, 'certificate.pdf', 'application/pdf;');
        }
        this.modal.hide('handleCertificate');
        this.currentMilestone = null;
      }).catch((err) => {
        this.$apiResponse.renderErrorMessage(err);
      }).finally(() => {
        this.$store.dispatch('app/setGlobalLoading', false);
      });
    },
    updateReader() {
      this.$store.dispatch('app/setGlobalLoading', true);
      const payload = {
        userId: this.$route.params.userid,
        readerId: this.$route.params.readerid,
        data: {
          age: this.form.age,
          name: this.form.name,
        },
      };

      this.$store.dispatch('users/updateUserReader', payload).then(() => {
        this.modal.hide('updateReader');
        this.getReader();
      }).catch((err) => {
        this.$apiResponse.renderErrorMessage(err);
      }).finally(() => {
        this.$store.dispatch('app/setGlobalLoading', false);
      });
    },
    deleteReader() {
      this.$store.dispatch('app/setGlobalLoading', true);
      const payload = {
        userId: this.$route.params.userid,
        readerId: this.$route.params.readerid,
      };

      this.$store.dispatch('users/deleteUserReader', payload).then(() => {
        this.$router.push(`/users/${payload.userId}`);
      }).catch((err) => {
        this.$apiResponse.renderErrorMessage(err);
      }).finally(() => {
        this.$store.dispatch('app/setGlobalLoading', false);
      });
    },
    async editChallengeOrg(challenge) {
      if (!(challenge.slug in this.allChallengeOrgs)) {
        this.isLoadingChallengeOrgs = true;
        await this.$store.dispatch('challenges/getChallengeOrganisations', challenge.slug).then((organisations) => {
          this.allChallengeOrgs[challenge.slug] = organisations;
        }).catch((err) => {
          this.$apiResponse.renderErrorMessage(err);
        }).finally(() => {
          this.isLoadingChallengeOrgs = false;
        });
      }
      const currentOrg = this.reader.challenges.filter((c) => c.slug === challenge.slug)[0].organisation;
      const currentOrgId = currentOrg ? currentOrg.id : null;
      this.editingChallengeOrg = {
        challenge,
        current: currentOrgId,
        selected: currentOrgId,
      };
    },
    async saveChallengeOrg() {
      this.isSavingChallengeOrg = true;
      const payload = {
        challengeSlug: this.editingChallengeOrg.challenge.slug,
        readerId: this.reader.id,
        data: {
          organisation_id: this.editingChallengeOrg.selected,
        },
      };

      try {
        await this.$store.dispatch('users/associateReaderToOrg', payload);
        await this.getReader();
      } catch (e) {
        this.$apiResponse.renderErrorMessage(e);
      }
      this.isSavingChallengeOrg = false;
    },
  },
  computed: {
    readerChallenges() {
      return this.reader.challenges.map((challenge) => {
        const stats = challenge.reader_stats;
        challenge.majorMilestones = challenge.certificates.filter((c) => c.type === 'major_milestone').map((c) => {
          const milestone = challenge.milestones.filter((m) => m.reward_id === c.reward_id)[0];
          return { ...c, stats, config: milestone.config };
        });
        challenge.minorMilestones = challenge.certificates.filter((c) => c.type === 'minor_milestone');
        return challenge;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.reader-challenge {
  table {
    thead {
      th {
        text-align: center;
      }
    }

    tbody {
      th {
        font-weight: bold;
      }

      td {
        text-align: center;
      }
    }
  }
}

.page-actions {
  display: flex;
  justify-content: flex-end;

  button {
    margin: 0 5px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
