<template>
  <wrapper>
    <!-- Main content -->
    <section class="account-page">
      <div class="row">
        <div class="col-md-12">
          <div class="box box-info">
            <!-- Input Addons -->
            <div class="box-header with-border">
              <h3 class="box-title">Add admin</h3>
            </div>
            <div class="box-body">
              <account-form v-model="form" @validated="createAdmin" :isNew="true" :apiErrors="apiErrors"></account-form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </wrapper>
  <!-- /.content -->
</template>

<script>
import Wrapper from '@/components/Wrapper';
import Form from '@/classes/Form';
import AdminForm from '@/components/forms/AdminForm';

export default {
  name: 'account',
  components: {
    // AdminForm,
    'account-form': AdminForm,
    Wrapper,
  },
  data() {
    return {
      section: 'Create admin',
      admin: null,
      form: new Form({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        allowed_access: { categories: {}, is_super_admin: false },
      }),
      apiErrors: null,
    };
  },
  methods: {
    createAdmin() {
      this.$store.dispatch('app/setGlobalLoading', true);

      if (!this.form.password || this.form.password.trim().length === 0) {
        delete this.form.password;
      }

      this.form.allowed_access.categories = Object.keys(this.form.allowed_access.categories).filter((x) => this.form.allowed_access.categories[x]);

      this.form.post('admin/admins')
        .then(() => {
          this.$router.push('/admins/');
        })
        .catch((error) => {
          if (error.errors) {
            const entries = Object.entries(error.errors);
            this.apiErrors = entries;
            this.$apiResponse.renderErrorMessage(error, 'An error has occurred please see below.');
          } else {
            this.$apiResponse.renderErrorMessage(error);
          }
          this.$store.dispatch('app/setGlobalLoading', false);
        });
    },
  },
};
</script>
