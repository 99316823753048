import AdminsView from '@/views/Admins';
import AdminView from '@/views/Admin';
import AddAdminView from '@/views/AddAdmin';

export default ([
  {
    path: '/admins/new',
    component: AddAdminView,
    name: 'Add an Admin',
    meta: { description: 'Add a new admin', requiresAuth: true, isSuperAdmin: true },
  },
  {
    path: '/admins/:id',
    component: AdminView,
    name: 'Admin Account',
    meta: {
      description: 'Manage the account of this admin',
      requiresAuth: true,
      isSuperAdmin: true,
    },
  },
  {
    path: '/admins',
    component: AdminsView,
    name: 'Admins',
    meta: { description: 'View the admins', requiresAuth: true, isSuperAdmin: true },
  },
]);
