export default ({
  en: {
    attributes: {
      last_name: 'Last Name',
      first_name: 'First Name',
      email: 'Email',
      package_id: 'Package',
      max_readers: 'Max Readers',
      amount: 'Amount',
      duration_length: 'duration length',
      discount_value: 'discount value',
      max_devices: 'max devices',
      renewal_duration_length: 'renewal length',
      payment_amount: 'renewal price',
      primary_contact_name: 'Contact Name',
      primary_contact_job_role: 'Job Role',
      primary_address_line_1: 'Address Line 1',
      primary_address_line_2: 'Address Line 2',
      primary_address_line_3: 'Address Line 3',
      primary_address_line_4: 'Address Line 4',
      primary_address_postcode: 'Postcode',
      primary_address_country: 'Country',
      primary_address_telephone: 'Telephone',
      primary_email: 'Email',
      billing_contact_name: 'Contact Name',
      billing_contact_job_role: 'Job Role',
      billing_address_line_1: 'Address Line 1',
      billing_address_line_2: 'Address Line 2',
      billing_address_line_3: 'Address Line 3',
      billing_address_line_4: 'Address Line 4',
      billing_address_postcode: 'Postcode',
      billing_address_country: 'Country',
      billing_address_telephone: 'Telephone',
      billing_email: 'Email',
      user_first_name: 'First Name',
      user_last_name: 'Last Name',
      user_email: 'Email',
    },
  },
});
