var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-filters" }, [
    _c("div", { staticClass: "user-filter user-filter__break" }, [
      _vm._m(0),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.schoolId,
              expression: "schoolId"
            }
          ],
          attrs: { name: "schoolId", id: "schoolId" },
          on: {
            change: [
              function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.schoolId = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              _vm.onSelect
            ]
          }
        },
        [
          _c("option", { domProps: { value: null } }, [_vm._v("All")]),
          _vm._l(_vm.schools, function(school) {
            return _c(
              "option",
              { key: school.id, domProps: { value: school.id } },
              [_vm._v(_vm._s(school.name))]
            )
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "label-container" }, [
      _c("label", [_vm._v("School:")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }