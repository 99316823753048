import { render, staticRenderFns } from "./User.vue?vue&type=template&id=1ddff42c&scoped=true&"
import script from "./User.vue?vue&type=script&lang=js&"
export * from "./User.vue?vue&type=script&lang=js&"
import style0 from "./User.vue?vue&type=style&index=0&id=1ddff42c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ddff42c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/annelise/Documents/Code/admin-panel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1ddff42c')) {
      api.createRecord('1ddff42c', component.options)
    } else {
      api.reload('1ddff42c', component.options)
    }
    module.hot.accept("./User.vue?vue&type=template&id=1ddff42c&scoped=true&", function () {
      api.rerender('1ddff42c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/users/User.vue"
export default component.exports