<template>
  <div :class="['flipcard', (flipped ? 'flipcard--flipped' : ''), (flipping ? 'flipcard--flipping' : '')]">
    <span class="flipcard__flipbtn" @click="flipped = !flipped"></span>
    <div class="flipcard__inner" @transitionstart.self="flipping=true" @transitionend.self="flipping=false">
      <div class="flipcard__front">
        <slot name="front">This is the front of the card</slot>
      </div>
      <div class="flipcard__back">
        <slot name="back">This is the back of the card</slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'flipcard',
  data() {
    return {
      flipped: false,
      flipping: false,
    };
  },
};
</script>

<style lang="scss">
.flipcard {

  perspective: 1000px;

  &__inner {
    position: relative;
    transition: transform 0.6s ease-in-out;
    transform-style: preserve-3d;
    width: 100%;
    height: 100%;
  }

  &__front, &__back {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }

  &__back {
    transform: rotate3d(1, 1, 0, 180deg);
    width:100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
  }

  &__flipbtn  {
    width: 2em;
    height: 2em;
    position: absolute;
    z-index: 1;
    top:0;
    right:0;
    color: #dff2f6;
    line-height: 1;
    font-size: 0.9em;
    transition: opacity 0.2s;
    cursor: pointer;
    background: transparent;
    background: linear-gradient(225deg, rgba(110, 155, 204, 1) 50%, rgba(0, 0, 0, 0) 50%);
    text-align:right;
    &:after {
      font-family: 'FontAwesome';
      font-style: normal;
      content: '\F128'; // ?
      padding:0.2em 0.25em;
      display:block;
    }
  }

  &--flipped {
    .flipcard__inner {
      transform: rotate3d(1,1,0,180deg);
    }

    .flipcard__flipbtn {
      background: transparent;
      &:after {
        content: '\F00D';
        padding: 0.15em 0.2em;
      }
    }
  }

  // Hide the button during flip otherwise it just floats there during the transition
  &--flipping {
    .flipcard__flipbtn {
      opacity:0;
    }
  }
}
</style>
