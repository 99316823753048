<template>
<div class="user-filters">
  <div class="user-filter user-filter__break" v-if="showReaderType">
    <div class="label-container">
      <label>Reader Type:</label>
    </div>
    <select name="readerType" id="readerType" v-model="readerType" @change="onSelect">
      <option value="all">All</option>
      <option value="standard">Home</option>
      <option value="enterprise">School</option>
    </select>
  </div>
</div>
</template>

<script>
export default {
  name: 'reader-attribute-filters',
  props: {
    cssStyles: String,
    showSignupSource: {
      type: Boolean,
      default: true,
    },
    showReaderType: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      readerType: 'all',
    };
  },
  methods: {
    onSelect() {
      const obj = {};
      if (this.showReaderType) {
        obj.readerType = this.readerType ?? 'all';
      }
      this.$emit('select', obj);
    },
  },
};
</script>
