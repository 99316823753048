import api from './index';

const login = (payload) => (
  new Promise((resolve, reject) => {
    api.post('auth', payload).then((res) => {
      resolve(res);
    }).catch((err) => {
      reject(err);
    });
  })
);

const logout = () => (
  new Promise((resolve, reject) => {
    api.delete('auth').then((res) => {
      resolve(res);
    }).catch((err) => {
      reject(err);
    });
  })
);

export {
  login,
  logout,
};
