<template>
  <!-- Left side column. contains the logo and sidebar -->
  <aside class="main-sidebar">
    <section class="sidebar">
      <ul class="sidebar-menu" data-widget="tree">
        <li class="header">MAIN NAVIGATION</li>
        <router-link tag="li" active-class="active" class="pageLink" to="/" exact v-if="!accessRestricted">
          <a><i class="fa fa-desktop"></i> <span class="page">Dashboard</span></a>
        </router-link>
        <li class="treeview reports" v-if="hasAccess('reports')">
          <a href="#">
            <i class="fa fa-table"></i> <span>Reports</span>
            <span class="pull-right-container"><i class="fa fa-angle-left pull-right"></i></span>
          </a>
          <ul class="treeview-menu">
            <router-link tag="li" active-class="active" class="pageLink" to="/reports/books" exact>
              <a><i class="fa fa-circle-o"></i> <span class="page">Books Usage</span></a>
            </router-link>
            <router-link tag="li" active-class="active" class="pageLink" to="/reports/users" exact>
              <a><i class="fa fa-circle-o"></i> <span class="page">Users</span></a>
            </router-link>
            <router-link tag="li" active-class="active" class="pageLink" to="/reports/subscribers" exact>
              <a><i class="fa fa-circle-o"></i> <span class="page">Subscribers</span></a>
            </router-link>
            <router-link tag="li" active-class="active" class="pageLink" to="/reports/reading" exact>
              <a><i class="fa fa-circle-o"></i> <span class="page">Reading</span></a>
            </router-link>
             <router-link tag="li" active-class="active" class="pageLink" to="/reports/purchases" exact>
              <a><i class="fa fa-circle-o"></i> <span class="page">Purchases</span></a>
            </router-link>
            <router-link tag="li" active-class="active" class="pageLink" to="/reports/challenges" exact>
              <a><i class="fa fa-circle-o"></i> <span class="page">Challenges</span></a>
            </router-link>
          </ul>
        </li>
        <router-link tag="li" active-class="active" class="pageLink" to="/schools" v-if="hasAccess('schools')">
          <a><i class="fa fa-building"></i> <span class="page">Schools</span></a>
          <ul v-if="!!currentSchool" class="treeview-menu menu-open menu-fixed-open">
            <router-link tag="li" active-class="active" class="pageLink" :to="'/schools/'+currentSchool.id" v-if="!accessRestricted">
                <a><i class="fa fa-circle-o"></i>{{trimMenuText(currentSchool.name)}}</a>
            </router-link>
          </ul>
        </router-link>
        <router-link tag="li" active-class="active" class="pageLink" to="/users" exact v-if="hasAccess('users')">
          <a><i class="fa fa-users"></i> <span class="page">Users</span></a>
        </router-link>
        <router-link tag="li" active-class="active" class="pageLink" to="/promocodes" exact v-if="hasAccess('users')">
          <a><i class="fa fa-ticket"></i> <span class="page">Promocodes</span></a>
        </router-link>
        <router-link tag="li" active-class="active" class="pageLink" to="/recordings" exact v-if="hasAccess('recordings')">
          <a><i class="fa fa-microphone"></i> <span class="page">Recordings</span></a>
        </router-link>
        <!-- <router-link tag="li" active-class="active" class="pageLink" to="/leaderboard" exact>
          <a><i class="fa fa-table"></i> <span class="page">Leaderboard</span></a>
        </router-link> -->
        <router-link tag="li" active-class="active" class="pageLink" to="/admins" exact v-if="isSuperAdmin">
          <a><i class="fa fa-users"></i> <span class="page">Admins</span></a>
        </router-link>
      </ul>
    </section>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'sidebar',
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('schools', ['currentSchool']),
    accessRestricted() {
      return this.user.email === 'hannah@auris.tech';
    },
    isSuperAdmin() {
      if (this.user && this.user.allowed_access) {
        return this.user.allowed_access.is_super_admin;
      }

      return false;
    },
  },
  methods: {
    trimMenuText(str) {
      // Yes - this is ugly but the "proper" solution seems to be a refactor of the admin LTE css
      const maxLen = 30;
      let stop = false;
      return str.split(' ').reduce((acc, v) => {
        if (stop) { return acc; }
        if ((acc + v).length > maxLen) { stop = true; return acc; }
        return `${acc} ${v}`;
      });
    },
    hasAccess(where) {
      if (this.user && this.user.allowed_access) {
        if (this.user.allowed_access.categories) {
          return this.user.allowed_access.categories.includes(where) || this.user.allowed_access.categories.includes('all');
        }
      }

      return false;
    },
  },
};
</script>
