<template>
  <wrapper>
    <section class="school-page">
      <!-- Modals Start -->
      <modal title="School Subscription" v-if="modal.isVisible('subscription')" @close="handleCancelSubChanges" :show-footer="false">
        <school-subscription-form :value="subscription" @validated="handleSubChanges($event)" @cancel="handleCancelSubChanges"/>
      </modal>
      <modal title="Zoho sync" v-if="modal.isVisible('syncZoho')" @close="modal.hide('syncZoho')" :show-footer="false">
        <p class="lead">The sync is happening. Please do not re-click on the button.</p>
      </modal>
      <modal title="WARNING" v-if="modal.isVisible('loginas')" :show-footer="true" state-class="danger" @close="handleCloseLoginAs">
        <p>You will automatically be logged into the School Portal as <b>{{willLoginAs.full_name}}</b> {{willLoginAs.enterprise_user_role === 'admin' ? 'an Administrator' : 'a Teacher'}} of <b>{{this.currentSchool.name}}</b>.</p>
        <p>You should <b>never</b> use this feature without the prior consent of a school administrator.
            You will be viewing the information of teachers and pupils at this school.</p>
        <template slot="footer">
          <button class="btn btn-danger" @click="loginAs(willLoginAs.id)">Continue to School Portal</button>
        </template>
      </modal>
      <modal title="School Setup Link" v-if="modal.isVisible('setupLink')" :show-footer="true" @close="handleCloseSetupLink">
        <p>Send the URL below to the school administrator to complete setup.</p>
        <input id="schoolSetupLink" type="text" :value="schoolSetupLink.url">
        <template slot="footer">
          <button @click="copyUrl" class="btn btn-danger">Copy Url</button>
        </template>
      </modal>
      <!-- Modals End -->
      <div class="multi-select-outer-container">
        <div class="multi-select-container">
          <multiselect
            ref="schoolTags"
            class="school-tags"
            v-model="schoolTags"
            :options="availableTags"
            placeholder="Search or add a tag"
            tagPlaceholder="Click here to add a tag"
            :searchable="true"
            :multiple="true"
            :taggable="true"
            label="name"
            track-by="code"
            @tag="addTag"
          >
          </multiselect>
        </div>
        <label @click="openMultiselect()" class="multi-select-label">Click here to add a tag</label>
      </div>

      <div class="row match-height" v-if="currentSchool">

        <div class="col-6 col-lg-3">
          <fieldset class="stats-box stats-box-border white">
            <legend class="stats-box-legend">Basic Stats</legend>
            <ul class="stats-box-list">
              <li class="stats-box-list-item"><span>Teachers</span> <span>{{currentSchool.teachers.length}}</span></li>
              <li class="stats-box-list-item"><span>Classes</span> <span>{{currentSchool.groups.length}}</span></li>
              <li class="stats-box-list-item"><span>Pupils</span> <span>{{currentSchool.readers_count}}</span></li>
            </ul>
          </fieldset>
        </div>
        <div class="col-6 col-lg-3">
          <fieldset class="stats-box stats-box-border white">
            <legend class="stats-box-legend">Subscription</legend>
            <div class="stats-box subscription-status" :class="{'active-subscription': subscriptionIsActive, 'inactive-subscription': !subscriptionIsActive}">
              <p>{{subscriptionStatusString}}</p>
            </div>
            <ul v-if="subscriptionIsActive" class="stats-box-list mb-0">
              <li class="stats-box-list-item">{{subscriptionMaxReadersString}}</li>
              <li class="stats-box-list-item">{{subscriptionDurationString}}</li>
              <li class="stats-box-list-item">{{subscriptionExpiryString}}</li>
            </ul>
            <div v-if="subscriptionIsActive" class="stats-box-button text-center">
              <button class="btn btn-primary" @click="modal.show('subscription')">Change Subscription</button>
            </div>
            <div v-else class="stats-box-button text-center">
              <button class="btn btn-primary" @click="modal.show('subscription')">Add Subscription</button>
            </div>
            <div v-if="subscriptionStatusString === 'Expired'" class="text-center">
              <p>{{subscriptionExpiryString}}</p>
            </div>
          </fieldset>
        </div>
        <div class="col-6 col-lg-3">
          <fieldset class="stats-box stats-box-border white" v-if="!schoolHasAcceptedTerms">
            <legend class="stats-box-legend">Complete Setup</legend>
            <p class="stats-box-text"><strong>This school has not yet completed setup.</strong> Click the button below to generate a URL you can send to the school administrator.</p>
            <div class="stats-box-button text-center">
              <button class="btn btn-primary" @click="createSetupToken()">Create Setup Link</button>
            </div>
          </fieldset>
        </div>
        <div class="col-md-3">
          <div class="stats-box stats-box-links">
            <button class="btn btn-primary" @click="$router.push(`/schools/${currentSchool.id}/reports/books`)">View Books Report</button>
            <button class="btn btn-primary" @click="$router.push(`/schools/${currentSchool.id}/reports/reading`)">View Reading Report</button>
            <button class="btn btn-primary" @click="$router.push(`${currentSchool.id}/edit`)">Update School Information</button>
            <loading-button class="btn btn-flex" @click="handleRecordingsButtonClick()" :isLoading="recordingsButtonIsLoading"><div v-if="!recordingsButtonIsLoading" :class="`recording-indicator${currentSchool.can_enable_recordings ? ' can-enable' : ''}${currentSchool.recording_enabled ? ' enabled' : ''}`"></div>{{recordingsButtonString}}</loading-button>
          </div>
        </div>
      </div>
      <div class="row match-height">
        <div class="col-md-6">
          <vue-good-table
            styleClass="vgt-table striped"
            theme="auris-admin"
            :columns="tables.users.columns"
            :rows="tables.users.rows"
            :totalRows="tables.users.rows.length"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 10,
              position: 'bottom',
              perPageDropdown: [10, 25, 50, 100],
              dropdownAllowAll: false,
              setCurrentPage: 1,
              nextLabel: 'next',
              prevLabel: 'prev',
              rowsPerPageLabel: 'Rows per page',
              ofLabel: 'of',
              pageLabel: 'page', // for 'pages' mode
              allLabel: 'All',
            }"
            :search-options="{
              enabled: false,
              trigger: 'key-up',
              skipDiacritics: true,
              placeholder: 'Search this table',
            }"
          >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <button v-if="props.row.enterprise_user_role !== 'admin'" title="promote to admin" class="btn btn-link user-link" @click="setAsAdmin(props.row.id, true)"><font-awesome-icon :icon="['fas', 'arrow-circle-up']"/></button>
              <button v-if="props.row.enterprise_user_role === 'admin'" title="demote from admin" class="btn btn-link user-link" @click="setAsAdmin(props.row.id, false)"><font-awesome-icon :icon="['fas', 'arrow-circle-down']"/></button>
              <button title="edit user" class="btn btn-link user-link" @click="$router.push(`/users/${props.row.id}`)"><font-awesome-icon :icon="['fas', 'user']"/></button>
              <button title="login as user" class="btn btn-link user-link" xxclick="loginAs(props.row.id)" @click="warnLoginAs(props.row.id)"><font-awesome-icon :icon="['fas', 'user-secret']"/></button>
            </span>
            <span v-if="props.column.field == 'app_last_seen_at'" class="wrap">
              {{timeAgo(props.row.app_last_seen_at)}}<br><small><i>{{props.formattedRow[props.column.field]}}</i></small>
            </span>
            <span v-else-if="props.column.field == 'sp_last_seen_at'" class="wrap">
              {{timeAgo(props.row.sp_last_seen_at)}}<br><small><i>{{props.formattedRow[props.column.field]}}</i></small>
            </span>
            <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>
          </template>
          <template slot="loadingContent">
            <div class="loadingContent">
                <i class="fa fa-refresh fa-spin"></i>
            </div>
          </template>
          </vue-good-table>
        </div>
         <div class="col-md-6">
          <vue-good-table
            styleClass="vgt-table striped"
            theme="auris-admin"
            :columns="tables.classes.columns"
            :rows="tables.classes.rows"
            :totalRows="tables.classes.rows.length"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 10,
              position: 'bottom',
              perPageDropdown: [10, 25, 50, 100],
              dropdownAllowAll: false,
              setCurrentPage: 1,
              nextLabel: 'next',
              prevLabel: 'prev',
              rowsPerPageLabel: 'Rows per page',
              ofLabel: 'of',
              pageLabel: 'page', // for 'pages' mode
              allLabel: 'All',
            }"
            :search-options="{
              enabled: false,
              trigger: 'key-up',
              skipDiacritics: true,
              placeholder: 'Search this table',
            }"
          >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <button title="view book usage" class="btn btn-link user-link" @click="$router.push(`/schools/${currentSchool.id}/classes/${props.row.id}/reports/books`)"><font-awesome-icon :icon="['fas', 'book']"/></button>
              <button title="view reading report" class="btn btn-link user-link" @click="$router.push(`/schools/${currentSchool.id}/classes/${props.row.id}/reports/reading`)"><font-awesome-icon :icon="['fas', 'book-open']"/></button>
            </span>
            <span v-if="props.column.field == 'subscription.expires_at'" class="wrap">
              <span :class="getCellClass(props.row)">
                {{props.row.subscription ? formatDate(props.row.subscription.expires_at) : null}}
              </span>
            </span>
            <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>
          </template>
          <template slot="loadingContent">
            <div class="loadingContent">
                <i class="fa fa-refresh fa-spin"></i>
            </div>
          </template>
          </vue-good-table>
        </div>
      </div>
      <div class="row match-height">
        <div class="col-md-12">
          <vue-good-table
            styleClass="vgt-table striped"
            theme="auris-admin"
            :columns="tables.invites.columns"
            :rows="tables.invites.rows"
            :totalRows="tables.invites.rows.length"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 10,
              position: 'bottom',
              perPageDropdown: [10, 25, 50, 100],
              dropdownAllowAll: false,
              setCurrentPage: 1,
              nextLabel: 'next',
              prevLabel: 'prev',
              rowsPerPageLabel: 'Rows per page',
              ofLabel: 'of',
              pageLabel: 'page', // for 'pages' mode
              allLabel: 'All',
            }"
            :search-options="{
              enabled: false,
              trigger: 'key-up',
              skipDiacritics: true,
              placeholder: 'Search this table',
            }"
          >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'expires_at'">
              {{formatExpiryDate(props.row.expires_at, props.row.status)}}
            </span>
            <span v-else-if="props.column.field == 'status'" :class="props.row.status === 'accepted' ? 'text-success' : 'text-danger'">
              {{props.row.status}}
            </span>
            <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>
          </template>
          </vue-good-table>
        </div>
      </div>
      <div class="row match-height">
        <div v-if="currentSchool && currentSchool.primaryContact" class="col-md-6">
          <fieldset class="stats-box stats-box-border white">
            <legend class="stats-box-legend">Primary Contact</legend>
            <h2 class="stats-box-heading">{{currentSchool.primaryContact.name}}</h2>
            <ul class="stats-box-list">
              <li v-if="currentSchool.primaryContact.job_role" class="stats-box-list-item">{{currentSchool.primaryContact.job_role}}</li>
              <li v-if="currentSchool.primaryContact.email" class="stats-box-list-item"><a :href="getMailTo(currentSchool.primaryContact.email)">{{currentSchool.primaryContact.email}}</a></li>
              <li v-if="currentSchool.primaryContact.phone" class="stats-box-list-item">{{currentSchool.primaryContact.phone}}</li>
              <li v-if="currentSchool.primaryContact.address.line1" class="stats-box-list-item">{{currentSchool.primaryContact.address.line1}}</li>
              <li v-if="currentSchool.primaryContact.address.line2" class="stats-box-list-item">{{currentSchool.primaryContact.address.line2}}</li>
              <li v-if="currentSchool.primaryContact.address.line3" class="stats-box-list-item">{{currentSchool.primaryContact.address.line3}}</li>
              <li v-if="currentSchool.primaryContact.address.line4" class="stats-box-list-item">{{currentSchool.primaryContact.address.line4}}</li>
              <li v-if="currentSchool.primaryContact.address.postcode" class="stats-box-list-item">{{currentSchool.primaryContact.address.postcode}}</li>
            </ul>
          </fieldset>
        </div>
        <div v-if="currentSchool && currentSchool.billingContact" class="col-md-6">
          <fieldset class="stats-box stats-box-border white">
            <legend class="stats-box-legend">Billing Contact</legend>
            <div class="stats-box-address">
              <h2 class="stats-box-heading">{{currentSchool.billingContact.name}}</h2>
              <ul class="stats-box-list">
                <li v-if="currentSchool.billingContact.job_role" class="stats-box-list-item">{{currentSchool.billingContact.job_role}}</li>
                <li v-if="currentSchool.billingContact.email" class="stats-box-list-item"><a :href="getMailTo(currentSchool.billingContact.email)">{{currentSchool.billingContact.email}}</a></li>
                <li v-if="currentSchool.billingContact.phone" class="stats-box-list-item">{{currentSchool.billingContact.phone}}</li>
                <li v-if="currentSchool.billingContact.address.line1" class="stats-box-list-item">{{currentSchool.billingContact.address.line1}}</li>
                <li v-if="currentSchool.billingContact.address.line2" class="stats-box-list-item">{{currentSchool.billingContact.address.line2}}</li>
                <li v-if="currentSchool.billingContact.address.line3" class="stats-box-list-item">{{currentSchool.billingContact.address.line3}}</li>
                <li v-if="currentSchool.billingContact.address.line4" class="stats-box-list-item">{{currentSchool.billingContact.address.line4}}</li>
                <li v-if="currentSchool.billingContact.address.postcode" class="stats-box-list-item">{{currentSchool.billingContact.address.postcode}}</li>
              </ul>
            </div>
          </fieldset>
        </div>
      </div>
    </section>
  </wrapper>
</template>

<script>
import Multiselect from 'vue-multiselect';
import Wrapper from '@/components/Wrapper';
// import ContactDetails from '@/components/ContactDetails';
import SchoolMixin from '@/mixins/SchoolMixin';
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';
import moment from 'moment';
import config from '@/config';
import ModalJS from '@/components/utils/Modal';
import Modal from '@/classes/Modal';
import SchoolSubscriptionForm from '@/components/forms/SchoolSubscriptionForm';
import HelpersMixin from '@/mixins/HelpersMixin';
import LoadingButton from '@/components/utils/LoadingButton';
import { mapState } from 'vuex';

export default {
  name: 'school',
  mixins: [SchoolMixin, HelpersMixin],
  components: {
    Wrapper,
    VueGoodTable,
    ModalJS, // eslint-disable-line
    modal: ModalJS,
    SchoolSubscriptionForm,
    LoadingButton,
    Multiselect,
  },
  data() {
    return {
      modal: new Modal({
        subscription: false,
        loginas: false,
        setupLink: false,
        syncZoho: false,
      }),
      school: null,
      schoolSetupLink: {
        url: null,
        expiryDate: null,
      },
      subscription: {
        max_readers: null,
        expires_at: moment().add(1, 'year').format(),
        renewal_price: null,
        renewal_duration_unit: '',
        packages: [],
      },
      recordingsButtonIsLoading: false,
      schoolTags: [],
      availableSchoolTags: [],
      willLoginAs: null,
      tables: {
        // Users Table Start
        users: {
          rows: [],
          columns: [
            {
              label: 'User',
              field: 'full_name',
            },
            {
              label: 'Position',
              field: 'enterprise_user_role',
              formatFn: (data) => (data === 'admin' ? 'Administrator' : 'Teacher'),
            },
            {
              label: 'Last Seen (app)',
              field: 'app_last_seen_at',
              type: 'date',
              dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
              dateOutputFormat: 'do MMMM yyyy h:mma',
            },
            {
              label: 'Last Seen (sp)',
              field: 'sp_last_seen_at',
              type: 'date',
              dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
              dateOutputFormat: 'do MMMM yyyy h:mma',
            },
            {
              label: 'Actions',
              field: 'actions',
              tdClass: 'details-control',
              sortable: false,

            },
          ],
        },
        // Users Table End

        // Classes Table Start
        classes: {
          rows: [],
          columns: [
            {
              label: 'Class',
              field: 'name',
            },
            {
              label: 'Pupils',
              field: 'readers_count',
              type: 'number',
            },
            {
              label: 'Books',
              field: 'assigned_book_count',
              type: 'number',
            },
            {
              label: 'Actions',
              field: 'actions',
              tdClass: 'details-control',
              sortable: false,

            },
          ],
        },

        // Invites Table Start
        invites: {
          rows: [],
          columns: [
            {
              label: 'Invitee Email',
              field: 'email',
            },
            {
              label: 'Invitee Name',
              field: 'name',
            },
            {
              label: 'Invited By',
              field: 'inviter.full_name',
            },
            {
              label: 'Status',
              field: 'status',
            },
            {
              label: 'Sent',
              field: 'created_at',
              type: 'date',
              dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
              dateOutputFormat: 'do MMMM yyyy',
            },
            {
              label: 'Expires On',
              field: 'expires_at',
              type: 'number',
              sortable: false,
            },
          ],
        },
      },

    };
  },
  mounted() {
    this.getSchoolData();
    this.getInviteData();
    if (this.availableTags.length === 0) {
      this.$store.dispatch('schools/getAvailableTags').catch((error) => {
        this.$apiResponse.renderErrorMessage(error);
      });
    }
  },
  methods: {
    openMultiselect() {
      this.$refs.schoolTags.$el.focus();
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      };
      this.schoolTags.push(tag);
    },
    formatExpiryDate(value, status) {
      const mValue = moment(value);
      const mToday = moment();
      if (status === 'accepted' || mValue.isBefore(mToday)) {
        return '';
      }
      return mValue.format('Do MMMM yyyy');
    },
    handleRecordingsButtonClick() {
      this.recordingsButtonIsLoading = true;
      const newValue = !this.currentSchool.can_enable_recordings;
      const data = {
        id: this.currentSchool.id,
        data: {
          can_enable_recordings: newValue,
        },
      };
      this.$store.dispatch('schools/updateSchool', data).then(() => {
        this.currentSchool.can_enable_recordings = newValue;
      }).catch((e) => {
        this.$apiResponse.renderErrorMessage(e);
      }).finally(() => {
        this.recordingsButtonIsLoading = false;
      });
    },
    copyUrl() {
      const copyText = document.querySelector('#schoolSetupLink');
      copyText.select();
      document.execCommand('copy');
    },
    createSetupToken() {
      this.$store.dispatch('schools/createSetupToken', this.currentSchool.id)
        .then((response) => {
          const rData = response.data;
          this.schoolSetupLink.url = new URL(`${config.webPortalURL}/setup/${rData.token}`);
          this.modal.show('setupLink');
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
        });
    },
    getInviteData() {
      this.$store.dispatch('schools/getInviteData', this.$route.params.schoolId)
        .then((response) => {
          this.tables.invites.rows = response.data.invites;
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
        });
    },
    async getSchoolData() {
      const { schoolId } = this.$route.params;
      if (!this.currentSchool || (this.currentSchool && this.currentSchool.id !== schoolId)) {
        await this.$store.dispatch('schools/getSchoolById', this.$route.params.schoolId);
      }
      this.prepFormSubscription();
      this.tables.users.rows = this.currentSchool.teachers;
      this.tables.classes.rows = this.currentSchool.groups;
      this.$route.meta.title = this.currentSchool.name;
      this.$route.meta.description = this.currentSchool.urn || '';
      this.schoolTags = this.currentSchool.tags;
    },
    prepFormSubscription() {
      if (this.currentSchool.subscription) {
        this.subscription = {
          ...this.currentSchool.subscription,
          packages: [...this.currentSchool.subscription.items.map((s) => s.package_id)],
        };
        // this.subscription = this.currentSchool.subscription;
        // this.subscription.packages = this.currentSchool.subscription.items.map((s) => s.package_id);
      } else {
        this.subscription.packages = [];
      }
    },
    updateSchoolTags() {
      this.currentSchool.tags = this.schoolTags.map((t) => t.name);
      this.$store.dispatch('app/setGlobalLoading', true);
      const data = {
        id: this.currentSchool.id,
        data: {
          tags: this.currentSchool.tags,
        },
      };
      this.$store.dispatch('schools/updateSchool', data).catch((error) => {
        this.$apiResponse.renderErrorMessage(error);
      }).finally(() => this.$store.dispatch('app/setGlobalLoading', false));
    },
    getDurationString(amount, duration) {
      if (!amount || amount < 1) {
        return 'Free account';
      }
      return `£${this.formatCurrencyPlaces(amount)} per ${duration}`;
    },
    getMailTo(email) {
      return `mailto:${email}`;
    },
    formatDate(value) {
      return value != null ? moment(value).format('Do MMMM yyyy') : null;
    },
    timeAgo(str) {
      if (!str) { return 'Never'; }
      return moment(str).fromNow();
    },
    handleCloseSetupLink() {
      this.modal.hide('setupLink');
    },
    handleCancelSubChanges() {
      this.modal.hide('subscription');
      this.prepFormSubscription();
    },
    handleSubChanges() {
      this.$store.dispatch('app/setGlobalLoading', true);

      // eslint-disable-next-line
      const data = (({ max_readers, expires_at, payment_amount, renewal_duration_unit, packages }) => ({ max_readers, expires_at, payment_amount, renewal_duration_unit, packages }))(this.subscription);

      const subData = {
        id: this.currentSchool.id,
        data,
      };

      this.$store.dispatch('schools/handleSubChanges', subData).then((response) => {
        this.currentSchool.subscription = response.data.subscription;
        this.prepFormSubscription();
        this.$apiResponse.renderDisappearingSuccessMessage(response, 'The subscription has been updated');
        this.modal.hide('subscription');
      }).catch((error) => {
        this.$apiResponse.renderErrorMessage(error);
      }).finally(() => {
        this.$store.dispatch('app/setGlobalLoading', false);
      });
    },
    setAsAdmin(id, admin) {
      this.$store.dispatch('app/setGlobalLoading', true);
      const data = {
        schoolId: this.currentSchool.id,
        userId: id,
        admin,
      };

      this.$store.dispatch('schools/setUserAdminStatus', data).then((response) => {
        this.$apiResponse.renderDisappearingSuccessMessage(response, admin ? 'The teacher has been promoted' : 'The teacher has been demoted');
        this.getSchoolData();
      }).catch((error) => {
        this.$apiResponse.renderErrorMessage(error);
      }).finally(() => {
        this.$store.dispatch('app/setGlobalLoading', false);
      });
    },
    warnLoginAs(id) {
      const user = this.tables.users.rows.filter((u) => u.id === id)[0];
      this.willLoginAs = user;
      this.modal.show('loginas');
    },
    handleCloseLoginAs() {
      this.modal.hide('loginas');
      this.willLoginAs = null;
    },
    loginAs(id) {
      this.$store.dispatch('auth/loginWithUserId', id).then((response) => {
        window.open(`${config.webPortalURL}/token/${response.data.token}`);
      }).catch((error) => {
        this.$apiResponse.renderErrorMessage(error);
      });
    },
    // syncZoho() {
    //   const data = { action: 'sync-zoho' };
    //   api.post(`admin/enterprises/${this.school.id}/actions`, data)
    //     .then(() => {
    //       this.modal.show('syncZoho');
    //     })
    //     .catch((error) => {
    //       this.$apiResponse.renderErrorMessage(error);
    //     });
    // },
  },
  computed: {
    ...mapState({
      availableTags: (state) => state.schools.availableTags,
      currentSchool: (state) => state.schools.currentSchool,
    }),
    hasSubscription() {
      return this.currentSchool && this.currentSchool.subscription;
    },
    subscriptionIsActive() {
      if (this.hasSubscription) {
        return !this.currentSchool.subscription.has_expired;
      }
      return false;
    },
    subscriptionStatusString() {
      if (!this.hasSubscription) {
        return 'Inactive';
      } if (this.subscriptionIsActive) {
        return 'Active';
      }
      return 'Expired';
    },
    subscriptionMaxReadersString() {
      return this.subscriptionIsActive ? `${this.currentSchool.subscription.max_readers} pupils` : 'No subscription';
    },
    subscriptionDurationString() {
      return this.subscriptionIsActive ? this.getDurationString(this.currentSchool.subscription.payment_amount, this.currentSchool.subscription.renewal_duration_unit) : 'No subscription';
    },
    subscriptionExpiryString() {
      return this.subscriptionIsActive ? `Expires ${this.formatDate(this.currentSchool.subscription.expires_at)}` : `Expired ${this.formatDate(this.currentSchool.subscription.expires_at)}`;
    },
    schoolNameString() {
      return this.currentSchool && this.currentSchool.name ? this.currentSchool.name : 'School Name';
    },
    schoolUrnString() {
      return this.currentSchool && this.currentSchool.urn ? this.currentSchool.urn : 'Urn';
    },
    schoolHasAcceptedTerms() {
      return !!(this.currentSchool && this.currentSchool.terms_accepted_at);
    },
    recordingsButtonString() {
      return this.currentSchool.can_enable_recordings ? 'Prevent Enable Recordings' : 'Allow Enable Recordings';
    },
  },
  watch: {
    schoolTags() {
      this.updateSchoolTags();
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.school-tags.multiselect .multiselect__tags {
  background-color: #f5f6f7;
  border: none;
}
.school-tags.multiselect .multiselect__input {
  height: 25px;
  width: 100%;
  border-radius: 0;
}

.school-tags.multiselect .multiselect__input {
  background-color: #f5f6f7;
}

.multi-select-container {
  justify-content: space-evenly;
  flex-direction: row;
  width: calc(100% - 160px);
}

.multi-select-label {
  margin-left: 15px;
}

.multi-select-outer-container {
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 30px);
  @media (min-width: 992px) { // bootstrap lg breakpoint
    width: calc(80% - 30px);
  }
  @media (min-width: 1200px) { // bootstrap xl breakpoint
    width: calc(50% - 30px);
  }
  margin-bottom: 15px;
}
</style>
