<template>
  <wrapper>
    <section class="promocode-page" v-if="pageReady">
      <promocode-form v-model="form" :pricing-options="pricingOptions" @validated="addPromocode"></promocode-form>
    </section>
  </wrapper>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import api from '@/api';
import Wrapper from '@/components/Wrapper';
import Form from '@/classes/Form';
import PromocodeMixin from '@/mixins/PromocodeMixin';
import PromocodeForm from '@/components/forms/PromocodeForm';

export default {
  name: 'add-promocode',
  components: {
    Wrapper,
    PromocodeForm,
  },
  mixins: [PromocodeMixin],
  data() {
    return {
      pageReady: false,
      form: new Form({
        code: null,
        target_type: 'subscription',
        type: 'coupon',
        discount_value: null,
        duration_length: null,
        duration_unit: 'months',
        valid_from: moment().toDate(),
        valid_until: moment().add(2, 'years').toDate(),
        min_user_created_at: null,
        max_uses_per_user: 1,
        max_uses_global: null,
        min_subscription_created_at: null,
        only_first_time_subscribers: true,
        exclude_current_subscribers: true,
        valid_options: [],
        discount_type: 'percent_off',
      }),
      pricingOptions: {},
    };
  },
  mounted() {
    this.getValidPricingOptions();
  },
  methods: {
    getValidPricingOptions() {
      api.get('admin/promocodes/valid_pricing_options')
        .then((response) => {
          let pricingOptions = response.data.valid_pricing_options;
          pricingOptions = pricingOptions.filter((p) => !p.is_archived);
          _.each(pricingOptions, (price) => {
            this.pricingOptions[price.id] = this.textifyPricingOption(price);
          });
          this.pageReady = true;
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
        });
    },
    addPromocode() {
      this.$store.dispatch('app/setGlobalLoading', true);
      api.post('admin/promocodes', this.formatData())
        .then((response) => {
          const { promocode } = response.data;
          this.$router.push(`/promocodes/${promocode.id}`);
        })
        .catch((error) => {
          this.$store.dispatch('app/setGlobalLoading', false);
          this.$apiResponse.renderErrorMessage(error);
        });
    },
    formatData() {
      let values = this.form.data();

      values.valid_from = moment(values.valid_from).startOf('day');
      if (values.valid_until !== null) {
        values.valid_until = moment(values.valid_until).endOf('day');
      }
      if (values.min_user_created_at !== null) {
        values.min_user_created_at = moment(values.min_user_created_at).startOf('day');
      }
      if (values.min_subscription_created_at !== null) {
        values.min_subscription_created_at = moment(values.min_subscription_created_at).startOf('day');
      }

      // remove null values
      values = _.omitBy(values, _.isNil);

      // parse to int
      if (!_.isEmpty(values.valid_options)) {
        values.valid_options = values.valid_options.map((x) => parseInt(x, 10));
      } else {
        delete values.valid_options;
      }
      return values;
    },
  },
};
</script>
