import _ from 'lodash';

export default class {
  constructor(data = {}) {
    this.id = data.id || null;
    this.name = data.contact_name || null;
    this.job_role = data.job_role || null;
    this.address = {
      line1: data.address_line_1 || null,
      line2: data.address_line_2 || null,
      line3: data.address_line_3 || null,
      line4: data.address_line_4 || null,
      postcode: data.postcode || null,
      countryCode: data.country_code || null,
      region: data.region || null,
      city: data.city || null,
      county: data.county || null,
    };
    this.phone = data.telephone || null;
    this.email = data.email || null;
    this.type = data.type || 'primary';
  }

  getFormObject() {
    return {
      id: this.id,
      type: this.type,
      job_role: this.job_role,
      contact_name: this.name,
      address_line_1: this.address.line1,
      address_line_2: this.address.line2,
      postcode: this.address.postcode,
      country_code: this.address.countryCode,
      city: this.address.city,
      county: this.address.county,
      telephone: this.phone,
      region: this.address.region,
      email: this.email,
    };
  }

  isTheSame(contact) {
    return this.job_role === contact.job_role && this.name === contact.name && this.phone === contact.phone && this.email === contact.email && _.isEqual(this.address, contact.address);
  }
}
