<template>
  <div class="datepicker-wrapper">
    <DatePicker
      v-model="dateRange"
      :mode="mode"
      :is-range="true"
      @input="handleDateRangeUpdated($event)"
      :popover="{ placement: 'bottom-start', visibility: 'click' }"
      :min-date="new Date(2018,0,1)"
      :max-date="maxDate"
      :masks="masks"
    >
      <template v-slot="{inputValue, inputEvents}">
        <input
          :value="inputValue.start"
          v-on="inputEvents.start"
          class="input-inner"
        />
        <svg
          class="w-4 h-4 mx-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M14 5l7 7m0 0l-7 7m7-7H3"
          />
        </svg>
        <input
          :value="inputValue.end"
          v-on="inputEvents.end"
          class="input-inner input-inner__right"
        />
      </template>
    </DatePicker>
    <div class="dropdown-toggle" v-if="showDropDown">
      <button class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" type="button" aria-haspopup="true" aria-expanded="false" :id="reference"><i class="fa fa-calendar"></i></button>
      <ul class="dropdown-menu" :aria-labelledby="reference" @click="handleChangeTimePeriod($event)">
        <li class="dropdown-item"><button type="button">Today</button></li>
        <li class="dropdown-item"><button type="button">Yesterday</button></li>
        <li class="dropdown-item"><button type="button">Last 7 days</button></li>
        <li class="dropdown-item"><button type="button">Last 30 days</button></li>
        <li class="dropdown-item"><button type="button">This month</button></li>
        <li class="dropdown-item"><button type="button">Last month</button></li>
        <li class="dropdown-item"><button type="button">This year</button></li>
        <li class="dropdown-item"><button type="button">Last year</button></li>
      </ul>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .w-4 {
    width: 1rem;
  }

  .mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }
  .h-4 {
    height: 1rem;
  }
  .datepicker-wrapper {
    border: 1px solid #ccc;
    display: flex;
    box-sizing: border-box;
    height: 35px;
    position: relative;
    width: fit-content;
  }

  .input-inner {
    border-top: none;
    border-bottom: none;
    border-left: none;
    border-right: 1px solid #ccc;
    width: calc(50% - 1rem);
    padding: 2px 10px 3px 10px;
    line-height: 14px;
    font-size: 11.9px;
    color: rgb(51, 51, 51);
    height: 100%;
    margin-left: 0;
  }

  .input-inner__right {
    border-left: 1px solid #ccc;
    margin-right: 0;
  }

  .container {
    width: 100%;
    display: flex;
  }

  .dropdown-toggle {
    border: none;
    border-left: none;
    width: 40px;
    height: 100%;
    background: rgb(236,240,245)
  }

  .btn {
    border: none;
  }

  .btn:hover {
    border: 1px solid #5C6BC0;
    transform: scale(1.1, 1.1)
  }

  .dropdown-menu > .dropdown-item > button {
    display: inline-block;
    width: 100%;
    border: none;
    background-color: unset;
    &:hover {
      background-color:#5C6BC0;
      color: white;
      width: 100%;
    }
  }

  .dropdown-menu {
    width: 100%;
  }

  .vc-popover-content.direction-bottom.vc-text-gray-900.vc-bg-white.vc-border.vc-border-gray-400.vc-rounded-lg  {
    border-radius: 0;
  }

</style>
<script>
import moment from 'moment';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

export default {
  // props: ['reference', 'initialDateRange', 'initialDateRange'],
  props: {
    reference: {},
    initialDateRange: {},
    value: {},
    mode: {
      default: 'date',
    },
    maxDate: {
      required: false,
      default: () => new Date(),
    },
    showDropDown: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  components: { DatePicker },
  data() {
    return {
      dateRange: null,
      masks: {
        input: this.maskInput,
      },
    };
  },
  created() {
    if (this.initialDateRange) {
      this.dateRange = this.initialDateRange;
    }
    if (this.value) {
      this.dateRange = this.value;
    }
  },
  watch: {
    value() {
      this.dateRange = this.value;
    },
  },
  methods: {
    handleDateRangeUpdated(e) {
      let dateRangeObject = {
        start: moment(e.start).startOf('day').toDate(),
        end: moment(e.end).endOf('day').toDate(),
      };
      if (this.mode === 'dateTime') {
        dateRangeObject = {
          start: moment(e.start).toDate(),
          end: moment(e.end).toDate(),
        };
      }
      this.$emit('date-range-change', dateRangeObject);
      this.$emit('input', dateRangeObject);
    },
    handleChangeTimePeriod(e) {
      const today = new Date();
      const value = e.target.innerHTML;
      let start = moment(today);
      let end = moment(today);
      switch (value) {
        case 'Yesterday':
          start = moment(today).subtract(1, 'days');
          end = moment(today).subtract(1, 'days');
          break;
        case 'Last 7 days':
          start = moment(today).subtract(7, 'days');
          end = moment(today);
          break;
        case 'Last 30 days':
          start = moment(today).subtract(30, 'days');
          end = moment(today);
          break;
        case 'This month':
          start = moment().startOf('month');
          end = moment(today);
          break;
        case 'Last month':
          start = moment().subtract(1, 'month').startOf('month');
          end = moment().subtract(1, 'month').endOf('month');
          break;
        case '3 months':
          start = moment().subtract(3, 'months');
          end = moment(today);
          break;
        case '6 months':
          start = moment().subtract(6, 'months');
          end = moment(today);
          break;
        case 'This year':
          start = moment().startOf('year');
          end = moment(today);
          break;
        case 'Last year':
          start = moment().subtract(1, 'year').startOf('year');
          end = moment().subtract(1, 'year').endOf('year');
          break;
        default:
      }
      this.dateRange = {
        start: start.startOf('day').toDate(),
        end: end.endOf('day').toDate(),
      };
      const dateRangeObject = {
        start: this.dateRange.start,
        end: this.dateRange.end,
      };
      this.$emit('date-range-change', dateRangeObject);
      this.$emit('input', dateRangeObject);
    },
  },
  computed: {
    maskInput() {
      if (this.mode === 'dateTime') {
        return 'DD/MM/YYYY HH:MM:SS';
      }

      return 'DD/MM/YYYY';
    },
  },
};
</script>
