import { mapState, mapGetters } from 'vuex';

export default ({
  computed: {
    ...mapState('app', ['dateRange']),
    ...mapGetters('app', ['dateRangeModel']),
  },
  methods: {
    handleChangeDateRange(dateRange, callback = null) {
      this.$store.dispatch('app/setDateRange', dateRange);

      // call component specific callback
      if (callback) this[callback]();
    },
  },
  beforeMount() {
    if (this.dateRange.startDate === null) {
      this.$store.dispatch('app/loadDateRangeFromStorage');
    }
  },
});
