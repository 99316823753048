<template>
  <wrapper>
    <section class="admins-page">
      <modal title="Delete Admin" v-if="modal.isVisible('delete')" @close="modal.hide('delete')">
        <p class="text-danger lead">This admin will be permanently deleted. <br/> This action is irreversible.</p>
        <div class="modal-footer">
          <slot name="footer"></slot>
          <button type="button" class="btn btn-warning" data-dismiss="modal" @click="modal.hide('delete')">Cancel</button>
          <button type="button" class="btn btn-primary btn-danger" data-dismiss="modal" @click="deleteAdmin">Delete Permanently</button>
        </div>
      </modal>
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 box-tools">
          <div class="pull-right">
            <button class="btn btn-primary" @click="$router.push('/admins/new')"><i class="fa fa-plus"></i> Add an admin
            </button>
          </div>
        </div>
      </div>
      <vue-good-table
          styleClass="vgt-table striped"
          theme="auris-admin"
          :columns="columns"
          :rows="admins"
          :totalRows="totalRecords"
          :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: 10,
          position: 'bottom',
          perPageDropdown: [10, 25, 50, 100],
          dropdownAllowAll: false,
          setCurrentPage: 1,
          nextLabel: 'next',
          prevLabel: 'prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page', // for 'pages' mode
          allLabel: 'All',
        }"
          :search-options="{
          enabled: true,
          trigger: 'key-up',
          skipDiacritics: true,
          placeholder: 'Search this table',
        }"
      >

        <template slot="table-row" slot-scope="props">
          <template v-if="props.column.field == 'actions'">
            <button @click="goToAdminPage($event)" class="btn btn-link user-link" :data-id="props.row.id"><i class="fa fa-edit"></i> Manage</button>
            <button
                @click="beginDeleteAdmin({id: props.row.id})"
                class="btn btn-link delete" :data-id="props.row.id"
            >
              <i class="fa fa-trash"></i>Delete
            </button>
          </template>
        </template>
      </vue-good-table>
    </section>
  </wrapper>
</template>

<script>
import Wrapper from '@/components/Wrapper';
import api from '@/api';
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';
import $ from 'jquery';
import ModalJS from '@/components/utils/Modal';
import Modal from '@/classes/Modal';
import { mapState } from 'vuex';

export default {
  name: 'admins',
  components: {
    Wrapper,
    VueGoodTable,
    ModalJS, // eslint-disable-line
    modal: ModalJS,
  },
  computed: {
    ...mapState({
      admins: (state) => state.admins.admins,
      totalRecords: (state) => state.admins.admins.length || 0,
    }),
  },
  data() {
    return {
      itemToBeDeleted: null,
      modal: new Modal({
        delete: false,
      }),
      columns: [
        {
          label: 'First Name',
          field: 'first_name',
        },
        {
          label: 'Last Name',
          field: 'last_name',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Actions',
          field: 'actions',
          tdClass: 'details-control',
          sortable: false,
        },
      ],
      previousSearchQuery: null,
    };
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.$store.dispatch('app/setGlobalLoading', true);
      this.$store.dispatch('admins/getAdmins').catch((error) => {
        this.$apiResponse.renderErrorMessage(error);
      }).finally(() => {
        this.$store.dispatch('app/setGlobalLoading', false);
      });
    },
    goToAdminPage(e) {
      const id = $(e.currentTarget).data('id');
      this.$router.push(`/admins/${id}`);
    },
    beginDeleteAdmin({ id }) {
      this.itemToBeDeleted = { id };
      this.modal.show('delete');
    },
    deleteAdmin() {
      api.delete(`admin/admins/${this.itemToBeDeleted.id}`)
        .then(() => {
          this.refreshData();
        })
        .catch((error) => {
          this.$store.dispatch('app/setGlobalLoading', false);
          this.$apiResponse.renderErrorMessage(error);
        })
        .finally(() => {
          this.modal.hide('delete');
          this.$store.dispatch('app/setGlobalLoading', false);
        });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'Admin Account') {
      this.$store.dispatch('admins/destroyTableState');
    }
    next(true);
  },
};
</script>
