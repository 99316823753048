import ReportsView from '@/views/Reports';
import UsersReportView from '@/views/reports/UsersReport';
import BooksUsageView from '@/views/reports/BooksUsage';
import SubscribersReportView from '@/views/reports/SubscribersReport';
import ReadingReportView from '@/views/reports/ReadingReport';
import PurchasesView from '@/views/reports/Purchases';
import ChallengesReportView from '@/views/reports/ChallengesReport';

export default ([
  {
    path: 'reports',
    alias: '/reports',
    component: ReportsView,
    name: 'Reports',
    meta: { requiresAuth: true, authorize: ['reports'] },
    children: [
      {
        path: 'books',
        alias: '',
        component: BooksUsageView,
        name: 'Reports: Books Usage',
        meta: { requiresAuth: true },
      },
      {
        path: 'users',
        component: UsersReportView,
        name: 'Users',
        meta: { requiresAuth: true },
      },
      {
        path: 'subscribers',
        component: SubscribersReportView,
        name: 'Subscribers',
        meta: { requiresAuth: true },
      },
      {
        path: 'reading',
        component: ReadingReportView,
        name: 'Reading',
        meta: { requiresAuth: true },
      },
      {
        path: 'purchases',
        component: PurchasesView,
        name: 'Purchases',
        meta: { requiresAuth: true },
      },
      {
        path: 'challenges',
        component: ChallengesReportView,
        name: 'challenges',
        meta: { requiresAuth: true },
      },
    ],
  },
]);
