import api from '@/api';
import { isCancel } from 'axios';

let reportLoaderToken = null;

export default {
  data() {
    return {
      cancelToken: null,
    };
  },
  methods: {
    getBooksUsage({
      startDate,
      endDate,
      delay = 10,
      lastPage = 0,
      showReadingSessions = false,
      schoolId,
      classId,
      assignedTo,
      isCsvRequest,
      authorsArray,
      collectionsArray,
      publishersArray,
      challenge,
      readerType,
    }) {
      if (classId) {
        assignedTo = 'class';
      }
      if (!isCsvRequest) {
        if (reportLoaderToken) {
          reportLoaderToken.cancel('Cancelling request as we have a different one incoming');
        }
        reportLoaderToken = api.CancelToken.source();
        return new Promise((resolve, reject) => {
          api.get(`statistics/books/reports?type=usage&delay=${delay}&start_time=${startDate.toISOString()}&end_time=${endDate.toISOString()}&last_page=${lastPage}&challenge=${challenge}&reader_type=${readerType}&show_reading_sessions=${showReadingSessions}&enterprise_id=${schoolId || ''}&enterprise_group_id=${classId || ''}&assigned_to=${assignedTo || ''}`,
            { cancelToken: reportLoaderToken.token })
            .then((response) => {
              const { books } = response.data;
              resolve(books);
            })
            .catch((error) => {
              // catch and ignore cancellation warnings
              if (!isCancel(error)) {
                this.$apiResponse.renderErrorMessage(error);
              }
              reject(error);
            });
        });
      }
      return new Promise((resolve, reject) => {
        api.get(`statistics/books/reports?type=usage&delay=${delay}&start_time=${startDate.toISOString()}&end_time=${endDate.toISOString()}&last_page=${lastPage}&challenge=${challenge}&reader_type=${readerType}&show_reading_sessions=${showReadingSessions}&enterprise_id=${schoolId || ''}&enterprise_group_id=${classId || ''}&assigned_to=${assignedTo || ''}&response=csv${authorsArray ? `&authors=${authorsArray}` : ''}${collectionsArray ? `&collections=${collectionsArray}` : ''}${publishersArray ? `&publishers=${publishersArray}` : ''}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            this.$apiResponse.renderErrorMessage(error);
            reject(error);
          });
      });
    },
    getPurchases({ startDate, endDate }) {
      return new Promise((resolve, reject) => {
        api.get(`admin/purchases/statistics?start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            this.$apiResponse.renderErrorMessage(error);
            reject(error);
          });
      });
    },
  },
};
