<template>
  <p v-bind:class="{ downOnPrevious: isNegative, upOnPrevious: isPositive }">{{percentage}}&percnt;</p>
</template>

<style lang="scss" scoped>
    .upOnPrevious::before {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        margin-top: 3px;
        margin-left: -20px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid green;
    }

    .downOnPrevious::before {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        margin-left: -20px;
        margin-top: 6px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #f00;
    }
</style>

<script>
export default {
  name: 'percentage',
  props: {
    percentage: Number,
  },
  computed: {
    isPositive() {
      return (this.percentage > 0);
    },
    isNegative() {
      return (this.percentage < 0);
    },
  },
};
</script>
