import api from '@/api';

export default ({
  namespaced: true,
  state: () => ({
    admins: [],
    activeAdmin: null,
  }),
  mutations: {
    setAdmins(state, admins) {
      state.admins = admins;
    },
    setActiveAdmin(state, admin) {
      state.activeAdmin = admin;
    },
    destroyTableState(state) {
      state.admins = [];
    },
  },
  actions: {
    getAdmins({ commit }) {
      return new Promise((resolve, reject) => {
        api.get('/admin/admins').then((response) => {
          const { admins } = response.data;
          commit('setAdmins', admins);
          resolve(admins);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getAdminById({ commit }, id) {
      return new Promise((resolve, reject) => {
        api.get(`/admin/admins/${id}`).then((response) => {
          const { admin } = response.data;
          commit('setActiveAdmin', admin);
          resolve(admin);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getBookResources(context, resource) {
      return new Promise((resolve, reject) => {
        api.get(`admin/books/${resource}`).then((response) => {
          const responseResource = response.data[resource];
          const filteredResource = responseResource.filter((w) => w !== null && w !== '');
          resolve(filteredResource);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    destroyTableState({ commit }) {
      commit('destroyTableState');
    },
  },
});
