import api from '@/api';

// sync dateRange to localStorage
const syncDateRange = (dateRange) => {
  if (window.localStorage) {
    window.localStorage.setItem('default_date_range', JSON.stringify(dateRange));
  }
};

export default ({
  namespaced: true,
  state: () => ({
    dateRange: {
      startDate: null,
      endDate: null,
    },
    pageHeading: '',
    isLoading: false,
  }),
  mutations: {
    setDateRange(state, payload) {
      state.dateRange.startDate = new Date(payload.start);
      state.dateRange.endDate = new Date(payload.end);
      syncDateRange(state.dateRange);
    },
    resetDateRange(state) {
      // set default start date to 7 days ago for proper range
      state.dateRange.startDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);

      // set default end date to today
      state.dateRange.endDate = new Date();

      syncDateRange(state.dateRange);
    },
    loadDateRangeFromStorage(state) {
      if (window.localStorage) {
        const storageDate = window.localStorage.getItem('default_date_range');
        const storageDateObj = JSON.parse(storageDate);

        if (storageDateObj) {
          const { startDate, endDate } = storageDateObj;
          state.dateRange.startDate = new Date(startDate);
          state.dateRange.endDate = new Date(endDate);
        } else {
          // set default start date to 7 days ago for proper range
          state.dateRange.startDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);

          // set default end date to today
          state.dateRange.endDate = new Date();

          syncDateRange(state.dateRange);
        }
      }
    },
    setPageHeading(state, pageHeading) {
      state.pageHeading = pageHeading;
    },
    setGlobalLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  actions: {
    setDateRange({ commit }, payload) {
      commit('setDateRange', payload);
    },
    resetDateRange({ commit }) {
      commit('resetDateRange');
    },
    loadDateRangeFromStorage({ commit }) {
      commit('loadDateRangeFromStorage');
    },
    setPageHeading({ commit }, pageHeading) {
      commit('setPageHeading', pageHeading);
    },
    fetchCountries() {
      return api.get('/app/config/countries');
    },
    setGlobalLoading({ commit }, isLoading) {
      commit('setGlobalLoading', isLoading);
    },
  },
  getters: {
    dateRangeModel: (state) => {
      const { startDate, endDate } = state.dateRange;
      return {
        start: startDate,
        end: endDate,
      };
    },
  },
});
