<template>
  <div>
    <modal title="Confirmation" v-if="showModal" :show-footer="!hideModalFooter" @close="closeModal">
      <slot name="modal"></slot>
    </modal>
    <loading-button :class="btnClasses" @click="$emit('click')">
      <slot name="button"></slot>
    </loading-button>
  </div>
</template>

<script>
import ModalJS from '@/components/utils/Modal';
import Modal from '@/classes/Modal';
import LoadingButton from '@/components/utils/LoadingButton';

export default {
  name: 'confirmation-button',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    hideModalFooter: {
      type: Boolean,
      default: false,
    },
    btnClasses: {
      type: String,
      default: 'btn btn-info',
    },
  },
  components: {
    LoadingButton,
    ModalJS, // eslint-disable-line
    modal: ModalJS,
  },
  data() {
    return {
      modal: new Modal({
        confirmation: false,
      }),
    };
  },
  methods: {
    closeModal() {
      this.modal.hide('confirmation');
      this.$emit('close');
    },
  },
};
</script>
