var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "subscription-package-with-form" }, [
    _c(
      "div",
      [
        _c("free-trial-subscription-form", {
          on: {
            validated: function($event) {
              return _vm.addCustomSubscription(_vm.form)
            }
          },
          model: {
            value: _vm.form,
            callback: function($$v) {
              _vm.form = $$v
            },
            expression: "form"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      _vm._l(_vm.sPackage.variants, function(variant, i) {
        return _c(
          "div",
          { key: i, staticClass: "predefined-buttons" },
          _vm._l(variant.prices, function(price) {
            return _c(
              "loading-button",
              {
                key: price.id,
                on: {
                  click: function($event) {
                    return _vm.addPredefinedSubscription(
                      _vm.sPackage.id,
                      variant.id,
                      price.id
                    )
                  }
                }
              },
              [_vm._v(_vm._s(_vm.displayPredefinedOption(variant, price)))]
            )
          }),
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }