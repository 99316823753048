import api from '@/api';

export default ({
  namespaced: true,
  state: () => ({
    reportLoaderToken: null,
  }),
  mutations: {
    setReportLoaderToken(state, token) {
      state.reportLoaderToken = token;
    },
  },
  actions: {
    getBooksUsage({ commit, state }, payload) {
      const {
        isCsvRequest, delay, startDate, endDate, lastPage, challenge, readerType,
        showReaderSessions, authorsArray, collectionsArray, publishersArray,
        schoolId, classId, assignedTo,
      } = payload;

      const params = {
        type: 'usage',
        delay: delay || 10,
        start_time: startDate,
        end_time: endDate,
        last_page: lastPage || 0,
        challenge: challenge || 'undefined',
        reader_type: readerType || 'undefined',
        show_reader_sessions: showReaderSessions || false,
        enterprise_id: schoolId || '',
        enterprise_group_id: classId || '',
        assigned_to: assignedTo || '',
      };

      if (isCsvRequest) {
        params.response = 'csv';
        if (authorsArray) {
          params.authors = authorsArray;
        }
        if (collectionsArray) {
          params.collections = collectionsArray;
        }
        if (publishersArray) {
          params.publishers = publishersArray;
        }
      }

      const data = { params };

      if (!isCsvRequest) {
        if (state.reportLoaderToken) {
          state.reportLoaderToken.cancel('Cancelling request as we have a different one incoming');
          commit('setReportLoaderToken', null);
        }
        commit('setReportLoaderToken', api.CancelToken.source());
        data.cancelToken = state.reportLoaderToken.token;
      }

      return new Promise((resolve, reject) => {
        api.get('statistics/books/reports', data).then((response) => {
          if (!isCsvRequest) {
            const { books } = response.data;
            resolve(books);
          } else {
            resolve(response);
          }
        }).catch((error) => {
          reject(error);
        }).finally(() => {
          if (!isCsvRequest) {
            commit('setReportLoaderToken', null);
          }
        });
      });
    },
    getPurchases(context, params) {
      return new Promise((resolve, reject) => {
        api.get('admin/purchases/statistics', { params }).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getChallengesReport(context, challenge) {
      const params = {
        include: 'total_challenge_readers,total_challenge_organisation_readers,total_challenge_users,challenge_organisations',
        challenge,
      };
      return api.get('reports/views', { params });
    },
  },
});
