var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "audio-player",
      class: { "show-skip-controls": _vm.isAudioLoaded },
      on: { mousemove: _vm.scrub, mouseup: _vm.stopScrubbing }
    },
    [
      _c("div", { staticClass: "audio-player__main" }, [
        _vm.isLoading
          ? _c("div", { staticClass: "audio-player__loading" }, [
              _c("div", { staticClass: "audio-player__loading--spinner" })
            ])
          : _vm._e(),
        !_vm.isLoading
          ? _c(
              "div",
              {
                staticClass: "audio-player__button audio-player__button--play",
                on: {
                  click: function($event) {
                    return _vm.togglePlay()
                  }
                }
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "18",
                      height: "24",
                      viewBox: "0 0 18 24"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        fill: "#566574",
                        "fill-rule": "evenodd",
                        d:
                          _vm.status === "playing"
                            ? "M0 0h6v24H0zM12 0h6v24h-6z"
                            : "M18 12L0 24V0"
                      }
                    })
                  ]
                )
              ]
            )
          : _vm._e(),
        _c("div", { staticClass: "audio-player__controls" }, [
          _c("span", { staticClass: "audio-player__time--current" }, [
            _vm._v(_vm._s(_vm.currentTime))
          ]),
          _c(
            "div",
            {
              staticClass: "audio-player__slider-hit-area",
              on: { mousedown: _vm.startScrubbing }
            },
            [
              _c(
                "div",
                {
                  ref: "durationSlider",
                  staticClass: "audio-player__slider",
                  attrs: { "data-direction": "horizontal" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "audio-player__progress",
                      style: { width: _vm.durationPercent }
                    },
                    [_c("div", { staticClass: "audio-player__progress--pin" })]
                  )
                ]
              )
            ]
          ),
          _c("span", { staticClass: "audio-player__time--total" }, [
            _vm._v(_vm._s(_vm.totalTime))
          ])
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isAudioLoaded && _vm.allowDownload,
                expression: "isAudioLoaded && allowDownload"
              }
            ],
            staticClass: "audio-player__button audio-player__button--playx",
            on: { click: _vm.downloadAudioFile }
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "20",
                  height: "20",
                  viewBox: "0 0 26 26"
                }
              },
              [
                _c("path", {
                  attrs: {
                    fill: "#566574",
                    d:
                      "M20 21c0-0.547-0.453-1-1-1s-1 0.453-1 1 0.453 1 1 1 1-0.453 1-1zM24 21c0-0.547-0.453-1-1-1s-1 0.453-1 1 0.453 1 1 1 1-0.453 1-1zM26 17.5v5c0 0.828-0.672 1.5-1.5 1.5h-23c-0.828 0-1.5-0.672-1.5-1.5v-5c0-0.828 0.672-1.5 1.5-1.5h7.266l2.109 2.125c0.578 0.562 1.328 0.875 2.125 0.875s1.547-0.313 2.125-0.875l2.125-2.125h7.25c0.828 0 1.5 0.672 1.5 1.5zM20.922 8.609c0.156 0.375 0.078 0.812-0.219 1.094l-7 7c-0.187 0.203-0.453 0.297-0.703 0.297s-0.516-0.094-0.703-0.297l-7-7c-0.297-0.281-0.375-0.719-0.219-1.094 0.156-0.359 0.516-0.609 0.922-0.609h4v-7c0-0.547 0.453-1 1-1h4c0.547 0 1 0.453 1 1v7h4c0.406 0 0.766 0.25 0.922 0.609z"
                  }
                })
              ]
            )
          ]
        )
      ]),
      _c("div", { staticClass: "audio-player__extra" }, [
        _c("div", { staticClass: "audio-player__skip_controls" }, [
          _c(
            "div",
            {
              staticClass: "audio-player__control--circle",
              on: {
                click: function($event) {
                  return _vm.skipRelative(-5)
                }
              }
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 399.69 399.28"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      fill: "currentColor",
                      d:
                        "m74.24 355.6 20.06-30.93c3.3 2.31 6.44 4.46 9.52 6.67a161.44 161.44 0 0 0 67.63 28.17 165.22 165.22 0 0 0 79.89-5.93c27.16-8.76 50-24.27 69.16-45.39a156.85 156.85 0 0 0 37-70.12 162.8 162.8 0 0 0 4.75-40.07 170 170 0 0 0-7.08-45.76 154.73 154.73 0 0 0-29.63-55.59c-20-24-44.44-41.57-74.24-51.22-20.71-6.7-42-9.67-63.79-7.88-27.54 2.25-53.18 10.4-76.36 25.63a161.18 161.18 0 0 0-47.4 47.69c-.16.26-.28.55-.59 1.17h61.43v37.28H0V24.72h37.14v58.47c39.07-51.85 90.19-80.54 154.6-83s118.24 21.2 160.12 70c69 80.39 61.5 198.66-10.71 270.61-74.1 73.79-188.8 76.66-266.91 14.8Z"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      fill: "currentColor",
                      d:
                        "M177.09 239.63c2.59 11.48 15.28 16.73 28.09 16.73 14.52 0 28.84-9.82 28.84-27.23 0-15.66-13.46-25.48-29.06-25.48-14.09 0-22.81 7.39-26.36 13h-21.09l6.35-76.92h86.4v19.06h-66.5l-3.55 37.93h1.19c3.12-4.28 13.13-11.28 29.59-11.28 25.39 0 47 15.56 47 43 0 28.1-21.73 46.68-53.69 46.68-28.84 0-48.75-15.27-49.93-35.5Z"
                    }
                  })
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "audio-player__control--circle",
              on: {
                click: function($event) {
                  return _vm.skipRelative(-2)
                }
              }
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 399.69 399.28"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      fill: "currentColor",
                      d:
                        "m74.24 355.6 20.06-30.93c3.3 2.31 6.44 4.46 9.52 6.67a161.44 161.44 0 0 0 67.63 28.17 165.22 165.22 0 0 0 79.89-5.93c27.16-8.76 50-24.27 69.16-45.39a156.85 156.85 0 0 0 37-70.12 162.8 162.8 0 0 0 4.75-40.07 170 170 0 0 0-7.08-45.76 154.73 154.73 0 0 0-29.63-55.59c-20-24-44.44-41.57-74.24-51.22-20.71-6.7-42-9.67-63.79-7.88-27.54 2.25-53.18 10.4-76.36 25.63a161.18 161.18 0 0 0-47.4 47.69c-.16.26-.28.55-.59 1.17h61.43v37.28H0V24.72h37.14v58.47c39.07-51.85 90.19-80.54 154.6-83s118.24 21.2 160.12 70c69 80.39 61.5 198.66-10.71 270.61-74.1 73.79-188.8 76.66-266.91 14.8Z"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      fill: "currentColor",
                      d:
                        "M152.78 179.55v-1.07c0-20.23 16.14-40.16 48.42-40.16 29.06 0 48 15.85 48 36.85 0 17.7-12.06 29-25.4 42.11l-36.26 36v1.17h64.24v19.25h-98.14v-14.51l54.56-54c8-7.88 16.57-16.63 16.57-28 0-12-10.22-19.94-24.1-19.94-15.5 0-24.54 9.92-24.54 21.1v1.17Z"
                    }
                  })
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "audio-player__control--circle",
              on: {
                click: function($event) {
                  return _vm.skipRelative(2)
                }
              }
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 399.69 399.28"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      fill: "currentColor",
                      d:
                        "m325.45 355.6-20.06-30.93c-3.3 2.31-6.44 4.46-9.52 6.67a161.34 161.34 0 0 1-67.63 28.17 165.23 165.23 0 0 1-79.89-5.93c-27.16-8.76-50-24.27-69.16-45.39a156.85 156.85 0 0 1-37-70.12A163.28 163.28 0 0 1 37.44 198a170 170 0 0 1 7.08-45.76 154.73 154.73 0 0 1 29.63-55.6c19.95-24 44.45-41.57 74.25-51.22 20.7-6.7 42-9.67 63.78-7.88 27.54 2.25 53.18 10.4 76.36 25.63a161.18 161.18 0 0 1 47.4 47.69c.16.26.28.55.59 1.17H275.1v37.28h124.59V24.72h-37.14v58.47C323.48 31.34 272.36 2.65 208 .18s-118.24 21.2-160.12 70c-69 80.39-61.5 198.66 10.72 270.61 74.04 73.8 188.74 76.67 266.85 14.81Z"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      fill: "currentColor",
                      d:
                        "M150.78 177.55v-1.07c0-20.23 16.14-40.16 48.42-40.16 29.06 0 48 15.85 48 36.85 0 17.7-12.06 29-25.4 42.11l-36.26 36v1.17h64.24v19.25h-98.14v-14.51l54.56-54c8-7.88 16.57-16.63 16.57-28 0-12-10.22-19.94-24.1-19.94-15.5 0-24.54 9.92-24.54 21.1v1.17Z"
                    }
                  })
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "audio-player__control--circle",
              on: {
                click: function($event) {
                  return _vm.skipRelative(5)
                }
              }
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 399.69 399.28"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      fill: "currentColor",
                      d:
                        "m325.45 355.6-20.06-30.93c-3.3 2.31-6.44 4.46-9.52 6.67a161.34 161.34 0 0 1-67.63 28.17 165.23 165.23 0 0 1-79.89-5.93c-27.16-8.76-50-24.27-69.16-45.39a156.85 156.85 0 0 1-37-70.12A163.28 163.28 0 0 1 37.44 198a170 170 0 0 1 7.08-45.76 154.73 154.73 0 0 1 29.63-55.6c19.95-24 44.45-41.57 74.25-51.22 20.7-6.7 42-9.67 63.78-7.88 27.54 2.25 53.18 10.4 76.36 25.63a161.18 161.18 0 0 1 47.4 47.69c.16.26.28.55.59 1.17H275.1v37.28h124.59V24.72h-37.14v58.47C323.48 31.34 272.36 2.65 208 .18s-118.24 21.2-160.12 70c-69 80.39-61.5 198.66 10.72 270.61 74.04 73.8 188.74 76.67 266.85 14.81Z"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      fill: "currentColor",
                      d:
                        "M177.09 239.63c2.59 11.48 15.28 16.73 28.09 16.73 14.52 0 28.84-9.82 28.84-27.23 0-15.66-13.45-25.48-29.06-25.48-14.09 0-22.81 7.39-26.36 13h-21.09l6.35-76.92h86.4v19.06h-66.5l-3.55 37.93h1.19c3.12-4.28 13.13-11.28 29.59-11.28 25.39 0 47 15.56 47 43 0 28.1-21.73 46.68-53.69 46.68-28.84 0-48.75-15.27-49.93-35.5Z"
                    }
                  })
                ]
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }