var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("wrapper", [
    _vm.pageReady
      ? _c("section", { staticClass: "subscription-page" }, [
          _c("h2", { staticClass: "text-center" }, [
            _vm._v(_vm._s(_vm.user.full_name))
          ]),
          _vm.packages.length > 0
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.filteredPackages, function(pack, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "col-md-12" },
                      [
                        _c("div", { staticClass: "decorator__line" }, [
                          _c("h3", [_vm._v(_vm._s(pack.title))]),
                          _c("hr")
                        ]),
                        pack.has_subscription
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass: "bg-warning p-3",
                                  staticStyle: { border: "1px solid #ff922e" }
                                },
                                [
                                  _vm._v(
                                    "A free trial is already applied for this package"
                                  )
                                ]
                              )
                            ]
                          : [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-md-12" },
                                  [
                                    _c("subscription-package-with-form", {
                                      attrs: { "s-package": pack }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]
                      ],
                      2
                    )
                  }),
                  0
                )
              ])
            : _c("div", { staticClass: "col-md-12" }, [
                _c("div", { staticClass: "alert alert-warning" }, [
                  _vm._v(
                    "There are currently no subscription packages available for this user. "
                  )
                ])
              ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }