var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "datepicker-wrapper" },
    [
      _c("DatePicker", {
        attrs: {
          mode: _vm.mode,
          "is-range": true,
          popover: { placement: "bottom-start", visibility: "click" },
          "min-date": new Date(2018, 0, 1),
          "max-date": _vm.maxDate,
          masks: _vm.masks
        },
        on: {
          input: function($event) {
            return _vm.handleDateRangeUpdated($event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var inputValue = ref.inputValue
              var inputEvents = ref.inputEvents
              return [
                _c(
                  "input",
                  _vm._g(
                    {
                      staticClass: "input-inner",
                      domProps: { value: inputValue.start }
                    },
                    inputEvents.start
                  )
                ),
                _c(
                  "svg",
                  {
                    staticClass: "w-4 h-4 mx-2",
                    attrs: {
                      fill: "none",
                      viewBox: "0 0 24 24",
                      stroke: "currentColor"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        "stroke-width": "2",
                        d: "M14 5l7 7m0 0l-7 7m7-7H3"
                      }
                    })
                  ]
                ),
                _c(
                  "input",
                  _vm._g(
                    {
                      staticClass: "input-inner input-inner__right",
                      domProps: { value: inputValue.end }
                    },
                    inputEvents.end
                  )
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.dateRange,
          callback: function($$v) {
            _vm.dateRange = $$v
          },
          expression: "dateRange"
        }
      }),
      _vm.showDropDown
        ? _c("div", { staticClass: "dropdown-toggle" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary dropdown-toggle",
                attrs: {
                  "data-toggle": "dropdown",
                  type: "button",
                  "aria-haspopup": "true",
                  "aria-expanded": "false",
                  id: _vm.reference
                }
              },
              [_c("i", { staticClass: "fa fa-calendar" })]
            ),
            _c(
              "ul",
              {
                staticClass: "dropdown-menu",
                attrs: { "aria-labelledby": _vm.reference },
                on: {
                  click: function($event) {
                    return _vm.handleChangeTimePeriod($event)
                  }
                }
              },
              [
                _vm._m(0),
                _vm._m(1),
                _vm._m(2),
                _vm._m(3),
                _vm._m(4),
                _vm._m(5),
                _vm._m(6),
                _vm._m(7)
              ]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "dropdown-item" }, [
      _c("button", { attrs: { type: "button" } }, [_vm._v("Today")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "dropdown-item" }, [
      _c("button", { attrs: { type: "button" } }, [_vm._v("Yesterday")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "dropdown-item" }, [
      _c("button", { attrs: { type: "button" } }, [_vm._v("Last 7 days")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "dropdown-item" }, [
      _c("button", { attrs: { type: "button" } }, [_vm._v("Last 30 days")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "dropdown-item" }, [
      _c("button", { attrs: { type: "button" } }, [_vm._v("This month")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "dropdown-item" }, [
      _c("button", { attrs: { type: "button" } }, [_vm._v("Last month")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "dropdown-item" }, [
      _c("button", { attrs: { type: "button" } }, [_vm._v("This year")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "dropdown-item" }, [
      _c("button", { attrs: { type: "button" } }, [_vm._v("Last year")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }