<template>
  <div class="subscribers-report-page">
    <div class="row">
      <div class="col-md-2">
        <div class="info-box">
          <span class="info-box-icon bg-aqua">
            <i class="fa fa-user"></i>
          </span>
          <div class="info-box-content">
            <span class="info-box-text">New Subscribers</span>
            <span class="info-box-number">{{ charts.newSubscribers.total('combined') === null ? 0 : charts.newSubscribers.total('combined') }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="info-box">
          <span class="info-box-icon bg-yellow">
            <i class="fa fa-flag"></i>
          </span>
          <div class="info-box-content" v-if="output === 'both'">
            <span class="info-box-text">Stripe - Single</span>
            <span class="info-box-number">{{ getTotal('stripe - single') }}</span>
          </div>
          <div class="info-box-content" v-else>
            <span class="info-box-text">Stripe</span>
            <span class="info-box-number">{{ getTotal('stripe') }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="info-box">
          <span class="info-box-icon bg-green">
            <i class="fa fa-flag"></i>
          </span>
          <div class="info-box-content" v-if="output === 'both'">
            <span class="info-box-text">Stripe - Family</span>
            <span class="info-box-number">{{ getTotal('stripe - family') }}</span>
          </div>
          <div class="info-box-content" v-else>
            <span class="info-box-text">iTunes</span>
            <span class="info-box-number">{{ getTotal('itunes') }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="info-box">
          <span class="info-box-icon bg-red">
            <i class="fa fa-flag"></i>
          </span>
          <div class="info-box-content" v-if="output === 'both'">
            <span class="info-box-text">iTunes - Single</span>
            <span class="info-box-number">{{ getTotal('itunes - single') }}</span>
          </div>
          <div class="info-box-content" v-else>
            <span class="info-box-text">Single</span>
            <span class="info-box-number">{{ getTotal('single') }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="info-box">
          <span class="info-box-icon bg-fuchsia">
            <i class="fa fa-flag"></i>
          </span>
          <div class="info-box-content" v-if="output === 'both'">
            <span class="info-box-text">iTunes - Family</span>
            <span class="info-box-number">{{ getTotal('itunes - family') }}</span>
          </div>
          <div class="info-box-content" v-else>
            <span class="info-box-text">Family</span>
            <span class="info-box-number">{{ getTotal('family') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-6">
        <div style="display: flex; gap: 2em">
        <div style="display: flex; align-items:center; gap: 0.5em; white-space: nowrap;">
          <label>Filter by date:</label>
          <DatePickerWithDropdown
            reference="time_period"
            @date-range-change="handleChangeDateRange($event, 'dateRangeUpdated')"
            v-model="dateRangeModel"
            :initialDateRange="dateRangeModel"
          />
        </div>
        <user-attribute-filters @select="onFilter" :show-user-type="false"></user-attribute-filters>
        </div>
      </div>

      <div class="chart-output col-md-6">
          <ul>
            <li>Segment by:</li>
            <li @click="choose('package')" :class="{'active': output === 'package'}">Package</li>
            <li @click="choose('provider')" :class="{'active': output === 'provider'}">Provider</li>
            <li @click="choose('both')" :class="{'active': output === 'both'}">Both</li>
          </ul>
      </div>
    </div>
    <div class="row">
      <div class="charts-container col-md-12">
        <chart :chart="charts.activeSubscribers"></chart>
      </div>
      <!-- <div class="charts-container col-md-12">
        <chart :chart="charts.subscribedVSNotSubscribed"></chart>
      </div> -->
      <div class="charts-container col-md-12">
        <chart :chart="charts.newSubscribers" class="mr-5"></chart>
        <chart :chart="charts.lostSubscribers"></chart>
      </div>
    </div>

  </div>
</template>
<script>
import _ from 'lodash';
import ChartMixin from '@/mixins/ChartMixin';
import DateRangeMixin from '@/mixins/DateRangeMixin';
import DatePickerWithDropdown from '@/components/DatePickerWithDropdown';
import { Chart, ChartCanvas, ChartConfig } from '@/plugins/Chart';
import UserAttributeFilters from '@/components/UserAttributeFilters';

const chartColors = {
  Stripe: '#f39c12',
  Combined: '#00c0ef',
  iTunes: '#00a65a',
  'Stripe - Single': '#f39c12',
  'Stripe - n/a': '#0057A6',
  'iTunes - n/a': '#09914E',
  'iTunes - Single': '#dd4b39',
  'iTunes - Family': '#f012be',
  Single: '#dd4b39',
  Family: '#f012be',
  'n/a': '#33FF9C',
};

export default {
  name: 'subscribers-report',
  mixins: [ChartMixin, DateRangeMixin],
  components: {
    chart: ChartCanvas,
    DatePickerWithDropdown,
    UserAttributeFilters,
  },
  data() {
    return {
      charts: {
        lostSubscribers: new Chart(
          'lost-subscribers',
          new ChartConfig()
            .withTitle('Subscribers Churn')
            .withYAxisFormatter(this.yAxisInteger)
            .setColors(chartColors)
            .isType('bar')
            .isStacked()
            .isTimeSeries(),
        ),
        newSubscribers: new Chart(
          'new-subscribers',
          new ChartConfig().withTitle('New Subscribers').isTimeSeries()
            .setColors(chartColors)
            .withYAxisFormatter(this.yAxisInteger)
            .withCustomTool({
              icon: "<i class='fa fa-search'></i>",
              index: -1,
              title: 'View underlying data',
              class: 'view-data-icon',
              click: () => {
                this.$router.push({ path: '/users', query: { subscribed_date_range: true } });
              },
            }),
        ),
        activeSubscribers: new Chart(
          'active-subscribers-over-time',
          new ChartConfig()
            .withTitle('Active Subscribers')
            .setColors(chartColors)
            .isTimeSeries(),
        ),
      },
      output: 'provider',
      doubleChartForTotals: null,
      challenge: 'all',
    };
  },
  created() {
    Chart.setCallback(this.getChart);
  },
  beforeMount() {
    this.fetchCharts();
    this.initChartToGetTotals();
  },
  methods: {
    onFilter(e) {
      this.challenge = e.challenge;
      this.fetchCharts();
    },
    initChartToGetTotals() {
      if (this.output === 'both') {
        return;
      }
      // let's call newSubscribers report for the other output type to fill the totals
      const chart = new Chart('new-subscribers');
      chart.setStartDate(this.dateRange.startDate).setEndDate(this.dateRange.endDate);
      chart.setOutput(this.output === 'provider' ? 'package' : 'provider');
      this.getChart(chart)
        .then((response) => {
          this.doubleChartForTotals = response.data.chart.total === null ? 0 : response.data.chart.total;
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
        });
    },
    dateRangeUpdated() {
      this.fetchCharts();
      this.initChartToGetTotals();
    },
    choose(output) {
      this.output = output;
      this.fetchCharts();
      this.initChartToGetTotals();
    },
    fetchCharts() {
      _.forEach(this.charts, (chart) => {
        chart.setStartDate(this.dateRange.startDate).setEndDate(this.dateRange.endDate);
        chart.setOutput(this.output);
        chart.setParams({ challenge: this.challenge, user_type: 'all' });
      });
      this.fetchDataForCharts(this.charts);
    },
    getTotal(key) {
      switch (this.output) {
        case 'provider':
          switch (key) {
            case 'itunes':
            case 'stripe':
              return this.charts.newSubscribers.total(key) === null ? 0 : this.charts.newSubscribers.total(key);
            case 'single':
            case 'family':
              if (this.doubleChartForTotals === null) {
                return 0;
              }
              return this.doubleChartForTotals[key] !== undefined ? this.doubleChartForTotals[key] : 0;
            default:
              return 0;
          }
        case 'package':
          switch (key) {
            case 'itunes':
            case 'stripe':
              if (this.doubleChartForTotals === null) {
                return 0;
              }
              return this.doubleChartForTotals[key] !== undefined ? this.doubleChartForTotals[key] : 0;
            case 'single':
            case 'family':
              return this.charts.newSubscribers.total(key) === null ? 0 : this.charts.newSubscribers.total(key);
            default:
              return 0;
          }
        case 'both':
          return this.charts.newSubscribers.total(key) === null ? 0 : this.charts.newSubscribers.total(key);
        default:
          return 0;
      }
    },
  },
};
</script>
