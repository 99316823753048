var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "challenges-report-page" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-2" },
        [
          _c(
            "flip-card",
            { attrs: { id: "db-readers-in-schools" } },
            [
              _c("dash-box", {
                attrs: {
                  slot: "front",
                  title: "Schools Registered",
                  value: _vm.totalOrgs.total,
                  active_value: _vm.totalOrgs.active
                },
                slot: "front"
              }),
              _c(
                "div",
                {
                  staticClass: "dashbox__help",
                  attrs: { slot: "back" },
                  slot: "back"
                },
                [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.totalOrgs.total.toLocaleString("en")))
                  ]),
                  _vm._v(" schools have registered, "),
                  _c("i", { staticClass: "fa fa-bolt" }),
                  _vm._v(" "),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.totalOrgs.active.toLocaleString("en")))
                  ]),
                  _vm._v(" have at least 1 child who has read something.")
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-2" },
        [
          _c(
            "flip-card",
            { attrs: { id: "db-readers-in-schools" } },
            [
              _c("dash-box", {
                attrs: {
                  slot: "front",
                  title: "Parents Registered",
                  value: _vm.totalUsers.total,
                  active_value: _vm.totalUsers.active
                },
                slot: "front"
              }),
              _c(
                "div",
                {
                  staticClass: "dashbox__help",
                  attrs: { slot: "back" },
                  slot: "back"
                },
                [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.totalUsers.total.toLocaleString("en")))
                  ]),
                  _vm._v(" parents have registered, "),
                  _c("i", { staticClass: "fa fa-bolt" }),
                  _vm._v(" "),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.totalUsers.active.toLocaleString("en")))
                  ]),
                  _vm._v(" have at least 1 child who has read something.")
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-2" },
        [
          _c(
            "flip-card",
            { attrs: { id: "db-readers-registered" } },
            [
              _c("dash-box", {
                attrs: {
                  slot: "front",
                  title: "Readers Registered",
                  value: _vm.totalReaders.total,
                  active_value: _vm.totalReaders.active
                },
                slot: "front"
              }),
              _c(
                "div",
                {
                  staticClass: "dashbox__help",
                  attrs: { slot: "back" },
                  slot: "back"
                },
                [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.totalReaders.total.toLocaleString("en")))
                  ]),
                  _vm._v(" children are in the challenge, "),
                  _c("i", { staticClass: "fa fa-bolt" }),
                  _vm._v(" "),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.totalReaders.active.toLocaleString("en")))
                  ]),
                  _vm._v(" have read something.")
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-2" },
        [
          _c(
            "flip-card",
            { attrs: { id: "db-readers-in-schools" } },
            [
              _c("dash-box", {
                attrs: {
                  slot: "front",
                  title: "Readers Associated to a School",
                  value: _vm.readersInOrgs.total,
                  active_value: _vm.readersInOrgs.active
                },
                slot: "front"
              }),
              _c(
                "div",
                {
                  staticClass: "dashbox__help",
                  attrs: { slot: "back" },
                  slot: "back"
                },
                [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.readersInOrgs.total.toLocaleString("en")))
                  ]),
                  _vm._v(" children are associated with a school, "),
                  _c("i", { staticClass: "fa fa-bolt" }),
                  _vm._v(" "),
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.readersInOrgs.active.toLocaleString("en"))
                    )
                  ]),
                  _vm._v(" have read something.")
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-2" },
        [
          _c(
            "flip-card",
            { attrs: { id: "db-readers-not-in-school" } },
            [
              _c("dash-box", {
                attrs: {
                  slot: "front",
                  title: "Readers not in School",
                  value: _vm.readersNotInOrgs.total,
                  active_value: _vm.readersNotInOrgs.active
                },
                slot: "front"
              }),
              _c(
                "div",
                {
                  staticClass: "dashbox__help",
                  attrs: { slot: "back" },
                  slot: "back"
                },
                [
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.readersNotInOrgs.total.toLocaleString("en"))
                    )
                  ]),
                  _vm._v(" children are "),
                  _c("strong", [_c("u", [_vm._v("not")])]),
                  _vm._v(" associated with a school, "),
                  _c("i", { staticClass: "fa fa-bolt" }),
                  _vm._v(" "),
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.readersNotInOrgs.active.toLocaleString("en"))
                    )
                  ]),
                  _vm._v(" have read something.")
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "col-md-2" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            staticStyle: { width: "100%" },
            on: { click: _vm.getCSV }
          },
          [_vm._v("Download CSV File")]
        )
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "report-header" }, [
          _c(
            "h2",
            {
              staticStyle: {
                "font-weight": "200",
                color: "#224450",
                "text-shadow": "1px 1px 0 #ffffffb3"
              }
            },
            [_vm._v("Schools")]
          ),
          _c("div", { staticClass: "user-filters" }, [
            _c("div", { staticClass: "user-filter user-filter__break" }, [
              _vm._m(0),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.challenge,
                      expression: "challenge"
                    }
                  ],
                  attrs: { name: "challenges", id: "challenges" },
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.challenge = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.refreshData
                    ]
                  }
                },
                _vm._l(_vm.challenges, function(ch) {
                  return _c(
                    "option",
                    { key: ch.id, domProps: { value: ch.slug } },
                    [_vm._v(_vm._s(_vm._f("uppercase")(ch.slug)))]
                  )
                }),
                0
              )
            ])
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("vue-good-table", {
            attrs: {
              styleClass: "vgt-table striped",
              theme: "auris-admin",
              columns: _vm.modifiedColumns,
              rows: _vm.data,
              totalRows: _vm.data.length,
              "pagination-options": {
                enabled: true,
                mode: "records",
                perPage: 25,
                position: "bottom",
                perPageDropdown: [10, 25, 50, 100],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: "next",
                prevLabel: "prev",
                rowsPerPageLabel: "Rows per page",
                ofLabel: "of",
                pageLabel: "page", // for 'pages' mode
                allLabel: "All"
              },
              "search-options": {
                enabled: true,
                placeholder: "Search this table"
              }
            },
            on: { "on-sort-change": _vm.handleSortChange }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "label-container" }, [
      _c("label", [_vm._v("Challenges:")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }