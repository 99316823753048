<template>
  <div class="date-input-container" :class="{'is-disabled': !dateRangePickerIsEnabled}">
    <div class="checkbox-locator">
      <input id="drp-registered-checkbox" type="checkbox" v-model="dateRangePickerIsEnabled">
    </div>
    <DatePicker
      v-model="dateRange"
      mode="date"
      :is-range="true"
      :popover="{ placement: 'bottom-end', visibility: 'click' }"
      :min-date="minDate"
      :max-date="maxDate"
      @input="handleDateRangeUpdated"
    >
      <template v-slot="{inputValue, inputEvents}">
        <input
          :value="inputValue.start"
          v-on="inputEvents.start"
          class="input-inner"
          :disabled="!dateRangePickerIsEnabled"
        />
        <svg
          class="w-4 h-4 mx-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          :class="{'hidden-visually':!dateRangePickerIsEnabled}"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M14 5l7 7m0 0l-7 7m7-7H3"
          />
        </svg>
        <input
          :value="inputValue.end"
          v-on="inputEvents.end"
          class="input-inner input-inner__right"
          :disabled="!dateRangePickerIsEnabled"
        />
      </template>
    </DatePicker>
  </div>
</template>
<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import moment from 'moment';

export default {
  name: 'date-range-picker-with-toggle',
  props: ['minDate', 'maxDate', 'initialDateRange'],
  components: { DatePicker },
  data() {
    return {
      dateRangePickerIsEnabled: false,
      dateRange: this.initialDateRange,
    };
  },
  methods: {
    handleDateRangeUpdated(e) {
      const dateRangeObject = {
        start: moment(e.start).startOf('day').toDate(),
        end: moment(e.end).endOf('day').toDate(),
      };
      this.$emit('date-range-change', dateRangeObject);
    },
  },
  watch: {
    dateRangePickerIsEnabled() {
      this.$emit('picker-enabled-change', this.dateRangePickerIsEnabled);
    },
  },
};
</script>
