<template>
  <ValidationObserver ref="parent" tag="div">
    <form class="ui form form-horizontal" @submit.prevent="onSubmit" autocomplete="on">

      <!-- URN & NAME -->
      <ValidationObserver :key=0 ref="details" v-if="stage === 0" tag="section">
        <div class="row">
          <div class="form-title">
            <h3>Enter School Details</h3>
          </div>
        </div>
        <ValidationProvider name="name" rules="required" v-slot="{errors}" slim>
        <div class="form-group" :class="{'has-error': errors.length > 0}">
          <label for="name" class="col-sm-3 control-label">School Name</label>
            <div class="col-sm-9 col-md-9">
              <div class="input-group width-100">
                <input class="form-control width-100" name="name" placeholder="Name" type="text" autocomplete="off"
                      v-model="form.enterprise.name"
                      id="name"
                      data-lpignore="true">
              </div>
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
        </div>
        </ValidationProvider>
        <ValidationProvider name="urn" rules="required" v-slot="{errors}" slim>
        <div class="form-group" :class="{'has-error': errors.length > 0}">
          <label for="urn" class="col-sm-3 control-label">URN</label>
          <div class="col-sm-9 col-md-9">
            <div class="input-group width-100">
              <input class="form-control width-100" name="urn" placeholder="URN" type="text" autocomplete="off"
                    v-model="form.enterprise.urn"
                    id="urn"
                    data-lpignore="true">
            </div>
            <span class="text-danger">{{ errors[0] }}</span>
          </div>
        </div>
        </ValidationProvider>
      </ValidationObserver>
      <!-- URN & NAME END-->

      <!-- CONTACTS START -->
      <ValidationObserver :key=1 v-if="stage === 1" ref="primary_contact" tag="section">
        <div class="row">
          <div class="form-title">
            <h3>Primary Contact</h3>
          </div>
        </div>

        <ValidationProvider name="primary_contact_name" rules="required" v-slot="{errors}" slim>
          <div class="form-group" :class="{'has-error': errors.length > 0}">
            <label for="primary_contact_name" class="col-sm-3 control-label">Name</label>
              <div class="col-sm-9 col-md-9">
                <div class="input-group width-100">
                  <input class="form-control width-100" name="primary_contact_name" placeholder="Name" type="text" autocomplete="off"
                        v-model="form.contact.primary.contact_name"
                        v-validate="{ required: true }"
                        id="primary_contact_name"
                        data-lpignore="true">
                </div>
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
          </div>
        </ValidationProvider>
        <ValidationProvider name="primary_contact_job_role" :rules="{required: false}" v-slot="{errors}" slim>
          <div class="form-group" :class="{'has-error': errors.length > 0}">
            <label for="primary_contact_job_role" class="col-sm-3 control-label">Job Role</label>
              <div class="col-sm-9 col-md-9">
                <div class="input-group width-100">
                  <input class="form-control width-100" name="primary_contact_job_role" placeholder="Job Role" type="text" autocomplete="off"
                        v-model="form.contact.primary.job_role"
                        v-validate="{ required: true }"
                        id="primary_contact_job_role"
                        data-lpignore="true">
                </div>
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
          </div>
        </ValidationProvider>
        <ValidationProvider name="primary_address_line_1" rules="required" v-slot="{errors}" slim>
          <div class="form-group" :class="{'has-error': errors.length > 0}">
            <label for="primary_address_line_1" class="col-sm-3 control-label">Address line 1</label>
              <div class="col-sm-9 col-md-9">
                <div class="input-group width-100">
                  <input class="form-control width-100" name="primary_address_line_1" placeholder="Address line 1" type="text" autocomplete="off"
                        v-model="form.contact.primary.address_line_1"
                        id="primary_address_line_1"
                        data-lpignore="true">
                </div>
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
          </div>
        </ValidationProvider>
        <ValidationProvider name="primary_address_line_2" :rules="{required:false}" v-slot="{errors}" slim>
          <div class="form-group" :class="{'has-error': errors.length > 0}">
            <label for="primary_address_line_2" class="col-sm-3 control-label">Address line 2</label>
              <div class="col-sm-9 col-md-9">
                <div class="input-group width-100">
                  <input class="form-control width-100" name="primary_address_line_2" placeholder="Address line 2" type="text" autocomplete="off"
                        v-model="form.contact.primary.address_line_2"
                        id="primary_address_line_2"
                        data-lpignore="true">
                </div>
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
          </div>
        </ValidationProvider>
        <ValidationProvider name="primary_address_postcode" rules="required" v-slot="{errors}" slim>
          <div class="form-group" :class="{'has-error': errors.length > 0}">
            <label for="primary_address_postcode" class="col-sm-3 control-label">Postcode</label>
              <div class="col-sm-9 col-md-9">
                <div class="input-group width-100">
                  <input class="form-control width-100" name="primary_address_postcode" placeholder="Postcode" type="text" autocomplete="off"
                        v-model="form.contact.primary.postcode"
                        id="primary_address_postcode"
                        data-lpignore="true">
                  <span class="text-danger">{{ errors[0] }}</span>
                </div>
              </div>
          </div>
        </ValidationProvider>
        <ValidationProvider name="primary_address_country" rules="required" v-slot="{errors}" slim>
          <div class="form-group" :class="{'has-error': errors.length > 0}">
            <label for="primary_address_country" class="col-sm-3 control-label">Country</label>
              <div class="col-sm-9 col-md-9">
                <div class="input-group width-100">
                  <select class="form-control" name="primary_address_country" id="primary_address_country"
                          v-model="form.contact.primary.country_code">
                    <option v-for="country in countries" :value="country['id']" :selected="country['id'] === value" :key="country['id']">{{country['name']}}</option>
                  </select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </div>
              </div>
          </div>
        </ValidationProvider>
        <ValidationProvider name="primary_region" v-slot="{errors}" slim>
          <div class="form-group" :class="{'has-error': errors.length > 0}">
            <label for="primary_address_region" class="col-sm-3 control-label">Region</label>
            <div class="col-sm-9 col-md-9">
              <div class="input-group width-100">
                <select class="form-control" name="primary_address_region" id="primary_address_region"
                        v-model="form.contact.primary.region" v-if="primaryRegions.length > 0">
                  <optgroup :label="group['group_name'] != '' ? group['group_name'] : 'Chose a region'" v-for="(group, key) in primaryRegions" :key="key">
                    <option v-for="(region, k) in group['values']" :value="region" :selected="region === value" :key="k">{{region}}</option>
                  </optgroup>
                </select>
                <input v-else class="form-control width-100" name="primary_address_region" placeholder="Region" type="text" autocomplete="off"
                       v-model="form.contact.primary.region"
                       id="primary_region"
                       data-lpignore="true">
              </div>
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider name="primary_city" v-slot="{errors}" slim>
          <div class="form-group" :class="{'has-error': errors.length > 0}">
            <label for="primary_city" class="col-sm-3 control-label">City</label>
            <div class="col-sm-9 col-md-9">
              <div class="input-group width-100">
                <input class="form-control width-100" name="primary_city" placeholder="City" type="text" autocomplete="off"
                       v-model="form.contact.primary.city"
                       id="primary_city"
                       data-lpignore="true">
              </div>
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider name="primary_county" v-slot="{errors}" slim>
          <div class="form-group" :class="{'has-error': errors.length > 0}">
            <label for="primary_county" class="col-sm-3 control-label">County</label>
            <div class="col-sm-9 col-md-9">
              <div class="input-group width-100">
                <input class="form-control width-100" name="primary_county" placeholder="County" type="text" autocomplete="off"
                       v-model="form.contact.primary.county"
                       id="primary_county"
                       data-lpignore="true">
              </div>
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider name="primary_address_telephone" :rules="{ required: true, regex: /^[+0-9]+$/ }" v-slot="{errors}" slim>
          <div class="form-group" :class="{'has-error': errors.length > 0}">
            <label for="primary_address_telephone" class="col-sm-3 control-label">Telephone</label>
              <div class="col-sm-9 col-md-9">
                <div class="input-group width-100">
                  <input class="form-control width-100" name="primary_address_telephone" placeholder="Telephone" type="text" autocomplete="off"
                        v-model="form.contact.primary.telephone"
                        id="primary_address_telephone"
                        data-lpignore="true">
                </div>
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
          </div>
        </ValidationProvider>
        <ValidationProvider name="primary_email" :rules="{ required: true, email: true }" v-slot="{errors}" slim>
          <div class="form-group" :class="{'has-error': errors.length > 0}">
            <label for="primary_email" class="col-sm-3 control-label">Email</label>
              <div class="col-sm-9 col-md-9">
                <div class="input-group width-100">
                  <input class="form-control width-100" name="primary_email" placeholder="Email" type="text" autocomplete="off"
                        v-model="form.contact.primary.email"
                        id="primary_email"
                        data-lpignore="true">
                </div>
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
          </div>
        </ValidationProvider>
      </ValidationObserver>

      <ValidationObserver :key=2 class="form-group" v-if="stage === 2" ref="contacts" tag="section">
        <div class="row">
          <div class="text-center">
            <h3>Is the Billing Contact the same as the Primary Contact?</h3>
          </div>
        </div>
        <div class="col-sm-offset-6 col-sm-10 col-md-8">
          <div class="row">
            <div class="btn-group btn-group-toggle" data-toggle="buttons" @click="handleContactsAreTheSameButtons($event)">
              <label class="btn btn-secondary" :class="{ active: form.contacts_are_the_same }">
                <input type="radio" name="contacts_are_the_same" id="contacts_are_the_same_yes" :value="true" v-model="form.contacts_are_the_same"> Yes
              </label>
              <button class="btn btn-secondary" :class="{ active: form.contacts_are_the_same !== null && !form.contacts_are_the_same }">
                <input type="radio" name="contacts_are_the_same" id="contacts_are_the_same_no" :value="false" v-model="form.contacts_are_the_same"> No
              </button>

            </div>
          </div>
        </div>
      </ValidationObserver>

      <ValidationObserver v-if="stage === 3" ref="billing_contact">
        <div class="row">
          <div class="form-title">
            <h3>Billing Contact</h3>
          </div>
        </div>
        <ValidationProvider v-slot="{errors}" name="billing_contact_name" :rules="{ required: true }" slim>
        <div class="form-group" :class="{'has-error': errors.length > 0}">
          <label for="billing_contact_name" class="col-sm-3 control-label">Name</label>

            <div class="col-sm-9 col-md-9">
              <div class="input-group width-100">
                <input class="form-control width-100" name="billing_contact_name" placeholder="Name" type="text" autocomplete="off"
                      v-model="form.contact.billing.contact_name"
                      id="billing_contact_name"
                      data-lpignore="true">
              </div>
              <span class="text-danger">{{ errors[0] }}</span>
            </div>

        </div>
        </ValidationProvider>
        <ValidationProvider name="billing_contact_job_role" :rules="{required: false}" v-slot="{errors}" slim>
          <div class="form-group" :class="{'has-error': errors.length > 0}">
            <label for="billing_contact_job_role" class="col-sm-3 control-label">Job Role</label>
              <div class="col-sm-9 col-md-9">
                <div class="input-group width-100">
                  <input class="form-control width-100" name="billing_contact_job_role" placeholder="Job Role" type="text" autocomplete="off"
                        v-model="form.contact.billing.job_role"
                        v-validate="{ required: true }"
                        id="billing_contact_job_role"
                        data-lpignore="true">
                </div>
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
          </div>
        </ValidationProvider>
        <ValidationProvider name="billing_address_line_1" v-slot="{errors}" :rules="{ required: true }" slim>
          <div class="form-group" :class="{'has-error': errors.length > 0}">
            <label for="billing_address_line_1" class="col-sm-3 control-label">Address line 1</label>

            <div class="col-sm-9 col-md-9">
              <div class="input-group width-100">
                <input class="form-control width-100" name="billing_address_line_1" placeholder="Address line 1" type="text" autocomplete="off"
                      v-model="form.contact.billing.address_line_1"
                      id="primary_address_line_1"
                      data-lpignore="true">
              </div>
              <span class="text-danger">{{ errors[0] }}</span>
            </div>

          </div>
        </ValidationProvider>
        <ValidationProvider v-slot="{errors}" name="billing_address_line_2" :rules="{ required: false }" slim>
          <div class="form-group" :class="{'has-error': errors.length > 0}">
            <label for="billing_address_line_2" class="col-sm-3 control-label">Address line 2</label>
              <div class="col-sm-9 col-md-9">
                <div class="input-group width-100">
                  <input class="form-control width-100" name="billing_address_line_2" placeholder="Address line 2" type="text" autocomplete="off"
                        v-model="form.contact.billing.address_line_2"
                        id="billing_address_line_2"
                        data-lpignore="true">
                </div>
                <span class="text-danger">{{ errors[0] }}</span>
              </div>

          </div>
        </ValidationProvider>
        <ValidationProvider name="billing_address_postcode" rules="required" v-slot="{errors}" slim>
          <div class="form-group" :class="{'has-error': errors.length > 0}">
            <label for="billing_address_postcode" class="col-sm-3 control-label">Postcode</label>
              <div class="col-sm-9 col-md-9">
                <div class="input-group width-100">
                  <input class="form-control width-100" name="billing_address_postcode" placeholder="Postcode" type="text" autocomplete="off"
                        v-model="form.contact.billing.postcode"
                        id="billing_address_postcode"
                        data-lpignore="true">
                  <span class="text-danger">{{ errors[0] }}</span>
                </div>
              </div>
          </div>
        </ValidationProvider>
        <ValidationProvider name="billing_address_country" v-slot="{errors}" :rules="{ required: true }" slim>
          <div class="form-group" :class="{'has-error': errors.length > 0}">
            <label for="billing_address_country" class="col-sm-3 control-label">Country</label>
            <div class="col-sm-9 col-md-9">
              <div class="input-group width-100">
                <select class="form-control" name="billing_address_country" id="billing_address_country"
                        v-model="form.contact.billing.country_code">
                  <option v-for="country in countries" :value="country['id']" :selected="country['id'] === value" :key="country['id']">{{country['name']}}</option>
                </select>
              </div>
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider name="billing_region" v-slot="{errors}" slim>
          <div class="form-group" :class="{'has-error': errors.length > 0}">
            <label for="billing_address_region" class="col-sm-3 control-label">Region</label>
            <div class="col-sm-9 col-md-9">
              <div class="input-group width-100">
                <select class="form-control" name="billing_address_region" id="billing_address_region"
                        v-model="form.contact.billing.region" v-if="billingRegions.length > 0">
                  <optgroup :label="group['group_name'] != '' ? group['group_name'] : 'Chose a region'" v-for="(group, key) in billingRegions" :key="key">
                    <option v-for="(region, k) in group['values']" :value="region" :selected="region === value" :key="k">{{region}}</option>
                  </optgroup>
                </select>
                <input v-else class="form-control width-100" name="billing_region" placeholder="Region" type="text" autocomplete="off"
                       v-model="form.contact.billing.region"
                       id="billing_region"
                       data-lpignore="true">
              </div>
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider name="billing_city" v-slot="{errors}" slim>
          <div class="form-group" :class="{'has-error': errors.length > 0}">
            <label for="billing_city" class="col-sm-3 control-label">City</label>
            <div class="col-sm-9 col-md-9">
              <div class="input-group width-100">
                <input class="form-control width-100" name="billing_city" placeholder="City" type="text" autocomplete="off"
                       v-model="form.contact.billing.city"
                       id="billing_city"
                       data-lpignore="true">
              </div>
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider name="billing_county" v-slot="{errors}" slim>
          <div class="form-group" :class="{'has-error': errors.length > 0}">
            <label for="billing_county" class="col-sm-3 control-label">County</label>
            <div class="col-sm-9 col-md-9">
              <div class="input-group width-100">
                <input class="form-control width-100" name="billing_county" placeholder="County" type="text" autocomplete="off"
                       v-model="form.contact.billing.county"
                       id="billing_county"
                       data-lpignore="true">
              </div>
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider name="billing_address_telephone" v-slot="{errors}" :rules="{ required: true, regex: /^[+0-9]+$/ }" slim>
          <div class="form-group" :class="{'has-error': errors.length > 0}">
            <label for="billing_address_telephone" class="col-sm-3 control-label">Telephone</label>
            <div class="col-sm-9 col-md-9">
              <div class="input-group width-100">
                <input class="form-control width-100" name="billing_address_telephone" placeholder="Telephone" type="text" autocomplete="off"
                      v-model="form.contact.billing.telephone"
                      id="billing_address_telephone"
                      data-lpignore="true">
              </div>
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider name="billing_email" :rules="{ required: true, email: true }" v-slot="{errors}" slim>
          <div class="form-group" :class="{'has-error': errors.length > 0}">
            <label for="billing_email" class="col-sm-3 control-label">Email</label>
              <div class="col-sm-9 col-md-9">
                <div class="input-group width-100">
                  <input class="form-control width-100" name="billing_email" placeholder="Email" type="text" autocomplete="off"
                        v-model="form.contact.billing.email"
                        id="billing_email"
                        data-lpignore="true">
                </div>
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
          </div>
        </ValidationProvider>
      </ValidationObserver>
      <!-- CONTACTS END -->

      <!-- SUBSCRIPTION START -->
      <school-subscription-form :value="form.subscription" v-if="stage === 4" :display-buttons="false" />
      <!-- SUBSCRIPTION END -->

      <!-- FIRST ADMIN ACCOUNT START -->
      <ValidationObserver :key=0 ref="details" v-if="stage === 5" tag="section">
        <div class="row">
          <div class="form-title">
            <h3>Admin Account Details</h3>
          </div>
        </div>
        <ValidationProvider name="user_first_name" rules="required" v-slot="{errors}">
          <div class="form-group" :class="{'has-error': errors.length > 0}">
            <label for="user_first_name" class="col-sm-3 control-label">First Name</label>
              <div class="col-sm-9 col-md-9">
                <div class="input-group width-100">
                  <input class="form-control width-100" name="user_first_name" placeholder="First Name" type="text" autocomplete="off"
                        v-model="form.user.first_name"
                        id="user_first_name"
                        data-lpignore="true">
                </div>
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
          </div>
        </ValidationProvider>
        <ValidationProvider name="user_last_name" rules="required" v-slot="{errors}">
          <div class="form-group" :class="{'has-error': errors.length > 0}">
            <label for="user_last_name" class="col-sm-3 control-label">Last Name</label>
              <div class="col-sm-9 col-md-9">
                <div class="input-group width-100">
                  <input class="form-control width-100" name="user_last_name" placeholder="First Name" type="text" autocomplete="off"
                        v-model="form.user.last_name"
                        id="user_last_name"
                        data-lpignore="true">
                </div>
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
          </div>
        </ValidationProvider>
        <ValidationProvider name="user_email" :rules="{required: true, email: true}" v-slot="{errors}">
          <div class="form-group" :class="{'has-error': errors.length > 0}">
            <label for="user_email" class="col-sm-3 control-label">Email</label>
            <div class="col-sm-9 col-md-9">
              <div class="input-group width-100">
                <input class="form-control width-100" name="user_email" placeholder="Email" type="text" autocomplete="off"
                      v-model="form.user.email"
                      id="user_email"
                      data-lpignore="true">
              </div>
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider name="user_job_role" v-slot="{errors}">
          <div class="form-group" :class="{'has-error': errors.length > 0}">
            <label for="user_job_role" class="col-sm-3 control-label">Job Role</label>
            <div class="col-sm-9 col-md-9">
              <div class="input-group width-100">
                <input class="form-control width-100" name="user_job_role" placeholder="Job Role" type="text" autocomplete="off"
                      v-model="form.user.job_role"
                      id="user_job_role"
                      data-lpignore="true">
              </div>
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
      </ValidationObserver>
      <!-- FIRST ADMIN ACCOUNT END -->

      <!-- CONFIRM DETAILS START -->
      <div class="row" v-if="stage === 6">
        <div class="col-md-9">
          <fieldset class="stats-box stats-box-border white mb-1-25">
            <legend class="stats-box-legend">Confirm School Details</legend>
            <div class="stats-box-heading-with-button">
              <h3 class="stats-box-heading">School Details</h3>
              <button @click="stage = 0">Edit <font-awesome-icon :icon="['fas', 'edit']"/></button>
            </div>
            <ul class="stats-box-list">
              <li class="stats-box-list-item" v-if="form.enterprise.name"><span>School Name</span><span>{{form.enterprise.name}}</span></li>
              <li class="stats-box-list-item" v-if="form.enterprise.urn"><span>School Urn</span><span>{{form.enterprise.urn}}</span></li>
            </ul>

            <div class="stats-box-heading-with-button">
              <h3 class="stats-box-heading">Primary Contact</h3>
              <button @click="stage = 1">Edit <font-awesome-icon :icon="['fas', 'edit']"/></button>
            </div>
            <ul class="stats-box-list">
              <li class="stats-box-list-item" v-if="form.contact.primary.contact_name"><span>Name</span><span>{{form.contact.primary.contact_name}}</span></li>
              <li class="stats-box-list-item" v-if="form.contact.primary.job_role"><span>Job Role</span><span>{{form.contact.primary.job_role}}</span></li>
              <li class="stats-box-list-item" v-if="form.contact.primary.address_line_1"><span>Address Line 1</span><span>{{form.contact.primary.address_line_1}}</span></li>
              <li class="stats-box-list-item" v-if="form.contact.primary.address_line_2"><span>Address Line 2</span><span>{{form.contact.primary.address_line_2}}</span></li>
              <li class="stats-box-list-item" v-if="form.contact.primary.postcode"><span>Postcode</span><span>{{form.contact.primary.postcode}}</span></li>
              <li class="stats-box-list-item" v-if="form.contact.primary.country_code"><span>Country Code</span><span>{{form.contact.primary.country_code}}</span></li>
              <li class="stats-box-list-item" v-if="form.contact.primary.region"><span>Region</span><span>{{form.contact.primary.region}}</span></li>
              <li class="stats-box-list-item" v-if="form.contact.primary.city"><span>City</span><span>{{form.contact.primary.city}}</span></li>
              <li class="stats-box-list-item" v-if="form.contact.primary.county"><span>County</span><span>{{form.contact.primary.county}}</span></li>
              <li class="stats-box-list-item" v-if="form.contact.primary.telephone"><span>Telephone</span><span>{{form.contact.primary.telephone}}</span></li>
              <li class="stats-box-list-item" v-if="form.contact.primary.email"><span>Email</span><span>{{form.contact.primary.email}}</span></li>
            </ul>

            <div v-if="!form.contacts_are_the_same">
              <div class="stats-box-heading-with-button" >
                <h3 class="stats-box-heading">Billing Contact</h3>
                <button @click="stage = 3">Edit <font-awesome-icon :icon="['fas', 'edit']"/></button>
              </div>
              <ul class="stats-box-list">
                <li class="stats-box-list-item" v-if="form.contact.billing.contact_name"><span>Name</span><span>{{form.contact.billing.contact_name}}</span></li>
                <li class="stats-box-list-item" v-if="form.contact.billing.job_role"><span>Job Role</span><span>{{form.contact.billing.job_role}}</span></li>
                <li class="stats-box-list-item" v-if="form.contact.billing.address_line_1"><span>Address Line 1</span><span>{{form.contact.billing.address_line_1}}</span></li>
                <li class="stats-box-list-item" v-if="form.contact.billing.address_line_2"><span>Address Line 2</span><span>{{form.contact.billing.address_line_2}}</span></li>
                <li class="stats-box-list-item" v-if="form.contact.billing.postcode"><span>Postcode</span><span>{{form.contact.billing.postcode}}</span></li>
                <li class="stats-box-list-item" v-if="form.contact.billing.country_code"><span>Country Code</span><span>{{form.contact.billing.country_code}}</span></li>
                <li class="stats-box-list-item" v-if="form.contact.billing.region"><span>Region</span><span>{{form.contact.billing.region}}</span></li>
                <li class="stats-box-list-item" v-if="form.contact.billing.city"><span>City</span><span>{{form.contact.billing.city}}</span></li>
                <li class="stats-box-list-item" v-if="form.contact.billing.county"><span>County</span><span>{{form.contact.billing.county}}</span></li>
                <li class="stats-box-list-item" v-if="form.contact.billing.telephone"><span>Telephone</span><span>{{form.contact.billing.telephone}}</span></li>
                <li class="stats-box-list-item" v-if="form.contact.billing.email"><span>Email</span><span>{{form.contact.billing.email}}</span></li>
              </ul>
            </div>

            <div v-else>
              <div class="stats-box-heading-with-button" >
                <h3 class="stats-box-heading">Billing Contact</h3>
                <button @click="stage = 2">Edit <font-awesome-icon :icon="['fas', 'edit']"/></button>

              </div>
              <ul class="stats-box-list">
                <li class="stats-box-list-item">
                    Billing Contact is the same as the primary contact
                </li>
              </ul>
            </div>

             <div class="stats-box-heading-with-button">
              <h3 class="stats-box-heading">Subscription Details</h3>
              <button @click="stage = 4">Edit <font-awesome-icon :icon="['fas', 'edit']"/></button>
            </div>
            <ul class="stats-box-list">
              <li class="stats-box-list-item" v-if="form.subscription.max_readers"><span>Maximum Readers</span><span>{{form.subscription.max_readers}}</span></li>
              <li class="stats-box-list-item" v-if="form.subscription.renewal_price"><span>Cost</span><span>£{{form.subscription.renewal_price}}</span></li>
              <li class="stats-box-list-item" v-if="form.subscription.renewal_duration_unit"><span>Duration</span><span>1 {{form.subscription.renewal_duration_unit}}</span></li>
            </ul>

            <div class="stats-box-heading-with-button">
              <h3 class="stats-box-heading">Admin Account</h3>
              <button @click="stage = 5">Edit <font-awesome-icon :icon="['fas', 'edit']"/></button>
            </div>
            <ul class="stats-box-list">
              <li class="stats-box-list-item" v-if="form.user.first_name"><span>First Name</span><span>{{form.user.first_name}}</span></li>
              <li class="stats-box-list-item" v-if="form.user.last_name"><span>Last Name</span><span>{{form.user.last_name}}</span></li>
              <li class="stats-box-list-item" v-if="form.user.email"><span>Email</span><span>{{form.user.email}}</span></li>
              <li class="stats-box-list-item" v-if="form.user.job_role"><span>Job Role</span><span>{{form.user.job_role}}</span></li>
            </ul>
          </fieldset>
        </div>

      </div>
      <!-- CONFIRM DETAILS END -->
      <div class="col-sm-offset-6 col-sm-10 col-md-8" v-if="showControls">
        <div class="row">
          <div class="col-md-2"><button class="btn btn-secondary" v-if="stage > 0" @click="handleBackStage()">Back</button></div>
          <div class="col-md-2" ><loading-button class="btn btn-primary" type="submit" :isLoading="isLoading">{{ submitButtonText }}</loading-button></div>
        </div>
      </div>

    </form>
  </ValidationObserver>
</template>

<script>

import FormMixin from '@/mixins/FormMixin';
import moment from 'moment';
import {
  ValidationObserver, ValidationProvider,
} from 'vee-validate';
import SchoolSubscriptionForm from '@/components/forms/SchoolSubscriptionForm';
import LoadingButton from '@/components/utils/LoadingButton.vue';

export default {
  name: 'create-school-wizard',
  props: ['value'],
  mixins: [FormMixin],
  components: {
    SchoolSubscriptionForm,
    ValidationObserver,
    ValidationProvider,
    LoadingButton,
  },
  data() {
    return {
      stage: 0,
      maxStage: 6,
      countries: [],
      primaryRegions: [],
      billingRegions: [],
      hasSeenConfirmDetails: false,
      durationOptions: [
        { text: 'Select duration', value: '', disabled: true },
        { text: 'Monthly', value: 'month' },
        { text: 'Yearly', value: 'year' },
      ],
      form: {
        contacts_are_the_same: null,
        user: {
          email: '',
          first_name: '',
          last_name: '',
          job_role: '',
        },
        enterprise: {
          name: '',
          urn: '',
        },
        contact: {
          primary: {
            type: 'primary',
            contact_name: '',
            job_role: '',
            address_line_1: '',
            address_line_2: '',
            postcode: '',
            country_code: 'GB',
            region: '',
            city: '',
            county: '',
            telephone: '',
            email: '',
          },
          billing: {
            type: 'billing',
            contact_name: '',
            job_role: '',
            address_line_1: '',
            address_line_2: '',
            postcode: '',
            country_code: 'GB',
            region: '',
            city: '',
            county: '',
            telephone: '',
            email: '',
          },
        },
        subscription: {
          max_readers: '',
          expires_at: moment().add(1, 'y').toDate(),
          renewal_price: '',
          renewal_duration_unit: '',
          renewal_duration: 1,
          packages: [],
        },
      },
      isLoading: false,
    };
  },
  beforeMount() {
  },
  mounted() {
    this.getCountries();
  },
  methods: {
    submitForm() {
      this.isLoading = true;
      const data = this.createDataForAPI();
      this.$store.dispatch('schools/createSchool', data).then((school) => {
        this.$router.push(`/schools/${school.id}`);
      }).catch((err) => {
        this.$apiResponse.renderErrorMessage(err);
      }).finally(() => {
        this.isLoading = false;
      });
    },
    createDataForAPI() {
      const newObject = {
        user: this.form.user,
        enterprise: {
          type: 'school',
          name: this.form.enterprise.name,
          urn: this.form.enterprise.urn,
          contact: [],
        },
        subscription: this.form.subscription,
      };
      newObject.enterprise.contact.push(this.form.contact.primary);
      newObject.enterprise.contact.push(this.form.contact.billing);
      return newObject;
    },
    handleBackStage() {
      if (this.stage === 4 && this.form.contacts_are_the_same) {
        this.stage = 2;
      } else {
        this.stage--;
      }
    },
    handleContactsAreTheSameButtons(e) {
      const contactsAreTheSame = e.target.getElementsByTagName('input')[0].value === 'true';
      this.form.contacts_are_the_same = contactsAreTheSame;
      if (!contactsAreTheSame) {
        this.form.contact.billing = {
          type: 'billing',
          contact_name: '',
          job_role: '',
          address_line_1: '',
          address_line_2: '',
          country_code: 'GBR',
          region: '',
          city: '',
          county: '',
          telephone: '',
          email: '',
        };
        this.stage++;
      } else {
        Object.assign(this.form.contact.billing, this.form.contact.primary);
        this.form.contact.billing.type = 'billing';
        this.stage = 4;
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.parent.validate();
      if (isValid && this.stage === this.maxStage) {
        this.submitForm();
      } else if (isValid && this.hasSeenConfirmDetails) {
        this.stage = this.maxStage;
      } else if (isValid) {
        if (this.stage === (this.maxStage - 1)) {
          this.hasSeenConfirmDetails = true;
        }
        this.stage++;
      }
    },
    getCountries() {
      this.$store.dispatch('app/fetchCountries').then((response) => {
        this.countries = response.data.countries;
        this.updateRegions(this.form.contact.primary.country_code, 'primaryRegions');
        this.updateRegions(this.form.contact.billing.country_code, 'billingRegions');
      }).catch((error) => {
        this.$apiResponse.renderErrorMessage(error);
      });
    },
    updateRegions(countryCode, key) {
      const selectedCountry = this.countries.find((country) => country.id === countryCode);
      this[key] = selectedCountry && selectedCountry.regions ? selectedCountry.regions.groups : [];
    },
  },
  computed: {
    showControls() {
      return this.stage !== 2;
    },
    submitButtonText() {
      return this.stage === this.maxStage ? 'Submit' : 'Next';
    },
  },
  watch: {
    'form.contact.primary.country_code': function (newCountryCode) {
      this.form.contact.primary.region = null;
      this.updateRegions(newCountryCode, 'primaryRegions');
    },
    'form.contact.billing.country_code': function (newCountryCode) {
      this.form.contact.billing.region = null;
      this.updateRegions(newCountryCode, 'billingRegions');
    },
  },
};
</script>
