var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showModal
        ? _c(
            "modal",
            {
              attrs: {
                title: "Confirmation",
                "show-footer": !_vm.hideModalFooter
              },
              on: { close: _vm.closeModal }
            },
            [_vm._t("modal")],
            2
          )
        : _vm._e(),
      _c(
        "loading-button",
        {
          class: _vm.btnClasses,
          on: {
            click: function($event) {
              return _vm.$emit("click")
            }
          }
        },
        [_vm._t("button")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }