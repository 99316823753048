<template>
  <header class="header main-header">
    <a href="#" @click.prevent="$router.push('/')" class="logo header__logo">
      <!-- mini logo for sidebar mini 50x50 pixels -->
      <!--<span class="header__logo&#45;&#45;sm"><b>F</b></span>-->
      <!-- logo for regular state and mobile devices -->
      <span class="header__logo--lg"><img src="/img/logo.png" alt="Logo" class="header__logo-image"></span>
    </a>
    <nav class="top-navbar navbar navbar-static-top" role="navigation">
      <!-- Sidebar toggle button-->
      <a href="#" class="sidebar-toggle" data-toggle="offcanvas" role="button">
        <span class="sr-only">Toggle navigation</span>
      </a>
      <!-- Navbar Right Menu -->
      <div class="top-navbar__dropdown">
        <ul class="nav navbar-nav">
          <!-- User Account Menu -->
          <li class="dropdown user-menu user">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown">
              {{activeUser.email}}
            </a>
            <ul class="dropdown-menu">
              <li>
                <router-link to="/logout">
                  <i class="fa fa-circle-o text-red"></i>
                  <span> Logout</span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'navbar',
  computed: {
    activeUser() {
      return {
        email: this.$store.state.auth.user.email,
      };
    },
  },
};
</script>
