<template>
  <div class="dashbox">
    <span class="dashbox__title">{{title}}</span>
    <div class="dashbox__value_row">
      <span class="dashbox__value">{{format_number(value)}}</span>
      <span v-if="conversion !== null" class="dashbox__conv">{{format_number(conversion)}}%</span>
      <span v-if="active_value !== null" class="dashbox__active_value">{{format_number(active_value)}}</span>
    </div>
    <slot name="table"></slot>
  </div>
</template>

<script>
export default {
  name: 'dashbox',
  components: { },
  props: {
    title: {
      required: true,
      type: String,
    },
    value: {
      required: true,
    },
    conversion: {
      required: false,
      default: null,
    },
    active_value: {
      required: false,
      default: null,
    },
  },
  methods: {
    format_number(n) {
      // eslint-disable-next-line no-restricted-globals
      return isNaN(n) ? n : parseInt(n, 10).toLocaleString('en');
    },
  },
};
</script>

<style lang="scss">
$dashbox-color-bad: rgb(211, 31, 31);
$dashbox-color-good: rgb(20, 165, 56);

.dashbox {

  height:100%;
  width:100%;
  min-width:250px;

  font-size: 16px;
  line-height: 1;

  padding:.5em;
  box-shadow: 2px 2px 3px 0px #c7d5e6;
  background: white;
  color: #5e5e5e;

  &__title {
    display:block;
    font-size: 1em;
  }

  &__value_row {
    display:flex;
    align-items: center;
    justify-content: space-between;
    margin-top: .5em;
    font-family: 'Roboto Slab';
  }

  &__value {
    display: block;
    font-size: 1.6em;
  }

  &__conv {
    display: block;
    font-size: 1.4em;
    &:before {
      font-family: 'FontAwesome';
      font-style: normal;
      font-size: 0.6em;
      vertical-align: middle;
      content: '\F079';
      margin-right:0.3em;
      color: #aaa;
    }
  }

  &__active_value {
    display: block;
    font-size: 1.4em;
    &:before {
      font-family: 'FontAwesome';
      font-style: normal;
      font-size: 0.6em;
      vertical-align: middle;
      content: '\F0E7';
      margin-right:0.3em;
      color: #aaa;
    }
  }

  &__table {
    font-size: 0.85em;
    margin: 1.5em -.25em 0 -.25em;
    tr:nth-child(even) > td { background: #eee; }
    tr > td { white-space: nowrap; padding-top:0.2em; padding-bottom: 0.2em; padding-left: 1em;}
    tr > td:first-child { width: 100%; padding-left: 0.25em; }
    tr > td:last-child { width: 100%; padding-right: 0.25em; }

    i.fa {
      margin-right:.2em;
      vertical-align: baseline;
      color: #aaa;
      font-size: 0.75em;
    }
  }
}
</style>
