import _ from 'lodash';

const manageAccess = (to, from, next) => {
  let { authorize } = to.meta;
  const { isSuperAdmin } = to.meta;
  const parent = to.matched.find((record) => record.meta.authorize);
  if (!authorize && parent) {
    authorize = parent.meta.authorize;
  }

  const currentUser = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');
  const accountType = localStorage.getItem('accountType');

  if (to.matched.some((record) => record.meta.requiresAuth) && (_.isEmpty(token) || accountType !== 'admin')) {
    next({
      path: '/login',
      query: { redirect: to.fullPath },
    });
  } else if (to.matched.some((record) => record.meta.guestsOnly) && !_.isEmpty(token) && accountType === 'admin') {
    next({
      path: '/',
      query: { redirect: to.fullPath },
    });
  } else if (authorize) {
    if (currentUser && currentUser.allowed_access && currentUser.allowed_access.categories
      && (currentUser.allowed_access.categories.some(function (x) { // eslint-disable-line
        return authorize.includes(x);
      })
          || currentUser.allowed_access.categories.includes('all'))
    ) {
      next();
    } else {
      next('/');
    }
  } else if (isSuperAdmin) {
    if (currentUser && currentUser.allowed_access && currentUser.allowed_access.is_super_admin) {
      next();
    } else {
      next('/');
    }
  } else {
    next();
  }
};

const restrictAccess = (to, from, next) => {
  const currentUser = JSON.parse(localStorage.getItem('user'));
  if (currentUser.email !== 'hannah@auris.tech') {
    next();
    return;
  }
  next('/recordings');
};

const clearLocalStorage = (to, from, next) => {
  if (window.localStorage) {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('account_type');
  }
  next();
};

export { restrictAccess, clearLocalStorage, manageAccess };
