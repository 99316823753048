<template>
  <wrapper>
    <section class="subscription-page" v-if="pageReady">
      <div class="row">
        <div class="tabs">
          <div class="btn-group" role="group">
            <button class="btn btn-primary" :class="{'active': tab === 'predefined'}"
                    @click="show('predefined')">Predefined
            </button>
            <button class="btn btn-primary" :class="{'active': tab === 'custom'}" @click="show('custom')">
              Custom
            </button>
          </div>
        </div>
      </div>
      <div v-if="packages.length > 0">
        <div class="row" v-if="tab === 'predefined'">
          <div class="col-md-4" v-for="(pack, index) in filteredPackages" :key="index">
            <div class="box box-primary box-solid">
              <div class="box-header with-border">
                <h3 class="box-title">{{ pack.title }}</h3>
                <div class="box-tools pull-right">
                  <button class="btn btn-box-tool" type="button" data-widget="collapse">
                    <i class="fa fa-minus"></i>
                  </button>
                </div>
              </div>
              <div class="box-body subscription-plan">
                <div class="subscription-plan__inner" v-for="(variant, index) in pack.variants" :key="index">
                  <div v-for="(price, pindex) in variant.prices" :key="pindex"
                       :class="{'subscription-plan__last': pindex === variant.prices.length - 1}">
                    <div class="subscription-plan__duration">{{ price.duration_length }} {{ price.duration_unit }}</div>
                    <div class="subscription-plan__price"><sup>£</sup> {{ price.price }}</div>
                    <div class="subscription-plan__description">{{ price.duration_length }} {{ price.duration_unit }} / {{
                      variant.max_devices }} maximum devices
                    </div>
                    <button class="btn bg-maroon subscription-plan__button"
                            @click="addSubscription(pack.id, variant.id, price.id)">Choose
                      this plan
                    </button>
                  </div>
                  <hr v-if="index !== pack.variants.length - 1" class="subscription-plan__divider">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="tab === 'custom'">
          <div class="col-md-12">
            <div class="box box-primary box-solid">
              <div class="box-header">
                <h3 class="box-title">Create a custom package</h3>
              </div>
              <div class="box-body">
                <subscription-form :packages="packages"
                                   v-model="form"
                                   @validated="addCustomSubscription"
                                   :free-trial="object === 'user'"></subscription-form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12" v-else>
        <div class="alert alert-warning">There are currently no subscription packages available for this {{ object
          }}
        </div>
      </div>
    </section>
  </wrapper>
</template>

<script>
import _ from 'lodash';
import api from '@/api';
import Wrapper from '@/components/Wrapper';
import SubscriptionForm from '@/components/forms/SubscriptionForm';
import SubscriptionMixin from '@/mixins/SubscriptionMixin';
import Form from '@/classes/Form';

export default {
  name: 'add-subscription',
  mixins: [SubscriptionMixin],
  components: {
    SubscriptionForm,
    Wrapper,
  },
  data() {
    return {
      pageReady: false,
      packages: [],
      tab: 'predefined',
      form: new Form({
        package_id: null,
        max_devices: '',
        payment_amount: '',
        renewal_duration_length: '',
        renewal_duration_unit: 'day',
      }),
      object: null,
      objectId: null,
    };
  },
  mounted() {
    this.object = this.$route.meta.object;
    this.objectId = this.$route.params.id;
    this.getAvailableSubscriptions();
  },
  methods: {
    getAvailableSubscriptions() {
      let url = '';
      if (this.object === 'school') {
        url = `admin/enterprises/${this.objectId}/available_subscriptions`;
      } else if (this.object === 'user') {
        url = `admin/users/${this.objectId}/available_subscriptions`;
      }
      api.get(url)
        .then((response) => {
          this.packages = response.data.packages;
          this.pageReady = true;
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
        });
    },
    addSubscription(packageId, packageVariantId, packageVariantPriceId) {
      this.$store.dispatch('app/setGlobalLoading', true);
      let url = '';
      if (this.object === 'school') {
        url = `admin/enterprises/${this.objectId}/subscriptions`;
      } else if (this.object === 'user') {
        url = `admin/users/${this.objectId}/subscriptions`;
      }
      api.post(url, {
        package_id: packageId,
        package_variant_id: packageVariantId,
        package_variant_price_id: packageVariantPriceId,
      })
        .then(() => {
          this.$router.back();
        })
        .catch((error) => {
          this.$store.dispatch('app/setGlobalLoading', false);
          this.$apiResponse.renderErrorMessage(error);
        });
    },
    addCustomSubscription() {
      this.$store.dispatch('app/setGlobalLoading', true);
      let url = '';
      if (this.object === 'school') {
        url = `admin/enterprises/${this.objectId}/subscriptions`;
      } else if (this.object === 'user') {
        url = `admin/users/${this.objectId}/subscriptions`;
      }
      this.form.max_readers = 10;
      api.post(url, this.form)
        .then(() => {
          this.$router.back();
        })
        .catch((error) => {
          this.$store.dispatch('app/setGlobalLoading', false);
          this.$apiResponse.renderErrorMessage(error);
        });
    },
    show(what) {
      this.tab = what;
    },
  },
  computed: {
    filteredPackages() {
      return _.filter(this.packages, { has_subscription: false });
    },
  },
};
</script>
