import Chart from './classes/Chart';
import ChartConfig from './classes/ChartConfig';
import ChartCanvas from './components/Chart';

export default {
  install(Vue) {
    Vue.component('chart', ChartCanvas);
  },
};

export { Chart, ChartCanvas, ChartConfig };
