import {
  fetchUsers as fetchUsersAPI, fetchUserById as fetchUserByIdAPI, deleteUser as deleteUserAPI,
  handleUserChallenge as handleUserChallengeAPI, handleUserActions as handleUserActionsAPI,
  getUserMailLogs as getUserMailLogsAPI,
} from '@/api/users';
import api from '@/api';

const defaultServerParams = {
  columnFilters: {},
  sort: [
    {
      field: 'created_at',
      type: 'desc',
    },
  ],
  page: 1,
  perPage: 10,
};

export default ({
  namespaced: true,
  state: () => ({
    user: null,
    users: [],
    totalRecords: 0,
    serverParams: { ...defaultServerParams },
    tableLoaded: false,
  }),
  mutations: {
    setUser(state, payload) {
      const { user } = payload;
      state.user = user;
    },
    setUsers(state, payload) {
      const { users } = payload;
      const { total_filtered: totalFiltered } = payload.meta;
      state.totalRecords = totalFiltered;
      state.users = users.map((u) => {
        if (u.subscription === null) {
          u.subscription = 'None';
        } else {
          const durationUnit = u.subscription.renewal_duration_unit.substring(0, 1).toUpperCase();
          const paymentProvider = (u.subscription.payment_provider[0].toUpperCase() + u.subscription.payment_provider.slice(1)).replace('Itunes', 'iTunes');
          u.subscription = `${paymentProvider}-${u.subscription.renewal_duration_length}${durationUnit}`;
        }
        return u;
      });
      state.tableLoaded = true;
    },
    setServerParams(state, payload) {
      const { challenge } = payload;
      state.serverParams = { ...state.serverParams, ...payload };
      if (challenge === 'all') {
        delete state.serverParams.challenge;
      }
    },
    destroyTableState(state) {
      state.users = [];
      state.totalRecords = 0;
      state.serverParams = { ...defaultServerParams };
      state.tableLoaded = false;
    },
  },
  actions: {
    fetchUsers({ commit }, payload) {
      return new Promise((resolve, reject) => {
        fetchUsersAPI(payload).then((response) => {
          commit('setUsers', response);
          resolve(response);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    fetchUserById({ commit }, payload) {
      const { id, params } = payload;
      return new Promise((resolve, reject) => {
        fetchUserByIdAPI(id, params).then((response) => {
          commit('setUser', response);
          resolve(response);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    updateUser({ commit }, payload) {
      const { userId, data, params } = payload;
      return new Promise((resolve, reject) => {
        api.post(`admin/users/${userId}`, data, { params }).then((response) => {
          commit('setUser', response.data);
          resolve(response.data.user);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    setServerParams({ commit }, payload) {
      commit('setServerParams', payload);
    },
    destroyTableState({ commit }) {
      commit('destroyTableState');
    },
    handleUserChallenge(context, payload) {
      const { id, data } = payload;
      return new Promise((resolve, reject) => {
        handleUserChallengeAPI(id, data).then((response) => {
          resolve(response);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    handleUserActions(context, payload) {
      const { id, action } = payload;
      return new Promise((resolve, reject) => {
        handleUserActionsAPI(id, action).then((response) => {
          resolve(response);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    getUserMailLogs(context, id) {
      return new Promise((resolve, reject) => {
        getUserMailLogsAPI(id).then((response) => {
          resolve(response);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    deleteUser(context, payload) {
      const { id } = payload;
      return new Promise((resolve, reject) => {
        deleteUserAPI(id).then((response) => {
          resolve(response);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    fetchUserReader(context, payload) {
      return new Promise((resolve, reject) => {
        const { userId, readerId } = payload;

        api.get(`admin/users/${userId}/readers/${readerId}`).then((response) => {
          const { reader } = response.data;
          resolve(reader);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    updateUserReader(context, payload) {
      return new Promise((resolve, reject) => {
        const { userId, readerId, data } = payload;

        api.post(`admin/users/${userId}/readers/${readerId}`, data).then(() => {
          resolve(1);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    deleteUserReader(context, payload) {
      return new Promise((resolve, reject) => {
        const { userId, readerId } = payload;

        api.delete(`admin/users/${userId}/readers/${readerId}`).then(() => {
          resolve(1);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    fetchUserReaderSessions(context, payload) {
      return new Promise((resolve, reject) => {
        const { userId, readerId } = payload;

        api.get(`admin/users/${userId}/readers/${readerId}/sessions`).then((response) => {
          const { sessions } = response.data;
          resolve(sessions);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    sendCertificateAction(context, payload) {
      return new Promise((resolve, reject) => {
        const { userId, readerId, data } = payload;
        api.post(
          `admin/users/${userId}/readers/${readerId}/actions`,
          data,
          { responseType: 'arraybuffer' },
        ).then((response) => {
          resolve(response.data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    associateReaderToOrg(context, payload) {
      return new Promise((resolve, reject) => {
        const { challengeSlug, readerId, data } = payload;

        api.post(`admin/challenges/${challengeSlug}/readers/${readerId}/associate-with-org`, data).then(() => {
          resolve(1);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    syncZoho(context, payload) {
      return new Promise((resolve, reject) => {
        const { userId, data } = payload;
        api.post(`admin/users/${userId}/actions`, data).then(() => {
          resolve(1);
        }).catch((err) => {
          reject(err);
        });
      });
    },
  },
});
