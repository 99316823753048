var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("wrapper", [
    _vm.recording
      ? _c("section", { staticClass: "recording-page" }, [
          (_vm.recording.transcription || "") === ""
            ? _c("div", { staticClass: "text-right mb-5" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: { click: _vm.togglePriority }
                  },
                  [
                    !_vm.recording.is_high_priority
                      ? _c("span", [
                          _c("i", { staticClass: "fa fa-square-o" }),
                          _vm._v(" Highlight for transcription")
                        ])
                      : _c("span", [
                          _c("i", { staticClass: "fa fa-check-square-o" }),
                          _vm._v(" Unhighlight for transcription")
                        ])
                  ]
                )
              ])
            : _vm._e(),
          _c("div", { staticClass: "row row-flex" }, [
            _c("div", { staticClass: "col-md-7" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "box box-info" }, [
                    _c("div", { staticClass: "box-header" }, [
                      _c("h3", { staticClass: "box-title" }, [
                        _vm._v("Basic Information")
                      ])
                    ]),
                    _c("div", { staticClass: "box-body" }, [
                      _c("table", { staticClass: "table table-striped" }, [
                        _c("tbody", [
                          _c("tr", [
                            _c("th", { staticClass: "cell-35" }, [
                              _vm._v("Book")
                            ]),
                            _c("td", [
                              _c("span", [
                                _vm._v(_vm._s(_vm.recording.book.title))
                              ]),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "badge ml-3",
                                  attrs: {
                                    href:
                                      "https://cms.auris.tech/books/" +
                                      _vm.recording.book.id +
                                      "/edit",
                                    target: "_blank"
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-external-link mr-1"
                                  }),
                                  _vm._v(" Open in CMS")
                                ]
                              ),
                              _c("br"),
                              _c("span", [
                                _vm._v(
                                  "Age range: " +
                                    _vm._s(_vm.recording.book.age_range) +
                                    " | Reading Level: " +
                                    _vm._s(_vm.recording.book.reading_level)
                                )
                              ])
                            ])
                          ]),
                          _vm.canSeePII
                            ? _c("tr", [
                                _c("th", { staticClass: "cell-30" }, [
                                  _vm._v("Reader")
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(_vm.recording.reader_name) +
                                      " (" +
                                      _vm._s(
                                        _vm.recording.reader_location_name ||
                                          "Unknown Location"
                                      ) +
                                      ")"
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _c("tr", [
                            _c("th", { staticClass: "cell-30" }, [
                              _vm._v("Age")
                            ]),
                            _c("td", [_vm._v(_vm._s(_vm.recording.reader_age))])
                          ]),
                          _c("tr", [
                            _c("th", { staticClass: "cell-30" }, [
                              _vm._v("Date Recorded")
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.recording.recorded_at))
                            ])
                          ]),
                          _c("tr", [
                            _c("th", { staticClass: "cell-30" }, [
                              _vm._v("Source")
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.recording.upload_source_user))
                            ])
                          ]),
                          _c("tr", [
                            _c("th", { staticClass: "cell-30" }, [
                              _vm._v("Wav Key")
                            ]),
                            _c(
                              "td",
                              { on: { click: _vm.copyTextFromElementEvent } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.recording.common_key +
                                      "__" +
                                      _vm.recording.book.id
                                  )
                                )
                              ]
                            )
                          ]),
                          _c("tr", [
                            _vm.reader_recordings_count.total !== null
                              ? _c("td", { attrs: { colspan: "2" } }, [
                                  _vm._v(
                                    _vm._s(_vm.reader_recordings_count.total) +
                                      " recordings by this reader"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    _vm._s(
                                      _vm.reader_recordings_count.transcribed
                                    ) + " have been transcribed so far"
                                  )
                                ])
                              : _c("td", { attrs: { colspan: "2" } }, [
                                  _vm._v(
                                    "Recording counts unavailable - Reader profile has been deleted"
                                  )
                                ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _vm.recording.session
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("div", { staticClass: "box box-info" }, [
                        _c("div", { staticClass: "box-header" }, [
                          _c("h3", { staticClass: "box-title" }, [
                            _vm._v("Session Information")
                          ])
                        ]),
                        _c("div", { staticClass: "box-body" }, [
                          _c("table", { staticClass: "table table-striped" }, [
                            _c("tbody", [
                              _c("tr", [
                                _c("th", { staticClass: "cell-30" }, [
                                  _vm._v("Reading Status")
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.recording.session.has_completed_book
                                        ? "Book Completed"
                                        : "Page " +
                                            _vm.recording.session.last_page +
                                            " of " +
                                            _vm.recording.book.pages_count
                                    )
                                  )
                                ])
                              ]),
                              _vm.recording.session.has_completed_book
                                ? _c("tr", [
                                    _c("th", { staticClass: "cell-30" }, [
                                      _vm._v("Accuracy")
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.recording.session
                                            .full_book_accuracy
                                        ) + "%"
                                      )
                                    ])
                                  ])
                                : _c("tr", [
                                    _c("th", { staticClass: "cell-30" }, [
                                      _vm._v("Projected Accuracy")
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.recording.session
                                            .range_progressive_accuracy
                                        ) + "%"
                                      )
                                    ])
                                  ]),
                              _c("tr", [
                                _c("th", { staticClass: "cell-30" }, [
                                  _vm._v("Stars")
                                ]),
                                _c(
                                  "td",
                                  [
                                    _vm._l(_vm.stars, function(star) {
                                      return [
                                        _c("img", {
                                          key: star,
                                          attrs: {
                                            alt: "",
                                            width: "25",
                                            src:
                                              "https://d3vlekh17wu64n.cloudfront.net/email_assets/stars_gold.png"
                                          }
                                        })
                                      ]
                                    }),
                                    _vm._l(_vm.greyStars, function(star) {
                                      return [
                                        _c("img", {
                                          key: "g" + star,
                                          attrs: {
                                            alt: "",
                                            width: "25",
                                            src:
                                              "https://d3vlekh17wu64n.cloudfront.net/email_assets/stars_grey.png"
                                          }
                                        })
                                      ]
                                    })
                                  ],
                                  2
                                )
                              ]),
                              _vm.recording.session.words_correct_per_minute
                                ? _c("tr", [
                                    _c("th", { staticClass: "cell-30" }, [
                                      _vm._v("Words correct per minute")
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.recording.session
                                            .words_correct_per_minute
                                        )
                                      )
                                    ])
                                  ])
                                : _vm._e()
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "box box-info" }, [
                    _c("div", { staticClass: "box-header" }, [
                      _c("h3", { staticClass: "box-title" }, [
                        _vm._v("Manual Metadata")
                      ])
                    ]),
                    _c("div", { staticClass: "box-body" }, [
                      _c(
                        "form",
                        {
                          ref: "recordingAttributesForm",
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.saveMetadata($event)
                            }
                          }
                        },
                        [
                          _c(
                            "table",
                            {
                              staticClass: "table table-striped mb-0",
                              attrs: { id: "recordingAttributesTbl" }
                            },
                            [
                              _c("tbody", [
                                _c("tr", [
                                  _c("th", { staticClass: "cell-30" }, [
                                    _vm._v("Verified Child Voice?")
                                  ]),
                                  _c("td", [
                                    _c("div", { staticClass: "radio" }, [
                                      _c("label", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.is_verified_child,
                                              expression: "is_verified_child"
                                            }
                                          ],
                                          attrs: {
                                            type: "radio",
                                            name: "is_verified_child"
                                          },
                                          domProps: {
                                            value: true,
                                            checked: _vm._q(
                                              _vm.is_verified_child,
                                              true
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              _vm.is_verified_child = true
                                            }
                                          }
                                        }),
                                        _vm._v("Yes, this is a child reading")
                                      ])
                                    ]),
                                    _c("div", { staticClass: "radio" }, [
                                      _c("label", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.is_verified_child,
                                              expression: "is_verified_child"
                                            }
                                          ],
                                          attrs: {
                                            type: "radio",
                                            name: "is_verified_child"
                                          },
                                          domProps: {
                                            value: false,
                                            checked: _vm._q(
                                              _vm.is_verified_child,
                                              false
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              _vm.is_verified_child = false
                                            }
                                          }
                                        }),
                                        _vm._v("No, it is "),
                                        _c("b", [_c("u", [_vm._v("not")])]),
                                        _vm._v(" a child reading")
                                      ])
                                    ])
                                  ])
                                ]),
                                _c("tr", [
                                  _c("th", { staticClass: "cell-30" }, [
                                    _vm._v("Background Noise")
                                  ]),
                                  _c("td", [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-12 mb-4" },
                                        [
                                          _c("b", [_vm._v("Level")]),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.bg_noise_level,
                                                  expression: "bg_noise_level"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.bg_noise_level = $event
                                                    .target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "option",
                                                { domProps: { value: 0 } },
                                                [
                                                  _vm._v(
                                                    "0 - No background noise at all"
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "option",
                                                { domProps: { value: 1 } },
                                                [
                                                  _vm._v(
                                                    "1 - Low volume noise but can still clearly understand reader"
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "option",
                                                { domProps: { value: 2 } },
                                                [
                                                  _vm._v(
                                                    "2 - Down the middle, can hear most of what is said"
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "option",
                                                { domProps: { value: 3 } },
                                                [
                                                  _vm._v(
                                                    "3 - Can barely hear the reader due to noise"
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-12 mb-4" },
                                        [
                                          _c("b", [_vm._v("Consistency")]),
                                          _c("div", { staticClass: "radio" }, [
                                            _c("label", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.bg_noise_consistent,
                                                    expression:
                                                      "bg_noise_consistent"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "radio",
                                                  name: "bg_noise_consistent"
                                                },
                                                domProps: {
                                                  value: true,
                                                  checked: _vm._q(
                                                    _vm.bg_noise_consistent,
                                                    true
                                                  )
                                                },
                                                on: {
                                                  change: function($event) {
                                                    _vm.bg_noise_consistent = true
                                                  }
                                                }
                                              }),
                                              _vm._v(
                                                "Consistent noise at this level throughout the majority of the recording."
                                              )
                                            ])
                                          ]),
                                          _c("div", { staticClass: "radio" }, [
                                            _c("label", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.bg_noise_consistent,
                                                    expression:
                                                      "bg_noise_consistent"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "radio",
                                                  name: "bg_noise_consistent"
                                                },
                                                domProps: {
                                                  value: false,
                                                  checked: _vm._q(
                                                    _vm.bg_noise_consistent,
                                                    false
                                                  )
                                                },
                                                on: {
                                                  change: function($event) {
                                                    _vm.bg_noise_consistent = false
                                                  }
                                                }
                                              }),
                                              _vm._v(
                                                "Inconsistent, such as a parent occasionally banging pots in the kitchen."
                                              )
                                            ])
                                          ])
                                        ]
                                      ),
                                      _c("div", { staticClass: "col-md-12" }, [
                                        _c("b", [_vm._v("Type")]),
                                        _c(
                                          "div",
                                          [
                                            _c("multiselect", {
                                              attrs: {
                                                "tag-placeholder":
                                                  "Add this as new tag",
                                                placeholder:
                                                  "Search or add a tag",
                                                options:
                                                  _vm.bg_noise_tags_options,
                                                multiple: true,
                                                taggable: true
                                              },
                                              on: { tag: _vm.addBGNoiseTag },
                                              model: {
                                                value: _vm.bg_noise_tags,
                                                callback: function($$v) {
                                                  _vm.bg_noise_tags = $$v
                                                },
                                                expression: "bg_noise_tags"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ])
                                  ])
                                ]),
                                _c("tr", [
                                  _c("th", { staticClass: "cell-30" }, [
                                    _vm._v("Reading clarity")
                                  ]),
                                  _c("td", [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.reading_clarity,
                                            expression: "reading_clarity"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        on: {
                                          change: function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.reading_clarity = $event.target
                                              .multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "option",
                                          { domProps: { value: 0 } },
                                          [
                                            _vm._v(
                                              "0 - Barely understand anything they've said"
                                            )
                                          ]
                                        ),
                                        _c(
                                          "option",
                                          { domProps: { value: 1 } },
                                          [
                                            _vm._v(
                                              "1 - You can make it out, but takes a few rounds of listening"
                                            )
                                          ]
                                        ),
                                        _c(
                                          "option",
                                          { domProps: { value: 2 } },
                                          [
                                            _vm._v(
                                              "2 - Mostly clear, but not quite top marks"
                                            )
                                          ]
                                        ),
                                        _c(
                                          "option",
                                          { domProps: { value: 3 } },
                                          [_vm._v("3 - Clearly spoken")]
                                        )
                                      ]
                                    )
                                  ])
                                ]),
                                _c("tr", [
                                  _c("th", { staticClass: "cell-30" }, [
                                    _vm._v("Reading fluency")
                                  ]),
                                  _c("td", [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.reading_fluency,
                                            expression: "reading_fluency"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        on: {
                                          change: function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.reading_fluency = $event.target
                                              .multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "option",
                                          { domProps: { value: 1 } },
                                          [
                                            _vm._v(
                                              "1 - Struggled with many words or relied on blended reading"
                                            )
                                          ]
                                        ),
                                        _c(
                                          "option",
                                          { domProps: { value: 2 } },
                                          [_vm._v("2 - Somewhere in between")]
                                        ),
                                        _c(
                                          "option",
                                          { domProps: { value: 3 } },
                                          [
                                            _vm._v(
                                              "3 - Didn't really struggle maintaining a good pace of reading"
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ]),
                                _c("tr", [
                                  _c("th", { staticClass: "cell-30" }, [
                                    _vm._v("Accent")
                                  ]),
                                  _c(
                                    "td",
                                    [
                                      _c("div", { staticClass: "checkbox" }, [
                                        _c("label", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.has_strong_accent,
                                                expression: "has_strong_accent"
                                              }
                                            ],
                                            attrs: {
                                              type: "checkbox",
                                              name: "accent_strong"
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.has_strong_accent
                                              )
                                                ? _vm._i(
                                                    _vm.has_strong_accent,
                                                    null
                                                  ) > -1
                                                : _vm.has_strong_accent
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$a = _vm.has_strong_accent,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.has_strong_accent = $$a.concat(
                                                        [$$v]
                                                      ))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.has_strong_accent = $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        ))
                                                  }
                                                } else {
                                                  _vm.has_strong_accent = $$c
                                                }
                                              }
                                            }
                                          }),
                                          _vm._v(
                                            "Has a strong & noteworthy regional accent"
                                          )
                                        ])
                                      ]),
                                      _vm.has_strong_accent
                                        ? _c("multiselect", {
                                            attrs: {
                                              "tag-placeholder":
                                                "Add this as new accent type",
                                              placeholder:
                                                "Select or create an accent",
                                              options:
                                                _vm.reader_accent_group_options,
                                              multiple: false,
                                              taggable: true
                                            },
                                            on: { tag: _vm.addAccentName },
                                            model: {
                                              value: _vm.reader_accent_group,
                                              callback: function($$v) {
                                                _vm.reader_accent_group = $$v
                                              },
                                              expression: "reader_accent_group"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]),
                                _c("tr", [
                                  _c("th", { staticClass: "cell-30" }, [
                                    _vm._v("Tags")
                                  ]),
                                  _c("td", [
                                    _c(
                                      "div",
                                      [
                                        _c("multiselect", {
                                          attrs: {
                                            "tag-placeholder":
                                              "Add this as new tag",
                                            placeholder: "Search or add a tag",
                                            options: _vm.tags_options,
                                            multiple: true,
                                            taggable: true
                                          },
                                          on: { tag: _vm.addTag },
                                          model: {
                                            value: _vm.tags,
                                            callback: function($$v) {
                                              _vm.tags = $$v
                                            },
                                            expression: "tags"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c("small", [
                                      _vm._v(
                                        "Optionally tag the batch name/number, or something important about the recording so you can find it later"
                                      )
                                    ])
                                  ])
                                ]),
                                _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-right",
                                      attrs: { colspan: "2" }
                                    },
                                    [
                                      _c(
                                        "loading-button",
                                        {
                                          attrs: {
                                            cssClasses: "btn btn-primary",
                                            isDisabled: !_vm.metadataDidChange,
                                            isLoading: _vm.isLoading
                                          }
                                        },
                                        [_vm._v("Save Metadata")]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ])
                            ]
                          )
                        ]
                      )
                    ])
                  ])
                ])
              ])
            ]),
            _c(
              "div",
              { staticClass: "col-md-5", attrs: { id: "audioPlayerColumn" } },
              [
                _c(
                  "div",
                  {
                    ref: "audioPlayerContainer",
                    class: { "text-center": true, float: !_vm.showQrCode },
                    attrs: { id: "audioPlayerContainer" }
                  },
                  [
                    _c("audio-player", {
                      attrs: { src: _vm.wavFile, allowDownload: true },
                      on: {
                        loadUrl: function($event) {
                          return _vm.getSignedUrls()
                        }
                      }
                    }),
                    _c("qrcode", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showQrCode,
                          expression: "showQrCode"
                        }
                      ],
                      ref: "qrcode",
                      staticClass: "qrcode",
                      attrs: {
                        value: "id:" + _vm.recording.id,
                        options: { width: 200, margin: 0 }
                      }
                    })
                  ],
                  1
                ),
                _c("div", {
                  ref: "qrcodeIntersector",
                  attrs: { id: "qrcodeIntersector" }
                })
              ]
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "box box-info" }, [
                _c("div", { staticClass: "box-header" }, [
                  _c("h3", { staticClass: "box-title" }, [
                    _vm._v("Transcription")
                  ])
                ]),
                _c("div", { staticClass: "box-body" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.transcription,
                        expression: "transcription"
                      }
                    ],
                    attrs: {
                      id: "transcriptionInput",
                      "data-gramm": "false",
                      autocomplete: "off",
                      autocorrect: "off",
                      autocapitalize: "off",
                      spellcheck: "false"
                    },
                    domProps: { value: _vm.transcription },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.transcription = $event.target.value
                      }
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "loading-button",
                        {
                          attrs: {
                            cssClasses: "btn btn-primary btn-lg",
                            isDisabled:
                              _vm.recording.transcription === _vm.transcription,
                            isLoading: _vm.isLoading
                          },
                          on: { click: _vm.saveTranscription }
                        },
                        [_vm._v("Save Transcription")]
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }