<template>
  <table class="dvt">
    <template v-for="periodKey in ['this_week', 'this_month', 'last_7_days', 'last_30_days']">
      <tr :key="periodKey" class="dvt__row" v-if="periods[periodKey]">
        <td class="dvt__period">{{labelForPeriod(periodKey)}}</td>
        <td class="dvt__num">{{format_numtype(periods[periodKey].current_value)}}</td>
        <td class="dvt__compare_icon"><i class="fa fa-exchange-alt"></i></td>
        <td class="dvt__prev_num">{{format_numtype(getPreviousValue(periods[periodKey]))}}</td>
        <td :class="'dvt__perc dvt__perc--'+(getChangePercentage(periods[periodKey]) < 0 ? 'down' : 'up')">{{format_number(getChangePercentage(periods[periodKey]))}}%</td>
      </tr>
    </template>
  </table>
</template>

<script>
import moment from 'moment';
import { capitalize } from 'lodash';

export default {
  name: 'DashBoxVelocityTable',
  components: { },
  props: {
    periods: {
      required: true,
    },
    compareToLastYear: {
      required: true,
      type: Boolean,
    },
    numType: {
      default: 'number',
    },
  },
  computed: {
    weekday_number() {
      return moment().isoWeekday();
    },
  },
  methods: {
    format_number(n) {
      return Number.isNaN(n) ? n : n.toLocaleString('en');
    },
    format_seconds(n) {
      return moment.duration(n, 'seconds').format('D[d] H[h] m[m]');
    },
    format_numtype(n) {
      return this.numType === 'seconds' ? this.format_seconds(n) : this.format_number(n);
    },
    getChangePercentage(period) {
      const cur = period.current_value;
      const prev = this.getPreviousValue(period);
      if (prev === 0) { return Infinity; }
      return Math.round(((cur - prev) / prev) * 100);
    },
    getPreviousValue(period) {
      return this.compareToLastYear ? period.last_year_value : period.previous_value;
    },
    labelForPeriod(periodKey) {
      return capitalize(periodKey.replaceAll('_', ' '));
    },
  },
};
</script>

<style lang="scss">
$dashbox-color-bad: rgb(211, 31, 31);
$dashbox-color-good: rgb(20, 165, 56);

.dvt {
  margin-top: 1em;
  font-size: 0.9em;
  width: 100%;

  &__row {
    // display:flex;
    // flex-direction: row;
    // align-items: center;
    // justify-content: space-between;
    &:nth-child(even) {
      background: #f5f5f5;
    }
    > * {
      padding: 0.2em 0.5em;
      white-space: nowrap;

    }
  }

  &__compare_icon {
    color: #cecece;
    opacity: 1;
    text-shadow: 1px 1px rgb(255, 255, 255);
    width:15%;
    text-align: center;
    i { margin: 0; }
    // flex: 0 0 auto;
  }

  &__period {
    // font-style: italic;
    width: 40%;
    font-weight: 500;
    // flex: 1 0 35%;
    // white-space: nowrap;
  }

  &__num {
    // flex: 1 0 auto;
    margin-right: 1em;
    width:10%;
    font-weight: 500;
    font-family: 'Roboto Mono';
  }
  &__prev_num {
    // flex: 1 0 auto;
    margin-right: 0.2em;
    width:10%;
    text-align: right;
    font-family: 'Roboto Mono';
  }

  &__perc {
    // flex: 0 0 auto;
    font-style: italic;
    text-align: right;
    font-family: 'Roboto Mono';
  }

  &__perc--down, &__perc--up {
    &:before {
      font-family: 'FontAwesome';
      font-style: normal;
      vertical-align: middle;
      margin-right: 0.2em;
      font-size: 0.75em;
    }
  }
  &__perc--down {
    color: $dashbox-color-bad;
    &:before {
      content: '\F063';
    }
  }
  &__perc--up {
    color: $dashbox-color-good;
    &:before {
      content: '\F062';
    }
  }

  // &__table {
  //   font-size: 0.85em;
  //   margin: 1em -.25em 0 -.25em;
  //   tr:nth-child(even) > td { background: #eee; }
  //   tr > td { white-space: nowrap; padding-top:0.2em; padding-bottom: 0.2em; padding-left: 1em;}
  //   tr > td:first-child { width: 100%; padding-left: 0.25em; }
  //   tr > td:last-child { width: 100%; padding-right: 0.25em; }

  //   i.fa {
  //     margin-right:.2em;
  //     vertical-align: baseline;
  //     color: #aaa;
  //     font-size: 0.75em;
  //   }
  // }
}
</style>
