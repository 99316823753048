<template>
  <wrapper>
    <section class="edit-school-page" v-if="school">
      <div class="row">
        <div class="col-md-12">
          <div class="box box-info">
            <div class="box-body">
              <school-form v-model="school" @validated="editSchool" @updated="handleUpdate"></school-form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </wrapper>
</template>

<script>
import { mapState } from 'vuex';
import Wrapper from '@/components/Wrapper';
import SchoolForm from '@/components/forms/SchoolForm';
import SchoolMixin from '@/mixins/SchoolMixin';

export default {
  name: 'edit-school',
  mixins: [SchoolMixin],
  components: {
    SchoolForm,
    'school-form': SchoolForm,
    Wrapper,
  },
  data() {
    return {
      school: null,
    };
  },
  computed: {
    ...mapState({
      currentSchool: (state) => state.schools.currentSchool,
    }),
  },
  mounted() {
    if (!this.currentSchool) {
      this.$store.dispatch('app/setGlobalLoading', true);
      this.$store.dispatch('schools/getSchoolById', this.$route.params.schoolId).then(() => {
        this.school = this.currentSchool.getFormObject();
      }).finally(() => {
        this.$store.dispatch('app/setGlobalLoading', false);
      });
    } else {
      this.school = this.currentSchool.getFormObject();
    }
  },
  methods: {
    handleUpdate(newData) {
      this.school = newData;
    },
    editSchool() {
      this.$store.dispatch('app/setGlobalLoading', true);
      const schoolData = {
        id: this.currentSchool.id,
        data: {
          id: this.school.id,
          name: this.school.name,
          urn: this.school.urn,
        },
      };
      let { billingContact } = this.school;
      if (this.school.billingSameAsPrimary) {
        billingContact = { ...this.school.primaryContact, type: 'billing', id: null };
      }
      const contactsData = {
        id: this.currentSchool.id,
        data: {
          contact: [
            billingContact,
            { ...this.school.primaryContact, type: 'primary' },
          ],
        },
      };
      this.$store.dispatch('schools/updateSchool', schoolData)
        .then(() => this.$store.dispatch('schools/updateSchoolContacts', contactsData))
        .then(() => {
          this.$store.dispatch('schools/schoolModified');
          this.$router.replace(`/schools/${this.currentSchool.id}`);
        }).catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
          // eslint-disable-next-line
        }).finally(() => {
          this.$store.dispatch('app/setGlobalLoading', false);
        });
    },
  },
};
</script>
