var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "wrapper",
    [
      _vm.modal.isVisible("deleteUser")
        ? _c(
            "modal",
            {
              attrs: {
                title: "Delete " + _vm.user.full_name,
                "show-footer": false
              },
              on: {
                close: function($event) {
                  _vm.modal.hide("deleteUser")
                  _vm.deletionString = null
                }
              }
            },
            [
              _c("p", { staticClass: "text-danger lead" }, [
                _vm._v("This user will be permanently deleted. "),
                _c("br"),
                _vm._v(" This action is irreversible.")
              ]),
              _c("div", { staticClass: "input-group width-100" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.deletionString,
                      expression: "deletionString"
                    }
                  ],
                  staticClass: "form-control width-100",
                  attrs: {
                    name: "full_name",
                    placeholder:
                      "Enter '" + _vm.user.full_name + "' to confirm",
                    type: "text",
                    autocomplete: "off"
                  },
                  domProps: { value: _vm.deletionString },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.deletionString = $event.target.value
                    }
                  }
                })
              ]),
              _c(
                "div",
                { staticClass: "modal-footer" },
                [
                  _vm._t("footer"),
                  _c(
                    "loading-button",
                    {
                      staticClass: "btn btn-warning",
                      attrs: { type: "button", "data-dismiss": "modal" },
                      on: {
                        click: function($event) {
                          _vm.modal.hide("deleteUser")
                          _vm.deletionString = null
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "loading-button",
                    {
                      staticClass: "btn btn-primary btn-danger",
                      attrs: {
                        type: "button",
                        "data-dismiss": "modal",
                        disabled: _vm.deletionString !== _vm.user.full_name
                      },
                      on: { click: _vm.deleteUser }
                    },
                    [_vm._v("Delete Permanently")]
                  )
                ],
                2
              )
            ]
          )
        : _vm._e(),
      _vm.modal.isVisible("syncZoho")
        ? _c(
            "modal",
            {
              attrs: { title: "Zoho sync", "show-footer": false },
              on: {
                close: function($event) {
                  return _vm.modal.hide("syncZoho")
                }
              }
            },
            [
              _c("p", { staticClass: "lead" }, [
                _vm._v(
                  "The sync is happening. Please do not re-click on the button."
                )
              ])
            ]
          )
        : _vm._e(),
      _vm.modal.isVisible("reset-dates")
        ? _c(
            "modal",
            {
              attrs: { title: "Modify Dates", "show-footer": false },
              on: {
                close: function($event) {
                  return _vm.modal.hide("reset-dates")
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "modal__reset-dates" },
                [
                  _c("DatePickerWithDropdown", {
                    attrs: {
                      placeholder: "Show data for...",
                      reference: "time_period",
                      initialDateRange: _vm.dateRange,
                      "max-date": _vm.maxDate,
                      "show-drop-down": false,
                      mode: "dateTime"
                    },
                    on: {
                      "date-range-change": function($event) {
                        return _vm.handleChangeDateRange($event)
                      }
                    },
                    model: {
                      value: _vm.dateRange,
                      callback: function($$v) {
                        _vm.dateRange = $$v
                      },
                      expression: "dateRange"
                    }
                  }),
                  _c("div", { staticClass: "mt-3 mb-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default mr-2",
                        staticStyle: { width: "100%" },
                        on: { click: _vm.resetChallengeDates }
                      },
                      [_vm._v("Reset start date to today")]
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "modal-footer" },
                    [
                      _vm._t("footer"),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          on: {
                            click: function($event) {
                              return _vm.handleUserChallenge(
                                _vm.currentChallengeSlug,
                                "reset-dates"
                              )
                            }
                          }
                        },
                        [_vm._v("Save")]
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.user
        ? _c("section", { staticClass: "user-page" }, [
            _c(
              "div",
              {
                staticClass:
                  "flex justify-content-between align-items-center mb-6"
              },
              [
                _c(
                  "h2",
                  { staticClass: "m-0" },
                  [
                    _vm._v(_vm._s(_vm.user.full_name) + " "),
                    _vm.user.country
                      ? _c("country-flag", {
                          attrs: { country: _vm.user.country, size: "normal" }
                        })
                      : _vm._e(),
                    _c("br"),
                    _c("small", [
                      _c("i", { staticClass: "fa fa-envelope" }),
                      _vm._v(_vm._s(_vm.user.email))
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "page-actions" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        on: {
                          click: function($event) {
                            return _vm.$router.push(_vm.user.id + "/edit")
                          }
                        }
                      },
                      [_vm._v("Update account details")]
                    ),
                    _c("confirmation-button", {
                      attrs: {
                        "show-modal":
                          _vm.showConfirmationModal["reset-password"],
                        "hide-modal-footer": true
                      },
                      on: {
                        close: function($event) {
                          _vm.showConfirmationModal["reset-password"] = false
                        },
                        click: _vm.handleResetPassword
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button",
                            fn: function() {
                              return [_vm._v("Reset password")]
                            },
                            proxy: true
                          },
                          {
                            key: "modal",
                            fn: function() {
                              return [
                                _c("p", [
                                  _vm._v(
                                    "An email containing a password reset link will be emailed to this user. Press continue below to send that email"
                                  )
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "flex justify-content-end" },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-success",
                                        attrs: { disabled: _vm.resetLoading },
                                        on: {
                                          click: _vm.handleResetPasswordContinue
                                        }
                                      },
                                      [_vm._v("Continue")]
                                    )
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3108594260
                      )
                    }),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        on: {
                          click: function($event) {
                            return _vm.modal.show("deleteUser")
                          }
                        }
                      },
                      [_vm._v("Delete account")]
                    ),
                    _vm.user.user_type === "enterprise"
                      ? _c(
                          "button",
                          { staticClass: "btn", on: { click: _vm.syncZoho } },
                          [_vm._v("Sync Zoho")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            ),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "box box-info mb-6" }, [
                  _c("div", { staticClass: "box-body" }, [
                    _c("table", { staticClass: "table table-striped" }, [
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [_vm._v("Registered")]),
                          _c("td", [
                            _vm.user.created_at
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatDate(_vm.user.created_at, true)
                                    )
                                  )
                                ])
                              : _c("span", [_vm._v("Unknown")])
                          ])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Last Seen")]),
                          _c("td", [
                            _vm.user.last_seen_at
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatDate(
                                        _vm.user.last_seen_at,
                                        true
                                      )
                                    )
                                  )
                                ])
                              : _c("span", [_vm._v("Unknown")])
                          ])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Digest Opted In")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.user.weekly_digest_optin_at ? "Yes" : "No"
                              ) + " "
                            ),
                            _c(
                              "a",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.toggleStatus(
                                      "weekly_digest_optin"
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.user.weekly_digest_optin_at
                                      ? "[opt out]"
                                      : "[opt in]"
                                  )
                                )
                              ]
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Marketing Opted In")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.user.marketing_optin_at ? "Yes" : "No"
                              ) + " "
                            ),
                            _c(
                              "a",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.toggleStatus("marketing_optin")
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.user.marketing_optin_at
                                      ? "[opt out]"
                                      : "[opt in]"
                                  )
                                )
                              ]
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Beta Program")]),
                          _c(
                            "td",
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.user.beta_access_status === "opted_in"
                                    ? "Opted In"
                                    : "Opted Out"
                                ) + " "
                              ),
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.toggleStatus(
                                        "beta_access_status"
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.user.beta_access_status === "opted_in"
                                        ? "[opt out]"
                                        : "[opt in]"
                                    )
                                  )
                                ]
                              ),
                              _vm.user.recordings_count > 0
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        tag: "a",
                                        to:
                                          "/users/" +
                                          this.user.id +
                                          "/recordings/"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "[view " +
                                          _vm._s(_vm.user.recordings_count) +
                                          " recordings]"
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ])
                    ])
                  ])
                ]),
                _vm.user.readers
                  ? _c(
                      "div",
                      [
                        _c("h3", [_vm._v("Reader Profiles")]),
                        _vm.user.readers.length === 0
                          ? _c("div", { staticClass: "alert alert-warning" }, [
                              _vm._v(
                                "The user has not yet created any reader profiles"
                              )
                            ])
                          : _vm._e(),
                        _vm._l(_vm.user.readers, function(reader) {
                          return _c(
                            "box-with-icons",
                            {
                              key: reader.id,
                              staticClass: "reader-profile__wrap",
                              on: {
                                click: function($event) {
                                  return _vm.$router.push(
                                    _vm.user.id + "/readers/" + reader.id
                                  )
                                }
                              }
                            },
                            [
                              _c("div", { staticClass: "reader-profile" }, [
                                _c("img", {
                                  staticClass: "avatar",
                                  attrs: {
                                    alt: "avatar",
                                    src:
                                      "/img/fonetti_avatars/" +
                                      reader.avatar +
                                      ".png"
                                  }
                                }),
                                _c("div", [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.$router.push(
                                            _vm.user.id +
                                              "/readers/" +
                                              reader.id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(reader.name) +
                                          " (" +
                                          _vm._s(reader.username) +
                                          ")"
                                      )
                                    ]
                                  ),
                                  _c("br"),
                                  _vm._v(" Age: " + _vm._s(reader.age) + " ")
                                ])
                              ])
                            ]
                          )
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ]),
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  this.user.challenges
                    ? [
                        _c("h3", { staticClass: "mt-0" }, [
                          _vm._v("Challenges")
                        ]),
                        _vm._l(_vm.userChallenges, function(challenge) {
                          return _c(
                            "box-with-icons",
                            {
                              key: challenge.id,
                              staticClass: "user-challenge",
                              attrs: {
                                icons: challenge.has_joined
                                  ? [
                                      {
                                        bgColor: challenge.in_progress
                                          ? _vm.iconColors.green
                                          : _vm.iconColors.red,
                                        icon: challenge.in_progress
                                          ? "hourglass"
                                          : "ban"
                                      }
                                    ]
                                  : []
                              }
                            },
                            [
                              challenge.has_joined
                                ? _c("div", { staticClass: "joined" }, [
                                    _c("div", [
                                      _c("p", { staticClass: "flex gap-3" }, [
                                        _c("strong", [
                                          _vm._v(_vm._s(challenge.name))
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "small",
                                          {
                                            staticClass: "badge bg-light-blue"
                                          },
                                          [_vm._v(_vm._s(challenge.slug))]
                                        )
                                      ]),
                                      _c("p", { staticClass: "mb-2" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatDate(
                                              challenge.pivot.created_at
                                            )
                                          ) +
                                            " - " +
                                            _vm._s(
                                              _vm.formatDate(
                                                challenge.pivot.ends_at
                                              )
                                            )
                                        )
                                      ]),
                                      challenge.in_progress
                                        ? _c("p", [
                                            _vm._v(
                                              _vm._s(challenge.days_remaining) +
                                                " days remaining"
                                            )
                                          ])
                                        : _vm._e()
                                    ]),
                                    challenge.in_progress
                                      ? _c(
                                          "div",
                                          { staticClass: "actions" },
                                          [
                                            _c("confirmation-button", {
                                              attrs: {
                                                "show-modal":
                                                  _vm.showConfirmationModal[
                                                    "resend-welcome-email"
                                                  ]
                                              },
                                              on: {
                                                close: function($event) {
                                                  _vm.showConfirmationModal[
                                                    "resend-welcome-email"
                                                  ] = false
                                                },
                                                click: function($event) {
                                                  return _vm.handleUserChallenge(
                                                    challenge.slug,
                                                    "resend-welcome-email"
                                                  )
                                                }
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "button",
                                                    fn: function() {
                                                      return [
                                                        _vm._v(
                                                          "Resend Welcome Email"
                                                        )
                                                      ]
                                                    },
                                                    proxy: true
                                                  },
                                                  {
                                                    key: "modal",
                                                    fn: function() {
                                                      return [
                                                        _vm._v(
                                                          "Email has been sent"
                                                        )
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn btn-info",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.startResetDates(
                                                      challenge
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("Modify Dates")]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ])
                                : _c(
                                    "div",
                                    {
                                      staticClass: "user-challenge not_joined"
                                    },
                                    [
                                      _c("p", { staticClass: "flex gap-3" }, [
                                        _c("strong", [
                                          _vm._v(_vm._s(challenge.name))
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "small",
                                          {
                                            staticClass: "badge bg-light-blue"
                                          },
                                          [_vm._v(_vm._s(challenge.slug))]
                                        )
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "actions" },
                                        [
                                          _c(
                                            "loading-button",
                                            {
                                              staticClass: "btn btn-info",
                                              on: {
                                                click: function($event) {
                                                  return _vm.handleUserChallenge(
                                                    challenge.slug,
                                                    "enrol"
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Register into challenge")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                            ]
                          )
                        })
                      ]
                    : _vm._e(),
                  this.user.subscriptions
                    ? [
                        _c("h3", [
                          _vm._v(" Subscriptions "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: {
                                click: function($event) {
                                  return _vm.$router.push(
                                    "/users/" +
                                      _vm.user.id +
                                      "/subscriptions/new"
                                  )
                                }
                              }
                            },
                            [_vm._v("Add free subscription")]
                          )
                        ]),
                        _vm._l(_vm.userSubscriptions, function(subscription) {
                          return _c(
                            "box-with-icons",
                            {
                              key: subscription.id,
                              staticClass: "user-subscription",
                              attrs: {
                                top: 5,
                                icons: [
                                  {
                                    bgColor: subscription.status_icon_bg,
                                    icon: subscription.status_icon,
                                    tooltip: subscription.status_tooltip
                                  },
                                  {
                                    bgColor: subscription.provider_icon_bg,
                                    icon: subscription.provider_icon,
                                    tooltip: subscription.provider_tooltip
                                  }
                                ]
                              }
                            },
                            [
                              _c("p", { staticClass: "title" }, [
                                _vm._v(_vm._s(subscription.title) + " "),
                                !subscription.has_expired
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "badge badge-success",
                                        staticStyle: {
                                          "margin-bottom": "3px",
                                          "margin-left": "5px"
                                        }
                                      },
                                      [_vm._v("subscribed")]
                                    )
                                  : _vm._e()
                              ]),
                              _c("p", { staticClass: "mb-2" }, [
                                subscription.payment_provider !== "manual"
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(subscription.duration_string) +
                                          " | "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(_vm._s(subscription.devices)),
                                subscription.payment_provider !== "manual"
                                  ? _c("span", [
                                      _vm._v(
                                        " | " +
                                          _vm._s(subscription.payment_amount)
                                      )
                                    ])
                                  : _vm._e()
                              ]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatDate(subscription.start_date)
                                  ) +
                                    " - " +
                                    _vm._s(
                                      _vm.formatDate(subscription.expires_at)
                                    )
                                )
                              ]),
                              _c("small", [
                                _vm._v(
                                  "Subscription ID: " +
                                    _vm._s(
                                      subscription.payment_provider_subscription_id
                                    )
                                )
                              ])
                            ]
                          )
                        })
                      ]
                    : _vm._e(),
                  _c("h3", [_vm._v("Mail Logs")]),
                  _vm.hideMailLogs
                    ? _c("div", { staticClass: "user-mail-logs__idle" }, [
                        _c("a", { on: { click: _vm.getUserMailLogs } }, [
                          _vm._v("Fetch entries")
                        ])
                      ])
                    : _c("div", { staticClass: "user-mail-logs" }, [
                        _c(
                          "table",
                          { staticClass: "table table-striped table-bordered" },
                          [
                            _c("thead", [
                              _c("tr", [
                                _c("th", [_vm._v("ID")]),
                                _c("th", [_vm._v("Subject")]),
                                _c("th", [_vm._v("Sent On")]),
                                _c("th")
                              ])
                            ]),
                            _c(
                              "tbody",
                              _vm._l(this.mailLogs, function(log, k) {
                                return _c("tr", { key: k }, [
                                  _c(
                                    "td",
                                    [
                                      _c("confirmation-button", {
                                        attrs: {
                                          "show-modal":
                                            _vm.showConfirmationModal[
                                              "copy-to-clipboard"
                                            ]
                                        },
                                        on: {
                                          close: function($event) {
                                            _vm.showConfirmationModal[
                                              "copy-to-clipboard"
                                            ] = false
                                          },
                                          click: function($event) {
                                            return _vm.startCopyToClipboard(
                                              log.id
                                            )
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "button",
                                              fn: function() {
                                                return [_vm._v("#")]
                                              },
                                              proxy: true
                                            },
                                            {
                                              key: "modal",
                                              fn: function() {
                                                return [
                                                  _vm._v(
                                                    "Copied to clipboard: " +
                                                      _vm._s(log.id)
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  ),
                                  _c("td", [_vm._v(_vm._s(log.subject))]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatDate(log.date, true, true)
                                      )
                                    )
                                  ]),
                                  _c("td", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "wrap",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      _vm._l(log.statuses, function(status, k) {
                                        return _c(
                                          "span",
                                          {
                                            key: k,
                                            staticClass: "badge badge-pill",
                                            class: _vm.getMailLogBadgeClass(
                                              status
                                            )
                                          },
                                          [_vm._v(_vm._s(status))]
                                        )
                                      }),
                                      0
                                    )
                                  ])
                                ])
                              }),
                              0
                            )
                          ]
                        )
                      ])
                ],
                2
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }