export default ({
  methods: {
    getCurrentGroup(groups, id) {
      return groups.find((group) => group.id === id || group.id.toString() === id.toString());
    },
    async loadPageHeadings(description = '') {
      // load page headings for specific pages
      if (this.$route && this.$route.params.schoolId) {
        // current school from store in case something's already set
        let { currentSchool } = this.$store.state.schools;

        // actual school being accessed in the page
        const { schoolId: pageSchoolId } = this.$route.params;

        // check if store school and page school is the same
        if (currentSchool && currentSchool.id === pageSchoolId) {
          // if true just get the store school's name
          this.$store.dispatch('app/setPageHeading', currentSchool.name);
        } else {
          // else fetch and set the store school to page school
          currentSchool = await this.$store.dispatch('schools/getSchoolById', pageSchoolId);
          // set page heading to page school name
          this.$store.dispatch('app/setPageHeading', currentSchool.name);
        }

        // check if the current page is class specific
        if (this.$route.params.classId) {
          const { classId } = this.$route.params;
          const currentClass = this.getCurrentGroup(currentSchool.groups, classId);
          this.$route.meta.description = `${currentClass.name} - ${description}`;
        } else {
          this.$route.meta.description = description;
        }
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!to.path.includes('schools')) {
      this.$store.dispatch('schools/destroyTableState');
    }
    if (to.name === 'Schools' || !to.path.includes('schools')) {
      this.$store.dispatch('schools/destroyCurrentSchool');
    }
    next(true);
  },
});
