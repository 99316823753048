import FreeTrialSubscriptionView from '@/views/users/FreeTrialSubscription';
import EditUserView from '@/views/users/Edit';
import UserSubscriptionsView from '@/views/UserSubscriptions';
import UsersView from '@/views/users/Users';
import UserView from '@/views/users/User';
import UserReaderView from '@/views/users/Reader';
import RecordingsView from '@/views/Recordings';

export default ([
  {
    path: '/users/:userid',
    component: UserView,
    name: 'User',
    meta: {
      description: 'Manage the account of this user',
      requiresAuth: true,
      context: 'user',
      authorize: ['users'],
    },
  },
  {
    path: '/users/:userid/edit',
    component: EditUserView,
    name: 'Account',
    meta: {
      description: 'Manage the account of this user',
      requiresAuth: true,
      context: 'user',
      authorize: ['users'],
    },
  },
  {
    path: '/users/:userid/recordings',
    component: RecordingsView,
    name: 'User Recordings',
    meta: {
      description: 'View the recordings of this user',
      requiresAuth: true,
      context: 'user',
      authorize: ['users'],
    },
  },
  {
    path: '/users/:userid/readers/:readerid',
    component: UserReaderView,
    name: 'Reader Profile',
    meta: {
      description: 'View the profile of this reader',
      requiresAuth: true,
      context: 'user',
      authorize: ['users'],
    },
  },
  {
    path: '/users',
    component: UsersView,
    name: 'Users',
    meta: { description: 'View the standard users', requiresAuth: true, authorize: ['users'] },
  },
  {
    path: '/users/:id/subscriptions',
    component: UserSubscriptionsView,
    name: 'User Subscriptions',
    meta: { description: 'View this user subscriptions', requiresAuth: true, authorize: ['users'] },
  },
  {
    path: '/users/:id/subscriptions/new',
    component: FreeTrialSubscriptionView,
    name: 'Add User Subscription',
    meta: {
      description: 'Add a subscription for the user',
      requiresAuth: true,
      object: 'user',
      authorize: ['users'],
    },
  },
]);
