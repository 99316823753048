<template>
  <wrapper>
    <section class="schools-page">
      <modal :title="`Delete ${itemToBeDeleted.name}`" v-if="modal.isVisible('deleteItem')" @close="cancelDeleteItem" :show-footer="false">
        <p class="text-danger lead">This {{itemToBeDeleted.type}} will be permanently deleted. <br/> This action is irreversible.</p>
         <div class="input-group width-100">
          <input class="form-control width-100" name="first_name" :placeholder="`Enter '${itemToBeDeleted.name}' to confirm`" v-model="itemToBeDeleted.inputString" type="text" autocomplete="off">
        </div>
        <div class="modal-footer">
          <slot name="footer"></slot>
          <button type="button" class="btn btn-warning" data-dismiss="modal" @click="cancelDeleteItem">Cancel</button>
          <button type="button" class="btn btn-primary btn-danger" data-dismiss="modal" @click="deleteItem(deleteItemApiCall)" :disabled="itemToBeDeleted.inputString !== itemToBeDeleted.name">Delete Permanently</button>
        </div>
      </modal>
      <div class="create-school-button-locator mb-3">
        <button @click="exportSchools" class="btn btn-primary mr-3"><i v-if="isExporting" class="fa fa-spinner fa-spin"></i>Export</button>
        <button class="btn btn-primary" @click="$router.push('/schools/create')">
          Create new school
        </button>
      </div>

      <div class="headline-stats mb-5" v-if="metaData">
        <DashBox id="dashbox-total-schools" title="Total Schools" :value="metaData.total" />
        <DashBox id="dashbox-subscribed-schools" title="Subscribed Schools" :value="metaData.num_subscribed" />
        <DashBox id="dashbox-unsubscribed-schools" title="Unsubscribed Schools" :value="metaData.num_unsubscribed" />
        <DashBox id="dashbox-ending-schools" title="Subscriptions Ending" :value="metaData.num_subscriptions_ending" />
      </div>

      <div class="row">
        <div class="col-md-12 mb-5">
          <div class="user-filters">
            <div class="user-filter user-filter__break" :class="{'disabled' : !enabledReadingTimeDateRange}">
              <div class="label-container">
                <label for="drp-registered-checkbox">Reading Time Between:</label>
              </div>
              <div class="col-md-5">
                <date-range-picker-with-toggle
                  :minDate="new Date(2018,0,1)"
                  :maxDate="endOfToday"
                  :dateRange="readingTimeDateRange"
                  @date-range-change="readingTimeDateRange = $event"
                  @picker-enabled-change="enabledReadingTimeDateRange = $event"
                />
              </div>
            </div>
            <div class="user-filter user-filter__break" :class="{'disabled' : !enabledCreatedOnDateRange}">
              <div class="label-container">
                <label for="drp-registered-checkbox">Created On:</label>
              </div>
              <div class="col-md-5">
                <date-range-picker-with-toggle
                  :minDate="new Date(2018,0,1)"
                  :maxDate="endOfToday"
                  :dateRange="createdOnDateRange"
                  @date-range-change="createdOnDateRange = $event"
                  @picker-enabled-change="enabledCreatedOnDateRange = $event"
                />
              </div>
            </div>
            <div class="user-filter user-filter__break" :class="{'disabled' : !enabledExpiresOnDateRange}">
              <div class="label-container">
                <label for="drp-registered-checkbox">Expires on:</label>
              </div>
              <div class="col-md-5">
                <date-range-picker-with-toggle
                  :minDate="new Date(2018,0,1)"
                  :maxDate="endOfToday"
                  :dateRange="expiresOnDateRange"
                  @date-range-change="expiresOnDateRange = $event"
                  @picker-enabled-change="enabledExpiresOnDateRange = $event"
                />
              </div>
            </div>
            <div class="user-filter user-filter__break" :class="{'disabled' : !enabledLatestActivityDateRange}">
              <div class="label-container">
                <label for="drp-registered-checkbox">Latest Activity:</label>
              </div>
              <div class="col-md-5">
                <date-range-picker-with-toggle
                  :minDate="new Date(2018,0,1)"
                  :maxDate="endOfToday"
                  :dateRange="latestActivityDateRange"
                  @date-range-change="latestActivityDateRange = $event"
                  @picker-enabled-change="enabledLatestActivityDateRange = $event"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div style="display: flex; gap: 20px;">
          <div class="user-filters">
            <div class="user-filter user-filter__break">
              <div class="label-container">
                <label>Subscription Status:</label>
              </div>
              <select name="subscriptionStatus" id="subscriptionStatus" v-model="subscriptionStatus">
                <option value="any">Any</option>
                <option value="subscribed">Subscribed</option>
                <option value="not_subscribed">Not Subscribed</option>
              </select>
            </div>
          </div>
          <multiselect
              :options="availableTags"
              v-model="selectedSchoolTags"
              :multiple="true"
              label="name"
              track-by="code"
              placeholder="Filter by tags"
          />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
            <vue-good-table
              styleClass="vgt-table striped"
              theme="auris-admin"
              :columns="columns"
              :rows="filteredRows"
              :totalRows="totalRecords"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'bottom',
                perPageDropdown: [10, 25, 50, 100],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
              }"
              :search-options="{
                enabled: true,
                trigger: 'key-up',
                skipDiacritics: true,
                placeholder: 'Search this table',
              }"
              :sort-options="{
                enabled: true,
                initialSortBy: {field: 'name', type: 'asc'}
              }"
              :isLoading.sync="isLoading"
            >
            <template slot="table-row" slot-scope="props">
              <button v-if="props.column.field == 'actions'" @click="goToSchoolPage($event)" class="btn btn-link user-link" :data-id="props.row.id"><i class="fa fa-edit"></i> Manage</button>
              <button v-if="props.column.field == 'actions'" @click="beginDeleteItem({name: props.row.name, id: props.row.id, type: 'school'})" class="btn btn-link delete" :data-id="props.row.id"><i class="fa fa-trash"></i>Delete</button>
              <span v-if="props.column.field == 'subscription.expires_at'" class="wrap">
                <span :class="getCellClass(props.row)">
                  {{ props.formattedRow[props.column.field] ? null : 'N/A' }}
                </span>
              </span>
              <span v-if="props.column.field == 'latest_activity'" class="wrap">
                {{timeAgo(props.row.latest_activity)}}<br><small><i>{{props.formattedRow[props.column.latest_activity]}}</i></small>
              </span>
              <span v-else-if="props.column.field == 'latest_reader_activity'" class="wrap">
                {{timeAgo(props.row.latest_reader_activity)}}<br><small><i>{{props.formattedRow[props.column.latest_reader_activity]}}</i></small>
              </span>
              <span v-else-if="props.column.field == 'reading_time.total_reading_time'" class="wrap">
                {{formatTime(props.row.reading_time.total_reading_time)}}<br><small><i class="fa fa-user"></i></small>{{props.row.reading_time.readers_count}}
              </span>
              <span v-else>
                {{props.formattedRow[props.column.field]}}
              </span>
            </template>
            <template slot="loadingContent">
              <div class="loadingContent">
                  <i class="fa fa-refresh fa-spin"></i>
              </div>
            </template>
            </vue-good-table>
          </div>
        </div>

    </section>
  </wrapper>
</template>

<script>
import Multiselect from 'vue-multiselect';
import Wrapper from '@/components/Wrapper';
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';
import moment from 'moment';
import DateRangePickerWithToggle from '@/components/DateRangePickerWithToggle';
import DeleteItemMixin from '@/mixins/DeleteItemMixin';
import ModalJS from '@/components/utils/Modal';
import Modal from '@/classes/Modal';
import HelpersMixin from '@/mixins/HelpersMixin';
import DashBox from '@/components/DashBox';
import { mapState } from 'vuex';

export default {
  name: 'schools',
  mixins: [HelpersMixin, DeleteItemMixin],
  components: {
    VueGoodTable,
    Wrapper,
    DateRangePickerWithToggle,
    Multiselect,
    ModalJS, // eslint-disable-line
    modal: ModalJS,
    DashBox,
  },
  data() {
    return {
      filteredRows: [],
      isLoading: false,
      isExporting: false,
      endOfToday: moment().endOf('day').toDate(),
      columns: [
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Teachers',
          field: 'users_count',
          type: 'number',
        },
        {
          label: 'Classes',
          field: 'groups_count',
          type: 'number',
        },
        {
          label: 'Pupils',
          field: 'readers_count',
          type: 'number',
        },
        {
          label: 'Reading Time',
          field: 'reading_time.total_reading_time',
          type: 'number',
          formatFn: this.formatTime,
        },
        {
          label: 'Created',
          field: 'created_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'do MMM yyyy',
          width: '10%',
          sortable: true,
        },
        {
          label: 'Subscribed Until',
          field: 'subscription.expires_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'do MMM yyyy',
          width: '10%',
          sortable: true,
          tdClass: (row) => (row.subscription?.has_expired ? 'has-error' : ''),
        },
        {
          label: 'Latest Activity (User)',
          field: 'latest_activity',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'do MMM yyyy',
          width: '10%',
          sortable: true,
        },
        {
          label: 'Latest Activity (Reader)',
          field: 'latest_reader_activity',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'do MMM yyyy',
          width: '10%',
          sortable: true,
        },
        {
          label: 'Actions',
          field: 'actions',
          tdClass: 'details-control',
          sortable: false,
        },
      ],
      totalRecords: 0,
      selectedSchoolTags: [],
      selectedRawSchoolTags: [],
      // start expires on
      enabledExpiresOnDateRange: false,
      expiresOnDateRange: {
        start: null,
        end: null,
      },
      // end expires on

      // start latest activity
      enabledLatestActivityDateRange: false,
      latestActivityDateRange: {
        start: null,
        end: null,
      },
      // end latest activity

      // start created on
      enabledCreatedOnDateRange: false,
      createdOnDateRange: {
        start: null,
        end: null,
      },
      // end created on

      // start reading time
      enabledReadingTimeDateRange: false,
      readingTimeDateRange: {
        start: null,
        end: null,
      },
      // end reading time
      subscriptionStatus: 'any',
      modal: new Modal({
        deleteItem: false,
      }),
    };
  },
  computed: {
    ...mapState({
      schools: (state) => state.schools.schools,
      metaData: (state) => state.schools.metaData,
      availableTags: (state) => state.schools.availableTags,
      schoolModified: (state) => state.schools.schoolModified,
    }),
  },
  mounted() {
    this.$store.commit('SET_CURRENT_USER', null);
    this.$store.commit('SET_CURRENT_SCHOOL', null);
    this.$store.dispatch('app/setGlobalLoading', true);
    this.$nextTick(() => {
      if (this.schools.length === 0 || this.schoolModified) {
        this.getReadingData();
      } else {
        this.filteredRows = Object.assign(this.schools);
      }
      if (this.availableTags.length === 0) {
        this.getAvailableTagsData();
      }
    });
  },
  methods: {
    exportSchools() {
      this.isExporting = true;
      // format payload
      const data = {
        reading_time_between: this.enabledReadingTimeDateRange ? this.readingTimeDateRange : null,
        created_at: this.enabledCreatedOnDateRange ? this.createdOnDateRange : null,
        expires_at: this.enabledExpiresOnDateRange ? this.expiresOnDateRange : null,
        latest_activity_at: this.enabledLatestActivityDateRange ? this.latestActivityDateRange : null,
        subscription_status: this.subscriptionStatus,
        tags: this.selectedSchoolTags,
      };

      // send api request
      this.$store.dispatch('schools/exportSchools', data).then((response) => {
        this.downloadFile(response, 'schools_data.csv');
      }).catch((error) => {
        this.$apiResponse.renderErrorMessage(error);
      }).finally(() => {
        this.isExporting = false;
      });
    },
    deleteItemApiCall() {
      const id = parseInt(this.itemToBeDeleted.id, 10);
      this.$store.dispatch('schools/deleteSchoolById', id).then((response) => {
        this.getReadingData();
        this.$apiResponse.renderSuccessMessage(response, 'The school has been deleted.');
      }).catch((error) => {
        this.$apiResponse.renderErrorMessage(error);
      }).finally(() => {
        this.cancelDeleteItem();
      });
    },
    getDateRangeString() {
      return '';
    },
    formatTime(totalSeconds) {
      if (totalSeconds < 1) {
        return 'None';
      }
      const seconds = Math.floor(totalSeconds % 60);
      const minutes = Math.floor((totalSeconds / 60) % 60);
      const hours = Math.floor((totalSeconds / (60 * 60)) % 24);
      const days = Math.floor((totalSeconds / (60 * 60 * 24)) % 7);
      const weeks = Math.floor(totalSeconds / (60 * 60 * 24 * 7));
      const secondsString = (seconds < 2) ? `${seconds}s` : `${seconds}s`;
      const minutesString = (minutes < 2) ? `${minutes}m ` : `${minutes}m `;
      const hoursString = (hours < 2) ? `${hours}h ` : `${hours}h `;
      const daysString = (days < 2) ? `${days}d ` : `${days}d `;
      const weeksString = (weeks < 2) ? `${weeks}w ` : `${weeks}w `;

      const returnvalue = `${weeks > 0 ? weeksString : ''}${days > 0 ? daysString : ''}${hours > 0 ? hoursString : ''}${minutes > 0 && weeks < 1 ? minutesString : ''}${seconds > 0 && days < 1 ? secondsString : ''}`;
      return returnvalue;
    },
    readingTimeDateRangeUpdated(range) {
      if (range) {
        this.getReadingData();
      }
    },
    getReadingData() {
      this.$store.dispatch('app/setGlobalLoading', true);
      this.isLoading = true;
      let data;
      if (this.enabledReadingTimeDateRange) {
        data = { start_date: this.readingTimeDateRange.start, end_date: this.readingTimeDateRange.end };
      } else {
        data = null;
      }
      this.$store.dispatch('schools/getSchools', data).catch((error) => {
        this.$apiResponse.renderErrorMessage(error);
      }).finally(() => {
        this.applyFilters();
        this.isLoading = false;
        this.$store.dispatch('app/setGlobalLoading', false);
      });
    },
    getAvailableTagsData() {
      this.$store.dispatch('app/setGlobalLoading', true);
      this.isLoading = true;
      this.$store.dispatch('schools/getAvailableTags').catch((error) => {
        this.$apiResponse.renderErrorMessage(error);
      }).finally(() => {
        this.isLoading = false;
        this.$store.dispatch('app/setGlobalLoading', false);
      });
    },
    goToSchoolPage(e) {
      const id = e.target.getAttribute('data-id');
      this.$router.push(`/schools/${id}`);
    },
    formatDate(value) {
      return value != null ? moment(value).format('Do MMM yyyy') : null;
    },
    getCellClass(row) {
      if (row.subscription) {
        return row.subscription.has_expired ? 'is-red' : '';
      }
      return '';
    },
    timeAgo(str) {
      if (!str) { return 'Never'; }
      return moment(str).fromNow();
    },
    applyFilters() {
      this.filteredRows = this.schools.filter((r) => {
        const conditionsArray = [];
        if (this.enabledExpiresOnDateRange && r.subscription && r.subscription.expires_at && this.expiresOnDateRange && this.expiresOnDateRange.start && this.expiresOnDateRange.end) {
          const expiresAtCondition = this.getDateRangeFilterCondition({ date: r.subscription.expires_at, range: this.expiresOnDateRange });
          conditionsArray.push(expiresAtCondition);
        }
        if (this.enabledLatestActivityDateRange && this.latestActivityDateRange && this.latestActivityDateRange.start && this.latestActivityDateRange.end) {
          let latestActivityCondition;
          if (r.latest_activity) {
            latestActivityCondition = this.getDateRangeFilterCondition({ date: r.latest_activity, range: this.latestActivityDateRange });
          } else {
            latestActivityCondition = false;
          }
          conditionsArray.push(latestActivityCondition);
        }
        if (this.enabledCreatedOnDateRange && r.created_at && this.createdOnDateRange && this.createdOnDateRange.start && this.createdOnDateRange.end) {
          const createdAtCondition = this.getDateRangeFilterCondition({ date: r.created_at, range: this.createdOnDateRange });
          conditionsArray.push(createdAtCondition);
        }
        if (this.subscriptionStatus === 'subscribed') {
          const subscriptionCondition = r.subscription && !moment(r.subscription.expires_at).isBefore(moment());
          conditionsArray.push(subscriptionCondition);
        } else if (this.subscriptionStatus === 'not_subscribed') {
          const subscriptionCondition = !r.subscription || r.subscription.has_expired || moment(r.subscription.expires_at).isBefore(moment());
          conditionsArray.push(subscriptionCondition);
        }
        if (this.enabledReadingTimeDateRange) {
          const condition = r.reading_time.total_reading_time > 0;
          conditionsArray.push(condition);
        }

        if (this.selectedSchoolTags.length > 0) {
          const tagsCondition = r.tags && this.selectedRawSchoolTags.every((t) => Object.prototype.hasOwnProperty.call(r.tags, t));
          conditionsArray.push(tagsCondition);
        }

        return conditionsArray.every((value) => value === true);
      });
    },
    getDateRangeFilterCondition({ date, range: { start, end } }) {
      if (!date) {
        return false;
      }
      const mDate = moment(date);
      const mStart = moment(start).startOf('day');
      const mEnd = moment(end).endOf('day');
      return mDate.isAfter(mStart) && mDate.isBefore(mEnd);
    },

  },
  watch: {
    schools(newVal) {
      this.filteredRows = newVal;
      this.applyFilters();
    },
    subscriptionStatus() {
      this.applyFilters();
    },
    selectedSchoolTags() {
      this.selectedRawSchoolTags = this.selectedSchoolTags.map((tag) => tag.code);
      this.applyFilters();
    },
    enabledExpiresOnDateRange() {
      if (!this.enabledExpiresOnDateRange || (this.expiresOnDateRange && this.expiresOnDateRange.start && this.expiresOnDateRange.end)) {
        this.applyFilters();
      }
    },
    enabledLatestActivityDateRange() {
      if (!this.enabledReadingTimeDateRange || (this.readingTimeDateRange && this.readingTimeDateRange.start && this.readingTimeDateRange.end)) {
        this.applyFilters();
      }
    },
    enabledCreatedOnDateRange() {
      if (!this.enabledCreatedOnDateRange || (this.createdOnDateRange && this.createdOnDateRange.start && this.createdOnDateRange.end)) {
        this.applyFilters();
      }
    },
    enabledReadingTimeDateRange() {
      if (!this.enabledReadingTimeDateRange || (this.readingTimeDateRange && this.readingTimeDateRange.start && this.readingTimeDateRange.end)) {
        this.getReadingData();
      }
    },
    createdOnDateRange() {
      this.applyFilters();
    },
    readingTimeDateRange() {
      this.getReadingData();
    },
    expiresOnDateRange() {
      this.applyFilters();
    },
    latestActivityDateRange() {
      this.applyFilters();
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
  .w-4 {
    width: 2rem;
  }

  .mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }
  .h-4 {
    height: 2rem;
  }
  .input-inner {
    border-top: none;
    border-bottom: none;
    border-left: none;
    border-right: none;
    width: 100%;
    padding: 2px 10px 3px 10px;
    line-height: 14px;
    font-size: 11.9px;
    color: rgb(51, 51, 51);
    height: 100%;
    margin-left: 4px;
  }

  .input-inner__right {
    margin-right: 4px;
    margin-left: 0px;
  }

  .headline-stats {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    .dashbox {
      width: auto;
    }
  }

</style>
